import {CustomerModel} from "./customer.model";
import {GuardModel} from "./guard.model";

export class MessageModel{
    id: number;
    tempId: string;
    body: string;
    timePass: number;
    conversationId: number;
    sender: CustomerModel | GuardModel;
    createdAt: string;
    info: boolean;
    isMine: boolean;
    seenAt: string;
}
