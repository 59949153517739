import {EmployeeRoleModel} from "../models/employeeRole.model";
import * as SharedActions from "./shared.actions";
import {CountryModel} from "../models/country.model";
import {CityModel} from "../models/city.model";
import {SkillModel} from "../models/skill.model";
import {UniformModel} from "../models/uniform.model";
import {UtilityOptionModel} from "../models/utilityOption.model";
import {ReportTypeModel} from "../models/reportType.model";
import {PaginationModel} from "../models/pagination.model";
import { NewsModel } from '../models/news.model';
import { StateModel } from '../models/state.model';

export interface State {
    firebaseToken: string;
    employeeRoles: EmployeeRoleModel[];
    countries: CountryModel[];
    states: StateModel[],
    cities: CityModel[];
    skills: SkillModel[];
    pagination: PaginationModel;
    skillsMeta: {
        isMoreSkills: boolean,
        page: number
    },
    skill: SkillModel;
    uniforms: UniformModel[];
    uniform: UniformModel;
    utilityOptions: UtilityOptionModel[];
    utilityOption: UtilityOptionModel;
    reportTypes: ReportTypeModel[];
    loading: boolean;
    news: NewsModel[];
}

const initialState: State = {
    firebaseToken: null,
    employeeRoles: [],
    countries: [],
    states: [],
    skills: [],
    pagination: null,
    skillsMeta: {
        isMoreSkills: false,
        page: 0
    },
    skill: null,
    uniforms: [],
    uniform: null,
    utilityOptions: [],
    utilityOption: null,
    reportTypes: [],
    cities: [],
    loading: false,
    news: [],
};

export function sharedReducer(state = initialState, action: SharedActions.SharedActions): State {
    switch (action.type) {
        case SharedActions.LOAD_STATES_SUCCESS:
            return {
                ...state,
                states: action.payload.states,
            };
        case SharedActions.LOAD_NEWS_SUCCESS:
            return {
                ...state,
                news: action.payload.news,
            };
        case SharedActions.SET_FIREBASE_TOKEN_FOR_PUSH_NOTIFICATIONS:
            return {
                ...state,
                firebaseToken: action.payload,
            };
        case SharedActions.LOAD_EMPLOYEE_ROLES:
            return {
                ...state,
                loading: true
            };
        case SharedActions.LOAD_EMPLOYEE_ROLES_SUCCESS:
            return {
                ...state,
                employeeRoles: action.payload.employeeRoles,
                loading: false
            };
        case SharedActions.LOAD_EMPLOYEE_ROLES_FAILED:
            return {
                ...state,
                loading: false
            };
        case SharedActions.LOAD_COUNTRIES:
            return {
                ...state,
                loading: true,
            };
        case SharedActions.LOAD_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload,
                loading: false,
            };
        case SharedActions.LOAD_COUNTRIES_FAILED:
            return {
                ...state,
                loading: false,
            };
        case SharedActions.LOAD_COUNTRY_CITIES:
            return {
                ...state,
                loading: true
            };
        case SharedActions.LOAD_COUNTRY_CITIES_SUCCESS:
            return {
                ...state,
                cities: action.payload,
                loading: false,
            };
        case SharedActions.LOAD_COUNTRY_CITIES_FAILED:
            return {
                ...state,
                loading: false,
            };
        case SharedActions.LOAD_SKILLS:
            return {
                ...state,
                skillsMeta: {
                    ...state.skillsMeta,
                    page: state.skillsMeta.page + 1,
                    isMoreSkills: false
                },
                loading: true
            };
        case SharedActions.LOAD_SKILLS_SUCCESS:
            return {
                ...state,
                // skills: [...state.skills, ...action.payload.skills],
                skills: action.payload.skills,
                pagination: action.payload.pagination,
                skillsMeta: {
                    ...state.skillsMeta,
                    // isMoreSkills: action.payload.pagination.currentPage != action.payload.pagination.lastPage
                },
                loading: false
            };
        case SharedActions.LOAD_SKILLS_FAILED:
            return {
                ...state,
                loading: false
            };
        case SharedActions.LOAD_SKILL:
            return {
                ...state,
                loading: true
            };
        case SharedActions.LOAD_SKILL_SUCCESS:
            return {
                ...state,
                skill: action.payload.skill,
                loading: false
            };
        case SharedActions.LOAD_SKILL_FAILED:
            return {
                ...state,
                loading: false
            };
        case SharedActions.CLEAR_SKILLS:
            return {
                ...state,
                skills: [],
                pagination: null,
                skillsMeta: {
                    ...state.skillsMeta,
                    page: 0,
                    isMoreSkills: true
                },
            };
        case SharedActions.CREATE_SKILL_SUCCESS:
            return {
                ...state,
                skills: state.skills.map(skill => {
                    if (skill.id == action.payload.parentId) {
                        return {
                            ...skill,
                            children: [...skill.children, action.payload.skill]
                        }
                    } else return skill
                })
            };
        case SharedActions.UPDATE_SKILL_SUCCESS:
            return {
                ...state,
                skill: action.payload.skill
            };
        case SharedActions.LOAD_UNIFORM:
            return {
                ...state,
                loading: true
            };
        case SharedActions.LOAD_UNIFORM_SUCCESS:
            return {
                ...state,
                uniforms: action.payload.uniforms,
                loading: false
            };
        case SharedActions.LOAD_UNIFORM_FAILED:
            return {
                ...state,
                loading: false
            };
        case SharedActions.LOAD_UNIFORM_DETAILS:
            return {
                ...state,
                loading: true
            };
        case SharedActions.LOAD_UNIFORM_DETAILS_SUCCESS:
            return {
                ...state,
                uniform: action.payload.uniform,
                loading: false
            };
        case SharedActions.LOAD_UNIFORM_DETAILS_FAILED:
            return {
                ...state,
                loading: false
            };
        case SharedActions.LOAD_SKILL_UNIFORMS_SUCCESS:
            return {
                ...state,
                uniforms: action.payload.uniforms,
            };
        case SharedActions.LOAD_SKILL_UNIFORM_SUCCESS:
            return {
                ...state,
                uniform: action.payload.uniform,
            };
        case SharedActions.LOAD_UTILITY_OPTIONS:
            return {
                ...state,
                loading: true
            };
        case SharedActions.LOAD_UTILITY_OPTIONS_SUCCESS:
            return {
                ...state,
                utilityOptions: action.payload.utilityOptions,
                loading: false
            };
        case SharedActions.LOAD_UTILITY_OPTIONS_FAILED:
            return {
                ...state,
                loading: false
            };
        case SharedActions.LOAD_SKILL_UTILITY_OPTIONS_SUCCESS:
            return {
                ...state,
                utilityOptions: action.payload.utilityOptions,
            };
        case SharedActions.LOAD_SKILL_UTILITY_OPTION_SUCCESS:
            return {
                ...state,
                utilityOption: action.payload.utilityOption,
            };
        case SharedActions.LOAD_REPORT_TYPES:
            return {
                ...state,
                loading: true
            };
        case SharedActions.LOAD_REPORT_TYPES_SUCCESS:
            return {
                ...state,
                reportTypes: action.payload.reports,
                loading: false
            };
        case SharedActions.LOAD_REPORT_TYPES_FAILED:
            return {
                ...state,
                loading: false
            };
        case SharedActions.CREATE_SKILL_UNIFORM_SUCCESS:
            return {
                ...state,
                uniforms: [...state.uniforms, action.payload.uniform]
            };
        case SharedActions.UPDATE_SKILL_UNIFORM_SUCCESS:
            return {
                ...state,
                uniforms: state.uniforms.map(uniform => {
                    if (uniform.id == action.payload.uniform.id)
                        return action.payload.uniform
                    else return uniform
                })
            };
        case SharedActions.DELETE_SKILL_UNIFORM_SUCCESS:
            return {
                ...state,
                uniforms: state.uniforms.filter(uniform => uniform.id != action.payload.uniform.id)
            };
        case SharedActions.CREATE_SKILL_UTILITY_OPTION_SUCCESS:
            return {
                ...state,
                utilityOptions: [...state.utilityOptions, action.payload.option]
            };
        case SharedActions.UPDATE_SKILL_UTILITY_OPTION_SUCCESS:
            return {
                ...state,
                utilityOptions: state.utilityOptions.map(utilityOption => {
                    if (utilityOption.id == action.payload.option.id)
                        return action.payload.option
                    else return utilityOption
                })
            };
        case SharedActions.DELETE_SKILL_UTILITY_OPTION_SUCCESS:
            return {
                ...state,
                utilityOptions: state.utilityOptions.filter(utilityOption => utilityOption.id != action.payload.option.id)
            };
        default:
            return state;
    }
}
