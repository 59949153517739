import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../../models/customer.model';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../store/app.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { createData } from '../create-object';
import * as AssignmentsActions from '../../store/assignments.actions';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';

import { setCulture } from '@syncfusion/ej2-base';

setCulture(localStorage.getItem('locale'));

@Component({
    selector: 'app-step3',
    templateUrl: './step3.component.html',
    styleUrls: ['./step3.component.scss'],
    providers: [DatePipe],
})
export class Step3Component implements OnInit, OnDestroy {
    minShiftDuration = 5; // in hours
    maxShiftDuration = 13; // in hours

    locale = 'sv';

    handPick: string = '1';
    today = new Date();
    minDatePicker = new Date(
        this.today.getFullYear(),
        this.today.getMonth(),
        // this.today.getDate() + 1,
        this.today.getDate(),
    );

    initDates: Date[] = [];
    multiselect: Boolean = true;
    calendarDates: Date[];
    dates: any[] = [];

    assignmentType: string = '1';
    repeatEvery: string = 'MONTHLY';

    subscription: Subscription;
    customer: CustomerModel;

    recurringPatternForm = this.formBuilder.group({
        freq: 'MONTHLY',
        interval: '1',
        byMonthDay: '1',
    });
    isCreatingAssignmentFromHistory: boolean;

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private formBuilder: FormBuilder,
                public datePipe: DatePipe,
                private router: Router) {
        if (localStorage.getItem('locale')) {
            this.locale = localStorage.getItem('locale');
        }
    }


    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.isCreatingAssignmentFromHistory = appState.assignments.isAssignmentCreatingFromHistory;
        });

        if (createData.isRecurring) {
            this.assignmentType = '2';
            if (createData.recurrencePattern) {
                this.recurringPatternForm.controls['freq'].setValue(createData.recurrencePattern.freq);
                this.recurringPatternForm.controls['interval'].setValue(createData.recurrencePattern.interval);
                this.repeatEvery = this.recurringPatternForm.controls['freq'].value;
                if (createData.recurrencePattern.byMonthDay) {
                    this.recurringPatternForm.controls['byMonthDay'].setValue(createData.recurrencePattern.byMonthDay);
                }
            }
        } else {
            this.assignmentType = '1';
            createData.isRecurring = false;
        }

        if (createData.dates) {
            this.dates = createData.dates.map(el => Object.assign({}, el));
            this.initDates = this.dates.map(x => x.date);
            // this.dates.forEach(date => {
            //     this.initDates.push(this.toDate(date.date));
            // });
        } else {
            // this.initDates = [new Date()];
            // this.dates.push({
            //     date: this.datePipe.transform(new Date(), "YYYY-MM-dd"),
            //     startTime: "16:00",
            //     endTime: "20:00"
            // });
        }
        createData.isManual = createData.isManual ? '1' : '0';
        // if (createData.isManual == true) {
        //     this.handPick = '1';
        // }
        // if (createData.isManual == false) {
        //     this.handPick = '0';
        // }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onChangeCalendar(event: any) {
        this.calendarDates = event;
        // this.dates = [];
        this.calendarDates.forEach(date => {
            // let startAtHours = moment(date).isSame(moment(), 'day')
            //     ? moment().add('5', 'minute').format('HH:mm')
            //     : moment().set('hour').;
            // let endAtHours = moment(date).isSame(moment(), 'day')
            //     ? moment().add('4', 'hour').add('5', 'minute').format('HH:mm')
            //     : '20:00';


            let startTime = moment(date).isSame(moment(), 'day')
                ? moment().add(10, 'minute')
                : moment(date).set('hour', 16).set('minute', 0);
            let endTime = moment(date).isSame(moment(), 'day')
                ? moment().add(5, 'hour').add(10, 'minute')
                : moment(date).set('hour', 21).set('minute', 0);

            // const startAt = date;
            // startAt.setTime(startAtHours);
            // startAt.setMinutes(0);
            //
            // const endAt = date;
            // endAt.setHours(endAtHours);
            // endAt.setMinutes(0);
            // let startAtDate = startAt.toISOString().split('T')[0];
            //
            const isFound = this.dates.some(el => {
                return el.date == startTime.format('YYYY-MM-DD');
            });


            if (!isFound) {
                this.dates.push({
                    // date: this.datePipe.transform(date, 'YYYY-MM-dd'),
                    date: startTime.format('YYYY-MM-DD'),
                    startTime: startTime.format('HH:mm'),
                    endTime: endTime.format('HH:mm'),
                    startAt: startTime,
                    endAt: endTime,
                });
            }
        });

        this.calendarDates = this.calendarDates.sort((a, b) => {
            return moment(a).diff(moment(b), 'd');
        });


        let calendarDatesMapped = this.calendarDates.map(calendarDateItem => {
            return moment(calendarDateItem).format('YYYY-MM-DD');
        });
        this.dates = this.dates.filter(dateItem => {
            return calendarDatesMapped.includes(dateItem.date);
        }).sort((a, b) => {
            return moment(a.date).diff(moment(b.date), 'd');
        });


    }

    public onChangeSelect(event: any): void {
        this.repeatEvery = event;
        switch (this.repeatEvery) {
            case 'MONTHLY':
                createData.recurrencePattern.freq = 'MONTHLY';
                break;
            case 'WEEKLY':
                createData.recurrencePattern.freq = 'WEEKLY';
                delete createData.recurrencePattern.byMonthDay;
                break;
        }
    }

    public onChangeInterval(event: any): void {
        createData.recurrencePattern.interval = event;
    }

    public onChangeMonthDay(event: any): void {
        createData.recurrencePattern.byMonthDay = event;
    }

    public onChangeType(): void {
        switch (this.assignmentType) {
            case '1':
                createData.isRecurring = false;
                delete createData.recurrencePattern;
                break;
            case '2':
                createData.isRecurring = true;
                createData.recurrencePattern = {
                    interval: 1,
                    freq: 'MONTHLY',
                    byMonthDay: 1,
                };
                break;
        }
    }

    public onSetDateStartTime(event: any, date: string) {
        let startAtTime = event.target.value;
        this.dates = this.dates.map(el => {
            if (el.date == date) {
                // let startTime = moment(date).isSame(moment(), 'day')
                //     ? moment().add('10', 'minute').format('HH:mm')
                //     : startAtTime;
                startAtTime = moment(date + ' ' + startAtTime).diff(moment(), 'minute') < 10
                    ? moment().add(10, 'minute').format('HH:mm')
                    : startAtTime;

                event.target.value = startAtTime;
                el.startTime = startAtTime;
                el.startAt = moment(new Date(date + 'T' + startAtTime));
            }
            return el;
        });

    }

    public onSetDateEndTime(event: any, date: string) {
        this.dates = this.dates.map(el => {
            if (el.date == date) {
                let endAt = moment(new Date(date + 'T' + event));
                el.endTime = event;
                el.endAt = endAt.isAfter(moment(el.startAt))
                    ? endAt
                    : endAt.add(1, 'day');
                return el;
            } else return el;
        });
    }

    public onSaveAndContinue(): void {

        if (this.dates.length == 0) {
            this._snackBar.open('Pick assignment dates to continue.', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });
            return;
        }
        let isValid = true;
        this.dates.forEach(date => {
            if (this.calculateTime(date, date.startTime, date.endTime) < this.minShiftDuration) {
                isValid = false;
                return;
            }
            if (this.calculateTime(date, date.startTime, date.endTime) > this.maxShiftDuration) {
                isValid = false;
                return;
            }
        });
        if (!isValid) {
            this._snackBar.open('The time range you have selected is invalid.', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });
            return;
        }

        createData.dates = this.dates;
        createData.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.toString();

        if (this.assignmentType == '2') {
            createData.isManual = false;
            createData.engagementType = 2;
        } else {
            createData.isManual = this.handPick == '1';
            createData.engagementType = this.handPick == '1' ? 1 : 2;
        }

        if (this.isCreatingAssignmentFromHistory) {
            if (!createData.jobPlaceId) {
                this.store.dispatch(new AssignmentsActions.UpdateStep(4));
                return;
            }
            if (!createData.numberOfPersonnel) {
                this.store.dispatch(new AssignmentsActions.UpdateStep(5));
                return;
            }
            this.store.dispatch(new AssignmentsActions.UpdateStep(7));
        } else {
            this.store.dispatch(new AssignmentsActions.UpdateStep(4));
        }
    }

    public toDate(string): Date {
        return new Date(string);
    }

    public NextRecurringEvent(string): Date {
        let nextDate = moment(string);

        switch (createData.recurrencePattern.freq) {
            case 'MONTHLY':
                let firstDateInArray = moment(this.calendarDates[0]);

                let firstNext = moment(firstDateInArray)
                    .add(createData.recurrencePattern.interval, 'M')
                    .set('date', createData.recurrencePattern.byMonthDay);

                let day = nextDate.diff(firstDateInArray, 'd');

                nextDate = firstNext.add(day, 'd');
                break;
            case 'WEEKLY':
                nextDate.add(createData.recurrencePattern.interval, 'w');
                break;
        }

        return nextDate.toDate();
    }

    public calculateTime(date, time1, time2): number {
        if ((!time1 && !time2) || !date) {
            return 4;
        }

        let dateTime1 = this.toDate(date.date + ' ' + time1);
        let dateTime2 = this.toDate(date.date + ' ' + time2);
        let MdateTime1 = moment(dateTime1);
        let MdateTime2 = moment(dateTime2);


        if (MdateTime1.diff(MdateTime2) > 0) {
            MdateTime2.add(1, 'days');
        }

        return Math.floor(MdateTime2.diff(MdateTime1, 'hours', true));
    }

    public calculateTimeHuman(date, time1, time2): string {

        let dateTime1 = this.toDate(date.date + ' ' + time1);
        let dateTime2 = this.toDate(date.date + ' ' + time2);
        let MdateTime1 = moment(dateTime1);
        let MdateTime2 = moment(dateTime2);


        if (MdateTime1.diff(MdateTime2) > 0) {
            MdateTime2.add(1, 'days');
        }

        return moment.duration(MdateTime2.diff(MdateTime1)).format('HH:mm');
    }

}
