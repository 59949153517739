import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from './messages.reducer';
import * as fromApp from '../../../store/app.reducer';
import * as MessagesActions from './messages.actions';
import {environment} from '../../../../environments/environment';
import {catchError, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConversationModel} from "../../../models/conversation.model";
import {PaginationModel} from "../../../models/pagination.model";
import {MessageModel} from "../../../models/message.model";

interface AuthResponseData {
    conversations: ConversationModel[],
    pagination: PaginationModel;
}

@Injectable()
export class MessagesEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private _snackBar: MatSnackBar,
        private store: Store<fromApp.AppState>,
    ) {
    }

    loadConversations = createEffect(() => {
        return this.actions$.pipe(
            ofType(MessagesActions.LOAD_CONVERSATIONS),
            withLatestFrom(this.store.select('messages')),
            switchMap(([action, state]: [MessagesActions.LoadConversations, State]) => {

                let params = new HttpParams();
                params = params.append('page', state.conversationsMeta.page);
                params = params.append('per_page', state.conversationsMeta.perPage);
                if (action.payload.conversationId) {
                    params = params.append('conversationId', action.payload.conversationId);
                }
                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/conversations`, {params})
                    .pipe(
                        switchMap((resData: {
                            data: ConversationModel[],
                            pagination: PaginationModel,
                            totalMessagesUnseenCount: number,
                        }) => {
                            return of(new MessagesActions.LoadConversationsSuccess({
                                conversations: resData.data,
                                pagination: resData.pagination,
                                totalMessagesUnseenCount: resData.totalMessagesUnseenCount
                            }));
                        }),
                        catchError(error => {
                            return of(new MessagesActions.LoadConversationsFailed());
                        }),
                    );
            }),
        );
    });

    loadMessages = createEffect(() => {
        return this.actions$.pipe(
            ofType(MessagesActions.LOAD_MESSAGES),
            withLatestFrom(this.store.select('messages')),
            switchMap(([action, state]: [MessagesActions.LoadMessages, State]) => {

                let params = new HttpParams();
                params = params.append('page', state.messagesMeta.page);
                params = params.append('per_page', state.messagesMeta.perPage);

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/conversations/${action.payload.conversationId}/messages`, {
                        params
                    })
                    .pipe(
                        switchMap((resData: {
                            data: MessageModel[],
                            pagination: PaginationModel,
                            totalMessagesUnseenCount: number
                        }) => {
                            return of(new MessagesActions.LoadMessagesSuccess({
                                messages: resData.data,
                                pagination: resData.pagination,
                                totalMessagesUnseenCount: resData.totalMessagesUnseenCount,
                                conversationId: action.payload.conversationId,
                            }));
                        }),
                        catchError(error => {
                            return of(new MessagesActions.LoadMessagesFailed());
                        }),
                    )
            }),
        );
    });

    sendMessage = createEffect(() => {
        return this.actions$.pipe(
            ofType(MessagesActions.SEND_MESSAGE),
            switchMap((action: MessagesActions.SendMessage) => {

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/conversations/${action.payload.conversationId}/messages`, {
                        body: action.payload.body
                    })
                    .pipe(
                        switchMap((resData: { data: MessageModel }) => {
                            return of(new MessagesActions.SendMessageSuccess({
                                message: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new MessagesActions.SendMessageFailed());
                        }),
                    )
            }),
        );
    });

    messageSeen = createEffect(() => {
        return this.actions$.pipe(
            ofType(MessagesActions.MESSAGE_SEEN),
            switchMap((action: MessagesActions.MessageSeen) => {

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/conversations/${action.payload.conversationId}/messages/${action.payload.messageId}/seen`);
            }),
        );
    }, {dispatch: false});

}


