import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from './../../store/app.reducer';
import * as AuthActions from '../../auth/store/auth.actions';
import { EmployeeModel } from '../../models/employee.model';
import { Subscription } from 'rxjs';
import { AssignmentModel } from '../../models/assignment.model';
import * as AssignmentsActions from '../assignments/store/assignments.actions';
import * as SharedActions from '../../shared-store/shared.actions';
import { NewsModel } from '../../models/news.model';
import { AssignmentStatisticsModel } from '../../models/assignment.statistics.model';
import { CustomerTodoModel } from '../../models/customer.todo.model';
import { CustomerModel } from '../../models/customer.model';
import { Router } from '@angular/router';
import { createData } from '../assignments/create-assignment/create-object';
import { filter, take } from 'rxjs/operators';
import { CustomerTodoActionTypeConst } from '../../constants/customer.todo.action.type.const';
import { ConversationModel } from '../../models/conversation.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {

    customer: CustomerModel;
    employee: EmployeeModel;
    storeSub: Subscription;
    assignmentStatistics: AssignmentStatisticsModel = null;
    customerTodos: CustomerTodoModel[] = [];

    assignments: AssignmentModel[] = [];
    time: any;
    partOfTheDay: string;
    helpOptionsOpened: boolean = false;
    news: NewsModel[] = [];

    constructor(private store: Store<fromApp.AppState>,
                private customerTodoActionTypeConst: CustomerTodoActionTypeConst,
                private router: Router) {
    }

    ngOnInit(): void {
        this.storeSub = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.employee = appState.auth.employee;
            this.assignments = appState.assignments.assignments.filter(item => {
                return item.jobPlace;
            });

            this.assignmentStatistics = appState.assignments.assignmentStatistics;

            this.news = appState.shared.news;
            this.customerTodos = appState.assignments.customerTodos;

        });

        this.time = new Date().getHours();
        if (this.time < 12)
            this.partOfTheDay = 'morning';
        else if (this.time >= 12 && this.time < 18)
            this.partOfTheDay = 'afternoon';
        else if (this.time >= 18)
            this.partOfTheDay = 'evening';
    }

    onTodoItemNavigate(customerTodo: CustomerTodoModel) {
        switch (customerTodo.todoObject.action) {
            case this.customerTodoActionTypeConst.ASSIGNMENT_DRAFT_CREATED:
                this.onCreateFromHistory(customerTodo.todoableId);
                break;

            case this.customerTodoActionTypeConst.SHIFT_APPROVE_HOURS:
                this.router.navigate(customerTodo.todoObject.actionUrl.angular.routerLink, {
                    queryParams: customerTodo.todoObject.actionUrl.angular.queryParams
                });
                break;

            case this.customerTodoActionTypeConst.NEED_TO_CREATE_COMPANY:
                this.router.navigate(customerTodo.todoObject.actionUrl.angular.routerLink, {
                    queryParams: customerTodo.todoObject.actionUrl.angular.queryParams
                });
                break;
        }

        // this.store.dispatch(new AssignmentsActions.DeleteCustomerTodo({
        //     customerTodoId,
        // }));

    }

    onCreateFromHistory(assignmentId): void {
        this.store.dispatch(new AssignmentsActions.ClearAssignment());
        this.store.dispatch(new AssignmentsActions.LoadAssignmentDetails({
            customerId: this.customer.id,
            assignmentsId: assignmentId,
        }));
        this.store.dispatch(new AssignmentsActions.UpdateStep(3));
        this.store.dispatch(new AssignmentsActions.CreateAssignmentFromHistory({
            isFromHistory: true,
        }));


        this.store.select('assignments', 'assignment')
            .pipe(
                filter(assignment => assignment != null),
                take(1)
            )
            .subscribe(assignment => {
                // createData.id = assignment.id;
                createData.assignmentCategoryId = assignment?.assignmentCategory?.id;
                createData.skillId = assignment?.skill?.id;
                createData.uniformId = assignment?.uniform?.id;
                createData.utilityOptionIds = [];
                assignment?.utilityOptions?.forEach(uo => {
                    createData.utilityOptionIds.push(uo.id);
                });
                createData.isRecurring = assignment?.isRecurring;
                if (assignment.isRecurring) {
                    createData.recurrencePattern = assignment?.recurringPattern;
                }
                createData.numberOfLeaders = assignment?.numberOfLeaders;
                createData.numberOfPersonnel = assignment?.numberOfPersonnel;
                createData.description = assignment?.description;
                createData.jobPlaceId = assignment?.jobPlace?.id;
                createData.contactPersonName = assignment?.contactPersonName;
                createData.contactPersonPhone = assignment?.contactPersonPhone;
                createData.dates = [];

                this.router.navigate(['/components/assignments/create-assignment/step3']);
            });
    }

    ngOnDestroy(): void {
        this.storeSub.unsubscribe();
    }
}
