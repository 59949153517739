import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegionModel } from '../../../models/region.model';
import { JobPlaceModel } from '../../../models/jobPlace.model';
import { Subscription } from 'rxjs';
import * as CompanyActions from '../store/company.actions';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { CustomerModel } from '../../../models/customer.model';
import { HereSuggesstionModel } from '../../../models/hereSuggesstion.model';
import { StateModel } from '../../../models/state.model';
import { HereLocationModel } from '../../../models/hereLocation.model';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
// import { MapsAPILoader } from '@agm/core';

@Component({
    selector: 'app-regions',
    templateUrl: './regions.component.html',
    styleUrls: ['./regions.component.scss'],
})
export class RegionsComponent implements OnInit, OnDestroy {

    createRegionModal: boolean = false;
    createObjectModal: boolean = false;
    searchAddressTimeout: any = null;

    objectsTabActive: boolean = true;
    teamTabActive: boolean = true;

    addTeamField: boolean = false;
    addObjectField: boolean = false;
    addRegionField: boolean = false;

    regions: RegionModel[];
    objects: JobPlaceModel[];
    customer: CustomerModel;
    states: StateModel[] = [];

    marker: {
        position: {
            lat: number,
            lng: number,
        }
    } = null;

    center: {
        lat: number,
        lng: number,
    } = null;

    hereLocation: HereLocationModel = null;


    // this.marker.position = {
    //     lat: Number(this.objects[0].lat),
    //     lng: Number(this.objects[0].lng)
    // }
    // this.center = {
    //     lat: Number(this.objects[0].lat),
    //     lng: Number(this.objects[0].lng)
    // }

    objectsInList: JobPlaceModel[] = [];
    objectsInSelectBox: JobPlaceModel[];
    objectInput: '';
    objectSelectList = false;
    filteredObjects: JobPlaceModel[];

    regionsInList: RegionModel[] = [];
    regionsInSelectBox: RegionModel[];
    regionInput: '';
    regionSelectList = false;
    addressSelectList = false;
    filteredRegions: RegionModel[];
    hereSuggestions: HereSuggesstionModel[];
    pickedAddress: any;

    subscription: Subscription;

    createRegionForm = this.formBuilder.group({
        name: ['', [Validators.required]],
    });
    createObjectForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        address: ['', [Validators.required]],
        // state: [new FormControl(), [Validators.required]],
    });
    statesSubscription: Subscription;

    lat: any;
    lng: any;
    zoom = 16;
    private geoCoder;

    @ViewChild('search')
    public searchElementRef: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                // private mapsAPILoader: MapsAPILoader,
                // private ngZone: NgZone,
                private router: Router) {
    }

    ngOnInit(): void {
        // this.mapsAPILoader.load().then(() => {
        //     this.geoCoder = new google.maps.Geocoder;
        //
        //     const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        //     autocomplete.addListener("place_changed", () => {
        //         this.ngZone.run(() => {
        //             //get the place result
        //             const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        //
        //             //verify result
        //             if (place.geometry === undefined || place.geometry === null) {
        //                 return;
        //             }
        //
        //             //set latitude, longitude and zoom
        //             this.lat = place.geometry.location.lat();
        //             this.lng = place.geometry.location.lng();
        //             this.zoom = 12;
        //         });
        //     });
        // });
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.regions = appState.company.regions;
            this.objects = appState.company.objects;
            this.objectsInSelectBox = appState.company.objects;
            this.regionsInSelectBox = appState.company.regions;
            this.hereSuggestions = appState.company.hereSuggestions;

            // if (this.hereSuggestions.length === 0) {
            //     this.marker = null;
            //     this.center = null;
            // }
            //
            // this.hereLocation = appState.company.hereLocation;
            // if (this.hereLocation) {
            //     this.marker = {
            //         position: {
            //             lat: this.hereLocation.lat,
            //             lng: this.hereLocation.lng,
            //         },
            //     };
            //
            //     this.center = {
            //         lat: this.hereLocation.lat,
            //         lng: this.hereLocation.lng,
            //     };
            // }

        });

        // this.statesSubscription = this.store.select('shared', 'states')
        //                               .subscribe(states => {
        //                                   console.log(states);
        //
        //                                   this.states = states;
        //                                   console.log(this.createObjectForm);
        //
        //
        //                                   this.createObjectForm.patchValue({
        //                                       state: this.states[0],
        //                                   });
        //                               });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        // this.statesSubscription.unsubscribe();
    }

    // onCreateRegionClicked(): void {
    //     this.createRegionModal = true;
    //     this.addObjectField = false;
    //     this.addTeamField = false;
    // }

    onAddObject(): void {
        this.addObjectField = true;
        this.objectsInSelectBox = this.objects;
        this.objectInput = '';
    }

    onAddObjectToList(object: JobPlaceModel): void {
        this.objectsInList.push(object);
        this.objectSelectList = false;
        this.addObjectField = false;
    }

    onRemoveObjectFromList(object: JobPlaceModel): void {
        this.objectsInList = this.objectsInList.filter(o => o.id != object.id);
    }

    onCreateRegionSubmit(): void {
        let jobPlacesIds: number[] = [];

        this.objectsInList.forEach(object => {
            jobPlacesIds.push(object.id);
        });

        let data: any = {};
        data.name = this.createRegionForm.get('name').value;
        data.jobPlacesIds = jobPlacesIds;

        this.store.dispatch(new CompanyActions.AddNewRegion({
            customerId: this?.customer?.id,
            data: data,
        }));

        this.createRegionModal = false;
        this.addObjectField = false;
        this.addTeamField = false;
        this.objectSelectList = false;
        this.objectsInList = [];
        this.createRegionForm.reset();
    }


    onCreateObjectClicked(): void {
        this.createObjectModal = true;
        this.addRegionField = false;
        this.addTeamField = false;
    }

    onAddRegion(): void {
        this.addRegionField = true;
        this.regionsInSelectBox = this.regions;
        this.regionInput = '';
    }

    // onAddRegionToList(region: RegionModel): void {
    //     this.regionsInList.push(region);
    //     this.regionSelectList = false;
    //     this.addRegionField = false;
    // }
    //
    // onRemoveRegionFromList(region: RegionModel): void {
    //     this.regionsInList = this.regionsInList.filter(r => r.id != region.id);
    // }

    // onCreateObjectSubmit(): void {
    //     let regionsIds: number[] = [];
    //
    //     this.regionsInList.forEach(object => {
    //         regionsIds.push(object.id);
    //     });
    //
    //     let data: any = {};
    //     data.name = this.createObjectForm.get('name').value;
    //
    //     if (!this.pickedAddress) {
    //         this._snackBar.open('Invalid address', 'Close', {
    //             duration: 3000,
    //             panelClass: ['snackbar'],
    //         });
    //
    //         return;
    //     }
    //     // data.regionsIds = regionsIds;
    //     data.locationId = this.pickedAddress.locationId;
    //     data.stateId = this.createObjectForm.get('state').value.id;
    //
    //     this.store.dispatch(new CompanyActions.AddNewObject({
    //         customerId: this?.customer?.id,
    //         data: data,
    //     }));
    //
    //     this.createObjectModal = false;
    //     this.addRegionField = false;
    //     this.addTeamField = false;
    //     this.regionSelectList = false;
    //     this.regionsInList = [];
    //     this.createObjectForm.reset();
    // }

    onAddTeamMember(): void {
        this.addTeamField = true;
    }

    onObjectsTab(): void {
        this.objectsTabActive = true;
        this.teamTabActive = false;
        this.addTeamField = false;
    }

    onTeamTab(): void {
        this.objectsTabActive = false;
        this.teamTabActive = true;
        this.addObjectField = false;
    }

    keyUpObjects(event: any): void {
        if (event.target.value == '') {
            this.objectsInSelectBox = this.objects;
            return;
        }
        this.objectsInSelectBox = this.objects.filter(object =>
            object.name.includes(event.target.value) || object.address.includes(event.target.value),
        );
    }

    keyUpRegions(event: any): void {
        if (event.target.value == '') {
            this.regionsInSelectBox = this.regions;
            return;
        }
        this.regionsInSelectBox = this.regions.filter(region => region.name.includes(event.target.value));
    }

    keyUpAddress(event: any): void {
        if (event.target.value == '') {
            this.addressSelectList = false;
            return;
        }

        clearTimeout(this.searchAddressTimeout);
        this.searchAddressTimeout = setTimeout(() => {
            let county = this.createObjectForm.get('state').value;
            let address = 'Sverige, ' + county.name + ', ' + event.target.value;
            this.pickedAddress = null;
            this.addressSelectList = true;
            this.center = null;
            this.marker = null;
            this.store.dispatch(new CompanyActions.AddressAutocomplete({
                address,
            }));
        }, 1000);
    }

    onPickAddress(address: HereSuggesstionModel): void {
        this.createObjectForm.controls['address'].setValue(address.label);
        this.addressSelectList = false;
        this.pickedAddress = address;
        if (address.locationId) {
            this.store.dispatch(new CompanyActions.LoadHereLocationByLocationId({
                locationId: address.locationId,
            }));
        }


    }

    onChangeState() {
        this.createObjectForm.patchValue({
            address: null,
        });
        this.pickedAddress = null;
        this.addressSelectList = false;
        this.center = null;
        this.marker = null;
    }

    handleAddressChange(address: any) {
        this.pickedAddress = address;

        this.marker = {
            position: {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng(),
            },
        };

        this.center = {
            lat: address.geometry.location.lat(),
            lng: address.geometry.location.lng(),
        };
    }

    onCreateObjectSubmit(): void {
        let data: any = {};
        data.name = this.createObjectForm.get('name').value;

        if (!this.pickedAddress) {
            this._snackBar.open('Invalid address', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });

            return;
        }

        // data.regionsIds = regionsIds;
        data.googlePlaceId = this.pickedAddress.place_id;
        data.googlePlace = this.pickedAddress;
        // data.stateId = this.createObjectForm.get('state').value.id;

        this.store.dispatch(new CompanyActions.AddNewObject({
            customerId: this?.customer?.id,
            data: data,
        }));

        this.createObjectModal = false;
        this.addRegionField = false;
        this.addTeamField = false;
        this.regionSelectList = false;
        this.regionsInList = [];
        this.createObjectForm.reset();
    }
}
