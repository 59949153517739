import {skillPricingModel} from "./skillPricing.model";

export class SkillModel {
    id: number;
    parentId: number;
    description: string;
    name: string;
    isChecked: boolean;
    imagePath: string;
    children: SkillModel[];
    isBasic: boolean;
    price: skillPricingModel;
    numberOfGuards: number;
}
