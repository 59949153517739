import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import * as SharedActions from '../../../shared-store/shared.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { CustomerModel } from '../../../models/customer.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GuardModel } from '../../../models/guard.model';
import * as MyStaffActions from '../store/my-staff.actions';
import { SkillModel } from '../../../models/skill.model';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { PayslipItemModel } from '../../../models/payslip-item.model';
import { PaginationModel } from '../../../models/pagination.model';

@Component({
    selector: 'app-staff-profile',
    templateUrl: './staff-profile.component.html',
    styleUrls: ['./staff-profile.component.scss'],
})
export class StaffProfileComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    customer: CustomerModel;
    guard: GuardModel;
    skills: SkillModel[] = [];
    guardSkillIds: number[] = [];
    guardPayslipItems: PayslipItemModel[] = [];
    pagination: PaginationModel;

    deleteModal = false;
    approveCertificateModal = false;
    declineCertificateModal = false;
    declineReason = '';

    billingModal = false;
    billingForm = new FormGroup({
        address: new FormControl('', Validators.required),
        // address2: new FormControl(''),
        // postalCode: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        // card_number: new FormControl('', Validators.required),
        // clearing_number: new FormControl('', Validators.required),
        bankAccount: new FormControl('', Validators.required),
        bankName: new FormControl('', Validators.required),
    });

    contactModal = false;
    contactForm = new FormGroup({
        phone: new FormControl('', Validators.required),
        // email: new FormControl('', [Validators.required, Validators.email]),
    });

    get skillsFromArray() {
        return this.skillForm.controls.skillIds as FormArray;
    }

    skillForm = this.fb.group({
        skillIds: new FormArray([]),
    });

    // checkboxSkills = this.skills.map(x => {
    //     return {
    //         name: x.name,
    //         value: x.id,
    //     };
    // });

    editCompetencesModal = false;

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private fb: FormBuilder,
                private router: Router) {

    }

    ngOnInit(): void {
        this.store.dispatch(new SharedActions.LoadSkills());
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.guard = appState.staff.guard;
            this.guardPayslipItems = appState.staff.guardPayslipItems;
            this.pagination = appState.staff.pagination;
        });

        this.store.select('shared', 'skills')
            .pipe(
                filter(x => x.length > 0),
                take(1),
                map(skills => {
                    let customerSkills = skills.flatMap((skill) => {
                        return [...skill.children];
                    });
                    this.skills = customerSkills;
                    this.guardSkillIds = this.guard.skills.map((item) => {
                        return item.id;
                    });
                    customerSkills.forEach((item) => this.skillsFromArray.push(new FormControl(this.guardSkillIds.includes(item.id))));

                    return of(skills);
                }),
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onDelete(): void {
        this.store.dispatch(new MyStaffActions.DeleteGuard({customerId: this.customer.id, guardId: this.guard.id}));
        this.deleteModal = false;
    }

    public onApproveCertificate(): void {
        //dispatch...
        this.approveCertificateModal = false;
    }

    public onDeclineCertificate(): void {
        //dispatch...
        this.declineCertificateModal = false;
        this.declineReason = '';
    }

    public onEditBilling(): void {
        //set form values
        this.billingForm.patchValue({
            address: this.guard.address,
            city: this.guard.city,
            bankAccount: this.guard.bankAccount,
            bankName: this.guard.bankName,
            // card_number: this.guard
            // clearing_number: this.guard.
        });
        this.billingModal = true;
    }

    public onSubmitBilling(): void {
        if(this.billingForm.invalid) return;

        this.store.dispatch(new MyStaffActions.EditGuard({
            guardId: this.guard.id,
            customerId: this.customer.id,
            data: this.billingForm.value,
        }));

        this.billingModal = false;
    }

    public onEditContactInfo(): void {
        //set form values
        this.contactForm.patchValue({
            phone: this.guard.phone,
            email: this.guard.email,
        });
        this.contactModal = true;

    }

    public onSubmitContactInfo(): void {
        //dispatch..
        if(this.contactForm.invalid) return;
        // let phone = this.contactForm.get('phone').value;
        // let email = this.contactForm.get('email').value;

        this.store.dispatch(new MyStaffActions.EditGuard({
            guardId: this.guard.id,
            customerId: this.customer.id,
            data: this.contactForm.value,
        }));
        this.contactModal = false;
    }

    public onSubmitSkillForm(): void {
        //dispatch..
        // let skillIds = this.skillForm.controls.skillIds.value;
        // console.log(skillIds);
        const skillIds = this.skillForm.value.skillIds
                             .map((checked, i) => checked ? this.skills[i].id : null)
                             .filter(v => v !== null);

        this.store.dispatch(new MyStaffActions.EditGuard({
            guardId: this.guard.id,
            customerId: this.customer.id,
            data: {
                skillIds,
            },
        }));

        this.editCompetencesModal = false;

    }

    public onEditCompetences(): void {
        //...
        this.editCompetencesModal = true;
    }

}
