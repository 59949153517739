<div class="main-wrapper">
    <div class="row main-row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="header">
                    <div class="flexed">
                        <img src="/assets/img/safetly logo.png">
                        <h3>{{'safetly' | translate}}</h3>
                    </div>
                </div>
                <div class="body">
                    <h1>{{'auth.login.title' | translate}}</h1>
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup] = "LoginForm" (ngSubmit)="onLogin()" id='login-form'>
                                <div class="form-group">
                                    <label for="email">{{'auth.login.email' | translate}}</label>
                                    <input class="form-control form-input" type="email" id="email" formControlName='email'/>
                                </div>
                                <div class="form-group" style="margin-top: 40px;">
                                    <label for="password">{{'auth.login.password' | translate}}</label>
                                    <input class="form-control form-input" type="password" id="password" formControlName='password'/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <input type="submit"
                        value="{{'buttons.login' | translate}}"
                        class="btn my-button btn-primary "
                        form="login-form"
                        [disabled]="!this.LoginForm.valid"/>
                    <p class="faded-p">{{'auth.login.link' | translate}}</p>
                    <p class="login-password-p" routerLink="/login-email">{{'auth.login.login-link' | translate}}</p>
                    <div class="box">
                        <p class="faded-p" style="margin-top: 0px;">{{'auth.no-account' | translate}}</p>
                        <p class="login-password-p" routerLink="/">{{'auth.create-account' | translate}}</p>
                    </div>
                </div>
                <div class="footer">
                    <div class="footer-p">
                        <p>{{'auth.login.footer' | translate}}</p>
                    </div>
                    <div class="btn my-button help-button btn-primary">
                        <img src="/assets/img/question-circle 1.svg">
                        <span>{{'help' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
