<div class="main-wrapper">
	<div class="row">
		<div class="col-12">
			<div class="inner-wrapper">
				<div class="payment">
					<h3>{{'company.billing.payment' | translate}}</h3>
					<div class="box" *ngIf="customer.billingInformation; else createBillingInformation">
						<p>{{'company.billing.invoiced' | translate}}</p>
						<div class="flexed">
							<h6>{{customer.billingInformation.companyName}}</h6>
							<p class="number-p">{{customer.billingInformation.companyNumber}}</p>
						</div>
						<p *ngIf="customer.billingInformation.address; else noAddress">
							{{customer.billingInformation.address}}, {{customer.billingInformation.city}}</p>
						<ng-template #noAddress>
							<p>-</p>
						</ng-template>
						<p>{{customer.billingInformation.contactName}}</p>
						<p>{{customer.billingInformation.email}}, {{customer.billingInformation.phone}}</p>
						<p (click)="onChangeBillingInfoClicked()" class="change-p">{{'buttons.change' | translate}}</p>
					</div>
					<ng-template #createBillingInformation>
						<div class="btn btn-primary my-button mt-2 ng-star-inserted"
						     (click)="changeBillingInfoModal = true">
							<p>Create billing information</p>
						</div>
					</ng-template>
				</div>
				<div class="billing-history">
					<h3>{{'company.billing.history' | translate}}</h3>
					<div class="table-responsive">
						<!-- TABLE -->
						<table class="table table-hover">
							<thead>
							<tr>
								<th class="sort-col">{{'table.thead.name' | translate}}</th>
								<!--[mdbTableSort]="elements" sortBy="id" -->
								<th>{{'table.thead.invoice-date' | translate}}</th>
								<th>{{'table.thead.expiry-date' | translate}}</th>
								<th>{{'table.thead.status' | translate}}</th>
								<th style="opacity: 0;">Download</th>
							</tr>
							</thead>
							<tbody>
							<tr *ngFor="let invoice of invoices">
								<td><p class="black">Invoice</p></td>
								<td><p class="table-faded">{{invoice.startDate | date:"dd LLLL yyyy"}}</p></td>
								<td><p class="table-faded">{{invoice.endDate | date:"dd LLLL yyyy"}}</p></td>
								<td>
									<div class="pill-paid" *ngIf="invoice.paid">
										<span>{{'company.billing.paid' | translate}}</span>
									</div>
									<div class="pill-not-paid" *ngIf="!invoice.paid">
										<span>{{'company.billing.unpaid' | translate}}</span>
									</div>
								</td>
								<td><a href="{{invoice.fileUrl}}" target="_blank" class="download">{{'company.billing.download' | translate}}</a></td>
							</tr>
							</tbody>
							<tfoot>
							<tr>
								<td colspan="5">
									<div class="showing">
										<img [ngClass]="pagination.currentPage === 1 ? 'disabled' : '' "
										     class="left-arrows" src="/assets/img/Arrow-left end.svg"
										     (click)="onFirstPage()">
										<img [ngClass]="pagination.currentPage === 1 ? 'disabled' : '' "
										     class="left-arrows" src="/assets/img/Arrow-left.svg"
										     (click)="onPreviousPage()">
										<p *ngIf="pagination.total > 0;else noRecords">{{'pagination' | translate:{
											from: pagination.from,
											to: pagination.to,
											total: pagination.total
											} }}
										</p>
										<ng-template #noRecords>
											<p>{{'pagination-no-records' | translate}}</p>
										</ng-template>
										<img [ngClass]="pagination.currentPage === pagination.lastPage ? 'disabled' : '' "
										     class="right-arrows" src="/assets/img/Arrow-right.svg"
										     (click)="onNextPage()">
										<img [ngClass]="pagination.currentPage === pagination.lastPage ? 'disabled' : '' "
										     class="right-arrows" src="/assets/img/Arrow-right end.svg"
										     (click)="onLastPage()">
									</div>
								</td>
							</tr>
							</tfoot>
						</table>
						<!-- /TABLE -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--Change Billing info Modal-->
<app-modal *ngIf="this.changeBillingInfoModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">{{'company.billing.change' | translate}}</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.changeBillingInfoModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<form [formGroup]="changeBillingForm" (ngSubmit)="onChangeBillingInfoSubmit()"
		      id="changeBillingForm">
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="form-group" style="margin-right: 20px;">
						<label for="companyName">{{'company.billing.company' | translate}}</label>
						<input formControlName="companyName" class="form-control" type="text"
						       id="companyName"/>
						<div class="text-danger"
						     *ngIf="changeBillingForm.controls['companyName'].dirty && changeBillingForm.hasError('required', 'companyName')">
							{{'company.billing.company' | translate}} {{'form.validation.required' | translate}}
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="companyNumber">{{'company.billing.number' | translate}}</label>
						<input formControlName="companyNumber" class="form-control" type="text" id="companyNumber"/>
						<div class="text-danger"
						     *ngIf="changeBillingForm.controls['companyNumber'].dirty
						     && changeBillingForm.hasError('required', 'companyNumber')">
							{{'company.billing.number' | translate}} {{'form.validation.required' | translate}}
						</div>
						<div class="text-danger"
						     *ngIf="changeBillingForm.controls['companyNumber'].dirty
						     && changeBillingForm.hasError('pattern', 'companyNumber')">
							{{'company.billing.number' | translate}} {{'form.validation.digit' | translate: {'number': 10} }}
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="address">{{'company.billing.address' | translate}}</label>
				<input formControlName="address" class="form-control" type="text"
				       id="address"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['address'].dirty && changeBillingForm.hasError('required', 'address')">
					{{'company.billing.address' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="addressSecond">{{'company.billing.address2' | translate}}</label>
				<input formControlName="addressSecond" class="form-control" type="text"
				       id="addressSecond"/>
			</div>
			<div class="form-group">
				<label for="postalCode">{{'company.billing.postal-code' | translate}}</label>
				<input formControlName="postalCode" class="form-control" type="text" id="postalCode"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['postalCode'].dirty && changeBillingForm.hasError('required', 'postalCode')">
					{{'company.billing.postal-code' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="city">{{'company.billing.city' | translate}}</label>
				<input formControlName="city" class="form-control" type="text" id="city"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['city'].dirty && changeBillingForm.hasError('required', 'city')">
					{{'company.billing.city' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>

			<p class="invoicing-contact-p">{{'company.billing.invoice' | translate}}</p>

			<div class="form-group">
				<label for="contactName">{{'company.billing.contact' | translate}}</label>
				<input formControlName="contactName" class="form-control" type="text" id="contactName"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['contactName'].dirty && changeBillingForm.hasError('required', 'contactName')">
					{{'company.billing.contact' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="phone">{{'company.billing.phone' | translate}}</label>
				<input formControlName="phone" class="form-control" type="text" id="phone"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['phone'].dirty && changeBillingForm.hasError('required', 'phone')">
					{{'company.billing.phone' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="email">{{'company.billing.email' | translate}}</label>
				<input formControlName="email" class="form-control" type="email" id="email"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['email'].dirty && changeBillingForm.hasError('required', 'email')">
					{{'company.billing.email' | translate}} {{'form.validation.required' | translate}}
				</div>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['email'].dirty && changeBillingForm.hasError('email', 'email')">
					{{'company.billing.email' | translate}} {{'form.validation.email' | translate}}
				</div>
			</div>
		</form>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.changeBillingInfoModal = false">{{'buttons.cancel' | translate}}</span>
					<!--<input type="submit"-->
					       <!--value="{{'buttons.save-changes' | translate}}"-->
					       <!--form="changeBillingForm"-->
					       <!--[disabled]="!this.changeBillingForm.valid"-->
					       <!--class="btn save-btn btn-primary"/>-->
					<input type="submit"
					       value="{{'buttons.save-changes' | translate}}"
					       form="changeBillingForm"
					       class="btn save-btn btn-primary"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>
