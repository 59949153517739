<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="steps-navigation">
                    <div class="d-flex justify-content-sm-between justify-content-center text-center">
                        <ng-container *ngFor="let stepIteration of steps">
                            <div [ngClass]="stepIteration.active ? 'active' : 'not-active'"
                                 class="circle"
                                 *ngIf="stepIteration.id < 8">
                                <img [src]="stepIteration.active ? stepIteration.iconActiveSrc : stepIteration.iconNotActiveSrc" alt="">

                                <!--<img *ngIf="!this.step2" src="/assets/img/single-neutral-actions-shield 1.svg" alt="">-->
                                <!--<img *ngIf="this.step2" src="/assets/img/single-neutral-actions-shield 1 active (1).svg" alt="">-->
                            </div>
                        </ng-container>
                        <div class="process-line process-line-active-{{step}}"></div>

                        <!--<div [ngClass]="this.step1 ? 'active' : 'not-active'" class="circle">-->
                            <!--<img src="/assets/img/buildings 1.svg" alt="">-->
                        <!--</div>-->
                        <!--<div class="process-line" [ngClass]="this.step2 ? 'active' : 'not-active'"></div>-->
                        <!--<div [ngClass]="this.step2 ? 'active' : 'not-active'" class="circle">-->
                            <!--<img *ngIf="!this.step2" src="/assets/img/single-neutral-actions-shield 1.svg" alt="">-->
                            <!--<img *ngIf="this.step2" src="/assets/img/single-neutral-actions-shield 1 active (1).svg" alt="">-->
                        <!--</div>-->
                        <!--<div [ngClass]="this.step3 ? 'active' : 'not-active'" class="circle">-->
                            <!--<img *ngIf="!this.step3" src="/assets/img/calendar 1.svg" alt="">-->
                            <!--<img *ngIf="this.step3" src="/assets/img/calendar 1 active (1).svg" alt="">-->
                        <!--</div>-->
                        <!--<div class="process-line2" [ngClass]="this.step3 ? 'active' : 'not-active'"></div>-->
                        <!--<div [ngClass]="this.step4 ? 'active' : 'not-active'" class="circle">-->
                            <!--<img *ngIf="!this.step4" src="/assets/img/task-list-text-1 1.svg" alt="">-->
                            <!--<img *ngIf="this.step4" src="/assets/img/task-list-text-active.svg" alt="">-->
                        <!--</div>-->
                        <!--<div class="process-line3" [ngClass]="this.step4 ? 'active' : 'not-active'"></div>-->
                        <!--<div [ngClass]="this.step5 ? 'active' : 'not-active'" class="circle">-->
                            <!--<img *ngIf="!this.step5" src="/assets/img/shield-unlock 1.svg" alt="">-->
                            <!--<img *ngIf="this.step5" src="/assets/img/shield-unlock-active.svg" alt="">-->
                        <!--</div>-->
                        <!--<div class="process-line4" [ngClass]="this.step5 ? 'active' : 'not-active'"></div>-->
                        <!--<div [ngClass]="this.step6 ? 'active' : 'not-active'" class="circle">-->
                            <!--<img *ngIf="!this.step6" src="/assets/img/pie-line-graph 1.svg" alt="">-->
                            <!--<img *ngIf="this.step6" src="/assets/img/pie-line-graph 1 active (1).svg" alt="">-->
                        <!--</div>-->
                        <!--<div class="process-line5" [ngClass]="this.step6 ? 'active' : 'not-active'"></div>-->
                        <!--<div [ngClass]="this.step7 ? 'active' : 'not-active'" class="circle">-->
                            <!--<img *ngIf="!this.step7" src="/assets/img/check-circle 1.svg" alt="">-->
                            <!--<img *ngIf="this.step7" src="/assets/img/check-circle 1 active.svg" alt="">-->
                        <!--</div>-->
                        <!--<div class="process-line6" [ngClass]="step == 7 ? 'active' : 'not-active'"></div>-->
                    </div>
                </div>

                <div class="d-flex justify-content-between gap-1 mt-5">
                    <div class="d-flex justify-content-start gap-1">
                        <div class="back">
                            <img src="/assets/img/back arrow.svg">
                            <p class="back-p" (click)="onBack()">{{'create-assignment.back' | translate}}</p>
                        </div>
                    </div>
                    <div *ngIf="step >= 3" class="save-close" (click)="onSaveAsDraft()">
                        <p class="close-p">{{'create-assignment.draft' | translate}}</p>
                        <img src="/assets/img/Close  blue(1).svg">
                    </div>
                </div>

                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
