import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from "../store/auth.actions";
import {Subscription} from "rxjs";
import {EmployeeModel} from "../../models/employee.model";

@Component({
    selector: 'app-email-verification',
    templateUrl: './email-verification.component.html',
    styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {

    public form = this.formBuilder.group({
        confirmPassword: ['', [Validators.required]],
        password: ['', [Validators.required]],
    });
    public showPasswords: boolean = false;
    private token: string;
    public employee: EmployeeModel;
    storeSub: Subscription;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.storeSub = this.store.subscribe(appState => {
            this.employee = appState.auth.employee;
        });
        this.route.queryParams.subscribe(params => {
            this.token = params.t;
        });
    }

    ngOnDestroy(): void {
        this.storeSub.unsubscribe();
    }

    get password() {
        return this.form.get('password');
    }

    get confirmPassword() {
        return this.form.get('confirmPassword');
    }

    onSubmit(): void {
        this.store.dispatch(new AuthActions.UpdateEmail({
            employeeId: this.employee.id,
            token: this.token,
            password: this.form.get('password').value,
            confirmPassword: this.form.get('confirmPassword').value
        }));
    }

}
