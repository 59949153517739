import {createSelector} from "@ngrx/store";

export const selectStep = createSelector(
    (state) => state['assignments'],
    (assignmentsState) =>  assignmentsState.step
);

export const getSkills = createSelector(
    (state) => state['assignments'],
    (assignmentsState) => assignmentsState.skills
);

export const isCreatingAssignmentFromHistory = createSelector(
    (state) => state['assignments'],
    (assignmentsState) => assignmentsState.isAssignmentCreatingFromHistory
);

