import {Action} from '@ngrx/store';
import {EmployeeRoleModel} from "../models/employeeRole.model";
import {CountryModel} from "../models/country.model";
import {CityModel} from "../models/city.model";
import {SkillModel} from "../models/skill.model";
import {UniformModel} from "../models/uniform.model";
import {UtilityOptionModel} from "../models/utilityOption.model";
import {ReportTypeModel} from "../models/reportType.model";
import {PaginationModel} from "../models/pagination.model";
import { NewsModel } from '../models/news.model';
import { StateModel } from '../models/state.model';

export const LOAD_NEWS = '[Shared] Load News';
export const LOAD_NEWS_SUCCESS = '[Shared] Load News Success';
export const LOAD_NEWS_FAILED = '[Shared] Load News Failed';

export const LOAD_EMPLOYEE_ROLES = '[Shared] Load All Employee Roles';
export const LOAD_EMPLOYEE_ROLES_SUCCESS = '[Shared] Load All Employee Roles Success';
export const LOAD_EMPLOYEE_ROLES_FAILED = '[Shared] Load All Employee Roles Failed';

export const LOAD_COUNTRIES = '[Shared] Load All Countries';
export const LOAD_COUNTRIES_SUCCESS = '[Shared] Load All Countries Success';
export const LOAD_COUNTRIES_FAILED = '[Shared] Load All Countries Failed';

export const LOAD_STATES = '[Shared] Load All States';
export const LOAD_STATES_SUCCESS = '[Shared] Load All States Success';
export const LOAD_STATES_FAILED = '[Shared] Load All States Failed';

export const LOAD_COUNTRY_CITIES = '[Shared] Load All Cities By CountryId';
export const LOAD_COUNTRY_CITIES_SUCCESS = '[Shared] Load All Cities By CountryId Success';
export const LOAD_COUNTRY_CITIES_FAILED = '[Shared] Load All Cities By CountryId Failed';

export const SET_FIREBASE_TOKEN_FOR_PUSH_NOTIFICATIONS = '[Shared] Set firebase token for push notifications';
export const SEND_FIREBASE_TOKEN_TO_SERVER = '[Shared] Send firebase token to server';
export const SEND_FIREBASE_TOKEN_TO_SERVER_SUCCESS = '[Shared] Send firebase token to server success';
export const SEND_FIREBASE_TOKEN_TO_SERVER_FAIL = '[Shared] Send firebase token to server fail';

export const LOAD_SKILLS = '[Shared] Load Skills';
export const LOAD_SKILLS_SUCCESS = '[Shared] Load Skills Success';
export const LOAD_SKILLS_FAILED = '[Shared] Load Skills Failed';

export const LOAD_SKILL = '[Shared] Load Skill';
export const LOAD_SKILL_SUCCESS = '[Shared] Load Skill Success';
export const LOAD_SKILL_FAILED = '[Shared] Load Skill Failed';

export const CREATE_SKILL = '[Shared] Create New Skill';
export const CREATE_SKILL_SUCCESS = '[Shared] Create New Skill Success';
export const CREATE_SKILL_FAILED = '[Shared] Create New Skill Failed';

export const UPDATE_SKILL = '[Shared] Update Skill';
export const UPDATE_SKILL_SUCCESS = '[Shared] Update Skill Success';
export const UPDATE_SKILL_FAILED = '[Shared] Update Skill Failed';

export const DELETE_SKILL = '[Shared] Delete Skill';
export const DELETE_SKILL_SUCCESS = '[Shared] Delete Skill Success';
export const DELETE_SKILL_FAILED = '[Shared] Delete Skill Failed';

export const CLEAR_SKILLS = '[Shared] Clear Skills';

//BASIC UNIFORMS
export const LOAD_UNIFORM = '[Shared] Load Uniform';
export const LOAD_UNIFORM_SUCCESS = '[Shared] Load Uniform Success';
export const LOAD_UNIFORM_FAILED = '[Shared] Load Uniform Failed';

export const LOAD_UNIFORM_DETAILS = '[Shared] Load Uniform Details';
export const LOAD_UNIFORM_DETAILS_SUCCESS = '[Shared] Load Uniform Details Success';
export const LOAD_UNIFORM_DETAILS_FAILED = '[Shared] Load Uniform Details Failed';

//SKILL UNIFORMS
export const LOAD_SKILL_UNIFORMS = '[Shared] Load Skill Uniforms';
export const LOAD_SKILL_UNIFORMS_SUCCESS = '[Shared] Load Skill Uniforms Success';
export const LOAD_SKILL_UNIFORMS_FAILED = '[Shared] Load Skill Uniforms Failed';

export const LOAD_SKILL_UNIFORM = '[Shared] Load Single Skill Uniform ';
export const LOAD_SKILL_UNIFORM_SUCCESS = '[Shared] Load Single Skill Uniform Success';
export const LOAD_SKILL_UNIFORM_FAILED = '[Shared] Load Single Skill Uniform Failed';

export const CREATE_SKILL_UNIFORM = '[Shared] Create Skill Uniform';
export const CREATE_SKILL_UNIFORM_SUCCESS = '[Shared] Create Skill Uniform Success';
export const CREATE_SKILL_UNIFORM_FAILED = '[Shared] Create Skill Uniform Failed';

export const UPDATE_SKILL_UNIFORM = '[Shared] Update Skill Uniform';
export const UPDATE_SKILL_UNIFORM_SUCCESS = '[Shared] Update Skill Uniform Success';
export const UPDATE_SKILL_UNIFORM_FAILED = '[Shared] Update Skill Uniform Failed';

export const DELETE_SKILL_UNIFORM = '[Shared] Delete Skill Uniform';
export const DELETE_SKILL_UNIFORM_SUCCESS = '[Shared] Delete Skill Uniform Success';
export const DELETE_SKILL_UNIFORM_FAILED = '[Shared] Delete Skill Uniform Failed';

//BASIC UTILITY OPTIONS
export const LOAD_UTILITY_OPTIONS = '[Shared] Load Utility Options';
export const LOAD_UTILITY_OPTIONS_SUCCESS = '[Shared] Load Utility Options Success';
export const LOAD_UTILITY_OPTIONS_FAILED = '[Shared] Load Utility Options Failed';

//SKILL UTILITY OPTIONS
export const LOAD_SKILL_UTILITY_OPTIONS = '[Shared] Load Skill Utility Options';
export const LOAD_SKILL_UTILITY_OPTIONS_SUCCESS = '[Shared] Load Skill Utility Options Success';
export const LOAD_SKILL_UTILITY_OPTIONS_FAILED = '[Shared] Load Skill Utility Options Failed';

export const LOAD_SKILL_UTILITY_OPTION = '[Shared] Load Skill Utility Option';
export const LOAD_SKILL_UTILITY_OPTION_SUCCESS = '[Shared] Load Skill Utility Option Success';
export const LOAD_SKILL_UTILITY_OPTION_FAILED = '[Shared] Load Skill Utility Option Failed';

export const CREATE_SKILL_UTILITY_OPTION = '[Shared] Create Skill Utility Option';
export const CREATE_SKILL_UTILITY_OPTION_SUCCESS = '[Shared] Create Skill Utility Option Success';
export const CREATE_SKILL_UTILITY_OPTION_FAILED = '[Shared] Create Skill Utility Option Failed';

export const UPDATE_SKILL_UTILITY_OPTION = '[Shared] Update Skill Utility Option';
export const UPDATE_SKILL_UTILITY_OPTION_SUCCESS = '[Shared] Update Skill Utility Option Success';
export const UPDATE_SKILL_UTILITY_OPTION_FAILED = '[Shared] Update Skill Utility Option Failed';

export const DELETE_SKILL_UTILITY_OPTION = '[Shared] Delete Skill Utility Option';
export const DELETE_SKILL_UTILITY_OPTION_SUCCESS = '[Shared] Delete Skill Utility Option Success';
export const DELETE_SKILL_UTILITY_OPTION_FAILED = '[Shared] Delete Skill Utility Option Failed';

export const LOAD_REPORT_TYPES = '[Shared] Load Report Types';
export const LOAD_REPORT_TYPES_SUCCESS = '[Shared] Load Report Types Success';
export const LOAD_REPORT_TYPES_FAILED = '[Shared] Load Report Types Failed';


export class LoadEmployeeRoles implements Action {
    readonly type = LOAD_EMPLOYEE_ROLES;
}

export class LoadEmployeeRolesSuccess implements Action {
    readonly type = LOAD_EMPLOYEE_ROLES_SUCCESS;

    constructor(public payload: {
        employeeRoles: EmployeeRoleModel[],
    }) {
    }
}

export class LoadEmployeeRolesFailed implements Action {
    readonly type = LOAD_EMPLOYEE_ROLES_FAILED;
}

export class LoadNews implements Action {
    readonly type = LOAD_NEWS;
}

export class LoadNewsSuccess implements Action {
    readonly type = LOAD_NEWS_SUCCESS;

    constructor(public payload: {
        news: NewsModel[],
    }) {
    }
}

export class LoadNewsFailed implements Action {
    readonly type = LOAD_NEWS_FAILED;
}

export class LoadCountries implements Action {
    readonly type = LOAD_COUNTRIES;
}

export class LoadCountriesSuccess implements Action {
    readonly type = LOAD_COUNTRIES_SUCCESS;

    constructor(public payload: CountryModel[]) {
    }
}

export class LoadCountriesFailed implements Action {
    readonly type = LOAD_COUNTRIES_FAILED;
}

export class LoadStates implements Action {
    readonly type = LOAD_STATES;
    constructor(public payload: {
        countryId: number
    }) {
    }
}

export class LoadStatesSuccess implements Action {
    readonly type = LOAD_STATES_SUCCESS;

    constructor(public payload: {
        states: StateModel[]
    }) {
    }
}

export class LoadStatesFailed implements Action {
    readonly type = LOAD_STATES_FAILED;
}

export class LoadCountryCities implements Action {
    readonly type = LOAD_COUNTRY_CITIES;

    constructor(public payload: number) {
    }
}

export class LoadCountryCitiesSuccess implements Action {
    readonly type = LOAD_COUNTRY_CITIES_SUCCESS;

    constructor(public payload: CityModel[]) {
    }
}

export class LoadCountryCitiesFailed implements Action {
    readonly type = LOAD_COUNTRY_CITIES_FAILED;
}

export class SetFirebaseTokenForPushNotifications implements Action {
    readonly type = SET_FIREBASE_TOKEN_FOR_PUSH_NOTIFICATIONS;

    constructor(public payload: string) {
    }
}

export class SendFirebaseTokenToServer implements Action {
    readonly type = SEND_FIREBASE_TOKEN_TO_SERVER;
}

export class SendFirebaseTokenToServerSuccess implements Action {
    readonly type = SEND_FIREBASE_TOKEN_TO_SERVER_SUCCESS;
}

export class SendFirebaseTokenToServerFail implements Action {
    readonly type = SEND_FIREBASE_TOKEN_TO_SERVER_FAIL;
}

export class LoadSkills implements Action {
    readonly type = LOAD_SKILLS;
}

export class LoadSkillsSuccess implements Action {
    readonly type = LOAD_SKILLS_SUCCESS;

    constructor(public payload: { skills: SkillModel[], pagination: PaginationModel }) {
    }
}

export class LoadSkillsFailed implements Action {
    readonly type = LOAD_SKILLS_FAILED;
}

export class LoadSkill implements Action {
    readonly type = LOAD_SKILL;

    constructor(public payload: { customerId: number, skillId: number }) {
    }
}

export class LoadSkillSuccess implements Action {
    readonly type = LOAD_SKILL_SUCCESS;

    constructor(public payload: { skill: SkillModel }) {
    }
}

export class LoadSkillFailed implements Action {
    readonly type = LOAD_SKILL_FAILED;
}

export class CreateSkill implements Action {
    readonly type = CREATE_SKILL;

    constructor(public payload: {
        customerId: number,
        data: {
            parentId: number,
            name: string,
            description?: string,
            image?: string,
            pricing: {
                baseSalary: number,
                unsocialHoursBonusWorkdayNight: number,
                unsocialHoursBonusWeekendDay: number,
                unsocialHoursBonusWeekendNight: number,
                unsocialHoursBonusCdates: number,
                unsocialHoursBonusDdates: number,
                isRequiredCertificate?: boolean
            }
        }
    }) {
    }
}

export class CreateSkillSuccess implements Action {
    readonly type = CREATE_SKILL_SUCCESS;

    constructor(public payload: { parentId: number, skill: SkillModel }) {
    }
}

export class CreateSkillFailed implements Action {
    readonly type = CREATE_SKILL_FAILED;
}

export class LoadUniform implements Action {
    readonly type = LOAD_UNIFORM;
}

export class LoadUniformSuccess implements Action {
    readonly type = LOAD_UNIFORM_SUCCESS;

    constructor(public payload: { uniforms: UniformModel[] }) {
    }
}

export class LoadUniformFailed implements Action {
    readonly type = LOAD_UNIFORM_FAILED;
}

export class LoadUniformDetails implements Action {
    readonly type = LOAD_UNIFORM_DETAILS;

    constructor(public payload: { customerId: number, uniformId: number }) {
    }
}

export class LoadUniformDetailsSuccess implements Action {
    readonly type = LOAD_UNIFORM_DETAILS_SUCCESS;

    constructor(public payload: { uniform: UniformModel }) {
    }
}

export class LoadUniformDetailsFailed implements Action {
    readonly type = LOAD_UNIFORM_DETAILS_FAILED
}

export class LoadSkillUniforms implements Action {
    readonly type = LOAD_SKILL_UNIFORMS;

    constructor(public payload: { skillId: number }) {
    }
}

export class LoadSkillUniformsSuccess implements Action {
    readonly type = LOAD_SKILL_UNIFORMS_SUCCESS;

    constructor(public payload: { uniforms: UniformModel[] }) {
    }
}

export class LoadSkillUniformsFailed implements Action {
    readonly type = LOAD_SKILL_UNIFORMS_FAILED;
}

export class LoadSkillUniform implements Action {
    readonly type = LOAD_SKILL_UNIFORM;

    constructor(public payload: { uniformId: number, skillId: number }) {
    }
}

export class LoadSkillUniformSuccess implements Action {
    readonly type = LOAD_SKILL_UNIFORM_SUCCESS;

    constructor(public payload: { uniform: UniformModel }) {
    }
}

export class LoadSkillUniformFailed implements Action {
    readonly type = LOAD_SKILL_UNIFORM_FAILED;
}

export class LoadUtilityOptions implements Action {
    readonly type = LOAD_UTILITY_OPTIONS;
}

export class LoadUtilityOptionsSuccess implements Action {
    readonly type = LOAD_UTILITY_OPTIONS_SUCCESS;

    constructor(public payload: { utilityOptions: UtilityOptionModel[] }) {
    }
}

export class LoadUtilityOptionsFailed implements Action {
    readonly type = LOAD_UTILITY_OPTIONS_FAILED;
}


export class LoadSkillUtilityOptions implements Action {
    readonly type = LOAD_SKILL_UTILITY_OPTIONS;

    constructor(public payload: { skillId: number }) {
    }
}

export class LoadSkillUtilityOptionsSuccess implements Action {
    readonly type = LOAD_SKILL_UTILITY_OPTIONS_SUCCESS;

    constructor(public payload: { utilityOptions: UtilityOptionModel[] }) {
    }
}

export class LoadSkillUtilityOptionsFailed implements Action {
    readonly type = LOAD_SKILL_UTILITY_OPTIONS_FAILED;
}

export class LoadSkillUtilityOption implements Action {
    readonly type = LOAD_SKILL_UTILITY_OPTION;

    constructor(public payload: { customerId: number, skillId: number, optionId: number }) {
    }
}

export class LoadSkillUtilityOptionSuccess implements Action {
    readonly type = LOAD_SKILL_UTILITY_OPTION_SUCCESS;

    constructor(public payload: { utilityOption: UtilityOptionModel }) {
    }
}

export class LoadSkillUtilityOptionFailed implements Action {
    readonly type = LOAD_SKILL_UTILITY_OPTION_FAILED;
}

export class LoadReportTypes implements Action {
    readonly type = LOAD_REPORT_TYPES;

}

export class LoadReportTypesSuccess implements Action {
    readonly type = LOAD_REPORT_TYPES_SUCCESS;

    constructor(public payload: { reports: ReportTypeModel[] }) {
    }
}

export class LoadReportTypesFailed implements Action {
    readonly type = LOAD_REPORT_TYPES_FAILED;
}

export class ClearSkills implements Action {
    readonly type = CLEAR_SKILLS;
}

export class UpdateSkill implements Action {
    readonly type = UPDATE_SKILL;

    constructor(public payload: {
        customerId: number,
        // skillId: number,
        data: SkillModel
    }) {
    }
}

export class UpdateSkillSuccess implements Action {
    readonly type = UPDATE_SKILL_SUCCESS;

    constructor(public payload: { skill: SkillModel }) {
    }
}

export class UpdateSkillFailed implements Action {
    readonly type = UPDATE_SKILL_FAILED;
}

export class CreateSkillUniform implements Action {
    readonly type = CREATE_SKILL_UNIFORM;

    constructor(public payload: {
        skillId: number,
        data: any
    }) {
    }
}

export class CreateSkillUniformSuccess implements Action {
    readonly type = CREATE_SKILL_UNIFORM_SUCCESS;

    constructor(public payload: { uniform: UniformModel }) {
    }
}

export class CreateSkillUniformFailed implements Action {
    readonly type = CREATE_SKILL_UNIFORM_FAILED;
}

export class UpdateSkillUniform implements Action {
    readonly type = UPDATE_SKILL_UNIFORM;

    constructor(public payload: {
        skillId: number,
        uniformId: number,
        data: any
    }) {
    }
}

export class UpdateSkillUniformSuccess implements Action {
    readonly type = UPDATE_SKILL_UNIFORM_SUCCESS;

    constructor(public payload: { uniform: UniformModel }) {
    }
}

export class UpdateSkillUniformFailed implements Action {
    readonly type = UPDATE_SKILL_UNIFORM_FAILED;
}

export class DeleteSkillUniform implements Action {
    readonly type = DELETE_SKILL_UNIFORM;

    constructor(public payload: {
        skillId: number,
        uniformId: number
    }) {
    }
}

export class DeleteSkillUniformSuccess implements Action {
    readonly type = DELETE_SKILL_UNIFORM_SUCCESS;

    constructor(public payload: { uniform: UniformModel }) {
    }
}

export class DeleteSkillUniformFailed implements Action {
    readonly type = DELETE_SKILL_UNIFORM_FAILED;
}

export class CreateSkillUtilityOption implements Action {
    readonly type = CREATE_SKILL_UTILITY_OPTION;

    constructor(public payload: {
        skillId: number,
        data: any
    }) {
    }
}

export class CreateSkillUtilityOptionSuccess implements Action {
    readonly type = CREATE_SKILL_UTILITY_OPTION_SUCCESS;

    constructor(public payload: { option: UtilityOptionModel }) {
    }
}

export class CreateSkillUtilityOptionFailed implements Action {
    readonly type = CREATE_SKILL_UTILITY_OPTION_FAILED;
}

export class UpdateSkillUtilityOption implements Action {
    readonly type = UPDATE_SKILL_UTILITY_OPTION;

    constructor(public payload: {
        skillId: number,
        optionId: number,
        data: any
    }) {
    }
}

export class UpdateSkillUtilityOptionSuccess implements Action {
    readonly type = UPDATE_SKILL_UTILITY_OPTION_SUCCESS;

    constructor(public payload: { option: UtilityOptionModel }) {
    }
}

export class UpdateSkillUtilityOptionFailed implements Action {
    readonly type = UPDATE_SKILL_UTILITY_OPTION_FAILED;
}

export class DeleteSkillUtilityOption implements Action {
    readonly type = DELETE_SKILL_UTILITY_OPTION;

    constructor(public payload: {
        skillId: number,
        optionId: number,
    }) {
    }
}

export class DeleteSkillUtilityOptionSuccess implements Action {
    readonly type = DELETE_SKILL_UTILITY_OPTION_SUCCESS;

    constructor(public payload: { option: UtilityOptionModel }) {
    }
}

export class DeleteSkillUtilityOptionFailed implements Action {
    readonly type = DELETE_SKILL_UTILITY_OPTION_FAILED;
}


export class DeleteSkill implements Action {
    readonly type = DELETE_SKILL;

    constructor(public payload: { skillId: number, customerId: number }) {
    }
}

export class DeleteSkillSuccess implements Action {
    readonly type = DELETE_SKILL_SUCCESS;

    constructor(public payload: { skill: SkillModel }) {
    }
}

export class DeleteSkillFailed implements Action {
    readonly type = DELETE_SKILL_FAILED;
}

export type SharedActions = LoadEmployeeRoles
    | LoadEmployeeRolesSuccess
    | LoadEmployeeRolesFailed
    | LoadCountries
    | LoadCountriesSuccess
    | LoadCountriesFailed
    | LoadCountryCities
    | LoadCountryCitiesSuccess
    | LoadCountryCitiesFailed
    | SetFirebaseTokenForPushNotifications
    | SendFirebaseTokenToServer
    | SendFirebaseTokenToServerSuccess
    | SendFirebaseTokenToServerFail
    | LoadSkills
    | LoadSkillsSuccess
    | LoadSkillsFailed
    | LoadUniform
    | LoadUniformSuccess
    | LoadUniformFailed
    | LoadUniformDetails
    | LoadUniformDetailsSuccess
    | LoadUniformDetailsFailed
    | LoadUtilityOptions
    | LoadUtilityOptionsSuccess
    | LoadUtilityOptionsFailed
    | LoadReportTypes
    | LoadReportTypesSuccess
    | LoadReportTypesFailed
    | CreateSkill
    | CreateSkillSuccess
    | CreateSkillFailed
    | ClearSkills
    | UpdateSkill
    | UpdateSkillSuccess
    | UpdateSkillFailed
    | LoadSkillUniforms
    | LoadSkillUniformsSuccess
    | LoadSkillUniformsFailed
    | LoadSkillUniform
    | LoadSkillUniformSuccess
    | LoadSkillUniformFailed
    | LoadSkillUtilityOptions
    | LoadSkillUtilityOptionsSuccess
    | LoadSkillUtilityOptionsFailed
    | LoadSkillUtilityOption
    | LoadSkillUtilityOptionSuccess
    | LoadSkillUtilityOptionFailed
    | LoadSkill
    | LoadSkillSuccess
    | LoadSkillFailed
    | CreateSkillUniform
    | CreateSkillUniformSuccess
    | CreateSkillUniformFailed
    | DeleteSkillUniform
    | DeleteSkillUniformSuccess
    | DeleteSkillUniformFailed
    | UpdateSkillUniform
    | UpdateSkillUniformSuccess
    | UpdateSkillUniformFailed
    | CreateSkillUtilityOption
    | CreateSkillUtilityOptionSuccess
    | CreateSkillUtilityOptionFailed
    | UpdateSkillUtilityOption
    | UpdateSkillUtilityOptionSuccess
    | UpdateSkillUtilityOptionFailed
    | DeleteSkillUtilityOption
    | DeleteSkillUtilityOptionSuccess
    | DeleteSkillUtilityOptionFailed
    | DeleteSkill
    | DeleteSkillSuccess
    | DeleteSkillFailed
    | LoadNews
    | LoadNewsSuccess
    | LoadNewsFailed
    | LoadStates
    | LoadStatesSuccess
    | LoadStatesFailed
    ;
