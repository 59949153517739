import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../store/app.reducer';
import * as AuthAction from '../../../auth/store/auth.actions';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'app-create-company',
    templateUrl: './create-company.component.html',
    styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnInit {

    createCompanyForm = this.fb.group({
        idNumber: this.fb.control('', [
            Validators.required,
            Validators.pattern("[0-9]{10}"),
        ]),
        companyName: this.fb.control('', [
            Validators.required,
        ]),
        email: this.fb.control('', [
            Validators.required,
            Validators.email
        ]),
        phone: this.fb.control('', Validators.required),
        avatar: this.fb.control(null),
        biography: this.fb.control(''),
    });

    constructor(private fb: FormBuilder,
                private router: Router,
                private store: Store<fromApp.AppState>) { }

    ngOnInit(): void {
        this.store.select('auth', 'customer')
            .pipe(
                filter(x => x != null),
                take(1)
            )
            .subscribe(customer => {
                this.router.navigate(['components/home']);
            })
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.createCompanyForm.patchValue({
                avatar: file,
            });
        }
    }

    onCreateCompany() {
        if(this.createCompanyForm.invalid) return;

        let formData = new FormData();

        for (let valueKey in this.createCompanyForm.value) {
            formData.append(valueKey, this.createCompanyForm.value[valueKey])
        }
        this.store.dispatch(new AuthAction.CreateCustomer(formData));
    }

}
