<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="flexed">
                    <div class="back" routerLink="/components/my-staff/invite/{{1}}">
                        <img src="/assets/img/back arrow.svg">
                        <p class="back-p">{{'back' | translate}}</p>
                    </div>
                    <p class="faded-p">My Staff</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">Invite Staff</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">Enter staff details</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">Send invite</p>
                </div>
                <h1>Send invite</h1>
                <p class="mt-3">Inviting to “Security guard”.</p>
                <p class="mt-1">Customize a message for the invited staff, or use our pre-made message.</p>

                <!--Text editor-->
                <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig" [(ngModel)]="htmlContent"></angular-editor>
                <!--/Text editor-->

                <div class="btn btn-primary my-button" (click)="onSendInvite()">
                    <p>Send invites</p>
                </div>
            </div>
        </div>
    </div>
</div>
