<div class="step-main-wrapper">
	<h1>{{'create-assignment.summary' | translate}}</h1>
	<div class="event-summary">
		<div class="flexed-center">
			<img src="/assets/img/calendar black.svg" alt="">
			<h3>{{'create-assignment.event-summary' | translate}}</h3>
		</div>
		<div class="wrapper">
			<div class="row main-row">
				<div class="col-12 col-sm-6">
					<!--                    <div class="d-flex flex-row justify-content-between gap-2 inner-row">-->
					<!--                        <div>-->
					<!--                            <h4>{{'create-assignment.guests' | translate}}</h4>-->
					<!--                            <p>150</p>-->
					<!--                        </div>-->
					<!--                        <p class="edit-p mt-0 mb-auto">{{'buttons.edit' | translate}}</p>-->
					<!--                    </div>-->
					<div class="row inner-row">
						<div class="col-10">
							<h4>{{'create-assignment.assignment-type' | translate}}</h4>
							<p>Event arranger</p>
						</div>
						<div class="col-2 text-right">
							<p class="edit-p" (click)="onStep(3)">{{'buttons.edit' | translate}}</p>
						</div>
					</div>
					<div class="row inner-row last-row">
						<div class="col-10">
							<h4>{{'create-assignment.date' | translate}}</h4>
							<p *ngIf="startDate">{{toDate(this?.startDate) | date:"d MMMM y"}}
								- {{toDate(this?.endDate) | date:"d MMMM y"}}</p>
							<p class="see-hours-p" (click)="this.assignmentHoursModal = true"
							   *ngIf="this?.assignmentHours?.length > 0">{{'create-assignment.see-hours' |
								translate}}</p>
						</div>
						<div class="col-2 text-right">
							<p class="edit-p" (click)="onStep(3)">{{'buttons.edit' | translate}}</p>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-6 col-right">
					<div class="row inner-row">
						<div class="col-10">
							<h4>{{'create-assignment.object' | translate}}</h4>
							<p>{{this?.object?.name}}</p>
							<p class="faded-p">{{this?.object?.address}}</p>
						</div>
						<div class="col-2 text-right">
							<p class="edit-p" (click)="onStep(4)">{{'buttons.edit' | translate}}</p>
						</div>
					</div>
					<div class="row inner-row">
						<div class="col-10">
							<h4>{{'create-assignment.description' | translate}}</h4>
							<p class="description-small" *ngIf="this.description; else noData">
								{{this?.description}}
							</p>
							<p class="see-hours-p" *ngIf="this.description"
							   (click)="this.descriptionModal = true">{{'create-assignment.see-all' | translate}}</p>
						</div>
						<div class="col-2 text-right">
							<p class="edit-p" (click)="onStep(4)">{{'buttons.edit' | translate}}</p>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>

	<div class="event-summary">
		<div class="flexed-center">
			<img src="/assets/img/multiple-neutral black.svg" alt="">
			<h3>{{'create-assignment.typeof-personnel' | translate}}</h3>
		</div>
		<div class="wrapper">
			<div class="row main-row">
				<div class="col-12 col-sm-6 col-left">
					<div class="row inner-row">
						<div class="col-10">
							<h4>{{'create-assignment.typeof-personnel' | translate}}</h4>
							<p>{{this?.skill?.name}}</p>
							<ul>
								<li>{{this?.uniform?.name}}</li>
								<li *ngFor="let option of this?.utilityOptions">{{option?.name}}</li>
							</ul>
						</div>
						<div class="col-2 text-right">
							<p class="edit-p" (click)="onStep(2)">{{'buttons.edit' | translate}}</p>
						</div>
					</div>
					<div class="row inner-row last-row">
						<div class="col-10">
							<h4>{{'create-assignment.no-personnel' | translate}}</h4>
							<p>{{this.numberOfPersonnel}} {{'create-assignment.guards' | translate}}</p>
							<p>{{this.numberOfLeaders}} {{'create-assignment.group-leaders' | translate}}</p>
						</div>
						<div class="col-2 text-right">
							<p class="edit-p" (click)="onStep(5)">{{'buttons.edit' | translate}}</p>
						</div>
					</div>
				</div>
				<div class="col-12 col-sm-6">
					<div class="row inner-row">
						<div class="col-12">
							<h4>{{'create-assignment.total-cost' | translate}}</h4>
							<p class="price-p">{{price?.estimatedPrice | currency}}</p>
							<p class="see-hours-p"
							   (click)="this.costBreakdownModal = true">{{'create-assignment.see-cost-breakdown' |
								translate}}</p>
						</div>
					</div>
					<div class="row inner-row" *ngIf="customer.billingInformation">
						<div class="col-10">
							<h4>{{'create-assignment.billing' | translate}}</h4>
							<div>
								<p class="faded-p" *ngIf="customer.billingInformation.companyName; else noData">
									{{customer.billingInformation.companyName}}
								</p>
								<p class="faded-p" *ngIf="customer.billingInformation.companyNumber; else noData">
									{{customer.billingInformation.companyNumber}}
								</p>
								<p class="faded-p" *ngIf="customer.billingInformation.city; else noData">
									{{customer.billingInformation.city}}
								</p>
								<p class="faded-p" *ngIf="customer.billingInformation.contactName; else noData">
									{{customer.billingInformation.contactName}}
								</p>
							</div>
						</div>
						<div class="col-2 text-right">
							<p class="edit-p" (click)="onEditBillingInfo()"
							   *ngIf="this?.customer?.roleId == 1">{{'buttons.edit' | translate}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="event-summary">
		<div class="flexed-center">
			<img src="/assets/img/phone-type black.svg" alt="">
			<h3>{{'create-assignment.contact' | translate}}</h3>
		</div>
		<div class="wrapper contact-wrapper">
			<form [formGroup]="contactForm">
				<div class="d-block justify-content-center d-sm-flex gap-2">
					<div class="form-group">
						<label for="name" style="left: 0;">{{'create-assignment.name' | translate}}</label>
						<input class="form-control" type="text" id="name" formControlName="name"/>
					</div>
					<div class="form-group">
						<label for="phoneContactForm" style="left: 0;">{{'create-assignment.phone' | translate}}</label>
						<input class="form-control mt-sm-0 mt-5" type="text" id="phoneContactForm" formControlName="phone"/>
					</div>
				</div>
			</form>
		</div>
	</div>

	<div class="btn btn-primary my-button" (click)="onSaveAndContinue()">
		<div class="flexed">
			<p>{{'create-assignment.place-order' | translate}}</p>
			<img src="/assets/img/arrow white.svg">
		</div>
	</div>
</div>

<!--Assignment hours Modal-->
<app-modal *ngIf="this.assignmentHoursModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">{{'create-assignment.assignment-hours' | translate}}
					({{this?.assignmentHours?.length}})</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.assignmentHoursModal = false" class="close" style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<div class="scroll-div">
			<div class="row scroll-row" *ngFor="let date of this?.assignmentHours">
				<div class="col-3">
					<p>{{toDate(date?.date) | date:"MMMM d, y"}}</p>
				</div>
				<div class="col-3">
					<p>{{date?.startTime}} - {{date?.endTime}}</p>
				</div>
				<div class="col-3">
					<p class="faded-p">{{calculateTimeHuman(date, date?.startTime, date?.endTime)}} </p>
				</div>
			</div>
		</div>
	</div>
</app-modal>

<!--Description Modal-->
<app-modal *ngIf="this.descriptionModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">{{'create-assignment.description' | translate}}</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.descriptionModal = false" class="close" style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<p class="description">{{this.description}}</p>
	</div>
</app-modal>

<!--Cost breakdown Modal-->
<app-modal *ngIf="this.costBreakdownModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">{{'create-assignment.cost-breakdown' | translate}}</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.costBreakdownModal = false" class="close" style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<p class="faded-p">
			All our staff are entitled to unsocial hour bonuses in accordance with the principles negotiated
			in accordance with Transport's “Bevakningsavtalet”.
			<br><br>
			As a result, prices vary based on time and date. Our system automatically calculates a quote for
			you and below you can see how we have calculated it.
			<br>
			*estimated price
		</p>
		<div class="line"></div>
		<div class="scroll-div">
			<div class="row">
				<div class="col-3">
					<p class="font-weight-bold">Shift Date</p>
				</div>
				<div class="col-3">
					<p class="font-weight-bold">Number of guards</p>
				</div>
				<div class="col-3">
					<p class="font-weight-bold">Cost per guard</p>
				</div>
				<div class="col-3">
					<p class="font-weight-bold">Total cost per shift</p>
				</div>
			</div>

			<div class="row scroll-row" *ngFor="let dateWithPrice of price.datesWithPrice">
				<div class="col-3">
					<p>{{dateWithPrice.date | date}}</p>
				</div>
				<div class="col-3">
					<p>{{price.guardsNumber}} guards</p>
				</div>
				<div class="col-3">
					<p class="faded-p">{{dateWithPrice.customerPerShiftPerGuard | currency}}</p>
				</div>
				<div class="col-3">
					<p>{{dateWithPrice.customerPerShift | currency}}</p>
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="row">
			<div class="col-6">
				<p class="faded-p">Fees excluding VAT.</p>
			</div>
			<div class="col-6">
				<div class="cost-wrapper">
					<p class="bold-faded">*{{'create-assignment.total-cost' | translate}}</p>
					<p class="total-price">{{price.estimatedPrice | currency}}</p>
				</div>
			</div>
		</div>
	</div>
</app-modal>

<!--Change Billing info Modal-->
<app-modal *ngIf="this.billingInfoModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">{{'company.billing.change' | translate}}</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.billingInfoModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<form [formGroup]="changeBillingForm" (ngSubmit)="onChangeBillingInfoSubmit()"
		      id="changeBillingForm">
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="form-group" style="margin-right: 20px;">
						<label for="companyName">{{'company.billing.company' | translate}}</label>
						<input formControlName="companyName" class="form-control" type="text"
						       id="companyName"/>
						<div class="text-danger"
						     *ngIf="changeBillingForm.controls['companyName'].dirty && changeBillingForm.hasError('required', 'companyName')">
							{{'company.billing.company' | translate}} {{'form.validation.required' | translate}}
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="companyNumber">{{'company.billing.number' | translate}}</label>
						<input formControlName="companyNumber" class="form-control" type="text" id="companyNumber"/>
						<div class="text-danger"
						     *ngIf="changeBillingForm.controls['companyNumber'].dirty
						     && changeBillingForm.hasError('required', 'companyNumber')">
							{{'company.billing.number' | translate}} {{'form.validation.required' | translate}}
						</div>
						<div class="text-danger"
						     *ngIf="changeBillingForm.controls['companyNumber'].dirty
						     && changeBillingForm.hasError('pattern', 'companyNumber')">
							{{'company.billing.number' | translate}} {{'form.validation.digit' | translate: {'number':
							10} }}
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="address">{{'company.billing.address' | translate}}</label>
				<input formControlName="address" class="form-control" type="text"
				       id="address"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['address'].dirty && changeBillingForm.hasError('required', 'address')">
					{{'company.billing.address' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="addressSecond">{{'company.billing.address2' | translate}}</label>
				<input formControlName="addressSecond" class="form-control" type="text"
				       id="addressSecond"/>
			</div>
			<div class="form-group">
				<label for="postalCode">{{'company.billing.postal-code' | translate}}</label>
				<input formControlName="postalCode" class="form-control" type="text" id="postalCode"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['postalCode'].dirty && changeBillingForm.hasError('required', 'postalCode')">
					{{'company.billing.postal-code' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="city">{{'company.billing.city' | translate}}</label>
				<input formControlName="city" class="form-control" type="text" id="city"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['city'].dirty && changeBillingForm.hasError('required', 'city')">
					{{'company.billing.city' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>

			<p class="invoicing-contact-p">{{'company.billing.invoice' | translate}}</p>

			<div class="form-group">
				<label for="contactName">{{'company.billing.contact' | translate}}</label>
				<input formControlName="contactName" class="form-control" type="text" id="contactName"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['contactName'].dirty && changeBillingForm.hasError('required', 'contactName')">
					{{'company.billing.contact' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="phone">{{'company.billing.phone' | translate}}</label>
				<input formControlName="phone" class="form-control" type="text" id="phone"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['phone'].dirty && changeBillingForm.hasError('required', 'phone')">
					{{'company.billing.phone' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="email">{{'company.billing.email' | translate}}</label>
				<input formControlName="email" class="form-control" type="email" id="email"/>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['email'].dirty && changeBillingForm.hasError('required', 'email')">
					{{'company.billing.email' | translate}} {{'form.validation.required' | translate}}
				</div>
				<div class="text-danger"
				     *ngIf="changeBillingForm.controls['email'].dirty && changeBillingForm.hasError('email', 'email')">
					{{'company.billing.email' | translate}} {{'form.validation.email' | translate}}
				</div>
			</div>
		</form>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.billingInfoModal = false">{{'buttons.cancel' | translate}}</span>
					<!--<input type="submit"-->
					<!--value="{{'buttons.save-changes' | translate}}"-->
					<!--form="changeBillingForm"-->
					<!--[disabled]="!this.changeBillingForm.valid"-->
					<!--class="btn save-btn btn-primary"/>-->
					<input type="submit"
					       value="{{'buttons.save-changes' | translate}}"
					       form="changeBillingForm"
					       class="btn save-btn btn-primary"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>

<ng-template #noData>
	<p>-</p>
</ng-template>

