export class BillingInformationModel {
    id: number;
    companyName: string;
    companyNumber: string;
    address: string;
    addressSecond: string;
    postalCode: string;
    city: string;
    contactName: string;
    phone: string;
    email: string;
}
