import {Action} from '@ngrx/store';
import {EmployeeModel} from "../../models/employee.model";
import {CustomerModel} from "../../models/customer.model";
import {PaginationModel} from "../../models/pagination.model";
import { BillingInformationModel } from '../../models/billingInformation.model';

export const LOGIN_START = '[Auth] Login Start';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAIL = '[Auth] Login Fail';
export const LOGOUT = '[Auth] Logout';
export const AUTOLOGIN = '[Auth] Autologin';

export const LOGIN_LINK = '[Auth] Send Login Link To Email';
export const LOGIN_LINK_SENT = '[Auth] Login Link Sent To Email';

export const SIGNUP_START = '[Auth] Signup Start';
export const SIGNUP_CODE_SENT = '[Auth] Signup Registration Code Sent';
export const SIGNUP_CODE = '[Auth] Signup Registration Code';

export const CHANGE_PASSWORD = '[Auth] Change Password';
export const CHANGE_PASSWORD_SUCCESS = '[Auth] Change Password Success';
export const CHANGE_PASSWORD_FAILED = '[Auth] Change Password Failed';

export const UPDATE_PROFILE = '[Auth] Update Profile';
export const UPDATE_PROFILE_SUCCESS = '[Auth] Update Profile Success';
export const UPDATE_PROFILE_FAILED = '[Auth] Update Profile Failed';

export const VERIFY_NEW_EMAIL = '[Auth] Verify New Email';
export const VERIFY_NEW_EMAIL_SUCCESS = '[Auth] Verify New Email Success';
export const UPDATE_EMAIL = '[Auth] Update Email';

// export const LOAD_CUSTOMERS = '[Auth] Get Customers For Employee';
// export const LOAD_CUSTOMERS_SUCCESS = '[Auth] Get Customers For Employee Success';
// export const LOAD_CUSTOMERS_FAILED = '[Auth] Get Customers For Employee Failed';

export const CREATE_CUSTOMER = '[Auth] Create New Customer';
export const CREATE_CUSTOMER_SUCCESS = '[Auth] Create New Customer Success';
export const CREATE_CUSTOMER_FAILED = '[Auth] Create New Customer Failed';

export const UPDATE_CUSTOMER = '[Auth] Update Customer';
export const UPDATE_CUSTOMER_SUCCESS = '[Auth] Update Customer Success';
export const UPDATE_CUSTOMER_FAILED = '[Auth] Update Customer Failed';

export const SET_INVITE_TOKEN = '[Auth] Set Invite Token';
export const CLEAR_INVITE_TOKEN = '[Auth] Clear Invite Token';

export const TRANSFER_OWNERSHIP = '[Auth] Transfer Ownership';

export const SWITCH_COMPANY_ACCOUNT = '[Auth] Switch Company Account';

export const UPDATE_BILLING_INFO = '[Company] Update Billing Information For Company';
export const UPDATE_BILLING_INFO_SUCCESS = '[Company] Update Billing Information For Company Success';
export const UPDATE_BILLING_INFO_FAILED = '[Company] Update Billing Information For Company Failed';

export const CREATE_BILLING_INFO = '[Company] Create Billing Information For Company';
export const CREATE_BILLING_INFO_SUCCESS = '[Company] Create Billing Information For Company Success';
export const CREATE_BILLING_INFO_FAILED = '[Company] Create Billing Information For Company Failed';

export class SignupStart implements Action {
    readonly type = SIGNUP_START;

    constructor(public payload: {
        email: string
    }) {
    }
}

export class SignupCodeSent implements Action {
    readonly type = SIGNUP_CODE_SENT;
}

export class SignupCode implements Action {
    readonly type = SIGNUP_CODE;

    constructor(public payload: {
        code: number,
    }) {
    }
}

export class SendLoginLink implements Action {
    readonly type = LOGIN_LINK;

    constructor(public payload: {
        email: string
    }) {
    }
}

export class LoginLinkSent implements Action {
    readonly type = LOGIN_LINK_SENT;
}

export class LoginStart implements Action {
    readonly type = LOGIN_START;

    constructor(public payload: {
        email: string,
        password: string,
    }) {
    }
}

export class Autologin implements Action {
    readonly type = AUTOLOGIN;
}

export class LoginSuccess implements Action {
    readonly type = LOGIN_SUCCESS;

    constructor(public payload: {
        employee: EmployeeModel,
        jwt: any,
    }) {
    }
}

export class LoginFailed implements Action {
    readonly type = LOGIN_FAIL;
}


export class Logout implements Action {
    readonly type = LOGOUT;
}

export class ChangePassword implements Action {
    readonly type = CHANGE_PASSWORD;

    constructor(public payload: {
        employeeId: number,
        oldPassword: string,
        password: string,
        confirmPassword: string,
    }) {
    }
}

export class ChangePasswordSuccess implements Action {
    readonly type = CHANGE_PASSWORD_SUCCESS;

    constructor(public payload: {
        employee: EmployeeModel,
    }) {
    }
}

export class ChangePasswordFailed implements Action {
    readonly type = CHANGE_PASSWORD_FAILED;
}

export class UpdateProfile implements Action {
    readonly type = UPDATE_PROFILE;

    constructor(public payload: {
        employeeId: number,
        locale?: string,
        firstName?: string,
        lastName?: string,
        email?: string,
        phone?: string
    }) {
    }
}

export class UpdateProfileSuccess implements Action {
    readonly type = UPDATE_PROFILE_SUCCESS;

    constructor(public payload: {
        employee: EmployeeModel,
    }) {
    }
}

export class UpdateProfileFailed implements Action {
    readonly type = UPDATE_PROFILE_FAILED;
}

export class UpdateEmail implements Action {
    readonly type = UPDATE_EMAIL;

    constructor(public payload: {
        employeeId: number,
        token: string,
        password: string,
        confirmPassword: string,
    }) {
    }
}

export class VerifyNewEmail implements Action {
    readonly type = VERIFY_NEW_EMAIL;

    constructor(public payload: {
        employeeId: number,
        email: string,
    }) {
    }
}

export class VerifyNewEmailSuccess implements Action {
    readonly type = VERIFY_NEW_EMAIL_SUCCESS;
}



// export class LoadCustomers implements Action {
//     readonly type = LOAD_CUSTOMERS;
//
//     constructor(public payload: {
//         employeeId: number
//     }) {
//     }
// }

// export class LoadCustomersSuccess implements Action {
//     readonly type = LOAD_CUSTOMERS_SUCCESS;
//
//     constructor(public payload: {
//         customers: CustomerModel[],
//         // pagination: PaginationModel
//     }) {
//     }
// }
//
// export class LoadCustomersFailed implements Action {
//     readonly type = LOAD_CUSTOMERS_FAILED;
// }

export class CreateCustomer implements Action {
    readonly type = CREATE_CUSTOMER;

    constructor(public payload: FormData) {
    }

    // constructor(public payload: {
    //     employeeId: number,
    //     email: string,
    //     password: string,
    //     idNumber: string,
    //     companyName?: string,
    //     phone?: string,
    //     image?: string
    // }) {
    // }
}

export class CreateCustomerSuccess implements Action {
    readonly type = CREATE_CUSTOMER_SUCCESS;

    constructor(public payload: {
        customer: CustomerModel
    }) {
    }
}

export class CreateCustomerFailed implements Action {
    readonly type = CREATE_CUSTOMER_FAILED;
}

export class SetInviteToken implements Action {
    readonly type = SET_INVITE_TOKEN;

    constructor(public payload: { inviteToken: string }) {
    }
}

export class ClearInviteToken implements Action {
    readonly type = CLEAR_INVITE_TOKEN;
}

export class TransferOwnership implements Action {
    readonly type = TRANSFER_OWNERSHIP;
}

export class UpdateCustomer implements Action {
    readonly type = UPDATE_CUSTOMER;

    constructor(public payload: {
        customerId: number,
        inviteToken?: 'activate' | 'deactivate'
    }) {
    }
}

export class UpdateCustomerSuccess implements Action {
    readonly type = UPDATE_CUSTOMER_SUCCESS;

    constructor(public payload: { customer: CustomerModel }) {
    }
}

export class UpdateCustomerFailed implements Action {
    readonly type = UPDATE_CUSTOMER_FAILED;
}

export class SwitchCompanyAccount implements Action {
    readonly type = SWITCH_COMPANY_ACCOUNT;

    constructor(public payload: { customer: CustomerModel }) {
    }
}

export class UpdateBillingInfo implements Action {
    readonly type = UPDATE_BILLING_INFO;

    constructor(public payload: {
        customerId: number,
        billingId: number,
        data: any
    }) {
    }
}

export class UpdateBillingInfoSuccess implements Action {
    readonly type = UPDATE_BILLING_INFO_SUCCESS;

    constructor(public payload: { billing: BillingInformationModel }) {
    }
}

export class UpdateBillingInfoFailed implements Action {
    readonly type = UPDATE_BILLING_INFO_FAILED;
}

export class CreateBillingInfo implements Action {
    readonly type = CREATE_BILLING_INFO;

    constructor(public payload: {
        customerId: number,
        companyName: string,
        companyNumber: string,
        address: string,
        addressSecond?: string,
        postalCode?: string,
        city?: string,
        contactName: string,
        phone: string,
        email: string
    }) {
    }
}

export class CreateBillingInfoSuccess implements Action {
    readonly type = CREATE_BILLING_INFO_SUCCESS;

    constructor(public payload: { billing: BillingInformationModel }) {
    }
}

export class CreateBillingInfoFailed implements Action {
    readonly type = CREATE_BILLING_INFO_FAILED;
}

export type AuthActions = SignupStart
    | SignupCodeSent
    | SignupCode
    | SendLoginLink
    | LoginLinkSent
    | LoginStart
    | LoginSuccess
    | LoginFailed
    | Autologin
    | Logout
    | ChangePassword
    | ChangePasswordSuccess
    | ChangePasswordFailed
    | UpdateProfile
    | UpdateProfileSuccess
    | UpdateProfileFailed
    | UpdateEmail
    | VerifyNewEmail
    | VerifyNewEmailSuccess
    // | LoadCustomers
    // | LoadCustomersSuccess
    // | LoadCustomersFailed
    | CreateCustomer
    | CreateCustomerSuccess
    | CreateCustomerFailed
    | SetInviteToken
    | ClearInviteToken
    | TransferOwnership
    | UpdateCustomer
    | UpdateCustomerSuccess
    | UpdateCustomerFailed
    | SwitchCompanyAccount
    // | LoadBillingInfo
    // | LoadBillingInfoSuccess
    // | LoadBillingInfoFailed
    // | LoadBillingInfoCollection
    // | LoadBillingInfoCollectionSuccess
    // | LoadBillingInfoCollectionFailed
    | UpdateBillingInfo
    | UpdateBillingInfoSuccess
    | UpdateBillingInfoFailed
    | CreateBillingInfo
    | CreateBillingInfoSuccess
    | CreateBillingInfoFailed
    ;
