import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Form, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {CustomerModel} from "../../models/customer.model";
import {EmployeeModel} from "../../models/employee.model";
import {AssignmentModel} from "../../models/assignment.model";
import {PaginationModel} from "../../models/pagination.model";
import * as AssignmentsActions from './store/assignments.actions';
import * as CompanyActions from '../company/store/company.actions';
import {JobPlaceModel} from "../../models/jobPlace.model";
import {SkillModel} from "../../models/skill.model";

@Component({
    selector: 'app-assignments',
    templateUrl: './assignments.component.html',
    styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit, OnDestroy {

    public subscription: Subscription;
    public customer: CustomerModel;
    public employee: EmployeeModel;

    public objects: JobPlaceModel[];
    public skills: SkillModel[];

    public assignments: AssignmentModel[];
    public pagination: PaginationModel;

    public dataLoading = false;

    public filterForm = new FormGroup({
        objects: new FormControl('0'),
        period: new FormControl('0'),
        skills: new FormControl('0'),
    });

    public params: {
        key: string,
        value: string
    }[] = [];


    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.employee = appState.auth.employee;
            this.dataLoading = appState.assignments.assignmentsLoading;
            this.assignments = appState.assignments.assignments;
            this.pagination = appState.assignments.pagination;
            this.objects = appState.company.objects;
            this.skills = appState.shared.skills;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onNextPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: this.pagination.currentPage + 1,
                params: this.params
            }));
        }
    }

    onPreviousPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: this.pagination.currentPage - 1,
                params: this.params
            }));
        }
    }

    onFirstPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: 1,
                params: this.params
            }));
        }
    }

    onLastPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: this.pagination.lastPage,
                params: this.params
            }));
        }
    }

    public onFilter(key: string, value: any): void {
        if (value == 0) {
            this.params = this.params.filter(el => el.key != key);
        } else {
            this.params = this.params.filter(el => el.key != key);
            this.params.push({key: key, value: value})
        }
        this.store.dispatch(new AssignmentsActions.ClearAssignments());
        this.store.dispatch(new AssignmentsActions.LoadAssignments({customerId: this.customer.id, page: 1, params: this.params}));
    }

}
