<div class="main-wrapper">
	<div class="row">
		<div class="col-12">
			<div class="inner-wrapper">

				<div class="d-flex">
					<p class="faded-p">{{'assignments.title' | translate}}</p>
					<img class="small-arrow" src="/assets/img/arrow small.svg">
					<p class="faded-p">{{'assignments.favourites.title' | translate}}</p>
					<img class="small-arrow" src="/assets/img/arrow small.svg">
					<p class="faded-p">{{this?.object?.name}}</p>
				</div>

				<h1>{{this?.object?.name}}</h1>

				<div class="row" style="margin-top: 30px;">
					<div class="col-12 col-lg-8 text-left">
						<div class="search-wrapper">
							<img class="search-icon" src="/assets/img/search 1.svg">
							<input (keyup)="onSearchFavourite($event.target.value)" class="search-input" type="search"
							       placeholder="{{'assignments.favourites.search' | translate}}"/>
						</div>
					</div>
					<div class="col-12 col-lg-4 text-right">
						<div class="btn btn-primary my-button" (click)="onAddFavouritesClicked()">
							<div class="flexed">
								<img src="/assets/img/plus white.svg">
								<p>{{'assignments.favourites.add' | translate}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="line"></div>

				<form [formGroup]="skillForm">
					<div class="form-group">
						<label for="competence1">{{'assignments.filters.competence' | translate}}</label>
						<select id="competence1" class="form-select" formControlName="skills"
						        (change)="onFilterFavouriteGuards($event.target.value)">
							<option [value]="0">{{'assignments.filters.all-competences' | translate}}</option>
							<option [value]="skill?.id" *ngFor="let skill of this?.skills">{{skill?.name}}</option>
						</select>
					</div>
				</form>

				<div class="wrapper favourites" (scroll)="onScrollFavourites($event)">
					<ng-template #noResults>
						<p *ngIf="!loading">No results</p>
					</ng-template>
					<div *ngIf="this?.favGuards?.length > 0; else noResults" class="row signatures-list"
					     id="favourites">
						<div *ngFor="let guard of this?.favGuards" class="signatures">
							<div class="d-flex justify-content-between gap-1">
								<div class="d-flex flex-row justify-content-start gap-2">
									<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
									     (click)="guardDetail = guard;
                                         guardDetailAction = guardDetailActionOptions.removeFromFavourites"
									     role="button">
									<div class="basic-info">
										<p (click)="guardDetail = guard;
										guardDetailAction = guardDetailActionOptions.removeFromFavourites;"
										   role="button">{{guard?.firstName}}</p>
										<!--<p>{{guard?.firstName}} {{guard?.lastName}}</p>-->
										<div class="d-flex justify-content-start gap-1">
											<ngb-rating max="5" [rate]="guard?.averageRate"
											            [readonly]="true"></ngb-rating>
											<p class="faded-p">{{guard?.averageRate}}</p>
											<img class="faded-check-icon" src="/assets/img/Group 596.png" alt="">
											<p class="faded-p check-p">{{guard.ratingCount}}</p>
										</div>
									</div>
								</div>
								<img (click)="onRemoveGuard(guard?.id)" class="close-icon mt-auto mb-auto"
								     src="/assets/img/Close.svg" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!--Add favourites Modal-->
<app-modal *ngIf="this.addFavouritesModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">{{'assignments.favourites.add' | translate}}</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.addFavouritesModal = false" class="close" style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<div class="search-wrapper">
			<img class="search-icon" src="/assets/img/search 1.svg">
			<input (keyup)="onSearchFavouriteToAdd($event.target.value)" class="search-input" type="search"
			       placeholder="{{'assignments.favourites.search-personnel' | translate}}"/>
		</div>
		<form [formGroup]="modalForm" class="mt-3">
			<div class="form-group">
				<label for="competence">{{'assignments.filters.competence' | translate}}</label>
				<select id="competence" class="form-select w-auto" formControlName="skills"
				        (change)="onFilterFavouriteToAdd($event.target.value)">
					<option [value]="0">{{'assignments.filters.all-competences' | translate}}</option>
					<option [value]="skill?.id" *ngFor="let skill of this?.skills">{{skill?.name}}</option>
				</select>
			</div>
		</form>
		<ng-template #noResults>
			<p>No results</p>
		</ng-template>
		<div *ngIf="guards.length > 0" class="favourites-list scroll-div" id="available"
		     (scroll)="onScrollAvailable($event)">
			<div class="row signatures-list">
				<div [ngClass]="isSelected(guard?.id) ? 'selected': ''" *ngFor="let guard of this?.guards"
				     class="signatures">
					<div class="d-flex justify-content-between gap-1">
						<div class="d-flex flex-row justify-content-start gap-2">
							<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
							     (click)="guardDetail = guard; guardDetailAction = guardDetailActionOptions.addToFavourites"
							     role="button">
							<div class="basic-info">
								<!--<p>{{guard?.firstName}} {{guard?.lastName}}</p>-->
								<p (click)="guardDetail = guard; guardDetailAction = guardDetailActionOptions.addToFavourites"
								   role="button">{{guard?.firstName}}</p>
								<!--<p class="faded-p">My Staff</p>-->
							</div>
						</div>
						<input type="checkbox" class="my-checkbox" [value]="guard?.id"
						       (change)="onChangeFavourite(guard?.id, $event.target.checked)">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
					<span class="cancel-btn"
					      (click)="this.addFavouritesModal = false">{{'buttons.cancel' | translate}}</span>
					<input type="button"
					       value="{{'assignments.favourites.add' | translate}}"
					       [disabled]="chosenFavourites.length == 0"
					       (click)="onAddFavourites()"
					       class="btn save-btn btn-primary"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>


<app-guard-detail-modal [guard]="guardDetail"
                        [action]="guardDetailAction"
                        (onClickAction)="onResolveGuardDetailAction($event)"
                        (closeModal)="guardDetail = null"
                        *ngIf="guardDetail"></app-guard-detail-modal>
