import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromApp from './../store/app.reducer';
import * as AuthActions from './store/auth.actions';


@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

    public EmailForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    });

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
    }

    get email() {
        return this.EmailForm.get('email');
    }

    onContinue(): void {
        this.store.dispatch(new AuthActions.SignupStart({email: this.EmailForm.get('email').value}));
    }

}
