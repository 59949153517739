import {Component, OnInit} from '@angular/core';

import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
    selector: 'app-send-invites',
    templateUrl: './send-invites.component.html',
    styleUrls: ['./send-invites.component.scss'],
})
export class SendInvitesComponent implements OnInit {

    htmlContent;

    editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '350px',
        minHeight: '0',
        maxHeight: '350px',
        width: 'auto',
        minWidth: '0',
        translate: 'yes',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        uploadUrl: 'v1/image',
        uploadWithCredentials: false,
        // sanitize: true,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [
                'strikeThrough',
                'subscript',
                'superscript',
                'indent',
                'outdent',
                'insertUnorderedList',
                'insertOrderedList',
                'fontName'
            ],
            [
                'textColor',
                'backgroundColor',
                'customClasses',
                'unlink',
                'insertImage',
                'insertVideo',
                'insertHorizontalRule',
                'removeFormat',
                'toggleEditorMode'
            ]
        ]
    };

    constructor() {
    }

    ngOnInit(): void {
    }

    public onSendInvite(): void {
        // console.log(this.htmlContent);
    }

}
