import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import {EmployeeModel} from "../../../models/employee.model";
import {PaginationModel} from "../../../models/pagination.model";
import {Subscription} from "rxjs";
import * as CompanyActions from '../store/company.actions';
import * as AuthActions from '../../../auth/store/auth.actions';
import {CustomerModel} from "../../../models/customer.model";
import {EmployeeRoleModel} from "../../../models/employeeRole.model";


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

    public editUser: boolean = false;
    public editUserModal: boolean = false;
    public deleteUserModal: boolean = false;
    public transferOwnershipModal: boolean = false;

    public loggedEmployee: EmployeeModel;
    public users: EmployeeModel[];
    public pagination: PaginationModel;
    public customer: CustomerModel;
    public allRoles: EmployeeRoleModel[];

    public userToEdit: EmployeeModel;
    public userToDelete: EmployeeModel;
    public userOptionsId: number;

    public subscription: Subscription;

    public editUserForm = this.formBuilder.group({
        role: ['', [Validators.required]],
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
    });

    constructor(private formBuilder: FormBuilder,
                private store: Store<fromApp.AppState>,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.loggedEmployee = appState.auth.employee;
            this.users = appState.company.employees;
            this.pagination = appState.company.pagination;
            this.customer = appState.auth.customer;
            this.allRoles = appState.shared.employeeRoles;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public openOptions(user: EmployeeModel): void {
        this.userOptionsId = user?.id;
    }

    public onEditUser(user: EmployeeModel): void {
        this.editUserForm.reset();
        this.editUserModal = true;
        this.userToEdit = user;

        if (this?.userToEdit?.employeeRole) {
            this.editUserForm.controls['role'].setValue(this?.userToEdit?.employeeRole?.id);
        }
        if (this?.userToEdit?.firstName) {
            this.editUserForm.controls['firstName'].setValue(this?.userToEdit?.firstName);
        }
        if (this?.userToEdit?.lastName) {
            this.editUserForm.controls['lastName'].setValue(this?.userToEdit?.lastName);
        }
        if (this?.userToEdit?.phone) {
            this.editUserForm.controls['phone'].setValue(this?.userToEdit?.phone);
        }
        if (this?.userToEdit?.email) {
            this.editUserForm.controls['email'].setValue(this?.userToEdit?.email);
        }
    }

    public onEditUserSubmit(): void {
        if (!this.editUserForm.valid) {
            return;
        }

        //ako zelim da ga postavim za ownera
        if(this.editUserForm.get('role').value == 1){
            this.transferOwnershipModal = true;
            return;
        }

        this.store.dispatch(new CompanyActions.UpdateEmployee({
            employeeId: this?.userToEdit?.id,
            customerId: this.customer.id,
            employeeRoleId: this.editUserForm.get('role').value
        }));

        this.userToEdit = null;
        this.editUserModal = false;
        this.editUserForm.reset();
    }

    public onDeleteUser(user: EmployeeModel): void {
        this.deleteUserModal = true;
        this.userToDelete = user;
    }

    public onSubmitDelete(): void {
        this.deleteUserModal = false;
        this.store.dispatch(new CompanyActions.DeleteEmployee({
            customerId: this.customer.id,
            employeeId: this?.userToDelete?.id
        }));
        this.userToDelete = null;
    }

    public onTransferOwnership(): void {
        if (!this.editUserForm.valid) {
            return;
        }

        this.store.dispatch(new CompanyActions.UpdateEmployee({
            employeeId: this?.userToEdit?.id,
            customerId: this.customer.id,
            employeeRoleId: this.editUserForm.get('role').value
        }));
        this.store.dispatch(new AuthActions.TransferOwnership());
        this.store.dispatch(new CompanyActions.TransferOwnership({loggedId: this?.loggedEmployee?.id}));

        this.userToEdit = null;
        this.editUserModal = false;
        this.editUserForm.reset();
        this.transferOwnershipModal = false;
    }

    public onCancelTransferOwnership(): void{
        this.transferOwnershipModal = false;
    }

    onNextPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new CompanyActions.LoadEmployees({
                customerId: this?.customer?.id,
                page: this.pagination.currentPage + 1
            }));
        }
    }

    onPreviousPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new CompanyActions.LoadEmployees({
                customerId: this?.customer?.id,
                page: this.pagination.currentPage - 1
            }));
        }
    }

    onFirstPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new CompanyActions.LoadEmployees({
                customerId: this?.customer?.id,
                page: 1
            }));
        }
    }

    onLastPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new CompanyActions.LoadEmployees({
                customerId: this?.customer?.id,
                page: this.pagination.lastPage
            }));
        }
    }
}
