import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export function shiftDurationValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.parent) {
        return {notSame: true};
    }
    let startTime = control.parent.get('startTime').value;
    let endTime = control.parent.get('endTime').value;

    startTime = moment(startTime, 'HH:mm');
    endTime = moment(endTime, 'HH:mm');

    let startAt = moment().set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: startTime.get('second'),
    });

    let endAt = moment().set({
        hour: endTime.get('hour'),
        minute: endTime.get('minute'),
        second: endTime.get('second'),
    });

    if (startAt.isAfter(endAt)) {
        endAt.add(1, 'days');
    }

    let numberOfHours =  moment.duration(endAt.diff(startAt)).asHours();

    return (numberOfHours >= 5 && numberOfHours <= 13) ? null : {shiftDurationValidator: true};
}