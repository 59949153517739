<div class="step-main-wrapper">
    <h1>{{'create-assignment.get-started' | translate}}</h1>
    <div class="wrapper">
        <h3>{{'create-assignment.from-category' | translate}}</h3>
        <div class="line"></div>
        <div class="row">
            <div class="col-12 col-sm-6 col-left cols" *ngFor="let category of categories" (click)="onCreateFromCategory(category.id)">
                <div class="flexed">
                    <img class="category-icon" src="{{category.imagePath}}" alt="">
                    <p>{{category.name}}</p>
                    <img class="arrow-icon" src="/assets/img/Arrow-right.svg" alt="">
                </div>
            </div>
            <!--<div class="col-12 col-sm-6 cols" (click)="onCreateFromCategory(this?.categories[1]?.id)">-->
                <!--<div class="flexed">-->
                    <!--<img class="category-icon" src="{{this?.categories[1]?.imagePath}}" alt="">-->
                    <!--<p>{{this?.categories[1]?.name}}</p>-->
                    <!--<img class="arrow-icon" src="/assets/img/Arrow-right.svg" alt="">-->
                <!--</div>-->
            <!--</div>-->
        </div>
        <!--<div class="row">-->
            <!--<div class="col-12 col-sm-6 col-left cols" (click)="onCreateFromCategory(this?.categories[3]?.id)">-->
                <!--<div class="flexed">-->
                    <!--<img class="category-icon" src="{{this?.categories[3]?.imagePath}}" alt="">-->
                    <!--<p>{{this?.categories[3]?.name}}</p>-->
                    <!--<img class="arrow-icon" src="/assets/img/Arrow-right.svg" alt="">-->
                <!--</div>-->
            <!--</div>-->
            <!--<div class="col-12 col-sm-6 cols" (click)="onCreateFromCategory(this?.categories[2]?.id)">-->
                <!--<div class="flexed">-->
                    <!--<img class="category-icon" src="{{this?.categories[2]?.imagePath}}" alt="">-->
                    <!--<p>{{this?.categories[2]?.name}}</p>-->
                    <!--<img class="arrow-icon" src="/assets/img/Arrow-right.svg" alt="">-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <!--<div class="row">-->
            <!--<div class="col-12 col-sm-6 col-left cols-last" (click)="onCreateFromCategory(this?.categories[4]?.id)">-->
                <!--<div class="flexed">-->
                    <!--<img class="category-icon" src="{{this?.categories[4]?.imagePath}}" alt="">-->
                    <!--<p>{{this?.categories[4]?.name}}</p>-->
                    <!--<img class="arrow-icon" src="/assets/img/Arrow-right.svg" alt="">-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
    </div>
    <div class="wrapper" style="margin-bottom: 100px;">
        <h3>{{'create-assignment.from-history' | translate}}</h3>
        <!-- TABLE -->
        <table class="table table-hover">
            <tbody>
            <tr *ngFor="let assignment  of this.assignments" (click)="onCreateFromHistory(assignment)">
                <td>
                    <p class="black" style="margin-bottom: 0px;">{{assignment?.jobPlace?.name}}</p>
                    <p style="margin-top: 0px;" class="table-faded">{{assignment.startAt | date:"d MMM"}}
                        - {{assignment.endAt  | date:"d MMM, YYYY"}}</p>
                </td>
                <td><p class="table-faded">Security Guard</p></td>
                <td><p class="table-faded">{{assignment?.numberOfPersonnel}}</p></td>
                <td><img class="arrow" src="/assets/img/Arrow-right.svg"></td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td colspan="4">
                    <div class="showing">
                        <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                             class="left-arrows" src="/assets/img/Arrow-left end.svg"
                             (click)="onFirstPage()">
                        <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                             class="left-arrows" src="/assets/img/Arrow-left.svg"
                             (click)="onPreviousPage()">
                        <p>
                            {{'pagination'| translate: {
                            from: this?.pagination?.from || 0,
                            to: this?.pagination?.to || 0,
                            total: this?.pagination?.total
                        }
                            }}
                        </p>
                        <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                             class="right-arrows" src="/assets/img/Arrow-right.svg" (click)="onNextPage()">
                        <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                             class="right-arrows" src="/assets/img/Arrow-right end.svg"
                             (click)="onLastPage()">
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>
        <!-- /TABLE -->
    </div>
</div>
