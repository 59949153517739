import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as AssignmentsActions from '../store/assignments.actions';
import * as CompanyActions from '../../company/store/company.actions';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../models/customer.model';
import { GuardModel } from '../../../models/guard.model';
import { PaginationModel } from '../../../models/pagination.model';
import { JobPlaceModel } from '../../../models/jobPlace.model';
import { SkillModel } from '../../../models/skill.model';
import { FormBuilder } from '@angular/forms';


@Component({
    selector: 'app-favourites-object',
    templateUrl: './favourites-object.component.html',
    styleUrls: ['./favourites-object.component.scss'],
})
export class FavouritesObjectComponent implements OnInit, OnDestroy {

    public addFavouritesModal: boolean = false;
    public chosenFavourites: number[] = [];
    public loading: boolean;

    guardDetail: GuardModel = null;
    guardDetailAction: string;
    guardDetailActionOptions = {
        addToFavourites: 'addToFavourites',
        removeFromFavourites: 'removeFromFavourites',
    };

    public subscription: Subscription;
    public customer: CustomerModel;

    public favGuards: GuardModel[];
    public favPagination: PaginationModel;
    public isLastFavPage: boolean;
    public favParams: {
        key: string,
        value: string
    }[] = [];

    guards: GuardModel[] = [];
    public pagination: PaginationModel;
    public isLastPage: boolean;
    public params: {
        key: string,
        value: string
    }[] = [];

    public object: JobPlaceModel;

    public skills: SkillModel[];

    public skillForm = this.fb.group({
        skills: '0',
    });
    public modalForm = this.fb.group({
        skills: '0',
    });


    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.favGuards = appState.assignments.favouriteGuards;
            this.favPagination = appState.assignments.favouritesPagination;
            this.isLastFavPage = appState.assignments.favouritesMeta.isLastPage;
            this.guards = appState.assignments.favouriteGuardsToAdd;
            this.pagination = appState.assignments.guardsToAddPagination;
            this.isLastPage = appState.assignments.guardsToAddMeta.isLastPage;
            this.object = appState.company.object;
            this.skills = appState.shared.skills;

            this.loading = appState.assignments.loading;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.store.dispatch(new AssignmentsActions.ClearFavouriteGuards());
        this.store.dispatch(new AssignmentsActions.ClearFavouriteToAdd());
    }

    onScrollFavourites(event): void {
        const height = document.getElementById('favourites').scrollHeight;
        const elementHeight = document.getElementById('favourites').offsetHeight;
        const scrollBottom = document.getElementById('favourites').scrollTop;

        if (!this.isLastFavPage && (scrollBottom >= (height - elementHeight) * 0.7)) {
            this.store.dispatch(new AssignmentsActions.LoadFavouriteGuards({
                jobPlaceId: this?.object?.id,
                params: this.favParams,
            }));
        }
    }

    onScrollAvailable(event): void {

        const height = document.getElementById('available').scrollHeight;
        const elementHeight = document.getElementById('available').offsetHeight;
        const scrollBottom = document.getElementById('available').scrollTop;

        if (!this.isLastPage && (scrollBottom >= (height - elementHeight) * 0.7)) {
            this.store.dispatch(new AssignmentsActions.LoadFavouriteGuardsToAdd({
                jobPlaceId: this?.object?.id,
                params: this.params,
            }));
        }
    }

    public onRemoveGuard(id: number): void {
        this.store.dispatch(new AssignmentsActions.DeleteFavouriteGuard({
            jobPlaceId: this?.object?.id,
            guardId: id,
        }));
    }

    public onAddFavouritesClicked(): void {
        this.store.dispatch(new AssignmentsActions.ClearFavouriteToAdd());
        this.store.dispatch(new AssignmentsActions.LoadFavouriteGuardsToAdd({
            jobPlaceId: this?.object?.id,
        }));
        this.chosenFavourites = [];
        this.addFavouritesModal = true;
    }

    onChangeFavourite(id: number, isChecked: boolean) {
        if (isChecked) {
            this.chosenFavourites.push(id);
        } else {
            let index = this.chosenFavourites.indexOf(id);
            this.chosenFavourites.splice(index, 1);
        }
    }

    public onAddFavourites(): void {
        this.store.dispatch(new AssignmentsActions.AddGuardsToFavourites({
            jobPlaceId: this?.object?.id,
            guardsIds: this.chosenFavourites,
        }));
        this.addFavouritesModal = false;
        this.chosenFavourites = [];
    }

    public isSelected(id: number) {
        return this.chosenFavourites.find(item => item == id);
    }

    public onFilterFavouriteGuards(skillId: any): void {
        if (skillId == 0) {
            this.favParams = this.favParams.filter(el => el.key != 'skills');
        } else {
            this.favParams = this.favParams.filter(el => el.key != 'skills');
            this.favParams.push({key: 'skills', value: skillId});
        }
        this.store.dispatch(new AssignmentsActions.ClearFavouriteGuards());
        this.store.dispatch(new AssignmentsActions.LoadFavouriteGuards({
            jobPlaceId: this?.object?.id,
            params: this.favParams,
        }));
    }

    public onFilterFavouriteToAdd(skillId: any): void {
        if (skillId == 0) {
            this.params = this.params.filter(el => el.key != 'skills');
        } else {
            this.params = this.params.filter(el => el.key != 'skills');
            this.params.push({key: 'skills', value: skillId});
        }
        this.store.dispatch(new AssignmentsActions.ClearFavouriteToAdd());
        this.store.dispatch(new AssignmentsActions.LoadFavouriteGuardsToAdd({
            jobPlaceId: this?.object?.id,
            params: this.params,
        }));
    }

    public onSearchFavourite(term: string): void {
        this.favParams = this.favParams.filter(el => el.key != 'search_term');
        this.favParams.push({key: 'search_term', value: term});

        this.store.dispatch(new AssignmentsActions.ClearFavouriteGuards());
        this.store.dispatch(new AssignmentsActions.LoadFavouriteGuards({
            jobPlaceId: this?.object?.id,
            params: this.favParams,
        }));

    }

    public onSearchFavouriteToAdd(term: string): void {

        this.params = this.params.filter(el => el.key != 'search_term');
        this.params.push({key: 'search_term', value: term});

        this.store.dispatch(new AssignmentsActions.ClearFavouriteToAdd());
        this.store.dispatch(new AssignmentsActions.LoadFavouriteGuardsToAdd({
            jobPlaceId: this?.object?.id,
            params: this.params,
        }));
    }

    onResolveGuardDetailAction($event) {
        switch ($event.action) {
            case this.guardDetailActionOptions.addToFavourites:
                this.store.dispatch(new AssignmentsActions.AddGuardsToFavourites({
                    jobPlaceId: this?.object?.id,
                    guardsIds: [$event.guard.id],
                }));
                break;
            case this.guardDetailActionOptions.removeFromFavourites:
                this.onRemoveGuard($event.guard.id);
                break;
        }
    }
}
