import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from "../../../store/app.reducer";
import { Subscription } from 'rxjs';
import { ReportModel } from '../../../models/report.model';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    report: ReportModel;
    id: number;


    constructor(private route: ActivatedRoute, private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.subscription = this.store.select('assignments', 'selectedReport')
                                .subscribe(report => {
                                    this.report = report;
                                });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }



}
