<div class="step-main-wrapper">
	<h1>{{'company.billing.add' | translate}}</h1>
	<div class="wrapper">
		<form [formGroup]="storeBillingInformation" (ngSubmit)="onSave()"
		      id="storeBillingInformation">
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="form-group" style="margin-right: 20px;">
						<label for="companyName">{{'company.billing.company' | translate}}</label>
						<input formControlName="companyName" class="form-control" type="text"
						       id="companyName"/>
						<div class="text-danger"
						     *ngIf="storeBillingInformation.controls['companyName'].dirty && storeBillingInformation.hasError('required', 'companyName')">
							{{'company.billing.company' | translate}} {{'form.validation.required' | translate}}
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="companyNumber">{{'company.billing.number' | translate}}</label>
						<input formControlName="companyNumber" class="form-control" type="text" id="companyNumber"/>
						<div class="text-danger"
						     *ngIf="storeBillingInformation.controls['companyNumber'].dirty
						     && storeBillingInformation.hasError('required', 'companyNumber')">
							{{'company.billing.number' | translate}} {{'form.validation.required' | translate}}
						</div>
						<div class="text-danger"
						     *ngIf="storeBillingInformation.controls['companyNumber'].dirty
						     && storeBillingInformation.hasError('pattern', 'companyNumber')">
							{{'company.billing.number' | translate}} {{'form.validation.digit' | translate: {'number':
							10} }}
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="address">{{'company.billing.address' | translate}}</label>
				<input formControlName="address" class="form-control" type="text"
				       id="address"/>
				<div class="text-danger"
				     *ngIf="storeBillingInformation.controls['address'].dirty && storeBillingInformation.hasError('required', 'address')">
					{{'company.billing.address' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="addressSecond">{{'company.billing.address2' | translate}}</label>
				<input formControlName="addressSecond" class="form-control" type="text"
				       id="addressSecond"/>
			</div>
			<div class="form-group">
				<label for="postalCode">{{'company.billing.postal-code' | translate}}</label>
				<input formControlName="postalCode" class="form-control" type="text" id="postalCode"/>
				<div class="text-danger"
				     *ngIf="storeBillingInformation.controls['postalCode'].dirty && storeBillingInformation.hasError('required', 'postalCode')">
					{{'company.billing.postal-code' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="city">{{'company.billing.city' | translate}}</label>
				<input formControlName="city" class="form-control" type="text" id="city"/>
				<div class="text-danger"
				     *ngIf="storeBillingInformation.controls['city'].dirty && storeBillingInformation.hasError('required', 'city')">
					{{'company.billing.city' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>

			<p class="invoicing-contact-p">{{'company.billing.invoice' | translate}}</p>

			<div class="form-group">
				<label for="contactName">{{'company.billing.contact' | translate}}</label>
				<input formControlName="contactName" class="form-control" type="text" id="contactName"/>
				<div class="text-danger"
				     *ngIf="storeBillingInformation.controls['contactName'].dirty && storeBillingInformation.hasError('required', 'contactName')">
					{{'company.billing.contact' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="phone">{{'company.billing.phone' | translate}}</label>
				<input formControlName="phone" class="form-control" type="text" id="phone"/>
				<div class="text-danger"
				     *ngIf="storeBillingInformation.controls['phone'].dirty && storeBillingInformation.hasError('required', 'phone')">
					{{'company.billing.phone' | translate}} {{'form.validation.required' | translate}}
				</div>
			</div>
			<div class="form-group">
				<label for="email">{{'company.billing.email' | translate}}</label>
				<input formControlName="email" class="form-control" type="email" id="email"/>
				<div class="text-danger"
				     *ngIf="storeBillingInformation.controls['email'].dirty && storeBillingInformation.hasError('required', 'email')">
					{{'company.billing.email' | translate}} {{'form.validation.required' | translate}}
				</div>
				<div class="text-danger"
				     *ngIf="storeBillingInformation.controls['email'].dirty && storeBillingInformation.hasError('email', 'email')">
					{{'company.billing.email' | translate}} {{'form.validation.email' | translate}}
				</div>
			</div>
		</form>
	</div>
	<div class="btn btn-primary my-button" (click)="onSave()">
		<div class="flexed">
			<p>{{'buttons.place-order' | translate}}</p>
			<img src="/assets/img/arrow white.svg">
		</div>
	</div>
</div>
