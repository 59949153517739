<mat-sidenav-container (window:resize)="onResize()">
    <mat-sidenav mode="{{this.mode}}" [(opened)]="opened">
        <div class="navigation">
            <img *ngIf="this.closeIcon" (click)="opened = false" class="close-icon" src="/assets/img/Close.svg">
            <div class="flexed-top logo-wrapper" routerLink="/components/home">
                <img class="logo" src="/assets/img/safetly logo.png">
                <h3>{{'safetly' | translate}}</h3>
            </div>

            <div class="flex-grow-1">

                <div class="row nav-item" (click)="onHomeClicked()" routerLink="/components/home"
                     routerLinkActive='route-active'>
                    <div *ngIf="this.router.url == '/components/home'" class="border-left"></div>
                    <div class="flexed">
                        <img *ngIf="!(this.router.url == '/components/home')" class="home-icon"
                             src="/assets/img/icon-home.svg">
                        <img *ngIf="this.router.url == '/components/home'" class="home-icon"
                             src="/assets/img/icon-home-selected.svg">
                        <p class="nav-item-title">{{'navigation.home' | translate}}</p>
                    </div>
                </div>


                <div *ngIf="this?.customer" [ngClass]="this.router.url.includes('assignments') || this.assignments == true  ? 'under-section-active' : '' "
                     class="row nav-item section">
                    <div *ngIf="this.assignments == true || this.router.url.includes('assignments')"
                         class="border-left"></div>
                    <div class="flexed" routerLink="/components/assignments" (click)="onAssignmentsClicked()">
                        <img *ngIf="!this.assignments == true" class="home-icon"
                             src="/assets/img/icon-assignments.svg">
                        <img *ngIf="this.assignments == true" class="home-icon"
                             src="/assets/img/icon-assignments-selected.svg">
                        <p [ngClass]="this.router.url.includes('create-assignment') || this.router.url.includes('favourites') ? 'not-bold' : '' "
                           class="nav-item-title">{{'navigation.assignments' | translate}}</p>
                        <!--                        <img class="add-icon" src="/assets/img/Add.svg" (click)="onCreateAssignment()">-->
                    </div>
                    <div *ngIf="this.router.url.includes('assignments') || this.assignments==true">
                        <p [ngClass]="this.favourites == true ? 'bolded' : 'not-bolded'"
                           (click)="onFavourites()"
                           class="under-section-p">
                            {{'navigation.favourites' | translate}}
                        </p>
                        <p [ngClass]="this.router.url.includes('create-assignment') ? 'bolded' : 'not-bolded'"
                           (click)="onCreateAssignment()"
                           class="under-section-p-second">
                            {{'navigation.create-assignment' | translate}}</p>
                    </div>
                </div>


                <div  *ngIf="this?.customer" [ngClass]="this.router.url.includes('reports') ? 'under-section-reports-active' : ''" class="row nav-item">
                    <div *ngIf="this.router.url.includes('reports')" class="border-left"></div>
                    <div class="flexed" routerLink="/components/reports/reports-details" (click)="onReportsClicked()">
                        <img *ngIf="!this.router.url.includes('reports')" class="home-icon" src="/assets/img/icon-reports.svg">
                        <img *ngIf="this.router.url.includes('reports')" class="home-icon"
                             src="/assets/img/icon-reports-selected.svg">
                        <p [ngClass]="this.router.url.includes('reports-details') || this.router.url.includes('reports/report') ? '' : 'not-bold'"
                           class="nav-item-title">{{'navigation.reports' | translate}}</p>
                    </div>
                    <div *ngIf="false">
                        <p [ngClass]="this.router.url.includes('report-types') ? 'bolded': 'not-bolded'" (click)="onReportTypes()"
                           *ngIf="this.reports == true"
                           class="under-section-p">{{'navigation.report-types' | translate}}</p>
                    </div>

                </div>


                <div  *ngIf="this?.customer" [ngClass]="this.router.url.includes('my-staff') || this.staff == true  ? 'under-section-staff-active' : '' "
                     class="row nav-item section">
                    <div *ngIf="this.staff == true || this.router.url.includes('my-staff')" class="border-left"></div>
                    <div class="flexed" routerLink="/components/my-staff" (click)="onStaffClicked()">
                        <img *ngIf="!this.staff == true" class="home-icon"
                             src="/assets/img/icon-staff.svg">
                        <img *ngIf="this.staff == true" class="home-icon"
                             src="/assets/img/icon-staff-selected.svg">
                        <p class="nav-item-title"
                           [ngClass]="this.router.url.includes('competences') || this.router.url.includes('my-staff/invite')
                            || this.router.url.includes('my-staff/send-invites')
                            || this.router.url.includes('payroll') ? 'not-bold' : '' ">
                            {{'navigation.my-staff' | translate}}
                        </p>
                    </div>

                    <div *ngIf="this.router.url.includes('my-staff') || this.staff==true">
                        <p class="under-section-p"
                           [ngClass]="this.competences == true ? 'bolded' : 'not-bolded'"
                           (click)="onCompetences()">
                            {{'navigation.competences' | translate}}
                        </p>
                        <p class="under-section-p-second"
                           [ngClass]="this.payroll == true ? 'bolded' : 'not-bolded'"
                           (click)="onPayroll()">
                            {{'navigation.payroll' | translate}}
                        </p>
                        <p class="under-section-p-second"
                           [ngClass]="this.router.url.includes('my-staff/invite') || this.router.url.includes('my-staff/send-invites')  || this.inviteStaff == true ? 'bolded' : 'not-bolded'"
                           (click)="onInviteStaff()">
                            {{'navigation.invite-staff' | translate}}
                        </p>
                    </div>
                </div>


                <div *ngIf="this?.customer" class="row nav-item" (click)="onMessagesClicked()" routerLink="/components/messages"
                     routerLinkActive='route-active'>
                    <div *ngIf="this.router.url == '/components/messages'" class="border-left"></div>
                    <div class="d-flex justify-content-between gap-1">
                        <div class="d-flex">
                            <img *ngIf="!(this.router.url == '/components/messages')" class="home-icon"
                                 src="/assets/img/icon-messages.svg">
                            <img *ngIf="(this.router.url == '/components/messages')" class="home-icon"
                                 src="/assets/img/icon-messages-selected.png">
                            <p class="nav-item-title d-flex">{{'navigation.messages' | translate}}
                                <span class="msg-badge" *ngIf="(messagesCount$ | async) > 0">{{messagesCount$ | async}}</span></p>
                        </div>
                    </div>

                </div>
            </div>

            <div class="bottom">
                <ng-container *ngIf="customer?.employeeRole.id != 3">
                    <div *ngIf="this?.customer" class="invite" routerLink="/components/company/invite-users" (click)="onCompanyClicked()">
                        <div class="flexed-invite">
                            <img class="plus-icon" src="/assets/img/plus.svg">
                            <p class="invite-p">{{'navigation.invite' | translate}}</p>
                        </div>
                    </div>
                </ng-container>

                <div  *ngIf="this?.customer" [ngClass]="this.router.url.includes('components/company') ? 'active' : '' " class="row nav-item"
                     (click)="onCompanyClicked()"
                     routerLink="/components/company/users" routerLinkActive='route-active'>
                    <div *ngIf="this.company == true" class="border-left"></div>
                    <div class="flexed">
                        <img *ngIf="this.company == false" class="profile-icon" src="/assets/img/icon-team.svg">
                        <img *ngIf="this.company == true" class="profile-icon" src="/assets/img/icon-team-selected.svg">
                        <p class="nav-item-title"
                           *ngIf="this?.customer; else noCustomer">{{this?.customer?.companyName}}</p>
                        <ng-template #noCustomer>
                            <p class="nav-item-title">No company</p>
                        </ng-template>
                    </div>
                </div>
                <div class="row nav-item" (click)="onProfileClicked()" routerLink="/components/profile"
                     routerLinkActive='route-active'>
                    <div *ngIf="this.profile == true" class="border-left"></div>
                    <div class="flexed">
                        <img *ngIf="this.profile == false" class="profile-icon" src="/assets/img/icon-profile.svg">
                        <img *ngIf="this.profile == true" class="profile-icon"
                             src="/assets/img/icon-profile-selected.svg">
                        <p class="nav-item-title">{{'navigation.profile' | translate}}</p>
                    </div>
                </div>
                <div class="line"></div>
                <div  *ngIf="this?.customer" class="flexed-bottom" (click)="switchAccountOptions = !switchAccountOptions">
                    <p class="switch-p">{{'navigation.switch-account' | translate}}</p>
                    <img class="switch-icon" src="/assets/img/switch.svg">
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <app-progress-bar-request></app-progress-bar-request>

        <button *ngIf="this.navButton" class="navigation-button" (click)="opened = !opened">
            <img *ngIf="opened" class="opened" src="/assets/img/switch.svg">
            <img *ngIf="!opened" src="/assets/img/switch.svg">
        </button>
        <router-outlet></router-outlet>

        <div *ngIf="switchAccountOptions" class="switch-account-options" (clickOutside)="switchAccountOptions = false">
            <ng-container *ngIf="this?.customers?.length > 0; else noCustomers">
                <div class="title">
                    <p class="bold">{{'navigation.switch' | translate}}</p>
                </div>
                <div class="d-flex justify-content-between item" *ngFor="let customer of this?.customers" (click)="onSwitchCustomer(customer)">
                    <p class="mt-auto mb-auto">{{customer?.companyName}}</p>
                    <img class="mt-auto mb-auto" src="/assets/img/Arrow-right.svg" alt="">
                </div>
            </ng-container>
            <ng-template #noCustomers>
                <div class="title">
                    <p class="bold">No companies to select. Create company first</p>
                </div>
            </ng-template>
        </div>

    </mat-sidenav-content>
</mat-sidenav-container>



