import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
    selector: 'app-report-types',
    templateUrl: './report-types.component.html',
    styleUrls: ['./report-types.component.scss']
})
export class ReportTypesComponent implements OnInit {

    public createReportTypeModal: boolean = false;
    public editReportTypeModal: boolean = false;

    public choosenFields: number[] = [];
    public choosenFieldsEdit: number[] = [];

    public createReportTypeForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        desc: ['', [Validators.required]],
    });
    public editReportTypeForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        desc: ['', [Validators.required]],
    });

    constructor(private formBuilder: FormBuilder, private router: Router) {
    }

    ngOnInit(): void {
    }

    onChangeField(i: number, isChecked: boolean) {
        if (isChecked) {
            this.choosenFields.push(i);
        } else {
            let index = this.choosenFields.indexOf(i);
            this.choosenFields.splice(index, 1);
        }
    }

    onChangeFieldEdit(i: number, isChecked: boolean) {
        if (isChecked) {
            this.choosenFieldsEdit.push(i);
        } else {
            let index = this.choosenFieldsEdit.indexOf(i);
            this.choosenFieldsEdit.splice(index, 1);
        }
    }

    public onEditReportType(): void {
        this.editReportTypeModal = true;
        //..postavi vrednosti forme
        this.editReportTypeForm.controls['name'].setValue('Wayfinding/direction guidance for visitors');
    }

    public onCreateReportType(): void {
        this.createReportTypeModal = false;
        //dispatch...
    }

    public onSubmitEditReportType(): void {
        this.editReportTypeModal = false;
        //dispatch...
    }

    public onDeleteReportType(): void {
        this.editReportTypeModal = false;
        //dispatch...
    }

}
