import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromApp from './../../store/app.reducer';
import * as AuthActions from './../store/auth.actions';

@Component({
    selector: 'app-register-step2',
    templateUrl: './register-step2.component.html',
    styleUrls: ['./register-step2.component.scss']
})
export class RegisterStep2Component implements OnInit {

    public SecurityCodeForm = this.formBuilder.group({
        code: ['', [Validators.required]],
    });
    private inviteToken: string = null;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.inviteToken = params.t;
            if (this.inviteToken) {
                this.store.dispatch(new AuthActions.SetInviteToken({inviteToken: this.inviteToken}));
            }
        });

    }

    get code() {
        return this.SecurityCodeForm.get('code');
    }

    onContinue(): void {
        let data: any = {};
        data.code = this.SecurityCodeForm.get('code').value
        // if (this.inviteToken) {
        //     data.inviteToken = this.inviteToken
        // }
        this.store.dispatch(new AuthActions.SignupCode(data));
    }

}
