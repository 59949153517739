import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../store/app.reducer';
import { createData } from '../create-object';
import * as AssignmentsActions from '../../store/assignments.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../../models/customer.model';
import { GuardModel } from '../../../../models/guard.model';
import { PaginationModel } from '../../../../models/pagination.model';

@Component({
    selector: 'app-step5',
    templateUrl: './step5.component.html',
    styleUrls: ['./step5.component.scss'],
})
export class Step5Component implements OnInit, OnDestroy {
    guardDetail: GuardModel = null;
    guardType: string = null;
    guardDetailAction: string = null;
    guardDetailActionOptions = {
        pickPerson: 'pickPerson',
        unpickPerson: 'unpickPerson',
        defineNumberOfPersonnel: 'defineNumberOfPersonnel',
        defineNumberOfLeaders: 'defineNumberOfLeaders',
    };
    public handPick: boolean = false;
    public filterEveryone: boolean = true;
    public filterFavourites: boolean = false;
    public rate: number = 4;

    public recurringEvent: boolean;

    public subscription: Subscription;
    public customer: CustomerModel;
    public guards: GuardModel[];
    pagination: PaginationModel;
    public isLastPage: boolean;

    public form = this.formBuilder.group({
        noPersonnel: '0',
        noGrLeaders: '0',
        hoursPersonnel: '12',
    });

    public selectedLeaders: number[] = [];
    public selectedPersonnel: number[] = [];

    isCreatingAssignmentFromHistory: boolean;

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private formBuilder: FormBuilder,
                private router: Router) {
    }

    ngOnInit(): void {

        this.subscription = this.store.subscribe(appState => {
            this.isCreatingAssignmentFromHistory = appState.assignments.isAssignmentCreatingFromHistory;
            this.customer = appState.auth.customer;
            if (this.filterEveryone) {
                this.guards = appState.assignments.guards;
                this.pagination = appState.assignments.pagination;
                this.isLastPage = appState.assignments.guardsMeta.isLastPage;
            } else if (this.filterFavourites) {
                this.guards = appState.assignments.favouriteGuards;
                this.pagination = appState.assignments.favouritesPagination;
                this.isLastPage = appState.assignments.favouritesMeta.isLastPage;
            }
        });
        if (createData.numberOfPersonnel) {
            this.form.controls['noPersonnel'].setValue(createData.numberOfPersonnel);
        }
        if (createData.numberOfLeaders) {
            this.form.controls['noGrLeaders'].setValue(createData.numberOfLeaders);
        }
        if (createData.leadersIds) {
            this.selectedLeaders = createData.leadersIds;
        }
        if (createData.guardsIds) {
            this.selectedPersonnel = createData.guardsIds;
        }
        if (createData.isManual) {
            this.handPick = createData.isManual;
        }
        this.recurringEvent = createData.isRecurring == true;

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.store.dispatch(new AssignmentsActions.ClearGuards());
    }

    onScrollGuards(event): void {
        // const height = document.getElementById('leaders').scrollHeight;
        // const elementHeight = document.getElementById('leaders').offsetHeight;
        // const scrollBottom = -document.getElementById('leaders').scrollTop;

        const height2 = document.getElementById('personnel').scrollHeight;
        const elementHeight2 = document.getElementById('personnel').offsetHeight;
        const scrollBottom2 = document.getElementById('personnel').scrollTop;

        if (!this.isLastPage && this.filterEveryone && (scrollBottom2 >= (height2 - elementHeight2) * 0.7)) {
            this.store.dispatch(new AssignmentsActions.LoadObjectGuards({
                jobPlaceId: createData.jobPlaceId,
                skillId: createData.skillId,
                uniformId: createData.uniformId,
                dates: createData.dates,
                timezone: createData.timezone,
            }));
        }
        if (!this.isLastPage && this.filterFavourites && (scrollBottom2 >= (height2 - elementHeight2) * 0.7)) {
            this.store.dispatch(new AssignmentsActions.LoadFavouriteGuards({
                jobPlaceId: createData.jobPlaceId,
            }));
        }

        // if (!this.isLastPage && this.filterEveryone && ((scrollBottom >= (height - elementHeight) * 0.7) || (scrollBottom2 >= (height2 - elementHeight2) * 0.7))) {
        //     this.store.dispatch(new AssignmentsActions.LoadObjectGuards({
        //         jobPlaceId: createData.jobPlaceId,
        //         skillId: createData.skillId,
        //         uniformId: createData.uniformId,
        //         dates: createData.dates,
        //         timezone: createData.timezone,
        //     }));
        // }
        // if (!this.isLastPage && this.filterFavourites && ((scrollBottom >= (height - elementHeight) * 0.7) || (scrollBottom2 >= (height2 - elementHeight2) * 0.7))) {
        //     this.store.dispatch(new AssignmentsActions.LoadFavouriteGuards({
        //         jobPlaceId: createData.jobPlaceId
        //     }));
        // }
    }

    public onChangeLeader(event: number, checked: boolean): void {

        if (checked) {

            // if (this.selectedLeaders.length == this.form.get('noGrLeaders').value) {
            //     return;
            // }

            if (this.selectedPersonnel.find(p => p == event)) {
                let index = this.selectedPersonnel.indexOf(event);
                this.selectedPersonnel.splice(index, 1);
            }
            this.selectedLeaders.push(event);
        } else {
            let index = this.selectedLeaders.indexOf(event);
            this.selectedLeaders.splice(index, 1);
        }
    }

    public onChangePersonnel(event: number, checked: boolean): void {
        if (checked) {

            // if (this.selectedPersonnel.length == this.form.get('noPersonnel').value) {
            //     return;
            // }

            if (this.selectedLeaders.find(p => p == event)) {
                let index = this.selectedLeaders.indexOf(event);
                this.selectedLeaders.splice(index, 1);
            }
            this.selectedPersonnel.push(event);
        } else {
            let index = this.selectedPersonnel.indexOf(event);
            this.selectedPersonnel.splice(index, 1);
        }
    }

    public isSelectedLeader(id: number) {
        return this.selectedLeaders.find(l => l == id);
    }

    public isSelectedPersonnel(id: number) {
        return this.selectedPersonnel.find(l => l == id);
    }

    public onFilterEveryone(): void {
        this.store.dispatch(new AssignmentsActions.ClearGuards());
        this.store.dispatch(new AssignmentsActions.LoadObjectGuards({
            jobPlaceId: createData.jobPlaceId,
            skillId: createData.skillId,
            uniformId: createData.uniformId,
            dates: createData.dates,
            timezone: createData.timezone,
        }));
        this.filterEveryone = true;
        this.filterFavourites = false;
    }

    public onFilterFavourites(): void {
        this.store.dispatch(new AssignmentsActions.ClearFavouriteGuards());
        this.store.dispatch(new AssignmentsActions.LoadFavouriteGuards({jobPlaceId: createData.jobPlaceId}));
        this.filterEveryone = false;
        this.filterFavourites = true;
    }

    public isDisabledLeader(id: number): boolean {
        return this.selectedLeaders.length == this.form.get('noGrLeaders').value && !this.selectedLeaders.find(e => e == id);
    }

    public isDisabledPersonnel(id: number): boolean {
        return this.selectedPersonnel.length == (this.form.get('noPersonnel').value - this.form.get('noGrLeaders').value) && !this.selectedPersonnel.find(e => e == id);
    }

    public onSaveAndContinue(): void {
        let numberOfPersonnel = Number(this.form.get('noPersonnel').value);
        let numberOfGroupLeaders = Number(this.form.get('noGrLeaders').value);
        if (numberOfPersonnel + numberOfGroupLeaders == 0) {
            this._snackBar.open('You have to input number of personnel', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });
            return;
        }

        // if ((this.form.get('noPersonnel').value != this.selectedLeaders.length + this.selectedPersonnel.length) && this.handPick) {
        //     this._snackBar.open('You have to select chosen number of personnel', 'Close', {
        //         duration: 3000,
        //         panelClass: ['snackbar']
        //     });
        //     return;
        // }


        createData.numberOfPersonnel = Number(this.form.get('noPersonnel').value);
        createData.numberOfLeaders = Number(this.form.get('noGrLeaders').value);
        createData.leadersIds = this.selectedLeaders.map(item => Number(item));
        createData.guardsIds = this.selectedPersonnel.map(item => Number(item));
        // createData.isManual = this.handPick;
        // createData.engagementType = this.handPick ? 1 : 2

        if (this.isCreatingAssignmentFromHistory) {
            this.store.dispatch(new AssignmentsActions.UpdateStep(7));
        } else {
            this.store.dispatch(new AssignmentsActions.UpdateStep(7));
        }
    }

    onResolveGuardDetailAction(actionData: { guardId: number, isChecked: boolean }) {
        // console.log(ac, isChecked);
        switch (this.guardType) {
            case 'guard':
                this.onChangePersonnel(actionData.guardId, actionData.isChecked);
                break;
            case 'leader':
                this.onChangeLeader(actionData.guardId, actionData.isChecked);
                break;

        }
    }

    onClickGuardDetails(guard: GuardModel, guardType: string) {
        this.guardDetail = guard;
        this.guardType = guardType;

        switch (this.guardType) {
            case 'guard':
                if (this.form.get('noPersonnel').value == 0) {
                    this.guardDetailAction = this.guardDetailActionOptions.defineNumberOfPersonnel;
                    break;
                }
                this.guardDetailAction = this.isSelectedPersonnel(guard.id)
                    ? this.guardDetailActionOptions.unpickPerson
                    : this.guardDetailActionOptions.pickPerson;
                break;
            case 'leader':
                if (this.form.get('noGrLeaders').value == 0) {
                    this.guardDetailAction = this.guardDetailActionOptions.defineNumberOfLeaders;
                    break;
                }
                this.guardDetailAction = this.isSelectedLeader(guard.id)
                    ? this.guardDetailActionOptions.unpickPerson
                    : this.guardDetailActionOptions.pickPerson;
                break;
        }
    }
}
