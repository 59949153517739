<div class="top">

	<div class="d-flex">
		<div class="back" routerLink="/components/assignments">
			<img src="/assets/img/back arrow.svg">
			<p class="back-p">{{'back' | translate}}</p>
		</div>
		<p class="faded-p">{{'navigation.assignments' | translate}}</p>
		<img class="small-arrow" src="/assets/img/arrow small.svg">
		<p class="faded-p">{{assignment?.jobPlace?.name}}</p>
	</div>

	<div class="d-block d-md-flex justify-content-between gap-2">
		<div class="d-flex align-items-center justify-content-start gap-2 mt-3 mb-3">
			<h1>{{assignment.jobPlace?.name}}</h1>
			<div class="pill" [ngClass]="{
                                'draft' : assignment?.status === 1,
                                'published': assignment?.status === 2,
                                'ongoing' : assignment?.status === 3,
                                'finished' : assignment?.status === 4
                                }">

				<span *ngIf="assignment?.status === 1;">{{'assignment-status.1' | translate}}</span>
				<span *ngIf="assignment?.status === 2;">{{'assignment-status.2' | translate}}</span>
				<span *ngIf="assignment?.status === 3;">{{'assignment-status.3' | translate}}</span>
				<span *ngIf="assignment?.status === 4;">{{'assignment-status.4' | translate}}</span>
			</div>
		</div>

		<p class="cancel-p mt-auto mb-auto"
		   *ngIf="assignment.status !== 4"
		   (click)="showCancelAssignmentModal = !showCancelAssignmentModal">{{'assignment.cancel-assignment.title' |
			translate}}</p>
	</div>

	<p>{{assignment?.startAt | date:"d MMMM YYYY"}}, {{assignment?.startAt | date:"HH:mm"}}
		- {{assignment?.endAt | date:"d MMMM YYYY"}}, {{assignment?.endAt | date:"HH:mm"}} </p>
	<div class="d-block d-sm-flex justify-content-start gap-5 flex-row">
		<div class="mr-5">
			<p class="title">{{'assignment-details.Competence' | translate}}</p>
			<p>{{assignment?.skill?.name}}</p>
		</div>
		<div class="mr-5">
			<p class="title">{{'assignment-details.Total cost' | translate}}</p>
			<p>{{assignment.totalCost | currency}}</p>
		</div>
		<div>
			<p class="title">{{'assignment-details.Creator' | translate}}</p>
			<p>{{assignment?.createdBy?.firstName}} {{assignment?.createdBy?.lastName}}</p>
		</div>
	</div>
</div>

<div class="main-wrapper">
	<div class="row">
		<div class="col-12">
			<div class="inner-wrapper">
				<!--info & description-->
				<div class="wrapper">
					<div class="d-flex justify-content-between gap-3 flex-row">
						<div>
							<p class="title">{{'assignment-details.Selecting guards' | translate}}</p>
							<p *ngIf="assignment.engagementType === 1">{{'assignment-details.Hand-pick personnel' |
								translate}}</p>
							<p *ngIf="assignment.engagementType === 2">{{'assignment-details.Personnel apply for the
								assignment' | translate}}</p>
						</div>
						<div>
							<p class="title">{{'assignment-details.Assignment category' | translate}}</p>
							<p>{{assignment.assignmentCategory.name}}</p>
						</div>
						<div>
							<p class="title">{{'assignment-details.Type of assignment' | translate}}</p>
							<p *ngIf="assignment.isRecurring">{{'assignment.assignment-type.recurring' | translate}}</p>
							<p *ngIf="!assignment.isRecurring">{{'assignment.assignment-type.one-time-event' |
								translate}}</p>
						</div>
						<div>
							<p class="title">{{'assignment-details.Object' | translate}}</p>
							<p>{{assignment.jobPlace?.name}}</p>
							<p class="faded-p">{{assignment.jobPlace?.address}}</p>
						</div>
					</div>
					<div class="line"></div>
					<h3>{{'assignment-details.Description' | translate}}</h3>
					<p class="faded-p description">{{assignment.description}}</p>
					<!--<p class="blue-p">See all</p>-->
				</div>

				<!--shifts-->
				<div class="wrapper">
					<h3>{{'assignment-details.Shifts' | translate}}</h3>
					<div class="scroll-div shift-list">
						<div class="row shift-item"
						     *ngFor="let shift of assignment.shifts" (click)="onSelectShift(shift)"
						     [ngClass]="selectedShift === shift ? 'active' : ''">
							<div class="col-2">
								<span>{{shift.startAt | date: 'MMM d, YYYY'}}</span>
							</div>
							<div class="col-2">
								<div class="btn btn-primary"
								     *ngIf="shiftStatusConst.PUBLISH === shift.status || shiftStatusConst.READY === shift.status"
								     (click)="onEditShiftHours($event, shift)">{{'Edit hours' | translate}}</div>
							</div>
							<div class="col-2">
								<span>{{shift.startAt | date: 'HH:mm'}} - {{shift.endAt | date: 'HH:mm'}}</span>
							</div>
							<div class="col-2">
								<!--<span class="faded-p">{{shift.duration}} {{'assignment-details.hours' | translate}}</span>-->
								<span class="faded-p">
                                        {{momentClass.duration(momentClass(shift.endAt)
                                        .diff(momentClass(shift.startAt))).format('HH:mm')}}
                                    </span>
							</div>
							<div class="col-4 text-end">
								<div class="pill" [ngClass]="{
                                'publish' : shift.status === 1,
                                'ready': shift.status === 2,
                                'ongoing' : shift.status === 3,
                                'finished' : shift.status === 4,
                                'canceled' : shift.status === 5
                                }">
									<span *ngIf="shift.status === 1;">{{'shift-status.1' | translate}}</span>
									<span *ngIf="shift.status === 2;">{{'shift-status.2' | translate}}</span>
									<span *ngIf="shift.status === 3;">{{'shift-status.3' | translate}}</span>
									<span *ngIf="shift.status === 4;">{{'shift-status.4' | translate}}</span>
									<span *ngIf="shift.status === 5;">{{'shift-status.5' | translate}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!--personnel with published-->
				<div class="wrapper" *ngIf="selectedShift?.status == 1 || selectedShift?.status == 2">
					<div class="d-flex justify-content-between">
						<h3>{{'assignment-details.Personnel' | translate}}</h3>
						<!--show only if published-->
						<div class="d-flex align-items-center"
						     *ngIf="selectedShift.status == 1 && !selectedShift.isFull">
							<ng-container [ngSwitch]="assignment.engagementType">
                                <span *ngSwitchCase="1"
                                      class="faded-p mr-4">
                                    {{selectedShift?.guards.hired.length}} {{ 'shift.accepted' | translate }}</span>
								<span *ngSwitchCase="2"
								      class="faded-p mr-4">
                                    {{selectedShift?.guards.applied.length}} {{ 'shift.applicants' | translate }}</span>
							</ng-container>
							<span (click)="pickTeamModal = true"
							      *ngIf="selectedShift?.guards.applied.length"
							      class="btn btn-primary rounded-pill">{{'assignment-details.Pick applicants' | translate}}</span>
						</div>

					</div>
					<div class="scroll-div">
						<h4>{{selectedShift.startAt | date:"MMM d, YYYY" }}</h4>

						<!--assignment published || ready-->
						<ng-container *ngIf="assignment.status === 2 || assignment.status === 3">
							<ng-container *ngIf="selectedShift.guards.hired.length > 0; else noGuards">
								<p class="section-p" *ngIf="selectedShift.status != 2">{{'assignment-details.Personnel' | translate}}
									({{selectedShift.guardsHiredCount}} {{'assignment-details.of' | translate}}
									{{selectedShift.totalGuardsAppliedCount}} {{'assignment-details.picked' |
									translate}})</p>
								<p class="section-p" *ngIf="selectedShift.status == 2">{{'assignment-details.Personnel' | translate}}
									({{selectedShift.guardsHiredCount}} {{'assignment-details.picked' |
									translate}})</p>
								<div class="row">
									<div class="col-12 col-sm-6"
									     *ngFor="let guard of selectedShift.guards.hired; let index = index">
										<div class="personnel-hours mr-2"
										     [ngClass]="{'ml-2': 1 === index % 2, 'mr-2': 0 === index % 2}">
											<div class="d-flex justify-content-between gap-2 flex-row">
												<div class="d-flex justify-content-start gap-1">
													<div class="img-wrapper" (click)="guardDetail = guard"
													     role="button">
														<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
														     alt="">
													</div>
													<!--<img src="/assets/img/Ellipse 34.svg">-->
													<div class="basic-info">
														<p (click)="guardDetail = guard" role="button">
															{{guard.firstName}}</p>
														<!--<p>{{guard.firstName}} {{guard.lastName}}</p>-->
														<!--<p class="faded-p">Group Leader</p>-->
														<a routerLink="/components/messages"
														   class="text-decoration-none"
														   *ngIf="guard.guardShift.inConversation"
														   [queryParams]="{conversationId: selectedShift.jobPlace.conversationId}">
															{{'assignment-details.Send message' | translate}}</a>
													</div>
												</div>
												<!--/toggle-->
											</div>
										</div>
									</div>
								</div>

							</ng-container>
							<ng-template #noGuards>
								<p>{{'assignment-details.Selected shift has no guards.' | translate}}</p>
							</ng-template>
						</ng-container>

						<!--assignment finished-->
						<ng-container *ngIf="assignment.status === 4">
							<ng-container *ngIf="selectedShift.guards.hired.length > 0; else noGuards">
								<p class="section-p">{{'assignment-details.Personnel' | translate}}
									({{selectedShift.guardsHiredCount}} {{'assignment-details.of' | translate}}
									{{selectedShift.totalGuardsAppliedCount}} {{'assignment-details.picked' |
									translate}})</p>
								<div class="personnel-hours" *ngFor="let guard of selectedShift.guards.hired">
									<div class="d-flex justify-content-between gap-2 flex-row">
										<div class="d-flex justify-content-start gap-1">
											<div class="img-wrapper" (click)="guardDetail = guard" role="button">
												<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
												     alt="">
											</div>
											<div class="basic-info">
												<p (click)="guardDetail = guard" role="button">{{guard.firstName}}</p>
												<!--<p>{{guard.firstName}} {{guard.lastName}}</p>-->
												<!--<p class="faded-p">Group Leader</p>-->
											</div>
										</div>
										<!--<p class="time">{{shift.startTime}} - {{shift.endTime}}</p>-->
										<!--toggle-->
										<div class="btn-group" role="group">
											<button *ngIf="checked" type="button" class="btn btn-left-checked">
												<img src="/assets/img/checked white.svg" alt="">
											</button>
											<button *ngIf="!checked" type="button" class="btn btn-left-unchecked"
											        (click)="checked = true">
												<img src="/assets/img/checked grey.svg" alt="">
											</button>
											<button *ngIf="!checked" type="button" class="btn btn-right-unchecked">
												<img src="/assets/img/x white.svg" alt="">
											</button>
											<button *ngIf="checked" type="button" class="btn btn-right-checked"
											        (click)="checked = false">
												<img src="/assets/img/x grey.svg" alt="">
											</button>
										</div>
										<!--/toggle-->
									</div>
								</div>
							</ng-container>
							<ng-template #noGuards>
								<p>{{'assignment-details.Selected shift has no guards.' | translate}}</p>
							</ng-template>
						</ng-container>

					</div>
				</div>
				<div class="wrapper" *ngIf="selectedShift?.status == 3 || selectedShift?.status == 4">
					<div class="d-flex justify-content-between">
						<h3>{{'assignment-details.Hours' | translate}}</h3>
					</div>
					<div class="scroll-div">
						<h4>{{selectedShift.startAt | date:"MMM d, YYYY" }}</h4>
						<ng-container *ngIf="selectedShift.guards.hired.length > 0; else noGuards">
							<div class="personnel-hours" *ngFor="let guard of selectedShift.guards.hired">

								<div class="row align-items-center">
									<div class="col-4">
										<div class="d-flex justify-content-start gap-1">
											<div class="img-wrapper" (click)="guardDetail = guard" role="button">
												<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
												     alt="">
											</div>
											<div class="basic-info">
												<p (click)="guardDetail = guard" role="button">{{guard.firstName}}</p>
												<!--<p>{{guard.firstName}} {{guard.lastName}}</p>-->
												<!--<p>{{guard.guardShift | json}}, {{guard.guardShift.status}}</p>-->
												<!--<p class="faded-p">Group Leader</p>-->
												<a routerLink="/components/messages"
												   class="text-decoration-none"
												   *ngIf="guard.guardShift.inConversation"
												   [queryParams]="{conversationId: selectedShift.jobPlace.conversationId}">
													{{'assignment-details.Send message' | translate}}</a>
											</div>
										</div>
									</div>
									<div class="col-4">
										<p class="time">{{(guard.guardShift.checkinTime | date: 'HH:mm') ||
											'guard-shift-status.Not checked-in' | translate}} -
											{{(guard.guardShift.checkoutTime | date: 'HH:mm') || 'guard-shift-status.Not
											check-out' | translate}}</p>
									</div>
									<div class="col-4 text-end">
										<ng-container [ngSwitch]="selectedShift.status">
											<ng-container *ngSwitchCase="3">
												<div class="pill">
                                                    <span class="pill" [ngClass]="{
                                'approve-by-system' : guard.guardShift.status === 300,
                                'approve': guard.guardShift.status === guardShiftStatusConst.CUSTOMER_APPROVED_HOURS,
                                'not-approve': guard.guardShift.status === guardShiftStatusConst.CUSTOMER_DISAPPROVE_HOURS,
                                'guard-canceled-job': guard.guardShift.status === guardShiftStatusConst.GUARD_CANCELED,
                                'guard-check-out': guard.guardShift.status === guardShiftStatusConst.CHECKEDOUT,
                                'guard-check-in': guard.guardShift.status === guardShiftStatusConst.CHECKEDIN
                                }">
                                                        {{'guard-shift-status.' + guard.guardShift.status | translate}}
                                                    </span>
												</div>
											</ng-container>
											<ng-container *ngSwitchCase="4">
												<div class="btn-group" role="group"
												     *ngIf="readyForAproveHoursStatuses.includes(guard.guardShift.status); else showStatus">

													<button type="button"
													        class="btn btn-left-checked"
													        (click)="onApproveHours(selectedShift.id, guard.id,
                                                    guardShiftStatusConst.CUSTOMER_APPROVED_HOURS)">
														<img src="/assets/img/checked white.svg" alt="">
													</button>
													<button type="button"
													        class="btn btn-right-unchecked"
													        (click)="onApproveHours(selectedShift.id, guard.id,
                                                    guardShiftStatusConst.CUSTOMER_DISAPPROVE_HOURS)">
														<img src="/assets/img/x white.svg" alt="">
													</button>

													<ng-template *ngIf="false">
														<button *ngIf="checked" type="button"
														        class="btn btn-left-checked">
															<img src="/assets/img/checked white.svg" alt="">
														</button>
														<button *ngIf="!checked" type="button"
														        class="btn btn-left-unchecked"
														        (click)="checked = true">
															<img src="/assets/img/checked grey.svg" alt="">
														</button>
														<button *ngIf="!checked" type="button"
														        class="btn btn-right-unchecked">
															<img src="/assets/img/x white.svg" alt="">
														</button>
														<button *ngIf="checked" type="button"
														        class="btn btn-right-checked"
														        (click)="checked = false">
															<img src="/assets/img/x grey.svg" alt="">
														</button>
													</ng-template>

												</div>
												<!--/toggle-->
												<ng-template #showStatus>
													<div class="pill" [ngClass]="{
                                'approve-by-system' : guard.guardShift.status === 300,
                                'approve': guard.guardShift.status === guardShiftStatusConst.CUSTOMER_APPROVED_HOURS,
                                'not-approve': guard.guardShift.status === guardShiftStatusConst.CUSTOMER_DISAPPROVE_HOURS,
                                'guard-canceled-job': guard.guardShift.status === guardShiftStatusConst.GUARD_CANCELED,
                                'guard-check-out': guard.guardShift.status === guardShiftStatusConst.CHECKEDOUT,
                                'guard-check-in': guard.guardShift.status === guardShiftStatusConst.CHECKEDIN
                                }">
                                            <span>
                                                {{'guard-shift-status.' + guard.guardShift.status | translate}}
                                            </span>
														<!--<span *ngIf="guard.guardShift.status === 301;">-->
														<!--{{'guard-shift-status.301' | translate}}-->
														<!--</span>-->
													</div>
												</ng-template>
											</ng-container>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-container>
						<ng-template #noGuards>
							<p>{{'assignment-details.Selected shift has no guards.' | translate}}</p>
						</ng-template>
					</div>
				</div>

				<!--ratings-->
				<div class="wrapper" *ngIf="selectedShift && selectedShift.status === 4">
					<h3>{{'assignment-details.Ratings' | translate}}</h3>
					<div class="scroll-div">
						<div *ngIf="false">
							<p class="section-p">Group leaders (1)</p>
							<div class="personnel-hours" *ngFor="let guard of selectedShift.guards.hired">
								<div class="d-flex justify-content-between gap-2">
									<div class="d-flex justify-content-start gap-1">
										<div class="img-wrapper">
											<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
											     alt="">
										</div>
										<div class="basic-info">
											<p>Aaron Persson</p>
											<p class="faded-p">Group Leader</p>
										</div>
									</div>
									<div class="flexed-rating">
										<p class="raiting-p">4 of 5</p>
										<ngb-rating max="5" [rate]="rate" [readonly]="true"></ngb-rating>
									</div>
								</div>
							</div>
						</div>

						<p class="section-p">{{'assignment-details.Personnel' | translate}}
							({{selectedShift.guards.hired.length}})</p>
						<div class="personnel-hours" *ngFor="let guard of selectedShift.guards.hired">
							<div class="d-flex justify-content-between gap-2">
								<div class="d-flex justify-content-start gap-1">
									<div class="img-wrapper" (click)="guardDetail = guard" role="button">
										<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}" alt="">
									</div>
									<div class="basic-info">
										<p (click)="guardDetail = guard" role="button">{{guard.firstName}}</p>
										<!--<p>{{guard.firstName + ' ' + guard.lastName}}</p>-->
										<p class="faded-p">{{'assignment-details.Guard' | translate}}</p>
									</div>
								</div>
								<div class="flexed-rating">
									<p class="raiting-p" *ngIf="guard.guardShift.rating; else noRating">
										{{ guard.guardShift.rating.rate + ' of 5' }}

										<ngb-rating max="5"
										            [rate]="guard.guardShift.rating.rate"
										            class="rating-active"
										            [readonly]="true">
										</ngb-rating>
									</p>
									<ng-template #noRating>
										<p class="raiting-p">{{'assignment-details.Not rated' | translate}}</p>
										<ngb-rating max="5"
										            [readonly]="false"
										            (rateChange)="onRateGuard($event, guard.id)">
										</ngb-rating>
									</ng-template>


								</div>
							</div>
						</div>
					</div>
				</div>

				<!--reports-->
				<div class="wrapper" *ngIf="selectedShift">
					<h3>{{'assignment-details.Reports' | translate}}</h3>
					<ng-template #noReports>
						<p>{{'assignment-details.No reports submitted for this shift.' | translate}}</p>
					</ng-template>
					<div class="scroll-div reports" *ngIf="selectedShift.reports.length > 0; else noReports">
						<div class="row reports-header">
							<div class="col">{{'assignment-details.Subject' | translate}}</div>
							<div class="col">{{'assignment-details.Date' | translate}}</div>
							<div class="col">{{'assignment-details.Time' | translate}}</div>
							<div class="col">{{'assignment-details.# Assignment' | translate}}</div>
							<div class="col">{{'assignment-details.Object' | translate}}</div>
						</div>

						<div class="row report-list-item"
						     role="button"
						     *ngFor="let report of selectedShift.reports"
						     routerLink="/components/reports/report/{{report.id}}">
							<div class="col"><p>{{report.subject}}</p></div>
							<div class="col"><p class="black">{{report.incidentTime | date: 'MMM d, Y'}}</p></div>
							<div class="col"><p>{{report.incidentTime | date: 'HH:mm'}}</p></div>
							<div class="col"><p>{{assignment.id}}</p></div>
							<div class="col"><p>{{assignment.jobPlace.name}}</p></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal [modalType]="1" *ngIf="showCancelAssignmentModal">
	<div class="popup-header">
		<div class="d-flex justify-content-between gap-2">
			<h3 class="modal-title">{{'assignment-details.Cancel assignment' | translate}}</h3>
			<span (click)="showCancelAssignmentModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
		</div>
	</div>
	<div class="popup-body mt-3 mb-3">

		<p class="section-p"
		   style="margin-top: 25px; text-transform: none;">{{"assignment-details.cancel-assignment-body" |
			translate}}</p>
	</div>
	<div class="popup-footer">
		<div class="d-flex justify-content-end gap-2">
			<span class="btn btn-primary" (click)="showCancelAssignmentModal = false;">{{'assignment-details.Discard' | translate}}</span>
			<input type="button"
			       value="{{'assignment-details.Yes, cancel assignment' | translate}}"
			       class="btn btn-danger"
			       (click)="onCancelAssignment()"
			/>
		</div>
	</div>
</app-modal>
<app-modal [modalType]="1" *ngIf="pickTeamModal">
	<div class="popup-header">
		<div class="d-flex justify-content-between gap-2">
			<h3 class="modal-title">{{'assignment-details.Pick applicants' | translate}}</h3>
			<span (click)="pickTeamModal = false" class="close; selectedPersonnel = [];">
                <img src="/assets/img/Close.svg" alt="">
            </span>
		</div>
	</div>
	<div class="popup-body mt-3 mb-3">

		<!--<p class="section-p">Group leaders ({{selectedLeaders.length}} of {{assignment?.numberOfLeaders}})</p>-->
		<!--<div class="scroll-div" id="leaders" (scroll)="onScrollGuards($event)">-->
		<!--<div class="row">-->
		<!--<div [ngClass]="isSelectedLeader(guard?.id) ? 'selected' : ''" class="personnel-wrapper" *ngFor="let guard of guards; let inx = index">-->
		<!--<div class="d-flex justify-content-between flex-row gap-2">-->
		<!--<div class="d-flex justify-content-start gap-1">-->
		<!--<img *ngIf="guard?.image; else noImg" class="profile-img" src="{{guard?.image}}">-->
		<!--<ng-template #noImg>-->
		<!--<img class="profile-img" src="/assets/img/Ellipse 34.svg">-->
		<!--</ng-template>-->
		<!--<div class="basic-info">-->
		<!--<p>{{guard?.firstName}} {{guard?.lastName}}</p>-->
		<!--<div class="d-flex">-->
		<!--<ngb-rating max="5" [rate]="guard?.averageRate" [readonly]="true"></ngb-rating>-->
		<!--<p class="faded-p mt-auto mb-auto">{{guard?.averageRate}}</p>-->
		<!--<img class="faded-check-icon" src="/assets/img/Group 596.png" alt="">-->
		<!--<p class="faded-p check-p mt-auto mb-auto">4</p>-->
		<!--</div>-->
		<!--</div>-->
		<!--</div>-->
		<!--<input class="checkbox mt-auto mb-auto" type="checkbox"-->
		<!--[value]="guard.id"-->
		<!--(change)="onChangeLeader($event.target.value, $event.target.checked)"-->
		<!--[checked]="isSelectedLeader(guard.id)"-->
		<!--[disabled] = "isDisabledLeader(guard.id)">-->
		<!--</div>-->
		<!--</div>-->
		<!--</div>-->
		<!--</div>-->

		<p class="section-p"
		   style="margin-top: 25px;">{{'assignment-details.Personnel' | translate}}
			({{selectedShift.acceptedGuardsCount}} {{'assignment-details.of' | translate}}
			{{selectedShift.guardsAppliedCount}})</p>
		<div class="scroll-div" id="personnel" (scroll)="onScrollGuards($event)">
			<div class="row">
				<div [ngClass]="isSelectedPersonnel(guard?.id) ? 'selected' : ''"
				     class="personnel-wrapper"
				     *ngFor="let guard of selectedShift.guards.applied; let inx = index">
					<div class="d-flex justify-content-between flex-row flex-wrap gap-2">
						<div class="d-flex justify-content-start gap-1">
							<div class="img-wrapper">
								<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}" alt="">
							</div>
							<div class="basic-info">
								<p>{{guard?.firstName}}</p>
								<!--<p>{{guard?.firstName}} {{guard?.lastName}}</p>-->
								<div class="d-flex">
									<ngb-rating max="5" [rate]="guard.averageRate" [readonly]="true"></ngb-rating>
									<p class="faded-p mt-auto mb-auto">{{guard.averageRate | number : '1.1-2' }}</p>
									<img class="faded-check-icon" src="/assets/img/Group 596.png" alt="">
									<p class="faded-p check-p mt-auto mb-auto">{{guard.ratingCount}}</p>
								</div>
							</div>
						</div>
						<input class="checkbox mt-auto mb-auto" type="checkbox"
						       [value]="guard.id"
						       [checked]="isSelectedPersonnel(guard.id)"
						       [disabled]="isDisabledPersonnel(guard.id)"
						       (change)="onChangePersonnel($event.target.value, $event.target.checked)">
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="popup-footer">
		<div class="d-flex justify-content-end gap-2">
			<span class="cancel-btn" (click)="pickTeamModal = false; selectedPersonnel = [];">Cancel</span>
			<input type="button"
			       value="{{'assignment-details.Pick applicants' | translate}}"
			       class="btn save-btn btn-primary"
			       (click)="onPickApplicants()"
			/>
		</div>
	</div>
</app-modal>

<app-guard-detail-modal [guard]="guardDetail" *ngIf="guardDetail"
                        (closeModal)="guardDetail = null"></app-guard-detail-modal>

<app-modal *ngIf="editShiftHoursModal">
	<div class="popup-header mb-4">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title mb-3">{{'Change hours' | translate}}</h3>
				<p class="text-muted mb-2">{{'Minimum shift duration:' | translate: {number: minShiftDuration} }}</p>
				<p class="text-muted mb-0">{{'Maximum shift duration:' | translate: {number: maxShiftDuration} }}</p>
			</div>
			<div class="col-4 text-right">
                <span (click)="editShiftHoursModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<form [formGroup]="updateShiftHoursForm" (ngSubmit)="onUpdateShiftHours()" id="updateShiftHoursForm">
			<div class="row">
				<div class="col-5">
					<div class="form-group" style="margin-right: 20px;">
						<label for="startTime" class="required">{{'Start time' | translate}}</label>
						<input formControlName="startTime" class="form-control"
						       type="time"
						       id="startTime"
						       (change)="onChangeShiftTime(updateShiftHoursForm.get('shift').value)"/>
					</div>
				</div>
				<div class="col-5">
					<div class="form-group" style="margin-right: 20px;">
						<label for="endTime" class="required">{{'End time' | translate}}</label>
						<input formControlName="endTime" class="form-control" type="time" id="endTime"
						       (change)="onChangeShiftTime(updateShiftHoursForm.get('shift').value)"/>
					</div>
				</div>
				<div class="col-2">
					<div class="form-group">
						<label for="totalHours">{{'Total hours' | translate}}</label>
						<input type="text"
						       [readonly]="true"
						       [ngClass]="{invalid: updateShiftHoursForm.hasError('shiftDurationValidator', 'shiftDuration')}"
						       formControlName="shiftDuration"
						       class="form-control"
						       id="totalHours">
					</div>

				</div>
			</div>
		</form>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="editShiftHoursModal = false">{{'buttons.cancel' | translate}}</span>
					<input type="submit"
					       value="{{'Save' | translate}}"
					       form="updateShiftHoursForm"
					       [disabled]="!updateShiftHoursForm.valid"
					       class="btn save-btn btn-primary"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>