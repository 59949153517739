import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from './my-staff.reducer';
import * as fromApp from '../../../store/app.reducer';
import * as MyStaffActions from './my-staff.actions';
import { environment } from '../../../../environments/environment';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginationModel } from '../../../models/pagination.model';
import { GuardModel } from '../../../models/guard.model';
import { GuardInviteModel } from '../../../models/guardInvite.model';
import { PayslipItemModel } from '../../../models/payslip-item.model';

@Injectable()
export class MyStaffEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private _snackBar: MatSnackBar,
        private store: Store<fromApp.AppState>,
    ) {
    }

    loadGuards = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.LOAD_GUARDS_INVITES),
            switchMap((action: MyStaffActions.LoadGuards) => {
                let params = new HttpParams();
                params = params.append('page', action.payload.page);
                params = params.append('per_page', 10);
                params = params.append('sort', 'desc');

                if (action.payload.params) {
                    action.payload.params.forEach(param => {
                        params = params.append(param.key, param.value);
                    });
                }


                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/guards`, {params})
                           .pipe(
                               switchMap((resData: { data: GuardInviteModel[], pagination: PaginationModel }) => {
                                   return of(new MyStaffActions.LoadGuardsSuccess({
                                       guards: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.LoadGuardsFailed());
                               }),
                           );
            }),
        );
    });

    loadGuard = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.LOAD_GUARD),
            switchMap((action: MyStaffActions.LoadGuard) => {
                return this.http.get(environment.apiUrl + `/guards/${action.payload.guardId}`)
                           .pipe(
                               switchMap((resData: { data: GuardModel }) => {
                                   return of(new MyStaffActions.LoadGuardSuccess({
                                       guard: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.LoadGuardFailed());
                               }),
                           );
            }),
        );
    });

    loadPayslipItems = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF),
            switchMap((action: MyStaffActions.LoadPayslipItemsForGuardMyStaff) => {
                // /customers/{customer}/my-staff-guards/{guard}/payslips
                return this.http.get(environment.apiUrl
                    + `/customers/${action.payload.customerId}/my-staff-guards/${action.payload.guardId}/payslips`, {
                               params: {
                                   page: action.payload.page,
                               },
                           })
                           .pipe(
                               switchMap((resData: { data: PayslipItemModel[], pagination: PaginationModel }) => {
                                   return of(new MyStaffActions.LoadPayslipItemsForGuardMyStaffSuccess({
                                       payslipItems: resData.data,
                                       pagination: resData.pagination
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.LoadPayslipItemsForGuardMyStaffFailed());
                               }),
                           );
            }),
        );
    });

    loadGuardsWithPayrolls = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.LOAD_GUARDS_WITH_PAYROLLS),
            switchMap((action: MyStaffActions.LoadGuardsWithPayrolls) => {
                let params = new HttpParams();
                params = params.append('page', action.payload.page);
                params = params.append('per_page', 10);
                params = params.append('sort', 'desc');

                if (action.payload.params) {
                    action.payload.params.forEach(param => {
                        params = params.append(param.key, param.value);
                    });
                }
                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/guards/payrolls`,
                    action.payload.body,
                    {
                        params,
                    })
                           .pipe(
                               switchMap((resData: { data: GuardModel[], pagination: PaginationModel; }) => {
                                   return of(new MyStaffActions.LoadGuardsWithPayrollsSuccess({
                                       guards: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.LoadGuardsWithPayrollsFailed());
                               }),
                           );
            }),
        );
    });

    editGuard = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.EDIT_GUARD),
            switchMap((action: MyStaffActions.EditGuard) => {
                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/my-staff-guards/${action.payload.guardId}`, action.payload.data)
                           .pipe(
                               switchMap((resData: { data: GuardModel }) => {
                                   this._snackBar.open('Guard successfully updated', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new MyStaffActions.EditGuardSuccess({
                                       guard: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   this._snackBar.open('Failed to edit this guard! Please, try again later.', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new MyStaffActions.EditGuardFailed());
                               }),
                           );
            }),
        );
    });

    deleteGuard = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.DELETE_GUARD),
            switchMap((action: MyStaffActions.DeleteGuard) => {
                return this.http.delete(environment.apiUrl + `/customers/${action.payload.customerId}/guards/${action.payload.guardId}`)
                           .pipe(
                               switchMap((resData: { data: GuardModel }) => {
                                   this._snackBar.open('Guard successfully deleted!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   this.router.navigate(['/components/my-staff']);
                                   return of(new MyStaffActions.DeleteGuardSuccess({
                                       guard: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   this._snackBar.open('Failed to delete this guard! Please, try again later.', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new MyStaffActions.DeleteGuardFailed());
                               }),
                           );
            }),
        );
    });

    sendInvites = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.SEND_INVITES),
            switchMap((action: MyStaffActions.SendInvites) => {

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/guards/invites`, {
                               data: action.payload.data,
                           })
                           .pipe(
                               switchMap((resData: { data: any }) => {
                                   this._snackBar.open('Invites sent successfully!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   this.router.navigate(['/components/my-staff']);
                                   return of(new MyStaffActions.SendInvitesSuccess({
                                       data: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   this.router.navigate(['/components/my-staff']);
                                   this._snackBar.open('Failed to send invites. Try again later.', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new MyStaffActions.SendInvitesFailed());
                               }),
                           );
            }),
        );
    });

    acceptInvite = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.ACCEPT_INVITE),
            switchMap((storeData: MyStaffActions.AcceptInvite) => {
                return this.http.get(environment.apiUrl + '/customer-guard-invites/' + storeData.payload.inviteToken + '/accept')
                           .pipe(
                               switchMap((resData: { data: GuardModel[] }) => {
                                   return [new MyStaffActions.AcceptInviteSuccess({guard: resData.data})];
                               }),
                               catchError(error => {
                                   this._snackBar.open('Failed to accept invitation! ', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new MyStaffActions.AcceptInviteFailed());
                               }),
                           );
            }),
        );
    });

    loadStaff = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.LOAD_STAFF),
            withLatestFrom(this.store.select('staff')),
            switchMap(([action, State]: [MyStaffActions.LoadStaff, State]) => {

                let params = new HttpParams();
                params = params.append('page', State.staffMeta.page);
                params = params.append('per_page', 10);

                if (action.payload.params) {
                    action.payload.params.forEach(param => {
                        params = params.append(param.key, param.value);
                    });
                }

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/my-staff-guards`, {
                               guardIds: action.payload.guardIds,
                           }, {params})
                           .pipe(
                               switchMap((resData: { data: GuardModel[], pagination: PaginationModel }) => {
                                   return of(new MyStaffActions.LoadStaffSuccess({
                                       guards: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.LoadStaffFailed());
                               }),
                           );
            }),
        );
    });

    loadMembers = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.LOAD_SKILL_MEMBERS),
            switchMap((action: MyStaffActions.LoadMembers) => {

                let params = new HttpParams();
                params = params.append('page', action.payload.page);
                params = params.append('per_page', 10);

                if (action.payload.params) {
                    action.payload.params.forEach(param => {
                        params = params.append(param.key, param.value);
                    });
                }

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/skills/${action.payload.skillId}/guards`, {params})
                           .pipe(
                               switchMap((resData: { data: GuardModel[], pagination: PaginationModel }) => {
                                   return of(new MyStaffActions.LoadMembersSuccess({
                                       members: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.LoadMembersFailed());
                               }),
                           );
            }),
        );
    });

    deleteMember = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.DELETE_SKILL_MEMBER),
            switchMap((action: MyStaffActions.DeleteSkillMember) => {

                return this.http.delete(environment.apiUrl + `/customers/${action.payload.customerId}/skills/${action.payload.skillId}/guards/${action.payload.guardId}`)
                           .pipe(
                               switchMap((resData: { data: GuardModel }) => {
                                   return of(new MyStaffActions.DeleteSkillMemberSuccess({
                                       guard: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.DeleteSkillMemberFailed());
                               }),
                           );
            }),
        );
    });

    addMembers = createEffect(() => {
        return this.actions$.pipe(
            ofType(MyStaffActions.ADD_SKILL_MEMBERS),
            switchMap((action: MyStaffActions.AddSkillMembers) => {

                return this.http.put(environment.apiUrl + `/customers/${action.payload.customerId}/skills/${action.payload.skillId}/guards`,
                    {
                        guardIds: action.payload.guardIds,
                    })
                           .pipe(
                               switchMap((resData: { data: GuardModel[] }) => {
                                   return of(new MyStaffActions.AddSkillMembersSuccess({
                                       guards: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new MyStaffActions.AddSkillMembersFailed());
                               }),
                           );
            }),
        );
    });
}
