<app-modal [modalType]="1">
	<div class="popup-header">
		<div class="d-flex justify-content-between gap-2">
			<h3 class="modal-title">{{(action == 'store'
				? 'staff.competences.create'
				: 'staff.competences.edit') | translate}}</h3>
			<span class="close" (click)="destroyComponent.emit()">
                <img src="/assets/img/Close.svg" alt="">
            </span>
		</div>
	</div>
	<div class="popup-body mt-3 mb-3">
		<form [formGroup]="skillForm" id="form" (ngSubmit)="onSubmitCompetence()">

			<div class="form-group">
				<label for="name">{{'staff.competences.form.name' | translate}}</label>
				<input formControlName="name" class="form-control" type="text" id="name"/>
			</div>
			<div class="form-group">
				<label for="parentId">{{'staff.competences.form.base' | translate}}</label>
				<select class="form-select" formControlName="parentId" id="parentId">
					<option *ngFor="let skill of this?.skills" [value]="skill?.id">{{skill?.name}}</option>
				</select>
			</div>
			<div class="form-group">
				<label for="desc">{{'staff.competences.form.description' | translate}}</label>
				<textarea formControlName="description" class="form-control" type="text" id="desc"></textarea>
			</div>

			<p>{{'staff.competences.form.salary' | translate}}</p>
			<div formGroupName="price">
				<div class="d-block d-md-flex justify-content-between gap-1">
					<div class="form-group">
						<label for="baseSalary">{{'staff.competences.form.salary-base' | translate}}</label>
						<input formControlName="baseSalary" class="form-control" type="number" id="baseSalary"/>
					</div>
					<div class="form-group flex-grow-1">
						<label for="isRequiredCertificate">{{'staff.competences.form.certificate' | translate}}</label>
						<select id="isRequiredCertificate" formControlName="isRequiredCertificate" class="form-select">
							<option value="0" selected="true">{{'staff.competences.form.no' | translate}}</option>
							<option value="1">{{'staff.competences.form.yes' | translate}}</option>
						</select>
					</div>
				</div>

				<p>{{'staff.competences.form.bonus' | translate}}</p>

				<div class="form-group">
					<label for="unsocialHoursBonusWorkdayDay">{{'staff.competences.form.mf-day' | translate}}</label>
					<input formControlName="unsocialHoursBonusWorkdayDay" class="form-control" type="number" id="unsocialHoursBonusWorkdayDay"/>
				</div>

				<div class="form-group">
					<label for="unsocialHoursBonusWorkdayNight">{{'staff.competences.form.mf-night' | translate}}</label>
					<input formControlName="unsocialHoursBonusWorkdayNight" class="form-control" type="number" id="unsocialHoursBonusWorkdayNight"/>
				</div>
				<div class="form-group">
					<label for="unsocialHoursBonusWeekendDay">{{'staff.competences.form.ss-day' | translate}}</label>
					<input formControlName="unsocialHoursBonusWeekendDay" class="form-control" type="number" id="unsocialHoursBonusWeekendDay"/>
				</div>
				<div class="form-group">
					<label for="unsocialHoursBonusWeekendNight">{{'staff.competences.form.ss-night' | translate}}</label>
					<input formControlName="unsocialHoursBonusWeekendNight" class="form-control" type="number" id="unsocialHoursBonusWeekendNight"/>
				</div>
				<div class="form-group">
					<label for="unsocialHoursBonusCdates">{{'staff.competences.form.c-dates' | translate}}</label>
					<input formControlName="unsocialHoursBonusCdates" class="form-control" type="number" id="unsocialHoursBonusCdates"/>
				</div>
				<div class="form-group">
					<label for="unsocialHoursBonusDdates">{{'staff.competences.form.d-dates' | translate}}</label>
					<input formControlName="unsocialHoursBonusDdates" class="form-control" type="number" id="unsocialHoursBonusDdates"/>
				</div>
			</div>

		</form>
	</div>
	<div class="popup-footer">
		<div class="d-flex justify-content-end gap-2">
			<span class="cancel-btn" (click)="destroyComponent.emit()">{{'buttons.cancel' | translate}}</span>
			<input type="submit"
			       form="form"
			       [disabled]="skillForm.invalid"
			       value="{{(action == 'store'
				? 'buttons.create'
				: 'buttons.save-changes') | translate}}"
			       class="btn save-btn btn-primary"
			/>
		</div>
	</div>
</app-modal>
