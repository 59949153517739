<div class="main-wrapper">

    <ngx-spinner
            bdColor="rgba(51,51,51,0.8)"
            size="medium"
            color="#fff"
            type="ball-scale-multiple"
    >
        <p style="font-size: 20px; color: white">{{'loading.moment' | translate}}</p>
    </ngx-spinner>


    <div class="row main-row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="header">
                    <div class="flexed">
                        <img src="/assets/img/safetly logo.png">
                        <h3>{{'safetly' | translate}}</h3>
                    </div>
                </div>
                <div class="body">
                    <h1>{{'loading.checking-info' | translate}}</h1>
                    <h3>{{'loading.patience' | translate}}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
