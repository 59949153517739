import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Store} from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as MyStaffActions from "../store/my-staff.actions";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-guard-accept-invite',
  templateUrl: './guard-accept-invite.component.html',
  styleUrls: ['./guard-accept-invite.component.scss']
})
export class GuardAcceptInviteComponent implements OnInit {
    appStoreUrl;
    playStoreUrl;
    private token: string;
    public accepted;
    public loading;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private spinner: NgxSpinnerService,
                private store: Store<fromApp.AppState>) {
        this.appStoreUrl = environment.appStoreUrl;
        this.playStoreUrl = environment.appStoreUrl;
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.token = params.t;
            this.store.dispatch(new MyStaffActions.AcceptInvite({inviteToken: this.token}));
        });
        //
        this.store.subscribe(appState => {
            this.accepted = appState.staff.acceptedInvitation;
            this.loading = appState.staff.loading;

            if (appState.staff.loading) {
                this.spinner.show();
            } else {
                this.spinner.hide();
            }
        });
    }

}
