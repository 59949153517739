<div class="step-main-wrapper">
    <h1>{{'create-assignment.reports' | translate}}</h1>
    <div class="wrapper">

        <div class="reports" *ngFor="let groups of this?.reports | groupBy;">
            <h3>{{groups.groupBy}}</h3>
            <div class="row">
                <div [ngClass]="isSelected(report?.id) ? 'active' : ''" class="report-wrapper"
                     *ngFor="let report of groups?.data">
                    <div class="d-flex justify-content-start gap-2">
                        <input type="checkbox" class="my-checkbox" [checked]="isSelected(report?.id)"
                               (change)="onChooseReport(report?.id, $event.target.checked)" [value]="report?.id">
                        <div class="text d-flex flex-column justify-content-between gap-2">
                            <div>
                                <p class="title">{{report?.name}}</p>
                                <p class="faded-p">{{report?.description}}</p>
                            </div>
                            <p class="see-required"
                               (click)="onSeeRequiredFields(report.id)"
                            >{{'create-assignment.see-required-fields' | translate}}</p>
                        </div>
                    </div>

                    <div class="required-fields" *ngIf="requiredFields  && report.id == this.requiredId">
                        <p *ngFor="let required of report?.requiredFields">{{required}}</p>
                    </div>

                </div>
            </div>
            <!--            <div class="flexed-add">-->
            <!--                <img src="/assets/img/plus.svg" alt="">-->
            <!--                <p class="add-p">Create new report type</p>-->
            <!--            </div>-->
        </div>
    </div>

    <div class="btn btn-primary my-button" (click)="onSaveAndContinue()">
        <div class="flexed-button">
            <p>{{'buttons.save&continue' | translate}}</p>
            <img src="/assets/img/arrow white.svg">
        </div>
    </div>

</div>
