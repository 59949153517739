import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../models/customer.model';
import { InvoiceModel } from '../../../models/invoice.model';
import { PaginationModel } from '../../../models/pagination.model';
import * as CompanyActions from '../store/company.actions';
import { BillingInformationModel } from '../../../models/billingInformation.model';
import * as AuthActions from '../../../auth/store/auth.actions';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit, OnDestroy {

    public changeBillingInfoModal: boolean = false;
    public changeBillingForm = this.formBuilder.group({
        companyName: ['', [Validators.required]],
        companyNumber: ['', [
            Validators.required,
            Validators.pattern("[0-9]{10}"),
        ]],
        address: ['', [Validators.required]],
        addressSecond: ['', []],
        postalCode: ['', [Validators.required]],
        city: ['', [Validators.required]],
        contactName: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
    });

    public subscription: Subscription;
    public customer: CustomerModel;
    public invoices: InvoiceModel[];
    public pagination: PaginationModel;
    public billing: BillingInformationModel;

    constructor(private formBuilder: FormBuilder,
                private store: Store<fromApp.AppState>,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.invoices = appState.company.invoices;
            this.pagination = appState.company.paginationInvoice;

            // this.billing = this.customer.billingInformation;
            // console.log(this.customer);
            // console.log(this.customer);


        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onChangeBillingInfoClicked(): void {
        this.changeBillingInfoModal = true;

        this.changeBillingForm.patchValue({
            companyName: this.customer.billingInformation.companyName,
            companyNumber: this.customer.billingInformation.companyNumber,
            address: this.customer.billingInformation.address,
            addressSecond: this.customer.billingInformation.addressSecond,
            postalCode: this.customer.billingInformation.postalCode,
            city: this.customer.billingInformation.city,
            contactName: this.customer.billingInformation.contactName,
            phone: this.customer.billingInformation.phone,
            email: this.customer.billingInformation.email,
        });
    }

    public onChangeBillingInfoSubmit(): void {
        if (this.changeBillingForm.invalid) {
            Object.keys(this.changeBillingForm.controls).forEach(key => {
                this.changeBillingForm.controls[key].markAsDirty();
            });
            return;
        }

        let billingInformation = new BillingInformationModel();

        this.changeBillingForm['_forEachChild']((control, name) => {
            if (control.dirty) {
                billingInformation[name] = control.value;
            }
        });

        if (this.customer.billingInformation) {
            this.store.dispatch(new AuthActions.UpdateBillingInfo({
                customerId: this.customer.id,
                billingId: this.customer.billingInformation.id,
                data: billingInformation,
            }));
        } else {
            this.store.dispatch(new AuthActions.CreateBillingInfo({
                customerId: this.customer.id,
                ...billingInformation,
            }));
        }

        this.changeBillingInfoModal = false;
    }

    onNextPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new CompanyActions.LoadInvoices({
                customerId: this?.customer?.id,
                page: this.pagination.currentPage + 1,
            }));
        }
    }

    onPreviousPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new CompanyActions.LoadInvoices({
                customerId: this?.customer?.id,
                page: this.pagination.currentPage - 1,
            }));
        }
    }

    onFirstPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new CompanyActions.LoadInvoices({
                customerId: this?.customer?.id,
                page: 1,
            }));
        }
    }

    onLastPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new CompanyActions.LoadInvoices({
                customerId: this?.customer?.id,
                page: this.pagination.lastPage,
            }));
        }
    }

}
