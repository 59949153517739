import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CustomerTodoActionTypeConst {
    public SHIFT_APPROVE_HOURS = 100;
    public ASSIGNMENT_DRAFT_CREATED = 200;

    // employee todos actions
    public NEED_TO_CREATE_COMPANY = 500;
}