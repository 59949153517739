import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { CustomerModel } from '../../../../models/customer.model';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../../../../store/app.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createData } from '../create-object';
import * as AssignmentsActions from '../../store/assignments.actions';
import { UtilityOptionModel } from '../../../../models/utilityOption.model';
import { UniformModel } from '../../../../models/uniform.model';
import { SkillModel } from '../../../../models/skill.model';
import * as SharedActions from '../../../../shared-store/shared.actions';
import { isCreatingAssignmentFromHistory } from '../../store/assignment.selector';
import { filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit, OnDestroy {

    public choose = false;

    public editOptionsModal: boolean = false;
    public competenceModal: boolean = false;
    public clothingModal: boolean = false;

    public chosenCategory: number;

    public chosenOptions: number[] = [];

    public subscription: Subscription;
    public customer: CustomerModel;

    public selectedUniform: UniformModel;
    public selectedSkill: SkillModel;
    public selectedUtilityOptions: UtilityOptionModel[] = [];

    public utilityOptions: UtilityOptionModel[] = [];
    public uniforms: UniformModel[] = [];
    public skills: SkillModel[] = [];

    isCreatingAssignmentFromHistory: boolean;

    public firstLoad = true;

    unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit(): void {
        this.chosenCategory = createData.assignmentCategoryId;
        this.store.dispatch(new SharedActions.LoadSkills());

        if (this.chosenCategory == 5 && createData.skillId) {
            createData.uniformId = null;
            this.store.dispatch(new SharedActions.LoadSkillUniforms({
                skillId: createData.skillId,
            }));
            this.store.dispatch(new SharedActions.LoadSkillUtilityOptions({
                skillId: createData.skillId,
            }));
        }

        // this.subscription = this.store.subscribe(appState => {
        //     this.customer = appState.auth.customer;
        //     // this.uniforms = appState.shared.uniforms;
        //     this.utilityOptions = appState.shared.utilityOptions;
        //     // this.skills = appState.shared.skills;
        //
        //     this.isCreatingAssignmentFromHistory = appState.assignments.isAssignmentCreatingFromHistory;
        //
        //     this.init();
        // });

        this.store.select('shared', 'skills')
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(x => x.length > 0),
                take(1)
            )
            .subscribe(skills => {
                this.skills = skills;

                this.selectedSkill = createData.skillId
                    ? this.skills.find(x => x.id == createData.skillId)
                    : this.skills[0];

                createData.skillId = this.selectedSkill.id;

                this.store.dispatch(new SharedActions.LoadSkillUniforms({
                    skillId: createData.skillId,
                }));
                this.store.dispatch(new SharedActions.LoadUtilityOptions());
            });

        this.store.select('shared', 'uniforms')
            .pipe(
                takeUntil(this.unsubscribe$),
                // filter(x => x.length > 0)
            )
            .subscribe(uniforms => {
                this.uniforms = uniforms;

                if (this.uniforms.length > 0) {
                    this.selectedUniform = createData.uniformId
                        ? this.uniforms.find(x => x.id == createData.uniformId)
                        : this.uniforms[0];

                    createData.uniformId = this.selectedUniform.id;
                } else {
                    createData.uniformId = undefined;
                    this.selectedUniform = null;
                }

            });

        this.store.select('shared', 'utilityOptions')
            .pipe(
                takeUntil(this.unsubscribe$),
            )
            .subscribe(utilityOptions => {
                this.utilityOptions = utilityOptions;
            });
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
        this.unsubscribe$.unsubscribe();
    }

    public init(): void {
        // if (this.chosenCategory == 5) {
        //     if (!createData.skillId) {
        //         this.choose = true;
        //     } else {
        //         this.skills.forEach(parent => {
        //             parent?.children.forEach(child => {
        //                 if (child.id == createData.skillId) {
        //                     this.selectedSkill = child;
        //                     return;
        //                 }
        //             });
        //         });
        //         if (createData.uniformId) {
        //             this.selectedUniform = this?.uniforms.find(el => el.id == createData.uniformId);
        //         } else {
        //             // this.selectedUniform = this?.uniforms[0];
        //             // createData.uniformId = this?.selectedUniform?.id;
        //         }
        //         if (createData.utilityOptionIds) {
        //             this.selectedUtilityOptions = this.utilityOptions.filter(el => createData.utilityOptionIds?.find(e => e == el.id));
        //             this.chosenOptions = createData.utilityOptionIds;
        //         } else {
        //             createData.utilityOptionIds = [];
        //             this.selectedUtilityOptions = [];
        //             this.chosenOptions = createData.utilityOptionIds;
        //         }
        //     }
        // } else {
        //     if (!createData.skillId) {
        //         this.selectedSkill = this?.skills[0];
        //         createData.skillId = this?.selectedSkill?.id;
        //     } else {
        //         this.selectedSkill = this.skills.find(el => el.id == createData.skillId);
        //     }
        //
        //     if (!createData.uniformId) {
        //         this.selectedUniform = this?.uniforms[0];
        //         createData.uniformId = this?.selectedUniform?.id;
        //     } else {
        //         this.selectedUniform = this.uniforms.find(el => el.id == createData.uniformId);
        //     }
        //
        //     if (!createData.utilityOptionIds) {
        //         // this.selectedUtilityOptions = this.utilityOptions.filter(el => el.id == 1 || el.id == 2);
        //         // createData.utilityOptionIds = [1, 2];
        //     } else {
        //         this.selectedUtilityOptions = this.utilityOptions.filter(el => createData.utilityOptionIds.find(e => e == el.id));
        //         this.chosenOptions = createData.utilityOptionIds;
        //     }
        // }
    }

    onChangeOption(id: number, isChecked: boolean, option: UtilityOptionModel) {
        if (isChecked) {
            this.chosenOptions.push(id);
            this.selectedUtilityOptions.push(option);
        } else {
            let index = this.chosenOptions.indexOf(id);
            let index2 = this.selectedUtilityOptions.indexOf(option);
            this.chosenOptions.splice(index, 1);
            this.selectedUtilityOptions.splice(index2, 1);
        }
    }

    public onCancel(): void {
        // this.chosenOptions = [1, 2];
        // this.selectedUtilityOptions = this.utilityOptions.filter(el => el.id == 1 || el.id == 2);
    }

    public onRemoveOption(option: UtilityOptionModel): void {
        let index = this.chosenOptions.indexOf(option.id);
        let index2 = this.selectedUtilityOptions.indexOf(option);
        this.chosenOptions.splice(index, 1);
        this.selectedUtilityOptions.splice(index2, 1);
    }

    onSelectedCompetence(skill: SkillModel): void {
        this.selectedSkill = skill;
        this.competenceModal = false;
        createData.skillId = skill.id;
        createData.uniformId = undefined;
        createData.utilityOptionIds = undefined;
        this.selectedUniform = null;
        this.selectedUtilityOptions = [];
        this.choose = false;

        if (this.chosenCategory == 5) {

            this.selectedUniform = null;
            this.selectedUtilityOptions = [];
            createData.uniformId = undefined;
            createData.utilityOptionIds = [];


            this.store.dispatch(new SharedActions.LoadSkillUtilityOptions({
                skillId: skill.id,
            }));
        }

        this.store.dispatch(new SharedActions.LoadSkillUniforms({
            skillId: skill.id,
        }));
    }

    onSelectedUniform(uniform: UniformModel): void {
        this.selectedUniform = uniform;
        this.clothingModal = false;
        createData.uniformId = uniform.id;
    }

    public onSaveOptions(): void {
        createData.utilityOptionIds = this.chosenOptions;
        this.editOptionsModal = false;
    }

    public onSaveAndContinue(): void {
        if (!createData.skillId) {
            this._snackBar.open('Please select competence.', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });
            return;
        }
        // if (!createData.skillId || !createData.uniformId || !createData.utilityOptionIds) {
        //     this._snackBar.open('Please, fill up all fields', 'Close', {
        //         duration: 3000,
        //         panelClass: ['snackbar'],
        //     });
        //     return;
        // }

        if (this.isCreatingAssignmentFromHistory) {
            this.store.dispatch(new AssignmentsActions.UpdateStep(7));
        } else {
            this.store.dispatch(new AssignmentsActions.UpdateStep(3));
        }
    }

    public isSelected(id: number) {
        return this.chosenOptions.find(el => el == id);
    }

}
