import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as SharedActions from '../../../../shared-store/shared.actions';
import { Store } from '@ngrx/store';
import { SkillModel } from '../../../../models/skill.model';
import * as fromApp from '../../../../store/app.reducer';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-skill-form',
    templateUrl: './skill-form.component.html',
    styleUrls: ['./skill-form.component.scss'],
})
export class SkillFormComponent implements OnInit {
    @Input('action') action: string = 'store';
    @Input('skill') skill: SkillModel = null;
    @Output('destroyComponent') destroyComponent = new EventEmitter();

    skills: SkillModel[] = [];
    skillForm = this.fb.group({
        name: ['', Validators.required],
        parentId: ['', Validators.required], //parentSkillId
        description: [''],
        price: this.fb.group({
            baseSalary: ['', Validators.required],
            isRequiredCertificate: ['', Validators.required],
            unsocialHoursBonusWorkdayDay: ['', Validators.required],
            unsocialHoursBonusWorkdayNight: ['', Validators.required],
            unsocialHoursBonusWeekendDay: ['', Validators.required],
            unsocialHoursBonusWeekendNight: ['', Validators.required],
            unsocialHoursBonusCdates: ['', Validators.required],
            unsocialHoursBonusDdates: ['', Validators.required],
        }),
    });
    competenceModal = true;
    constructor(private store: Store<fromApp.AppState>,
                private fb: FormBuilder,
    ) {

    }

    ngOnInit(): void {
        this.store.select('shared', 'skills')
            .pipe(
                filter(x => x != []),
                take(1),
            )
            .subscribe(skills => {
                this.skills = skills;
            });

        this.initForm();
    }

    private initForm() {
        switch (this.action) {
            case 'store':
                this.skillForm.get(['price', 'isRequiredCertificate']).setValue('0');
                break;
            case 'update':
                this.skillForm.patchValue(this.skill);
                break;
        }
    }
    public onSubmitCompetence(): void {
        if (this.skillForm.invalid) {
            return;
        }

        switch (this.action) {
            case 'store':
                this.store.dispatch(new SharedActions.CreateSkill({customerId: 1, data: this.skillForm.value}));
                break;
            case 'update':
                let skillObj = new SkillModel();
                skillObj = this.skillForm.value;
                skillObj.id = this.skill.id;
                this.store.dispatch(new SharedActions.UpdateSkill({customerId: 1, data: skillObj}));
                break;
        }
        this.destroyComponent.emit();
    }

}
