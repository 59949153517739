import * as CompanyActions from './company.actions';
import {EmployeeModel} from '../../../models/employee.model';
import {PaginationModel} from '../../../models/pagination.model';
import {InvoiceModel} from '../../../models/invoice.model';
import {InviteModel} from "../../../models/invite.model";
import {BillingInformationModel} from "../../../models/billingInformation.model";
import {RegionModel} from "../../../models/region.model";
import {JobPlaceModel} from "../../../models/jobPlace.model";
import {HereSuggesstionModel} from "../../../models/hereSuggesstion.model";
import { HereLocationModel } from '../../../models/hereLocation.model';

export interface State {
    employee: EmployeeModel;
    employees: EmployeeModel[];
    pagination: PaginationModel;
    invoices: InvoiceModel[];
    invoice: InvoiceModel;
    paginationInvoice: PaginationModel;
    invites: InviteModel[];
    // billings: BillingInformationModel[];
    // billing: BillingInformationModel;
    loading: boolean;
    regions: RegionModel[];
    region: RegionModel;
    objects: JobPlaceModel[];
    object: JobPlaceModel;
    hereSuggestions: HereSuggesstionModel[];
    hereLocation: HereLocationModel;
}

const initialState: State = {
    employee: null,
    employees: [],
    pagination: null,
    invoices: [],
    invoice: null,
    paginationInvoice: null,
    invites: [],
    // billings: [],
    // billing: null,
    loading: false,
    regions: [],
    region: null,
    objects: [],
    object: null,
    hereSuggestions: [],
    hereLocation: null,
};

export function companyReducer(state = initialState, action: CompanyActions.CompanyActions): State {
    switch (action.type) {
        case CompanyActions.LOAD_HERE_LOCATION_BY_LOCATION_ID_SUCCESS:
            return {
                ...state,
                hereLocation: action.payload.hereLocation
            };
        case CompanyActions.LOAD_EMPLOYEES:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.LOAD_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.payload.employees,
                pagination: action.payload.pagination,
                loading: false
            };
        case CompanyActions.LOAD_EMPLOYEES_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.CLEAR_EMPLOYEES:
            return {
                ...state,
                employees: [],
                loading: false
            };
        case CompanyActions.DELETE_EMPLOYEE:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.DELETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employees: state.employees.filter(e => e.id != action.payload.employee.id),
                loading: false
            };
        case CompanyActions.DELETE_EMPLOYEE_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.LOAD_INVOICES:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.LOAD_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: action.payload.invoices,
                paginationInvoice: action.payload.pagination,
                loading: false
            };
        case CompanyActions.LOAD_INVOICES_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.LOAD_INVOICE:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.LOAD_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: action.payload.invoice,
                loading: false
            };
        case CompanyActions.LOAD_INVOICE_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.INVITE_USERS:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.INVITE_USERS_SUCCESS:
            return {
                ...state,
                invites: action.payload.invites,
                loading: false
            };
        case CompanyActions.INVITE_USERS_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.ACCEPT_INVITE:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.ACCEPT_INVITE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.ACCEPT_INVITE_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.UPDATE_EMPLOYEE:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employees: state.employees.map(e => {
                    if (e.id === action.payload.employee.id) {
                        return action.payload.employee
                    } else return e
                }),
                loading: false
            };
        case CompanyActions.UPDATE_EMPLOYEE_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.TRANSFER_OWNERSHIP:
            return {
                ...state,
                employees: state.employees.map(employee => {
                    if (employee.id === action.payload.loggedId) {
                        return {
                            ...employee,
                            employeeRole: {
                                ...employee.employeeRole,
                                id: 2,
                                name: 'Administrator'
                            }
                        };
                    } else return employee
                })
            };
        // case CompanyActions.LOAD_BILLING_COLLECTION:
        //     return {
        //         ...state,
        //         loading: true
        //     };
        // case CompanyActions.LOAD_BILLING_COLLECTION_SUCCESS:
        //     return {
        //         ...state,
        //         billings: action.payload.billings,
        //         billing: action.payload.billings[0],
        //         loading: false
        //     };
        // case CompanyActions.LOAD_BILLING_COLLECTION_FAILED:
        //     return {
        //         ...state,
        //         loading: false
        //     };
        // case CompanyActions.LOAD_BILLING_INFO:
        //     return {
        //         ...state,
        //         loading: true
        //     };
        // case CompanyActions.LOAD_BILLING_INFO_SUCCESS:
        //     return {
        //         ...state,
        //         billing: action.payload.billing,
        //         loading: false
        //     };
        // case CompanyActions.LOAD_BILLING_INFO_FAILED:
        //     return {
        //         ...state,
        //         loading: false
        //     };

        case CompanyActions.LOAD_REGIONS:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.LOAD_REGIONS_SUCCESS:
            return {
                ...state,
                regions: action.payload.regions,
                loading: false
            };
        case CompanyActions.LOAD_REGIONS_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.ADD_REGION:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.ADD_REGION_SUCCESS:
            return {
                ...state,
                regions: [action.payload.region, ...state.regions],
                loading: false
            };
        case CompanyActions.ADD_REGION_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.LOAD_OBJECTS:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.LOAD_OBJECTS_SUCCESS:
            return {
                ...state,
                objects: action.payload.objects,
                loading: false
            };
        case CompanyActions.LOAD_OBJECTS_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.ADD_OBJECT:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.ADD_OBJECT_SUCCESS:
            return {
                ...state,
                objects: [action.payload.object, ...state.objects],
                loading: false
            };
        case CompanyActions.ADD_OBJECT_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.ADDRESS_AUTOCOMPLETE:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.ADDRESS_AUTOCOMPLETE_SUCCESS:
            return {
                ...state,
                hereSuggestions: action.payload.hereSuggestions,
                loading: false
            };
        case CompanyActions.ADDRESS_AUTOCOMPLETE_FAILED:
            return {
                ...state,
                loading: false
            };
        case CompanyActions.LOAD_OBJECT:
            return {
                ...state,
                loading: true
            };
        case CompanyActions.LOAD_OBJECT_SUCCESS:
            return {
                ...state,
                object: action.payload.object,
                loading: false
            };
        case CompanyActions.LOAD_OBJECT_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
