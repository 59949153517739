import * as MyStaffActions from "./my-staff.actions";
import {GuardModel} from "../../../models/guard.model";
import {PaginationModel} from "../../../models/pagination.model";
import {GuardInviteModel} from "../../../models/guardInvite.model";
import {LOAD_STAFF} from "./my-staff.actions";
import { LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF_SUCCESS } from './my-staff.actions';
import { PayslipItemModel } from '../../../models/payslip-item.model';


export interface State {
    guards: GuardInviteModel[];
    guardsWithPayrolls: GuardModel[];
    guardPayslipItems: PayslipItemModel[];
    pagination: PaginationModel;
    staff: GuardModel[];
    staffPagination: PaginationModel;
    staffMeta: {
        isMoreStaff: boolean,
        page: number
    },
    members: GuardModel[];
    membersPagination: PaginationModel;
    guard: GuardModel;
    loading: boolean;
    acceptedInvitation: boolean;
}

const initialState: State = {
    guards: [],
    guardsWithPayrolls: [],
    guardPayslipItems: [],
    pagination: null,
    staff: [],
    staffPagination: null,
    staffMeta: {
        isMoreStaff: false,
        page: 0
    },
    members: [],
    membersPagination: null,
    guard: null,
    loading: false,
    acceptedInvitation: false
}

export function myStaffReducer(state = initialState, action: MyStaffActions.MyStaffActions): State {
    switch (action.type) {
        case MyStaffActions.LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF_SUCCESS:
            return {
                ...state,
                guardPayslipItems: action.payload.payslipItems,
                pagination: action.payload.pagination
            };
        case MyStaffActions.LOAD_GUARDS_WITH_PAYROLLS_SUCCESS:
            return {
                ...state,
                guardsWithPayrolls: action.payload.guards,
                pagination: action.payload.pagination
            };
        case MyStaffActions.LOAD_GUARDS_INVITES_SUCCESS:
            return {
                ...state,
                guards: action.payload.guards,
                pagination: action.payload.pagination
            };
        case MyStaffActions.LOAD_STAFF:
            return {
                ...state,
                staffMeta: {
                    ...state.staffMeta,
                    isMoreStaff: true,
                    page: state.staffMeta.page + 1
                }
            };
        case MyStaffActions.LOAD_STAFF_SUCCESS:
            return {
                ...state,
                staff: [...state.staff, ...action.payload.guards],
                staffPagination: action.payload.pagination,
                staffMeta: {
                    ...state.staffMeta,
                    isMoreStaff: action.payload.pagination.lastPage != action.payload.pagination.currentPage,
                }
            };
        case MyStaffActions.CLEAR_STAFF:
            return {
                ...state,
                staff: [],
                staffPagination: null,
                staffMeta: {
                    ...state.staffMeta,
                    isMoreStaff: false,
                    page: 0
                }
            };
        case MyStaffActions.CLEAR_GUARDS:
            return {
                ...state,
                guards: [],
                pagination: null
            };
        case MyStaffActions.ACCEPT_INVITE:
            return {
                ...state,
                loading: true
            };
        case MyStaffActions.ACCEPT_INVITE_SUCCESS:
            return {
                ...state,
                acceptedInvitation: true,
                loading: false
            };
        case MyStaffActions.ACCEPT_INVITE_FAILED:
            return {
                ...state,
                acceptedInvitation: false,
                loading: false
            };
        case MyStaffActions.LOAD_GUARD_SUCCESS:
            return {
                ...state,
                guard: action.payload.guard,
            };
        case MyStaffActions.EDIT_GUARD_SUCCESS:
            return {
                ...state,
                guard: action.payload.guard,
            };
        case MyStaffActions.DELETE_GUARD_SUCCESS:
            return {
                ...state,
                guard: null,
            };
        case MyStaffActions.LOAD_SKILL_MEMBERS:
            return {
                ...state,
                loading: true
            };
        case MyStaffActions.LOAD_SKILL_MEMBERS_SUCCESS:
            return {
                ...state,
                members: action.payload.members,
                membersPagination: action.payload.pagination,
                loading: false
            };
        case MyStaffActions.LOAD_SKILL_MEMBERS_FAILED:
            return {
                ...state,
                loading: false
            };
        case MyStaffActions.ADD_SKILL_MEMBERS_SUCCESS:
            return {
                ...state,
                members: [...state.members, ...action.payload.guards],
            };
        case MyStaffActions.DELETE_SKILL_MEMBER_SUCCESS:
            return {
                ...state,
                members: state.members.filter(member => member.id != action.payload.guard.id)
            };
        default:
            return state;
    }
}
