<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="d-sm-flex justify-content-sm-between d-block justify-content-center text-left">
                    <div>
                        <h1>{{'profile.title' | translate}}</h1>
                    </div>
                    <div>
                        <div class="flexed" (click)="onLogout()">
                            <img class="logout-icon" src="/assets/img/logout.svg">
                            <p class="logout-p">{{'profile.logout' | translate}}</p>
                        </div>
                    </div>
                </div>
                <div class="info-wrapper">

                    <div class="basic-info">
                        <h3>{{'profile.info' | translate}}</h3>
                        <div class="d-sm-flex justify-content-sm-between d-block justify-content-center text-left info-block">
                            <div>
                                <p class="info-title">{{'profile.name' | translate}}</p>
                                <p *ngIf="employee?.firstName; else noContent" class="value">{{employee?.firstName}}</p>
                            </div>
                            <div>
                                <p class="info-title">{{'profile.surname' | translate}}</p>
                                <p *ngIf="employee?.lastName; else noContent" class="value">{{employee?.lastName}}</p>
                            </div>
                            <div>
                                <p class="edit-p" (click)="onOpenEditNameModal()">{{'buttons.edit' | translate}}</p>
                            </div>
                        </div>
                        <ng-template #noContent>
                            <p class="value">{{'profile.unset' | translate}}</p>
                        </ng-template>
                        <div class="line"></div>
                    </div>

                    <div class="contact-info">
                        <h3>{{'profile.contact' | translate}}</h3>
                        <div class="d-sm-flex justify-content-sm-between d-block justify-content-center text-left info-block">
                            <div>
                                <p class="info-title">{{'profile.email' | translate}}</p>
                                <p class="value">{{employee?.email}}</p>
                            </div>
                            <div>
                                <p class="edit-p" (click)="onOpenEditEmailModal()">{{'buttons.edit' | translate}}</p>
                            </div>
                        </div>
                        <div class="d-sm-flex justify-content-sm-between d-block justify-content-center text-left info-block">
                            <div>
                                <p class="info-title">{{'profile.phone' | translate}}</p>
                                <p *ngIf="employee?.phone; else noPhone" class="value">{{employee?.phone}}</p>
                            </div>
                            <div>
                                <p class="edit-p"
                                   (click)="this.onOpenEditPhoneModal()">{{'buttons.edit' | translate}}</p>
                            </div>
                        </div>
                        <ng-template #noPhone>
                            <p class="value">{{'profile.no-phone' | translate}}</p>
                        </ng-template>
                        <div class="line"></div>
                    </div>

                    <div class="basic-info">
                        <h3>{{'profile.password' | translate}}</h3>
                        <p class="change-password-p"
                           (click)="onOpenPasswordModal()">{{'profile.change-password' | translate}}</p>
                        <div class="line"></div>
                    </div>

                    <div class="basic-info">
                        <h3>{{'profile.preferences' | translate}}</h3>
                        <div class="d-sm-flex justify-content-sm-between d-block justify-content-center text-left info-block">
                            <div>
                                <p class="info-title">{{'profile.language' | translate}}</p>
                                <p *ngIf="employee?.locale == 'en'" class="value">{{'profile.en' | translate}}</p>
                                <p *ngIf="employee?.locale == 'sv'" class="value">{{'profile.sv' | translate}}</p>
                            </div>
                            <div>
                                <p *ngIf="employee?.locale == 'en'; else sv"
                                   class="edit-p"
                                   (click)="onChangeApplicationLanguage('sv')"
                                >{{'profile.change-sw' | translate}}</p>
                            </div>
                        </div>
                        <ng-template #sv>
                            <p class="edit-p"
                               (click)="onChangeApplicationLanguage('en')"
                            >{{'profile.change-en' | translate}}</p>
                        </ng-template>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<!--Change Name Modal-->
<app-modal *ngIf="this.changeNameModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">{{'profile.change-name' | translate}}</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.changeNameModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <form [formGroup]="changeNameForm" (ngSubmit)="onChangeName()" id="changeNameForm">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="form-group name-form-group">
                        <label for="name">{{'profile.name' | translate}}</label>
                        <input formControlName="firstName" class="form-control" type="text" id="name"/>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="form-group">
                        <label for="lastname">{{'profile.surname' | translate}}</label>
                        <input formControlName="lastName" class="form-control" type="text" id="lastname"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.changeNameModal = false">{{'buttons.cancel' | translate}}</span>
                    <input type="submit"
                           value="{{'buttons.save-changes' | translate}}"
                           form="changeNameForm"
                           class="btn save-btn btn-primary"
                           [disabled]="!this.changeNameForm.valid"
                    />
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Change Email Modal-->
<app-modal *ngIf="this.changeEmailModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">{{'profile.change-email' | translate}}</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.changeEmailModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <p class="info-title">{{'profile.verify-email-msg' | translate}}</p>
        <form [formGroup]="changeEmailForm" (ngSubmit)="onChangeEmail()" id="changeEmailForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group" style="margin-right: 20px;">
                        <label for="email">{{'profile.new-email' | translate}}</label>
                        <input formControlName="email" class="form-control" type="email" id="email"
                               placeholder="example@safetly.com"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.changeEmailModal = false">{{'buttons.cancel' | translate}}</span>
                    <input type="submit"
                           value="{{'buttons.save-changes' | translate}}"
                           form="changeEmailForm"
                           [disabled]="!this.changeEmailForm.valid"
                           class="btn save-btn btn-primary"/>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Change Phone Modal-->
<app-modal *ngIf="this.changePhoneModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">{{'profile.change-phone' | translate}}</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.changePhoneModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <form [formGroup]="changePhoneForm" (ngSubmit)="onChangePhone()" id="changePhoneForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group" style="margin-right: 20px;">
                        <label for="phone">{{'profile.new-phone' | translate}}</label>
                        <input formControlName="phone" class="form-control" type="text" id="phone"
                               placeholder="000-000 000 00"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.changePhoneModal = false">{{'buttons.cancel' | translate}}</span>
                    <input type="submit"
                           form="changePhoneForm"
                           value="{{'buttons.save-changes' | translate}}"
                           class="btn save-btn btn-primary"
                           [disabled]="!this.changePhoneForm.valid"/>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Change Password Modal-->
<app-modal *ngIf="this.changePasswordModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">{{'profile.change-password' | translate}}</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.changePasswordModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()" id="changePasswordForm">
            <div class="row">
                <div class="col-12">
                    <div class="form-group" style="margin-right: 20px;">
                        <label for="old">{{'profile.current-pass' | translate}}</label>
                        <input formControlName="currentPassword" class="form-control"
                               type="{{this.showPasswords==true ? 'text' : 'password'}}" id="old"
                               placeholder="**********"/>
                    </div>
                    <div class="form-group" style="margin-right: 20px;">
                        <label for="new">{{'profile.new-pass' | translate}}</label>
                        <input formControlName="newPassword" class="form-control"
                               type="{{this.showPasswords==true ? 'text' : 'password'}}" id="new"
                               placeholder="**********"/>
                    </div>
                    <div class="form-group" style="margin-right: 20px;">
                        <label for="new2">{{'profile.confirm-pass' | translate}}</label>
                        <input formControlName="newPassword2" class="form-control"
                               type="{{this.showPasswords==true ? 'text' : 'password'}}" id="new2"
                               placeholder="**********"/>
                    </div>
                    <p *ngIf="!passwordMatching"
                       class="validation-msg">{{'profile.not-matching' | translate}}</p>
                </div>

                <p class="error-msg"
                   *ngIf="currentPassword.errors?.minlength || newPassword.errors?.minlength">
                    {{'profile.condition' | translate}}
                </p>

                <div class="flexed" *ngIf="!showPasswords">
                    <img class="show-password-icon" src="/assets/img/show-password-icon.svg" alt="">
                    <p class="faded-p" (click)="this.showPasswords = true">{{'auth.show' | translate}}</p>
                </div>
                <div class="flexed" *ngIf="showPasswords">
                    <img class="hide-password-icon" src="/assets/img/hide-password-icon.svg" alt="">
                    <p class="faded-p" (click)="this.showPasswords = false">{{'auth.hide' | translate}}</p>
                </div>
            </div>
        </form>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.changePasswordModal = false">{{'buttons.cancel' | translate}}</span>
                    <input type="submit"
                           form="changePasswordForm"
                           value="{{'buttons.save-changes' | translate}}"
                           class="btn save-btn btn-primary"
                           [disabled]="!this.changePasswordForm.valid"/>
                </div>
            </div>
        </div>
    </div>
</app-modal>
