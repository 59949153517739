<div class="main-wrapper">
	<div class="row">
		<div class="col-12">
			<div class="inner-wrapper">

				<div class="d-flex mb-4">
					<p class="faded-p">{{'navigation.my-staff' | translate}}</p>
					<img class="small-arrow" src="/assets/img/arrow small.svg">
					<p class="faded-p">{{'payroll.Payroll' | translate}}</p>
				</div>

				<h1>{{'payroll.Payroll' | translate}}</h1>

				<div class="d-block d-sm-flex justify-content-between gap-2 mb-3">
					<div class="filters d-lg-flex justify-content-start gap-2 d-block flex-wrap">
						<form [formGroup]="filterForm">
							<div class="d-lg-flex justify-content-start gap-2 d-block  text-left info-block flex-wrap">
								<div class="d-flex justify-content-start gap-2">
									<p class="select-label">{{'payroll.Period' | translate}}</p>
									<select class="form-select" formControlName="period"
									        (change)="onFilter('period', $event.target.value)">
										<option [value]="period.id" *ngFor="let period of periods">{{period.label}}</option>
									</select>
								</div>
								<div class="d-flex justify-content-start gap-2">
									<p class="select-label">{{'assignments.filters.competence' | translate}}</p>
									<select class="form-select" formControlName="skills"
									        (change)="onFilter('skillId',$event.target.value)">
										<option [value]="0">{{'assignments.filters.all-competences' | translate}}
										</option>
										<option *ngFor="let skill of this?.skills"
										        [value]="skill?.id">{{skill?.name}}
										</option>
									</select>
								</div>
							</div>
						</form>
						<div class="clear-filters d-flex justify-content-start gap-1" (click)="onClearFilters()">
							<img src="/assets/img/clear-icon.png" alt="">
							<p>{{'payroll.Clear filters' | translate}}</p>
						</div>
					</div>
					<div class="btn btn-primary my-button">
						<div class="d-flex justify-content-between gap-1">
							<img src="/assets/img/export-white.png" alt="">
							<p>{{'payroll.Export period' | translate}}</p>
						</div>
					</div>
				</div>

				<div class="wrapper">

					<div class="search-wrapper">
						<img class="search-icon" src="/assets/img/search 1.svg">
						<input class="search-input"
						       (keyup)="keyUpSearch($event)"
						       type="search"
						       #searchInput
						       placeholder="{{'payroll.Search people' | translate}}"/>
					</div>

					<!-- TABLE -->
					<table class="table table-hover">
						<thead>
						<tr>
							<th class="sort-col">{{'table.thead.name' | translate}}</th>
							<!--<th>Competences</th>-->
							<th>{{'payroll.Hourly rate' | translate}}</th>
							<th>{{'payroll.Total hours' | translate}}</th>
							<th>{{'payroll.Salary this period' | translate}}</th>
							<th class="text-right" style="opacity: 0">dropdown</th>
						</tr>
						</thead>
						<tbody>
						<ng-container *ngFor="let guard of guards">
							<tr data-toggle="collapse" class="accordion-toggle"
							    [ngClass]="openedRow == guard.id ? 'opened' : ''"
							    (click)="openedRow == guard.id ? openedRow = null : openedRow = guard.id">
								<td>
									<p><b>{{guard.firstName}} {{guard.lastName}}</b></p>
									<p class="faded-p">{{guard.personalId}}</p>
								</td>
								<!--<td><p>Nurse</p></td>-->
								<td><p>{{guard.payslips.meta.hourlyRate}} SEK</p></td>
								<td><p>{{guard.payslips.meta.totalHours}} hours</p></td>
								<td><p>{{guard.payslips.meta.totalBrutoSalary}} SEK</p></td>
								<td>
									<div *ngIf="openedRow != guard.id; else opened" class="dropdown-icon"></div>
									<ng-template #opened>
										<div class="dropdown-icon active"></div>
									</ng-template>
								</td>
							</tr>
							<ng-container *ngIf="guard.id == this.openedRow">
								<tr class="opened">
									<td colspan="12">
										<div class="d-flex justify-content-end gap-1">
											<img src="/assets/img/download-file.svg" alt="">
											<p class="blue-p">{{'payroll.Export person' | translate}}</p>
										</div>
									</td>
								</tr>
								<ng-container *ngFor="let payslip of guard.payslips.data">
									<!--hidden row info-->
									<tr class="opened">
										<td colspan="6">
											<div class="accordion-body">
												<div class="d-flex justify-content-between gap-2 top">
													<span>{{payslip.payslipItems.length}} {{'payroll.assignments this period' | translate}}</span>

												</div>
												<table class="table">
													<thead>
													<tr>
														<th>{{'payroll.Date' | translate}}</th>
														<th>{{'payroll.Time' | translate}}</th>
														<th>{{'payroll.Competence' | translate}}</th>
														<th>{{'payroll.Hourly rate' | translate}}</th>
														<th>{{'payroll.Assignment total' | translate}}</th>
													</tr>
													</thead>
													<tbody>
													<tr *ngFor="let payslipItem of payslip.payslipItems">
														<td><p>{{payslipItem.startAt | date: 'd MMMM Y'}}</p></td>
														<td><p>{{payslipItem.startAt | date: 'HH:mm'}} -
															{{payslipItem.endAt | date: 'HH:mm'}}</p></td>
														<td><p>{{payslipItem.skill.name}}</p></td>
														<td><p>{{payslipItem.hourlyRate}} SEK</p></td>
														<td><p>{{payslipItem.brutoSalary}} SEK</p></td>
													</tr>
													</tbody>
												</table>
											</div>
										</td>
									</tr>
								</ng-container>

							</ng-container>

						</ng-container>
						</tbody>
						<tfoot>
						<tr>
							<td colspan="6">
								<div class="showing">
									<img class="left-arrows" src="/assets/img/Arrow-left end.svg"
									     *ngIf="page != 1"
									     (click)="onClickPagination(page = 1)">
									<img class="left-arrows" src="/assets/img/Arrow-left.svg"
									     (click)="onClickPagination(page = page - 1)" *ngIf="page != 1">
									<p>{{'pagination' | translate:{
										from: pagination.from,
										to: pagination.to,
										total: pagination.total
										} }}
									</p>
									<img class="right-arrows" src="/assets/img/Arrow-right.svg"
									     *ngIf="page != pagination.lastPage"
									     (click)="onClickPagination(page = page + 1)">
									<img class="right-arrows" src="/assets/img/Arrow-right end.svg"
									     *ngIf="page != pagination.lastPage"
									     (click)="onClickPagination(page = pagination.lastPage)">
								</div>
							</td>
						</tr>
						</tfoot>
					</table>
					<!-- /TABLE -->
				</div>
			</div>
		</div>
	</div>
</div>
