import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GuardShiftStatusConst {
    public CUSTOMER_APPROVED_HOURS = 301;
    public CUSTOMER_DISAPPROVE_HOURS = 302;

    public CHECKEDIN = 2;
    public CHECKEDOUT = 3;
    public GUARD_CANCELED = -5;
}