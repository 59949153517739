import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import {createData} from "../create-object";
import * as AssignmentsActions from '../../store/assignments.actions';
import {Subscription} from "rxjs";
import {CustomerModel} from "../../../../models/customer.model";
import {ReportTypeModel} from "../../../../models/reportType.model";

@Component({
    selector: 'app-step6',
    templateUrl: './step6.component.html',
    styleUrls: ['./step6.component.scss']
})
export class Step6Component implements OnInit, OnDestroy {

    public chosenReports: number[] = [];
    public subscription: Subscription;
    public customer: CustomerModel;

    public reports: ReportTypeModel[];
    public requiredFields = false;
    public requiredId = null;

    constructor(private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {

        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.reports = appState.shared.reportTypes;
        });

        if(createData.reportTypeIds){
            this.chosenReports = createData.reportTypeIds;
        }

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onChooseReport(id: number, isChecked: boolean) {
        if (isChecked) {
            this.chosenReports.push(id);
        } else {
            let index = this.chosenReports.indexOf(id);
            this.chosenReports.splice(index, 1);
        }
    }

    public isSelected(id: number) {
        return this.chosenReports.find(element => element == id);
    }

    public onSaveAndContinue(): void {
        this.store.dispatch(new AssignmentsActions.UpdateStep(7));
        createData.reportTypeIds = this.chosenReports;
    }

    public onSeeRequiredFields(reportId: number): void{
        this.requiredId = reportId;
        this.requiredFields = !this.requiredFields;
    }

}
