import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../models/customer.model';
import { SkillModel } from '../../../models/skill.model';
import { UniformModel } from '../../../models/uniform.model';
import { UtilityOptionModel } from '../../../models/utilityOption.model';
import * as SharedActions from '../../../shared-store/shared.actions';
import { GuardModel } from '../../../models/guard.model';
import { PaginationModel } from '../../../models/pagination.model';
import * as MyStaffActions from '../store/my-staff.actions';

@Component({
    selector: 'app-competence-details',
    templateUrl: './competence-details.component.html',
    styleUrls: ['./competence-details.component.scss'],
})
export class CompetenceDetailsComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    customer: CustomerModel;
    skill: SkillModel;
    uniforms: UniformModel[];
    options: UtilityOptionModel[];
    baseSkills: SkillModel[];

    guards: GuardModel[];
    pagination: PaginationModel;

    staff: GuardModel[];
    staffPagination: PaginationModel;
    isMoreStaff: boolean;

    uniformToEdit: UniformModel;
    optionToEdit: UtilityOptionModel;
    mode: 'CREATE' | 'EDIT';
    showSkillForm = false;
    properties = true;
    members = false;

    //filters
    params: {
        key: string,
        value: string
    }[] = [];

    editCompetenceModal = false;
    deleteCompetenceModal = false;
    // form = new FormGroup({
    //     name: new FormControl('', Validators.required),
    //     base: new FormControl('', Validators.required),
    //     description: new FormControl('', Validators.required),
    //     salary: new FormControl('', Validators.required),
    //     certificate: new FormControl('', Validators.required),
    //     bonusMF: new FormControl('', Validators.required),
    //     bonusSSday: new FormControl('', Validators.required),
    //     bonusSSnight: new FormControl('', Validators.required),
    //     bonusC: new FormControl('', Validators.required),
    //     bonusD: new FormControl('', Validators.required),
    // });

    createEditClothingModal = false;
    clothingForm = new FormGroup({
        name: new FormControl('', Validators.required),
        file: new FormControl('', Validators.required),
        fileSource: new FormControl(''),
    });

    createEditOptionModal = false;
    optionForm = new FormGroup({
        name: new FormControl('', Validators.required),
        file: new FormControl('', Validators.required),
        fileSource: new FormControl(''),
    });

    addMembersModal = false;
    chosenMembers = [];

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.skill = appState.shared.skill;
            this.uniforms = appState.shared.uniforms;
            this.options = appState.shared.utilityOptions;
            this.guards = appState.staff.members;
            this.pagination = appState.staff.membersPagination;
            this.baseSkills = appState.shared.skills;
            this.staff = appState.staff.staff;
            this.staffPagination = appState.staff.staffPagination;
            this.isMoreStaff = appState.staff.staffMeta.isMoreStaff;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // public onEditCompetence(): void {
    //     this.editCompetenceModal = true;
    //     this.form.patchValue({
    //         name: this.skill?.name,
    //         base: this.skill?.parentId,
    //         description: this.skill?.description,
    //         salary: this.skill?.price?.baseSalary,
    //         certificate: this.skill?.price?.isRequiredCertificate ? 1 : 0,
    //         bonusMF: this.skill?.price?.unsocialHoursBonusWorkdayNight,
    //         bonusSSday: this.skill?.price?.unsocialHoursBonusWeekendDay,
    //         bonusSSnight: this.skill?.price?.unsocialHoursBonusWeekendNight,
    //         bonusC: this.skill?.price?.unsocialHoursBonusCdates,
    //         bonusD: this.skill?.price?.unsocialHoursBonusDdates,
    //     });
    // }

    // public onSubmitCompetence(): void {
    //     let data: any = {};
    //
    //     data.parentId = Number(this.form.get('base').value);
    //     data.name = this.form.get('name').value;
    //     if (this.form.get('description').value) {
    //         data.description = this.form.get('description').value;
    //     }
    //     data.pricing = {
    //         baseSalary: Number(this.form.get('salary').value),
    //         unsocialHoursBonusWorkdayNight: Number(this.form.get('bonusMF').value),
    //         unsocialHoursBonusWeekendDay: Number(this.form.get('bonusSSday').value),
    //         unsocialHoursBonusWeekendNight: Number(this.form.get('bonusSSnight').value),
    //         unsocialHoursBonusCdates: Number(this.form.get('bonusC').value),
    //         unsocialHoursBonusDdates: Number(this.form.get('bonusD').value),
    //     };
    //     if (this.form.get('certificate').value) {
    //         data.pricing.isRequiredCertificate = Number(this.form.get('certificate').value);
    //     }
    //
    //     this.store.dispatch(new SharedActions.UpdateSkill({
    //         customerId: this.customer.id,
    //         skillId: this.skill.id,
    //         data: data,
    //     }));
    //     this.editCompetenceModal = false;
    // }

    public onDeleteCompetence(): void {
        this.store.dispatch(new SharedActions.DeleteSkill({customerId: this.customer.id, skillId: this.skill.id}));
        this.deleteCompetenceModal = false;
    }

    public onCreateEditClothing(mode: 'EDIT' | 'CREATE', uniform?: UniformModel): void {
        this.clothingForm.reset();
        this.createEditClothingModal = true;
        this.mode = mode;

        if (this.mode == 'EDIT') {
            this.uniformToEdit = uniform;
            this.clothingForm.controls['name'].setValue(uniform.name);
        }
    }

    public onSubmitClothing(): void {
        let formData = new FormData();
        if (this.mode == 'EDIT') {
            if (this.clothingForm.get('fileSource').value) {
                formData.append('image', this.clothingForm.value.fileSource);
            }
            formData.append('name', this.clothingForm.get('name').value);
            formData.append('_method', 'PUT');
            this.store.dispatch(new SharedActions.UpdateSkillUniform({
                skillId: this.skill.id,
                uniformId: this.uniformToEdit.id,
                data: formData,
            }));
        } else {
            formData.append('name', this.clothingForm.get('name').value);
            formData.append('image', this.clothingForm.value.fileSource);
            this.store.dispatch(new SharedActions.CreateSkillUniform({
                skillId: this.skill.id,
                data: formData,
            }));
        }
        this.createEditClothingModal = false;
    }

    public onDeleteClothing(uniform: UniformModel): void {
        this.store.dispatch(new SharedActions.DeleteSkillUniform({skillId: this.skill.id, uniformId: uniform.id}));
        this.createEditClothingModal = false;
    }

    public onCreateEditOption(mode: 'EDIT' | 'CREATE', option?: UtilityOptionModel): void {
        this.optionForm.reset();
        this.createEditOptionModal = true;
        this.mode = mode;

        if (this.mode == 'EDIT') {
            this.optionToEdit = option;
            this.optionForm.controls['name'].setValue(option.name);
        }
    }

    public onSubmitOption(): void {
        let formData = new FormData();
        if (this.mode == 'EDIT') {
            if (this.optionForm.get('fileSource').value) {
                formData.append('image', this.optionForm.value.fileSource);
            }
            formData.append('name', this.optionForm.get('name').value);
            formData.append('_method', 'PUT');
            this.store.dispatch(new SharedActions.UpdateSkillUtilityOption({
                skillId: this.skill.id,
                optionId: this.optionToEdit.id,
                data: formData,
            }));
        } else {
            formData.append('name', this.optionForm.get('name').value);
            formData.append('image', this.optionForm.value.fileSource);
            this.store.dispatch(new SharedActions.CreateSkillUtilityOption({
                skillId: this.skill.id,
                data: formData,
            }));
        }
        this.createEditOptionModal = false;
    }

    public onDeleteOption(option: UtilityOptionModel): void {
        this.store.dispatch(new SharedActions.DeleteSkillUtilityOption({skillId: this.skill.id, optionId: option.id}));
        this.createEditOptionModal = false;
    }

    public onAddMembersClicked(): void {
        let guardIds = this.guards.map(g => g.id);
        this.store.dispatch(new MyStaffActions.ClearStaff());
        this.store.dispatch(new MyStaffActions.LoadStaff({customerId: this.customer.id, guardIds: guardIds}));
        this.addMembersModal = true;
    }

    public onChangeMember(event: number, checked: boolean): void {
        if (checked) {
            this.chosenMembers.push(Number(event));
        } else {
            let index = this.chosenMembers.indexOf(Number(event));
            this.chosenMembers.splice(index, 1);
        }
    }

    public isSelectedMember(id: number) {
        return this.chosenMembers.find(l => l == id);
    }

    public onAddMembers(): void {
        this.store.dispatch(new MyStaffActions.AddSkillMembers({
            customerId: this.customer.id,
            skillId: this.skill.id,
            guardIds: this.chosenMembers,
        }));
        this.chosenMembers = [];
        this.addMembersModal = false;
    }

    public onRemoveMember(guard: GuardModel): void {
        this.store.dispatch(new MyStaffActions.DeleteSkillMember({
            customerId: this.customer.id,
            skillId: this.skill.id,
            guardId: guard.id,
        }));
    }

    onNextPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new MyStaffActions.LoadMembers({
                customerId: this.customer.id,
                skillId: this.skill.id,
                page: this.pagination.currentPage + 1,
                params: this.params,
            }));
        }
    }

    onPreviousPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new MyStaffActions.LoadMembers({
                customerId: this.customer.id,
                skillId: this.skill.id,
                page: this.pagination.currentPage - 1,
                params: this.params,
            }));
        }
    }

    onFirstPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new MyStaffActions.LoadMembers({
                customerId: this.customer.id,
                skillId: this.skill.id,
                page: 1,
                params: this.params,
            }));
        }
    }

    onLastPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new MyStaffActions.LoadMembers({
                customerId: this.customer.id,
                page: this.pagination.lastPage,
                skillId: this.skill.id,
                params: this.params,
            }));
        }
    }

    onFileChange(files: any) {
        if (files.length > 0) {
            const file = files[0] as File;
            if (file.size > 5 * 1024 * 1024) {
                this._snackBar.open('Chosen file size is to big!', 'close', {duration: 2000});
                return;
            }
            this.clothingForm.patchValue({
                fileSource: file,
            });
            this.optionForm.patchValue({
                fileSource: file,
            });
        }
    }

    onScrollStaff(event): void {
        const height = document.getElementById('staff-id').scrollHeight;
        const elementHeight = document.getElementById('staff-id').offsetHeight;
        const scrollBottom = document.getElementById('staff-id').scrollTop;

        if (this.isMoreStaff && (scrollBottom >= (height - elementHeight) * 0.7)) {
            this.store.dispatch(new MyStaffActions.LoadStaff({
                customerId: this.customer.id,
            }));
        }
    }
}
