<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

                <div class="mt-5">
                    <h1>{{'staff.title' | translate}}</h1>
                </div>

                <div class="d-block d-md-flex justify-content-start justify-content-md-between gap-md-2 mt-5 header-wrapper">
                    <div class="search-wrapper">
                        <img class="search-icon" src="/assets/img/search 1.svg">
                        <input (keyup.enter)="onFilter('search_term',$event.target.value)" class="search-input"
                               type="search" placeholder="{{'staff.search' | translate}}"/>
                    </div>
                    <div class="btn btn-primary my-button mt-md-0 mt-2" routerLink="/components/my-staff/invite">
                        <div class="d-flex">
                            <img src="/assets/img/plus white.svg">
                            <p>{{'staff.invite' | translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="filters d-lg-flex justify-content-between d-block flex-wrap">
                    <form [formGroup]="filterForm">
                        <div class="d-lg-flex justify-content-start gap-4 d-block  text-left info-block flex-wrap">
                            <div class="d-flex justify-content-start gap-2 mt-1">
                                <p class="select-label">{{'staff.form.status' | translate}}</p>
                                <select class="form-select" formControlName="status"
                                        (change)="onFilter('status',$event.target.value)">
                                    <option [value]="0">{{'staff.form.status-all' | translate}}</option>
                                    <option *ngFor="let i of [1,2,3]" [value]="i">{{'staff.invite-status.' + i | translate}}</option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-start gap-2 mt-1">
                                <p class="select-label">{{'assignments.filters.competence' | translate}}</p>
                                <select class="form-select" formControlName="skills"
                                        (change)="onFilter('skill_id',$event.target.value)">
                                    <option [value]="0">{{'assignments.filters.all-competences' | translate}}</option>
                                    <option *ngFor="let skill of this?.skills"
                                            [value]="skill?.id">{{skill?.name}}</option>
                                </select>
                            </div>
                        </div>
                    </form>
                    <div class="clear-filters d-flex justify-content-start gap-1" (click)="onClearFilters()">
                        <img src="/assets/img/clear-icon.png" alt="">
                        <p>{{'staff.clear-filters' | translate}}</p>
                    </div>
                </div>

                <div class="table-responsive">
                    <!-- TABLE -->
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th class="sort-col">{{'table.thead.name' | translate}}</th>
                            <th>{{'table.thead.contact' | translate}}</th>
                            <th>{{'table.thead.competence' | translate}}</th>
                            <th class="text-right">{{'table.thead.status' | translate}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let guard of guards" (click)="onGuardClicked(guard)">

                            <td>
                                <div class="d-flex justify-content-between gap-2 name-wrapper">
                                    <img *ngIf="guard?.guard?.image" src="{{guard?.guard?.image}}" alt="">
                                    <img *ngIf="!guard?.guard?.image" src="/assets/img/empty-profile-picture.png" alt="">
                                    <div class="mt-auto mb-auto">
                                        <ng-container *ngIf="guard.guard; else guardInviteModel">
                                            <p><b>{{guard.guard.firstName}} {{guard.guard.lastName}}</b></p>
                                        </ng-container>
                                        <ng-template #guardInviteModel>
                                            <p><b>{{guard?.firstName}} {{guard?.lastName}}</b></p>
                                        </ng-template>

                                        <!--<p class="faded-p">{{guard?.id}}</p>-->
                                    </div>
                                </div>
                            </td>

                            <td>
                                <p>{{guard?.guard.email || guard?.email}}</p>
                                <p>{{guard?.guard.phone || guard?.phone}}</p>
                            </td>

                            <td>
                                <div class="d-flex justify-content-start gap-2 skill-wrapper"
                                     *ngIf="guard?.skill; else noSkills">
                                    <div class="skill">
                                        <span>{{guard?.skill?.name}}</span>
                                    </div>
                                </div>

                                <ng-template #noSkills>
                                    -
                                </ng-template>

                            </td>

                            <td>

                                <ng-container [ngSwitch]="guard?.status">
                                    <div class="pill waiting" *ngSwitchCase="1">
                                        <span>{{'staff.invite-status.1' | translate}}</span>
                                    </div>
                                    <div class="pill active" *ngSwitchCase="2">
                                        <span>{{'staff.invite-status.2' | translate}}</span>
                                    </div>
                                    <div class="pill expired" *ngSwitchCase="3">
                                        <span>{{'staff.invite-status.3' | translate}}</span>
                                    </div>
                                </ng-container>

                            </td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr *ngIf="pagination">
                            <td colspan="4">
                                <div class="showing">
                                    <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                                         class="left-arrows"
                                         src="/assets/img/Arrow-left end.svg"
                                         (click)="onFirstPage()">
                                    <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                                         class="left-arrows"
                                         src="/assets/img/Arrow-left.svg"
                                         (click)="onPreviousPage()">
                                    <p *ngIf="pagination.total > 0;else noRecords">{{'pagination' | translate:{
                                        from: this?.pagination?.from,
                                        to: this?.pagination?.to,
                                        total: this?.pagination?.total
                                    } }}
                                    </p>
                                    <ng-template #noRecords>
                                        <p>{{'pagination-no-records' | translate}}</p>
                                    </ng-template>
                                    <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                                         class="right-arrows"
                                         src="/assets/img/Arrow-right.svg"
                                         (click)="onNextPage()">
                                    <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                                         class="right-arrows"
                                         src="/assets/img/Arrow-right end.svg"
                                         (click)="onLastPage()">
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    <!-- /TABLE -->
                </div>

            </div>
        </div>
    </div>
</div>
