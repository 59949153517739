import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
//bar chart
import {
    ApexAxisChartSeries,
    ApexChart,
    ChartComponent,
    ApexDataLabels,
    ApexPlotOptions,
    ApexYAxis,
    ApexLegend,
    ApexStroke,
    ApexXAxis,
    ApexFill,
    ApexTooltip,
} from 'ng-apexcharts';
import * as AssignmentsActions from '../../assignments/store/assignments.actions';
import { JobPlaceModel } from '../../../models/jobPlace.model';
import { SkillModel } from '../../../models/skill.model';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as CompanyActions from '../../company/store/company.actions';
import { CustomerModel } from '../../../models/customer.model';
import * as SharedActions from '../../../shared-store/shared.actions';
import { ReportModel } from '../../../models/report.model';
import { PaginationModel } from '../../../models/pagination.model';
import { ReportStatistic } from '../../../models/reportStatistic';
import * as ApexCharts from 'apexcharts';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    yaxis: ApexYAxis;
    xaxis: ApexXAxis;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
};

@Component({
    selector: 'app-reports-details',
    templateUrl: './reports-details.component.html',
    styleUrls: ['./reports-details.component.scss'],
})
export class ReportsDetailsComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    @ViewChild('chart') chart: ApexCharts;
    @ViewChild('chartDiv') chartDiv: ElementRef;
    @ViewChildren('.info-box') infoBoxElements: ElementRef[];
    chartOptions: Partial<ChartOptions>;

    reportTypesModal: boolean = false;
    fromTime = moment().subtract(1, 'month').startOf('month');
    toTime = moment().subtract(1, 'month').endOf('month');
    calendarLabel = 'Last month';
    dateString = 'lastMonth';
    isCustom = false;
    subBetween = 'subMonth';
    reportTypes = [
        {
            id: 0,
            name: 'Total assignments cost',
            field: 'totalAssignmentCostReport',
            unit: 'kr',
            label: 'kr prev period',
            hidden: false,
        },
        {
            id: 1,
            name: 'My Staff salaries',
            field: 'myStaffSalariesReport',
            unit: 'kr',
            label: 'kr prev period',
            hidden: false,
        },
        {
            id: 2,
            name: 'Safety\'s personnel cost',
            field: 'safetyPersonnelCostReport',
            unit: 'kr',
            label: 'kr prev period',
            hidden: false,
        },
        {
            id: 3,
            name: 'No. assignment hours',
            field: 'assignmentHoursReport',
            unit: 'h',
            label: 'h prev period',
            hidden: false,
        },
        {
            id: 4,
            name: 'Arrest',
            field: 'arrestReport',
            unit: '',
            label: 'prev period',
            hidden: false,
        },
        {
            id: 5,
            name: 'Taken into custody',
            field: 'custodyReport',
            unit: '',
            label: 'prev period',
            hidden: false,
        },
        {
            id: 6,
            name: 'Vandalism/Graffiti',
            field: 'vandalismReport',
            unit: '',
            label: 'prev period',
            hidden: false,
        },
        {
            id: 7,
            name: 'Banished',
            field: 'banishedReport',
            unit: '',
            label: 'prev period',
            hidden: false,
        },
    ];
    showCalendar: boolean = false;
    chosenReportTypes: number[] = [];

    reportTypesForm = this.fb.group({
        reportTypeIds: new FormArray(this.reportTypes.map(() => new FormControl(true))),
    });

    customer: CustomerModel;
    objects: JobPlaceModel[];
    skills: SkillModel[];
    reports: ReportModel[];
    reportsStatistics: ReportStatistic;
    pagination: PaginationModel;

    // reportTypesForm = this.formBuilder.group({
    //     repo: '',
    // });

    filterForm = new FormGroup({
        objects: new FormControl('0'),
        skills: new FormControl('0'),
    });

    params: {
        key: string,
        value: string
    }[] = [];

    constructor(private fb: FormBuilder,
                private router: Router,
                private store: Store<fromApp.AppState>,
                private render: Renderer2,
                private elem: ElementRef,
                private translate: TranslateService,
                private _snackBar: MatSnackBar) {
        this.translate.get('report-details').subscribe(x => {
            this.calendarLabel = x['last-month'];

            this.reportTypes[0].name = x['Total assignments cost'];
            this.reportTypes[1].name = x['My Staff salaries'];
            this.reportTypes[2].name = x['Safety\'s personnel cost'];
            this.reportTypes[3].name = x['No. assignment hours'];
            this.reportTypes[4].name = x['Arrest'];
            this.reportTypes[5].name = x['Taken into custody'];
            this.reportTypes[6].name = x['Vandalism/Graffiti'];
            this.reportTypes[7].name = x['Banished'];

        });

    }

    ngOnInit(): void {
        this.initChart();
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.objects = appState.company.objects;
            this.skills = appState.shared.skills.flatMap((item) => {
                return [item, ...item.children]
            });
            this.reports = appState.assignments.reports;
            this.pagination = appState.assignments.pagination;
            this.reportsStatistics = appState.assignments.reportStatistics;

        });

        this.store.dispatch(new CompanyActions.LoadObjects({customerId: this.customer.id}));
        this.store.dispatch(new SharedActions.LoadSkills());
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public initChart(): void {
        this.chartOptions = {
            series: [
                {
                    // name: 'first',
                    //data: [0, 1, 2, 2, 0, 1, 2]
                    data: [
                        // {x: '05 Mar', y: 1},
                        // {x: '05 Mar', y: 5},
                        // {x: '10 Mar', y: 1},
                        // {x: '16 Mar', y: 1},
                        // {x: '18 Mar', y: 2},
                        // {x: '04 Mar', y: 0},
                        // {x: '08 Mar', y: 0},
                        // {x: '23 Mar', y: 1},
                    ],
                },
                // {
                //     name: "second",
                //     //data: [1, 2, 1, 0, 2, 1, 0]
                //     data: [
                //         {x: '03 Mar', y: 1},
                //         {x: '13 Mar', y: 2},
                //         {x: '19 Mar', y: 1},
                //         {x: '09 Mar', y: 2},
                //         {x: '10 Mar', y: 1},
                //         {x: '23 Mar', y: 1},
                //         {x: '16 Mar', y: 1},
                //     ]
                // },
            ],
            chart: {
                id: 'chartReport',
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '65%',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                type: 'datetime',
                categories: [],
                tickAmount: 7,
                labels: {
                    style: {
                        colors: '#7E849A',
                    },
                    datetimeFormatter: {
                        year: 'yyyy',
                        month: 'MMM \'yy',
                        day: 'dd MMM',
                        hour: 'HH:mm',
                    },
                },
                tickPlacement: 'on',
            },
            yaxis: {
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        colors: '#7E849A',
                    },
                },
            },
            fill: {
                opacity: 1,
                colors: ['#3970F4', '#C1C1C1', '#234BAB'],
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return '' + val + '';
                    },
                },
            },
            legend: {
                show: false,
            },
        };

        this.chart = new ApexCharts('chartReport', this.chartOptions);
    }

    onChangeTypes(i: number, isChecked: boolean) {
        if (isChecked) {
            if (this.chosenReportTypes.length < 8)
                this.chosenReportTypes.push(i);
        } else {
            let index = this.chosenReportTypes.indexOf(i);
            this.chosenReportTypes.splice(index, 1);
        }
    }

    public disabledBox(i: number): boolean {
        return this.chosenReportTypes.find(t => t == i) == undefined && this.chosenReportTypes.length == 8;
    }

    public onChooseReportTypes(): void {
        this.chosenReportTypes = [];
        this.reportTypesModal = true;
    }

    public onApplyTypes(): void {
        this.resetActiveInfoBox();
        let reportTypeIds = this.reportTypesForm.controls.reportTypeIds.value as Array<number>;

        this.reportTypes = this.reportTypes.map(item => {
            item.hidden = !reportTypeIds[item.id];
            return item;
        });

        this.reportTypesModal = false;
    }

    public onFilter(key: string, value: any): void {
        if (value == 0) {
            this.params = this.params.filter(el => el.key != key);
        } else {
            this.params = this.params.filter(el => el.key != key);
            this.params.push({key: key, value: value});
        }


        // this.store.dispatch(new AssignmentsActions.ClearAssignments());
        // this.store.dispatch(new AssignmentsActions.LoadAssignments({customerId: 1, page: 1, params: this.params}));
        this.dispatchLoadReports(1);
        // this.store.dispatch(new AssignmentsActions.LoadReports({
        //     customerId: this.customer.id,
        //     page: 1,
        //     params: this.transformedParams(),
        //     fromTime: this.fromTime.unix(),
        //     toTime: this.toTime.unix(),
        // }));

        this.dispatchLoadReportStatistics();
    }

    private transformedParams() {
        let body = {};
        this.params.forEach(param => {
            body[param.key] = param.value;
        });

        return body;
    }

    onNextPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.dispatchLoadReports(this.pagination.currentPage + 1);
            // this.store.dispatch(new AssignmentsActions.LoadReports({
            //     customerId: this.customer.id,
            //     page: this.pagination.currentPage + 1,
            //     params: this.transformedParams(),
            //     fromTime: this.fromTime.unix(),
            //     toTime: this.toTime.unix(),
            // }));
        }
    }

    onPreviousPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.dispatchLoadReports(this.pagination.currentPage - 1);
            // this.store.dispatch(new AssignmentsActions.LoadReports({
            //     customerId: this.customer.id,
            //     page: this.pagination.currentPage - 1,
            //     params: this.transformedParams(),
            //     fromTime: this.fromTime.unix(),
            //     toTime: this.toTime.unix(),
            // }));
        }
    }

    onFirstPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.dispatchLoadReports(1);
            // this.store.dispatch(new AssignmentsActions.LoadReports({
            //     customerId: this.customer.id,
            //     page: 1,
            //     params: this.transformedParams(),
            //     fromTime: this.fromTime.unix(),
            //     toTime: this.toTime.unix(),
            // }));
        }
    }

    onLastPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.dispatchLoadReports(this.pagination.lastPage);
            // this.store.dispatch(new AssignmentsActions.LoadReports({
            //     customerId: this.customer.id,
            //     page: this.pagination.lastPage,
            //     params: this.transformedParams(),
            //     fromTime: this.fromTime.unix(),
            //     toTime: this.toTime.unix(),
            // }));
        }
    }


    onClickReportType(event, chart: { count: number; date: string }[]) {
        this.resetActiveInfoBox();

        this.render.addClass(event.target.closest('.info-box'), 'active');

        this.chartOptions.series[0].data = chart.map(item => {
            return {
                x: item.date,
                y: item.count,
            };
        });


        this.chart = new ApexCharts(this.chartDiv.nativeElement, this.chartOptions);
        this.chart.render().then(res => {
        });
    }

    resetActiveInfoBox() {
        let infoBoxElements = this.elem.nativeElement.querySelectorAll('.info-box');
        infoBoxElements.forEach(el => {
            this.render.removeClass(el, 'active');
        });

        this.chartOptions.series[0].data = [];
    }

    onSelectDate(dateString) {
        this.dateString = dateString;
        this.isCustom = false;
        switch (dateString) {
            case 'lastMonth':
                this.translate.get('report-details.last-month').subscribe(x => this.calendarLabel = x);
                this.fromTime = moment().subtract(1, 'month').startOf('month');
                this.toTime = moment().subtract(1, 'month').endOf('month');
                this.subBetween = 'subMonth';
                break;
            case 'thisMonth':
                this.translate.get('report-details.this-month').subscribe(x => this.calendarLabel = x);
                this.fromTime = moment().startOf('month');
                this.toTime = moment().endOf('month');
                this.subBetween = 'subMonth';
                break;
            case 'thisYear':
                this.translate.get('report-details.this-year').subscribe(x => this.calendarLabel = x);
                this.fromTime = moment().startOf('year');
                this.toTime = moment().endOf('year');
                this.subBetween = 'subYear';
                break;
            case 'previousYear':
                this.translate.get('report-details.previous-year').subscribe(x => this.calendarLabel = x);
                this.fromTime = moment().subtract(1, 'year').startOf('year');
                this.toTime = moment().subtract(1, 'year').endOf('year');
                this.subBetween = 'subYear';
                break;
        }


        this.showCalendar = false;
        this.dispatchLoadReportStatistics();
        this.dispatchLoadReports(1);
    }

    onCustomDatePicker(startEl, endEl) {
        if (startEl.value && !endEl.value) {
            endEl.min = startEl.value;
        }
        if (endEl.value && !startEl.value) {
            startEl.max = endEl.value;
        }

        if (startEl.value && endEl.value) {
            this.showCalendar = false;
            this.fromTime = moment(startEl.value).startOf('day');
            this.toTime = moment(endEl.value).endOf('day');
            this.isCustom = true;

            this.translate.get('report-details.custom-dates').subscribe(x => this.calendarLabel = x);

            this.dispatchLoadReportStatistics();
            this.dispatchLoadReports(1);
        }
    }

    private dispatchLoadReportStatistics() {
        this.chartOptions.series[0].data = [];
        this.resetActiveInfoBox();
        this.store.dispatch(new AssignmentsActions.LoadReportsStatistics({
            customerId: this.customer.id,
            fromTime: this.fromTime.unix(),
            toTime: this.toTime.unix(),
            isCustom: this.isCustom,
            subBetween: this.subBetween,
            jobPlaceIds: this.transformedParams().hasOwnProperty('assignment.jobPlace')
                ? [this.transformedParams()['assignment.jobPlace']]
                : [],
            skillIds: this.transformedParams().hasOwnProperty('assignment.skill')
                ? [this.transformedParams()['assignment.skill']]
                : [],
        }));
    }

    private dispatchLoadReports(page) {
        this.store.dispatch(new AssignmentsActions.LoadReports({
            customerId: this.customer.id,
            page: page,
            params: this.transformedParams(),
            fromTime: this.fromTime.unix(),
            toTime: this.toTime.unix(),
        }));
    }
}
