<div class="overlay">
	<div class="guard-profile" (clickOutside)="closeModal.emit();">
		<div class="d-flex">
			<p class="name flex-grow-1">{{guard.firstName}}</p>
			<span class="close" (click)="closeModal.emit();" role="button"><img src="/assets/img/Close.svg" alt=""></span>
		</div>
		<p class="label mb-4"><img src="/assets/img/approved.png" alt="" class="mr-1">{{'guard-details.approved-by' | translate}} Safetly</p>
		<div class="d-flex">
			<div class="image-rating-wrapper">
				<div class="img-wrapper">
					<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}" alt="">
				</div>
				<div class="text-center" *ngIf="guard.ratingCount > 0; else noRating">
					<div class="d-flex justify-content-between align-items-start mt-3">
						<ngb-rating max="5" [rate]="guard?.averageRate" [readonly]="true"></ngb-rating>
						<p class="label">{{guard?.averageRate}} / 5</p>
					</div>

					<p class="label">
						<img src="/assets/img/check_circle-active.png" alt="">
						{{guard.ratingCount}} {{'guard-details.ratings' | translate}}</p>

				</div>
				<ng-template #noRating>
					<p class="text-center mt-2">{{'guard-details.no-rating-yet' | translate}}</p>
				</ng-template>
			</div>
			<div class="flex-grow-1">
				<div class="row">
					<div class="col-12">
						<p class="label">{{'guard-details.about' | translate}} {{guard.firstName}}</p>
						<p class="description">{{guard.biography}}</p>
						<!--<p class="description">{{guard.biography || 'guard-details.no-info' | translate }}</p>-->
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<p class="label">{{'navigation.competences' | translate}}</p>
						<p><span class="skill-tag"
						         *ngFor="let skill of guard.skills">{{skill.name}}</span></p>
					</div>
				</div>
			</div>
		</div>

		<div class="controls mt-4">
			<span class="btn mr-3" (click)="closeModal.emit(false);">{{'buttons.cancel' | translate}}</span>

			<ng-container [ngSwitch]="action">
				<span *ngSwitchCase="actionOptions.pickPerson"
				      (click)="onClickAction.emit({guardId: guard.id, isChecked: true}); closeModal.emit()"
				      class="btn btn-primary">{{'buttons.pick-person' | translate}}</span>

				<span *ngSwitchCase="actionOptions.unpickPerson"
				      (click)="onClickAction.emit({guardId: guard.id, isChecked: false}); closeModal.emit()"
				      class="btn btn-primary">{{'buttons.unpick-person' | translate}}</span>

				<span *ngSwitchCase="actionOptions.addToFavourites"
				      (click)="onClickAction.emit({guard: guard, action: action}); closeModal.emit()"
				      class="btn btn-primary">{{'buttons.add-to-favourites' | translate}}</span>

				<span *ngSwitchCase="actionOptions.removeFromFavourites"
				      (click)="onClickAction.emit({guard: guard, action: action}); closeModal.emit()"
				      class="btn btn-primary">{{'buttons.remove-as-favourite' | translate}}</span>

				<span *ngSwitchCase="actionOptions.defineNumberOfLeaders"
				      (click)="closeModal.emit()"
				      class="btn btn-primary">{{'buttons.define-number-of-personnel' | translate}}</span>

				<span *ngSwitchCase="actionOptions.defineNumberOfPersonnel"
				      (click)="closeModal.emit()"
				      class="btn btn-primary">{{'buttons.define-number-of-leaders' | translate}}</span>
			</ng-container>
		</div>
	</div>

</div>
