import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import * as fromAuth from '../auth/store/auth.reducer';
import * as fromCompany from '../components/company/store/company.reducer';
import * as fromAssignments from '../components/assignments/store/assignments.reducer';
import * as fromMessages from '../components/messages/store/messages.reducer';
import * as fromShared from '../shared-store/shared.reducer';
import * as fromMyStaff from '../components/my-staff/store/my-staff.reducer';
import {environment} from '../../environments/environment';
import {from} from "rxjs";

export interface AppState {
    auth: fromAuth.State;
    company: fromCompany.State;
    assignments: fromAssignments.State;
    messages: fromMessages.State;
    shared: fromShared.State;
    staff: fromMyStaff.State;
}

export const appReducer: ActionReducerMap<AppState> = {
    auth: fromAuth.authReducer,
    company: fromCompany.companyReducer,
    assignments: fromAssignments.assignmentsReducer,
    messages: fromMessages.messagesReducer,
    shared: fromShared.sharedReducer,
    staff: fromMyStaff.myStaffReducer
};

