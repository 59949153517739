<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="flexed">
                    <p class="faded-p">Reports</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">Report types</p>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h1>Report types</h1>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="btn btn-primary my-button" (click)="this.createReportTypeModal = true">
                            <div class="flexed">
                                <img src="/assets/img/plus white.svg">
                                <p>New report type</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <!-- TABLE -->
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>Name</th>  <!--[mdbTableSort]="elements" sortBy="id" -->
                            <th>Times used</th>
                            <th style="opacity: 0;">Arrow</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let i of [1,2,3]">
                            <td><p class="black">Wayfinding/direction guidance for visitors</p></td>
                            <td><p class="black">3</p></td>
                            <td><img (click)="onEditReportType()" class="arrow" src="/assets/img/Arrow-right.svg"></td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="3">
                                <div class="showing">
                                    <img class="left-arrows" src="/assets/img/Arrow-left end.svg">
                                    <img class="left-arrows" src="/assets/img/Arrow-left.svg">
                                    <p>Showing 1-10 of 10</p>
                                    <img class="right-arrows" src="/assets/img/Arrow-right.svg">
                                    <img class="right-arrows" src="/assets/img/Arrow-right end.svg">
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    <!-- /TABLE -->
                </div>
            </div>
        </div>
    </div>
</div>

<!--Create report Modal-->
<app-modal *ngIf="this.createReportTypeModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">Create report type</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.createReportTypeModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <form [formGroup]="createReportTypeForm" (ngSubmit)=" onCreateReportType()"
              id="createReportTypeForm">
            <div class="form-group">
                <label for="name">Report name</label>
                <input class="form-control" formControlName="name" id="name" type="text"/>
            </div>
            <div class="form-group">
                <label for="desc">Report description</label>
                <textarea class="form-control" formControlName="desc" id="desc"
                          type="text"></textarea>
            </div>
            <p class="bold">Report fields</p>
            <div class="form-group">
                <div class="form-check">
                    <input checked class="form-check-input" name="fields" type="checkbox"
                           id="date" [value]="1"
                           (change)="onChangeField(1, $event.target.checked)">
                    <label class="form-check-label" for="date">Date/time</label>
                </div>
                <div class="form-check">
                    <input checked class="form-check-input" name="fields" type="checkbox"
                           id="desc" [value]="2"
                           (change)="onChangeField(2, $event.target.checked)">
                    <label class="form-check-label" for="desc">Description</label>
                </div>
                <div class="form-check">
                    <input checked class="form-check-input" name="fields" type="checkbox"
                           id="num" [value]="3"
                           (change)="onChangeField(3, $event.target.checked)">
                    <label class="form-check-label" for="num">Number of occurances</label>
                </div>
            </div>
        </form>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn" (click)="this.createReportTypeModal = false">Cancel</span>
                    <input type="submit"
                           form="createReportTypeForm"
                           value="Save changes"
                           class="btn save-btn btn-primary"/>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Edit report Modal-->
<app-modal *ngIf="this.editReportTypeModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">Edit report type</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.editReportTypeModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <form [formGroup]="editReportTypeForm" (ngSubmit)="onSubmitEditReportType()"
              id="editReportTypeForm">
            <div class="form-group">
                <label for="name">Report name</label>
                <input class="form-control" formControlName="name" id="name" type="text"/>
            </div>
            <div class="form-group">
                <label for="desc">Report description</label>
                <textarea class="form-control" formControlName="desc" id="desc"
                          type="text"></textarea>
            </div>
            <p class="bold">Report fields</p>
            <div class="form-group">
                <div class="form-check">
                    <input class="form-check-input" name="fields" type="checkbox" id="date2"
                           [value]="1"
                           (change)="onChangeFieldEdit(1, $event.target.checked)">
                    <label class="form-check-label" for="date2">Date/time</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="fields" type="checkbox" id="desc2"
                           [value]="2"
                           (change)="onChangeFieldEdit(2, $event.target.checked)">
                    <label class="form-check-label" for="desc2">Description</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" name="fields" type="checkbox" id="num2"
                           [value]="3"
                           (change)="onChangeFieldEdit(3, $event.target.checked)">
                    <label class="form-check-label" for="num2">Number of occurrences</label>
                </div>
            </div>
        </form>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-4 text-left">
                <p (click)="onDeleteReportType()" class="delete">Delete report type</p>
            </div>
            <div class="col-8 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn" (click)="this.editReportTypeModal = false">Cancel</span>
                    <input type="submit"
                           form="editReportTypeForm"
                           value="Save changes"
                           class="btn save-btn btn-primary"/>
                </div>
            </div>
        </div>
    </div>
</app-modal>
