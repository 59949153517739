import 'moment-duration-format';

import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthComponent} from './auth/auth.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginPasswordComponent} from './auth/login-password/login-password.component';
import {LoginEmailComponent} from './auth/login-email/login-email.component';
import {StoreModule} from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import {EffectsModule} from '@ngrx/effects';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RegisterStep2Component} from './auth/register-step2/register-step2.component';
import {LoginEmailStep2Component} from './auth/login-email-step2/login-email-step2.component';
import {ComponentsComponent} from './components/components.component';
import {HomeComponent} from './components/home/home.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from '@angular/material/button';
import {ProfileComponent} from './components/profile/profile.component';
import {CompanyComponent} from './components/company/company.component';
import {UsersComponent} from './components/company/users/users.component';
import {BillingComponent} from './components/company/billing/billing.component';
import {RegionsComponent} from './components/company/regions/regions.component';
import {SettingsComponent} from './components/company/settings/settings.component';

import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {InviteUsersComponent} from './components/invite-users/invite-users.component';
import {ReportsComponent} from './components/reports/reports.component';
import {ReportsDetailsComponent} from './components/reports/reports-details/reports-details.component';
import {ReportComponent} from './components/reports/report/report.component';
import {ReportTypesComponent} from './components/reports/report-types/report-types.component';

import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {NgApexchartsModule} from 'ng-apexcharts';
import {AssignmentsComponent} from './components/assignments/assignments.component';
import {FavouritesComponent} from './components/assignments/favourites/favourites.component';
import {CreateAssignmentComponent} from './components/assignments/create-assignment/create-assignment.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FavouritesObjectComponent} from './components/assignments/favourites-object/favourites-object.component';
import {Step1Component} from './components/assignments/create-assignment/step1/step1.component';
import {Step2Component} from './components/assignments/create-assignment/step2/step2.component';
import {Step3Component} from './components/assignments/create-assignment/step3/step3.component';
import {Step4Component} from './components/assignments/create-assignment/step4/step4.component';
import {Step5Component} from './components/assignments/create-assignment/step5/step5.component';
import {Step6Component} from './components/assignments/create-assignment/step6/step6.component';
import {Step7Component} from './components/assignments/create-assignment/step7/step7.component';
import {Step8Component} from './components/assignments/create-assignment/step8/step8.component';
import {AssignmentOrderedComponent} from './components/assignments/create-assignment/assignment-ordered/assignment-ordered.component';
import {HelpComponent} from './components/help/help.component';

import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';

import {AuthEffects} from "./auth/store/auth.effects";
import {CompanyEffects} from "./components/company/store/company.effects";
import {MessagesEffects} from "./components/messages/store/messages.effects";
import {SharedEffects} from "./shared-store/shared.effects";

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";

import { NgxSpinnerModule } from "ngx-spinner";

import {GoogleMapsModule} from '@angular/google-maps';
import {Interceptor} from "./interception";
import { EmailVerificationComponent } from './auth/email-verification/email-verification.component';
import {AssignmentsEffects} from "./components/assignments/store/assignments.effects";
import {MyStaffEffects} from "./components/my-staff/store/my-staff.effects";
import { MessagesComponent } from './components/messages/messages.component';
import {environment} from "../environments/environment";
import {MessagingService} from "./service/messaging.service";
import { LoginOverLinkComponent } from './auth/login-over-link/login-over-link.component';
import { AcceptInviteComponent } from './components/company/accept-invite/accept-invite.component';
import { GroupByPipe } from './pipes/group-by.pipe';
import { LoaderComponent } from './shared/loader/loader.component';
import { AssignmentDetailsComponent } from './components/assignments/assignment-details/assignment-details.component';
import { ModalComponent } from './shared/modal/modal.component';
import { ProgressBarRequestComponent } from './shared/progress-bar-request/progress-bar-request.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GroupByDatePipe } from './pipes/group-by-date.pipe';

import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { MyStaffComponent } from './components/my-staff/my-staff.component';
import { CompetencesComponent } from './components/my-staff/competences/competences.component';
import { PayrollComponent } from './components/my-staff/payroll/payroll.component';
import { InviteStaffComponent } from './components/my-staff/invite-staff/invite-staff.component';
import { StaffProfileComponent } from './components/my-staff/staff-profile/staff-profile.component';
import { CompetenceDetailsComponent } from './components/my-staff/competence-details/competence-details.component';
import { InviteManualComponent } from './components/my-staff/invite-staff/invite-manual/invite-manual.component';
import { SendInvitesComponent } from './components/my-staff/invite-staff/send-invites/send-invites.component';

import localeSv from '@angular/common/locales/sv';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeSv);

// Imported Kolkov Text Editor package
import { AngularEditorModule } from '@kolkov/angular-editor';
import { GuardAcceptInviteComponent } from './components/my-staff/guard-accept-invite/guard-accept-invite.component';
import { GuardShiftStatusConst } from './constants/guard.shift.status.const';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { GuardDetailModalComponent } from './shared/guard-detail-modal/guard-detail-modal.component';
import { SkillFormComponent } from './components/my-staff/shared/skill-form/skill-form.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CreateCompanyComponent } from './components/company/create-company/create-company.component';
// import { AgmCoreModule } from '@agm/core';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        LoginPasswordComponent,
        LoginEmailComponent,
        RegisterStep2Component,
        LoginEmailStep2Component,
        ComponentsComponent,
        HomeComponent,
        ProfileComponent,
        CompanyComponent,
        UsersComponent,
        BillingComponent,
        RegionsComponent,
        SettingsComponent,
        InviteUsersComponent,
        ReportsComponent,
        ReportsDetailsComponent,
        ReportComponent,
        ReportTypesComponent,
        AssignmentsComponent,
        FavouritesComponent,
        CreateAssignmentComponent,
        FavouritesObjectComponent,
        Step1Component,
        Step2Component,
        Step3Component,
        Step4Component,
        Step5Component,
        Step6Component,
        Step7Component,
        Step8Component,
        AssignmentOrderedComponent,
        HelpComponent,
        EmailVerificationComponent,
        MessagesComponent,
        LoginOverLinkComponent,
        AcceptInviteComponent,
        GroupByPipe,
        LoaderComponent,
        AssignmentDetailsComponent,
        ModalComponent,
        ProgressBarRequestComponent,
        GroupByDatePipe,
        MyStaffComponent,
        CompetencesComponent,
        PayrollComponent,
        InviteStaffComponent,
        StaffProfileComponent,
        CompetenceDetailsComponent,
        InviteManualComponent,
        SendInvitesComponent,
        GuardAcceptInviteComponent,
        ClickOutsideDirective,
        GuardDetailModalComponent,
        SkillFormComponent,
        CreateCompanyComponent,
    ],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        StoreModule.forRoot(fromApp.appReducer),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
            autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        }),
        EffectsModule.forRoot([
            AuthEffects,
            CompanyEffects,
            AssignmentsEffects,
            MessagesEffects,
            SharedEffects,
            MyStaffEffects
        ]),
        MatSidenavModule,
        BrowserAnimationsModule,
        MatListModule,
        MatButtonModule,
        NgApexchartsModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        NgbModule,
        MatButtonToggleModule,
        GoogleMapsModule,
        NgxSpinnerModule,
        CalendarModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        // Kolkov Text Editor Module
        AngularEditorModule,
        // AgmCoreModule.forRoot({
        //     apiKey: 'AIzaSyDyZMH14lUfn95sof7Cpp_mgftqgICO8qc',
        //     libraries: ["places"],
        //     apiVersion: 'quarterly'
        // }),
        GooglePlaceModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          // enabled: environment.production,
          enabled: true,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        MessagingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: DEFAULT_CURRENCY_CODE, useValue: 'SEK'
        },
        {
            provide: LOCALE_ID,
            useValue: 'sv-SV' // 'de' for Germany, 'fr' for France ...
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
