import {Component, OnDestroy, OnInit} from '@angular/core';
import {createData} from "../create-object";
import {Store} from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {CustomerModel} from "../../../../models/customer.model";
import * as AssignmentsActions from '../../store/assignments.actions';
import {AssignmentCategoryModel} from "../../../../models/assignmentCategory.model";
import {AssignmentModel} from "../../../../models/assignment.model";
import {PaginationModel} from "../../../../models/pagination.model";
import * as SharedActions from "../../../../shared-store/shared.actions";

@Component({
    selector: 'app-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit, OnDestroy {


    public subscription: Subscription;
    public customer: CustomerModel;
    public categories: AssignmentCategoryModel[];
    public assignments: AssignmentModel[];
    public pagination: PaginationModel;

    public assignment: AssignmentModel;
    public fromHistory = false;

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
        this.store.dispatch(new AssignmentsActions.UpdateStep(1));
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.categories = appState.assignments.categories;
            this.assignments = appState.assignments.assignments;
            this.pagination = appState.assignments.pagination;
            this.assignment = appState.assignments.assignment;

            if (this.assignment && this.fromHistory) {
                this.setCreateDataValues();
                this.fromHistory = false;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onCreateFromCategory(id: number): void {
        if (id == 5) {
            //provera da li postoje kreirani skilovi, ako ne postoje ispisi poruku i izadji
        }

        this.store.dispatch(new AssignmentsActions.CreateAssignmentFromHistory({
            isFromHistory: false,
        }));
        createData.assignmentCategoryId = id;
        if (createData.assignmentCategoryId) {
            createData.skillId = undefined;
            createData.uniformId = undefined;
            createData.utilityOptionIds = [];
        }
        this.store.dispatch(new AssignmentsActions.UpdateStep(2));
    }

    onCreateFromHistory(assignment: AssignmentModel): void {
        this.fromHistory = true;
        this.store.dispatch(new AssignmentsActions.CreateAssignmentFromHistory({
            isFromHistory: true,
        }));
        this.store.dispatch(new AssignmentsActions.LoadAssignmentDetails({
            customerId: this.customer.id,
            assignmentsId: assignment.id
        }));
    }

    public setCreateDataValues() {
        createData.assignmentCategoryId = this?.assignment?.assignmentCategory?.id;
        createData.skillId = this?.assignment?.skill?.id;
        createData.uniformId = this?.assignment?.uniform?.id;
        // createData.utilityOptionIds = [];
        createData.utilityOptionIds = this.assignment.utilityOptions.map(x => x.id);
        // this?.assignment?.utilityOptions?.forEach(uo => {
        //     createData.utilityOptionIds.push(uo.id);
        // });
        createData.isRecurring = this?.assignment?.isRecurring;
        if (this?.assignment.isRecurring) {
            createData.recurrencePattern = this?.assignment?.recurringPattern;
        }
        createData.numberOfLeaders = this?.assignment?.numberOfLeaders;
        createData.numberOfPersonnel = this?.assignment?.numberOfPersonnel;
        createData.description = this?.assignment?.description;
        createData.jobPlaceId = this?.assignment?.jobPlace?.id;
        createData.contactPersonName = this?.assignment?.contactPersonName;
        createData.contactPersonPhone = this?.assignment?.contactPersonPhone;
        createData.dates = [];
        // this?.assignment?.shifts?.forEach(shift => {
        //     createData.dates.push({
        //         date: shift?.date,
        //         startTime: shift?.startTime,
        //         endTime: shift?.endTime
        //     });
        // });
        if (this.fromHistory) {
            this.store.dispatch(new AssignmentsActions.UpdateStep(3));
        } else {
            this.store.dispatch(new AssignmentsActions.UpdateStep(2));
        }
    }

    onNextPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: this.pagination.currentPage + 1
            }));
        }
    }

    onPreviousPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: this.pagination.currentPage - 1
            }));
        }
    }

    onFirstPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: 1
            }));
        }
    }

    onLastPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                customerId: this.customer.id,
                page: this.pagination.lastPage
            }));
        }
    }

}
