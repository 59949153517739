<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

                <div class="d-flex mb-4">
                    <p class="faded-p">{{'staff.title' | translate}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{'staff.competences.title' | translate}}</p>
                </div>

                <div class="d-block d-sm-flex justify-content-between gap-2 mb-3">
                    <h1>{{'staff.competences.title' | translate}}</h1>
                    <div class="btn btn-primary my-button">
                        <div class="d-flex justify-content-between gap-1" (click)="showSkillForm = true">
                            <img src="/assets/img/plus%20white.svg" alt="">
                            <p>{{'staff.competences.new' | translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="wrapper">
                    <!-- TABLE -->
                    <!--<div class="table-responsive scrolling" (scroll)="onScroll($event)">-->
                    <div class="table-responsive scrolling">
                        <table class="table table-hover" id="skills">
                            <thead>
                            <tr>
                                <th>{{'table.thead.competence' | translate}}</th>
                                <th>{{'table.thead.members' | translate}}</th>
                                <th>{{'table.thead.hourly' | translate}}</th>
                                <th>{{'table.thead.certificate' | translate}}</th>
                                <th class="text-right" style="opacity: 0">arrow</th>
                            </tr>
                            </thead>
                            <tbody *ngFor="let skill of skills">
                            <tr>
                                <td>
                                    <div class="d-flex justify-content-start gap-2">
                                        <img *ngIf="skill?.imagePath" class="competence-icon" src="{{skill?.imagePath}}" alt="">
                                        <img *ngIf="!skill?.imagePath" class="competence-icon" src="/assets/img/competence.png" alt="">
                                        <div class="mt-auto mb-auto">
                                            <p><b>{{skill?.name}}</b></p>
                                            <p class="faded-p" *ngIf="skill?.isBasic">{{'staff.competences.base' | translate}}</p>
                                        </div>
                                    </div>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <!--<td><p>15</p></td>-->
                                <!--<td><p>350 SEK</p></td>-->
                                <!--<td><p>{{skill?.skillPricing?.isRequiredCertificate ? 'Yes' : 'No'}}</p></td>-->
                                <td>
                                    <div class="arrow-icon d-none"></div>
                                </td>
                            </tr>
                            <tr *ngFor = "let child of skill.children" routerLink="/components/my-staff/competences/{{child?.id}}" class="competence">
                                <td>
                                    <div class="ml-5 d-flex justify-content-start gap-2">
                                        <img *ngIf="child.imagePath;else noSkillImage" class="competence-icon" src="{{child.imagePath}}" alt="">
                                        <ng-template #noSkillImage>
                                            <img  class="competence-icon" src="/assets/img/subcategory.png" alt="">
                                        </ng-template>

                                        <div class="mt-auto mb-auto">
                                            <p><b>{{child.name}}</b></p>
                                            <p class="faded-p" *ngIf="child.isBasic">{{'staff.competences.base' | translate}}</p>
                                        </div>
                                    </div>
                                </td>
                                <td><p>{{child.numberOfGuards}}</p></td>
                                <td><p>{{child?.price?.baseSalary || 'NA'}} SEK</p></td>
                                <td><p>{{child?.price?.isRequiredCertificate ? 'Yes' : 'No'}}</p></td>
                                <td>
                                    <div class="arrow-icon"></div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- /TABLE -->
                </div>

            </div>
        </div>
    </div>
</div>

<!---Create skill modal--->
<app-skill-form *ngIf="showSkillForm"
                [action]="'store'"
                (destroyComponent)="showSkillForm = false"></app-skill-form>
