import {Component, OnDestroy, OnInit} from '@angular/core';
import {EmployeeModel} from "../../models/employee.model";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from '../store/auth.actions';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
    selector: 'app-login-over-link',
    templateUrl: './login-over-link.component.html',
    styleUrls: ['./login-over-link.component.scss']
})
export class LoginOverLinkComponent implements OnInit {

    private param: string;
    private token: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private spinner: NgxSpinnerService,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.param = params.t;

            this.token =  this.param;
        });

        this.spinner.show();

        localStorage.setItem('jwt', this.token);
        this.store.dispatch(new AuthActions.Autologin());
        this.router.navigate(['/components/home']);
    }


}
