import {Component, OnDestroy, OnInit} from '@angular/core';
import * as AssignmentsActions from '../store/assignments.actions';
import {Subscription} from "rxjs";
import {CustomerModel} from "../../../models/customer.model";
import {Store} from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {JobPlaceModel} from "../../../models/jobPlace.model";
import * as CompanyActions from '../../company/store/company.actions';

@Component({
    selector: 'app-favourites',
    templateUrl: './favourites.component.html',
    styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit, OnDestroy {

    public subscription: Subscription;
    public customer: CustomerModel;
    public objects: JobPlaceModel[];

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit(): void {

        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.objects = appState.company.objects;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
