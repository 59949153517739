// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    appStoreUrl: '',
    playStoreUrl: '',
    apiUrl: 'https://apiv2.safetly.epicss.dev/api',
    // apiUrl: 'https://api.safetly.se/api',
    // apiUrl: 'https://apiv2.safetly.se/api',
    // appUrl: 'http://localhost:4200',
    appUrl: 'https://safetly-webapp.epicss.dev',
    googleMapApiKey: 'AIzaSyCdGvpQnK_pQnLcNt1sv6GH_fafjDDZGZQ',
    firebase: {
        apiKey: "AIzaSyBjMQ_FZmMUwjZNg_N6FSw7Kd2TpaBGyGs",
        authDomain: "safetly-v2.firebaseapp.com",
        projectId: "safetly-v2",
        storageBucket: "safetly-v2.appspot.com",
        messagingSenderId: "529691811975",
        appId: "1:529691811975:web:5d0e0d9b1bbb585c5d5822",
        measurementId: "G-CXHLBJM9ME"
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
