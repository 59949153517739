import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import {Router} from "@angular/router";
import {EmployeeModel} from "../../models/employee.model";
import {CustomerModel} from "../../models/customer.model";
import {Subscription} from "rxjs";
import * as CompanyActions from '../company/store/company.actions';
import * as AuthActions from '../../auth/store/auth.actions';
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-invite-users',
    templateUrl: './invite-users.component.html',
    styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit, OnDestroy {
    appUrl = environment.appUrl;
    public editField: string;
    public customer: CustomerModel;
    public employee: EmployeeModel;
    public subscription: Subscription;

    public inviteObject: {
        id: number,
        email?: string,
        firstName?: string,
        lastName?: string,
        phone?: string,
        employeeRoleId?: string
    }[] = [];


    constructor(private formBuilder: FormBuilder,
                private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.employee = appState.auth.employee;

        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onInviteUsers(): void {
        let invites = this.inviteObject.filter(element => element.email !== undefined && this.isValidEmail(element.email));
        invites.forEach(element => {
            if (!element.employeeRoleId) {
                element.employeeRoleId = '3'
            }
            // delete element.id;
        });

        if (invites.length < 1) {
            return;
        }

        this.store.dispatch(new CompanyActions.InviteUsers({
            employee: this.employee.id,
            customer: this.customer.id,
            data: this.inviteObject
        }));

    }

    public isValidEmail(email: string): boolean {
        if (!email) {
            return true;
        }
        let isValid = /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/.test(email);
        return isValid;
    }

    public addToObject(id: number, field: string, value: string): void {

        if (value === '') {
            return;
        }

        if (this.inviteObject.find(el => el.id === id)) {
            if (field === 'email') {
                this.inviteObject.find(el => el.id === id).email = value;
            }
            if (field === 'firstName') {
                this.inviteObject.find(el => el.id === id).firstName = value;
            }
            if (field === 'lastName') {
                this.inviteObject.find(el => el.id === id).lastName = value;
            }
            if (field === 'phone') {
                this.inviteObject.find(el => el.id === id).phone = value;
            }
            if (field === 'role') {
                this.inviteObject.find(el => el.id === id).employeeRoleId = value;
            }
        } else {
            if (field === 'email') {
                this.inviteObject.push({id: id, email: value});
            }
            if (field === 'firstName') {
                this.inviteObject.push({id: id, firstName: value});
            }
            if (field === 'lastName') {
                this.inviteObject.push({id: id, lastName: value});
            }
            if (field === 'phone') {
                this.inviteObject.push({id: id, phone: value});
            }
            if (field === 'role') {
                this.inviteObject.push({id: id, employeeRoleId: value});
            }
        }

    }

    public onCopyInviteLink(): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.appUrl + `/invite?t=${this?.customer?.inviteToken}`;

        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this._snackBar.open('Link has been copied to clipboard!', 'Close', {
            duration: 3000,
            panelClass: ['snackbar']
        });
    }

    public onGenerateInviteLink(): void {
        this.store.dispatch(new AuthActions.UpdateCustomer({
            customerId: this?.customer?.id,
            inviteToken: 'activate'
        }));
    }

    public onDeactivateLink(): void {
        this.store.dispatch(new AuthActions.UpdateCustomer({
            customerId: this?.customer?.id,
            inviteToken: 'deactivate'
        }));
    }

}
