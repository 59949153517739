<div class="main-wrapper">
	<div class="row">
		<div class="col-12">
			<div class="inner-wrapper">
				<div class="d-sm-flex justify-content-sm-between d-block justify-content-center text-left">
					<div>
						<h1>{{'createCompany.Create company' | translate}}</h1>
					</div>
				</div>
				<div class="info-wrapper">
					<form [formGroup]="createCompanyForm"
					      #form="ngForm"
					      (ngSubmit)="onCreateCompany()"

					      id="createCompanyForm">
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label for="avatar">{{'createCompany.Company Logo' | translate}}</label>
									<input class="form-control"
									       (change)="onFileChange($event)"
									       type="file"
									       accept="image/*"
									       hidden
									       #file
									       id="avatar"/>
									<div class="browse-file-btn" role="button" (click)="file.click()">
										<img src="/assets/img/browse-icon.png" alt="">
										<span>
											{{createCompanyForm.controls['avatar']?.value?.name
											? createCompanyForm.controls['avatar'].value.name
											: 'Browse files'}}</span>
									</div>
								</div>

							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<div class="form-group">
									<label for="companyName" class="required">{{'createCompany.Company Name' | translate}}</label>
									<input formControlName="companyName"
									       class="form-control"
									       type="text"
									       required
									       id="companyName"/>
									<div class="text-danger"
									     *ngIf="form.submitted && createCompanyForm.hasError('required', 'companyName')">
										{{'company.billing.company' | translate}} {{'form.validation.required' | translate}}
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="form-group">
									<label for="idNumber" class="required">{{'createCompany.Organization Number' | translate}}</label>
									<input formControlName="idNumber"
									       class="form-control"
									       required
									       type="number"
									       id="idNumber"/>
									<div class="text-danger"
									     *ngIf="form.submitted
						     && createCompanyForm.hasError('required', 'idNumber')">
										{{'company.billing.number' | translate}} {{'form.validation.required' | translate}}
									</div>
									<div class="text-danger"
									     *ngIf="form.submitted
						     && createCompanyForm.hasError('pattern', 'idNumber')">
										{{'company.billing.number' | translate}} {{'form.validation.digit' | translate: {'number': 10} }}
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="form-group">
									<label for="email" class="required">{{'createCompany.Email' | translate}}</label>
									<input formControlName="email"
									       class="form-control"
									       type="text"
									       required
									       id="email"/>
									<div class="text-danger"
									     *ngIf="form.submitted
						     && createCompanyForm.hasError('required', 'email')">
										{{'company.billing.number' | translate}} {{'form.validation.required' | translate}}
									</div>
									<div class="text-danger"
									     *ngIf="form.submitted
						     && createCompanyForm.hasError('email', 'email')">
										{{'company.billing.number' | translate}} {{'form.validation.digit' | translate: {'number': 10} }}
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="form-group">
									<label for="phone" class="required">{{'createCompany.Phone' | translate}}</label>
									<input formControlName="phone"
									       class="form-control"
									       required
									       type="text"
									       id="phone"/>
									<div class="text-danger"
									     *ngIf="form.submitted
						     && createCompanyForm.hasError('required', 'phone')">
										{{'company.billing.number' | translate}} {{'form.validation.required' | translate}}
									</div>
									<div class="text-danger"
									     *ngIf="form.submitted
						     && createCompanyForm.hasError('pattern', 'phone')">
										{{'company.billing.number' | translate}} {{'form.validation.digit' | translate: {'number': 10} }}
									</div>
								</div>
							</div>

							<div class="col-12">
								<div class="form-group">
									<label for="biography">{{'createCompany.Description' | translate}}</label>
									<textarea formControlName="biography"
									          class="form-control"
									          type="text"
									          id="biography"
									          rows="6"
									          placeholder="{{'createCompany.Description placeholder' | translate}}"></textarea>
									<!--<div class="text-danger"-->
									     <!--*ngIf="createCompanyForm.controls['biography'].dirty-->
						     <!--&& createCompanyForm.hasError('required', 'biography')">-->
										<!--{{'company.billing.number' | translate}} {{'form.validation.required' | translate}}-->
									<!--</div>-->
									<!--<div class="text-danger"-->
									     <!--*ngIf="createCompanyForm.controls['biography'].dirty-->
						     <!--&& createCompanyForm.hasError('pattern', 'biography')">-->
										<!--{{'company.billing.number' | translate}} {{'form.validation.digit' | translate: {'number': 10} }}-->
									<!--</div>-->
								</div>
							</div>
						</div>

						<input type="submit"
						       value="{{'createCompany.Create Company' | translate}}"
						       class="btn save-btn btn-primary"/>
						<a class="btn btn-secondary ml-3" routerLink="/components/home">{{'createCompany.Cancel' | translate}}</a>

					</form>
				</div>

			</div>
		</div>
	</div>
</div>
