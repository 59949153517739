import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from './shared.reducer';
import * as fromApp from '../store/app.reducer';
import * as fromShared from './shared.reducer';
import * as SharedActions from './shared.actions';
import {environment} from '../../environments/environment';
import {catchError, filter, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EmployeeRoleModel} from "../models/employeeRole.model";
import {CountryModel} from "../models/country.model";
import {CityModel} from "../models/city.model";
import {SkillModel} from "../models/skill.model";
import {UniformModel} from "../models/uniform.model";
import {UtilityOptionModel} from "../models/utilityOption.model";
import {ReportTypeModel} from "../models/reportType.model";
import {PaginationModel} from "../models/pagination.model";
import { NewsModel } from '../models/news.model';
import { StateModel } from '../models/state.model';


interface CompanyResponseData {
    employeeRoles: EmployeeRoleModel[];
}

@Injectable()
export class SharedEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private _snackBar: MatSnackBar,
        private store: Store<fromApp.AppState>,
    ) {
    }

    init$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROOT_EFFECTS_INIT),
            switchMap(
                () => {
                    return [
                        new SharedActions.LoadEmployeeRoles(),
                        new SharedActions.LoadStates({
                            countryId: 213 // sweeden country
                        }),
                        // new SharedActions.LoadCountries(),
                        // new SharedActions.LoadCountryCities(1),
                    ];
                },
            ),
        );
    });

    loadStates = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_STATES),
            switchMap((action: SharedActions.LoadStates) => {
                return this.http.get(environment.apiUrl + `/countries/${action.payload.countryId}/states`)
                           .pipe(
                               switchMap((resData: { data: StateModel[] }) => {
                                   return of(new SharedActions.LoadStatesSuccess({
                                       states: resData.data
                                   }));
                               }),
                               catchError(error => {
                                   return of(new SharedActions.LoadStatesFailed());
                               }),
                           )
            }),
        );
    });

    loadNews = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_NEWS),
            switchMap((action: SharedActions.LoadNews) => {
                return this.http.get(environment.apiUrl + '/news')
                    .pipe(
                        switchMap((resData: { data: NewsModel[] }) => {
                            return of(new SharedActions.LoadNewsSuccess({
                                news: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadNewsFailed());
                        }),
                    )
            }),
        );
    });

    loadEmployeeRoles = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_EMPLOYEE_ROLES),
            switchMap((action: SharedActions.LoadEmployeeRoles) => {
                return this.http.get(environment.apiUrl + '/employee-roles')
                    .pipe(
                        switchMap((resData: { data: EmployeeRoleModel[] }) => {
                            return of(new SharedActions.LoadEmployeeRolesSuccess({
                                employeeRoles: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadEmployeeRolesFailed());
                        }),
                    )
            }),
        );
    });

    loadCountries = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_COUNTRIES),
            switchMap(
                (storeData: SharedActions.LoadCountries) => {
                    return this.http.get(environment.apiUrl + '/countries')
                        .pipe(
                            switchMap((resData: { data: CountryModel[] }) => {
                                return of(new SharedActions.LoadCountriesSuccess(resData.data));
                            }),
                            catchError(error => {
                                return of(new SharedActions.LoadCountriesFailed());
                            }),
                        );
                },
            ),
        );
    });

    loadCountryCities = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_COUNTRY_CITIES),
            switchMap(
                (storeData: SharedActions.LoadCountryCities) => {
                    return this.http.get(environment.apiUrl + '/countries/' + storeData.payload + '/cities')
                        .pipe(
                            switchMap((resData: { data: CityModel[] }) => {
                                return of(new SharedActions.LoadCountryCitiesSuccess(resData.data));
                            }),
                            catchError(error => {
                                return of(new SharedActions.LoadCountryCitiesFailed());
                            }),
                        );
                },
            ),
        );
    });

    sendFirebaseTokenToServer = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.SEND_FIREBASE_TOKEN_TO_SERVER),
            withLatestFrom(this.store.select('shared')),
            filter(([action, state]: [SharedActions.SendFirebaseTokenToServer, fromShared.State]) => state.firebaseToken !== null),
            switchMap(
                ([action, state]: [SharedActions.SendFirebaseTokenToServer, fromShared.State]) => {
                    return this.http.post(environment.apiUrl + '/employees/devices', {
                        token: state.firebaseToken,
                        clientId: 3, // for web client app,
                        model: navigator.userAgent,
                        appVersion: 1,
                        osVersion: navigator.appVersion
                    }).pipe(
                        switchMap((res) => {
                            return of(new SharedActions.SendFirebaseTokenToServerSuccess());
                        }),
                        catchError(error => {
                            return of(new SharedActions.SendFirebaseTokenToServerFail());
                        }),
                    );
                },
            ),
        );
    });

    loadSkills = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_SKILLS),
            withLatestFrom(this.store.select('shared')),
            switchMap(([action, State]: [SharedActions.LoadSkills, State]) => {

                let params = new HttpParams();
                params = params.append('page', State.skillsMeta.page);
                params = params.append('per_page', 10);

                return this.http.get(environment.apiUrl + `/skills`, {params: params})
                    .pipe(
                        switchMap((resData: { data: SkillModel[], pagination: PaginationModel }) => {
                            return of(new SharedActions.LoadSkillsSuccess({
                                skills: resData.data,
                                pagination: resData.pagination
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadSkillsFailed());
                        }),
                    )
            }),
        );
    });

    loadSkill = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_SKILL),
            withLatestFrom(this.store.select('shared')),
            switchMap(([action, State]: [SharedActions.LoadSkill, State]) => {

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/skills/${action.payload.skillId}`)
                    .pipe(
                        switchMap((resData: { data: SkillModel }) => {
                            return of(new SharedActions.LoadSkillSuccess({
                                skill: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadSkillFailed());
                        }),
                    )
            }),
        );
    });

    createSkill = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.CREATE_SKILL),
            switchMap((action: SharedActions.CreateSkill) => {
                let parentId = action.payload.data.parentId;
                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/skills`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: SkillModel }) => {
                            this._snackBar.open('Competence is successfully created!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.CreateSkillSuccess({
                                skill: resData.data,
                                parentId: parentId
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to create competence. Please, try again later.', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.CreateSkillFailed());
                        }),
                    )
            }),
        );
    });

    updateSkill = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.UPDATE_SKILL),
            switchMap((action: SharedActions.UpdateSkill) => {
                return this.http.put(environment.apiUrl + `/customers/${action.payload.customerId}/skills/${action.payload.data.id}`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: SkillModel }) => {
                            this._snackBar.open('Competence is successfully updated!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.UpdateSkillSuccess({
                                skill: resData.data
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to update this competence. Please, try again later.', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.UpdateSkillFailed());
                        }),
                    )
            }),
        );
    });

    deleteSkill = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.DELETE_SKILL),
            switchMap((action: SharedActions.DeleteSkill) => {
                return this.http.delete(environment.apiUrl + `/customers/${action.payload.customerId}/skills/${action.payload.skillId}`)
                    .pipe(
                        switchMap((resData: { data: SkillModel }) => {
                            this._snackBar.open('Competence is successfully deleted!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            this.router.navigate(['/components/my-staff/competences']);
                            return of(new SharedActions.DeleteSkillSuccess({
                                skill: resData.data
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to delete this competence. Please, try again later.', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.DeleteSkillFailed());
                        }),
                    )
            }),
        );
    });

    loadUniforms = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_UNIFORM),
            switchMap((action: SharedActions.LoadUniform) => {

                return this.http.get(environment.apiUrl + `/uniforms`)
                    .pipe(
                        switchMap((resData: { data: UniformModel[] }) => {
                            return of(new SharedActions.LoadUniformSuccess({
                                uniforms: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadUniformFailed());
                        }),
                    )
            }),
        );
    });

    loadUniform = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_UNIFORM_DETAILS),
            switchMap((action: SharedActions.LoadUniformDetails) => {

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/uniforms/${action.payload.uniformId}`)
                    .pipe(
                        switchMap((resData: { data: UniformModel }) => {
                            return of(new SharedActions.LoadUniformDetailsSuccess({
                                uniform: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadUniformDetailsFailed());
                        }),
                    )
            }),
        );
    });

    loadSkillUniforms = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_SKILL_UNIFORMS),
            switchMap((action: SharedActions.LoadSkillUniforms) => {

                return this.http.get(environment.apiUrl + `/skills/${action.payload.skillId}/uniforms`)
                    .pipe(
                        switchMap((resData: { data: UniformModel[] }) => {
                            return of(new SharedActions.LoadSkillUniformsSuccess({
                                uniforms: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadSkillUniformsFailed());
                        }),
                    )
            }),
        );
    });

    loadSkillUniform = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_SKILL_UNIFORM),
            switchMap((action: SharedActions.LoadSkillUniform) => {

                return this.http.get(environment.apiUrl + `/skills/${action.payload.skillId}/uniforms/${action.payload.uniformId}`)
                    .pipe(
                        switchMap((resData: { data: UniformModel }) => {
                            return of(new SharedActions.LoadSkillUniformSuccess({
                                uniform: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadSkillUniformFailed());
                        }),
                    )
            }),
        );
    });

    createSkillUniform = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.CREATE_SKILL_UNIFORM),
            switchMap((action: SharedActions.CreateSkillUniform) => {

                return this.http.post(environment.apiUrl + `/skills/${action.payload.skillId}/uniforms`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: UniformModel }) => {
                            this._snackBar.open('Uniform is successfully created!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.CreateSkillUniformSuccess({
                                uniform: resData.data,
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to create uniform. Please, try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.CreateSkillUniformFailed());
                        }),
                    )
            }),
        );
    });

    updateSkillUniform = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.UPDATE_SKILL_UNIFORM),
            switchMap((action: SharedActions.UpdateSkillUniform) => {

                return this.http.post(environment.apiUrl + `/skills/${action.payload.skillId}/uniforms/${action.payload.uniformId}`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: UniformModel }) => {
                            this._snackBar.open('Uniform is successfully updated!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.UpdateSkillUniformSuccess({
                                uniform: resData.data,
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to update uniform. Please, try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.UpdateSkillUniformFailed());
                        }),
                    )
            }),
        );
    });

    deleteSkillUniform = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.DELETE_SKILL_UNIFORM),
            switchMap((action: SharedActions.DeleteSkillUniform) => {

                return this.http.delete(environment.apiUrl + `/skills/${action.payload.skillId}/uniforms/${action.payload.uniformId}`)
                    .pipe(
                        switchMap((resData: { data: UniformModel }) => {
                            this._snackBar.open('Uniform is successfully removed!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.DeleteSkillUniformSuccess({
                                uniform: resData.data,
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to remove uniform. Please, try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.DeleteSkillUniformFailed());
                        }),
                    )
            }),
        );
    });

    loadUtilityOptions = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_UTILITY_OPTIONS),
            switchMap((action: SharedActions.LoadUtilityOptions) => {

                return this.http.get(environment.apiUrl + `/utility-options`)
                    .pipe(
                        switchMap((resData: { data: UtilityOptionModel[] }) => {
                            return of(new SharedActions.LoadUtilityOptionsSuccess({
                                utilityOptions: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadUtilityOptionsFailed());
                        }),
                    )
            }),
        );
    });

    loadSkillUtilityOptions = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_SKILL_UTILITY_OPTIONS),
            switchMap((action: SharedActions.LoadSkillUtilityOptions) => {

                return this.http.get(environment.apiUrl + `/skills/${action.payload.skillId}/utility-options`)
                    .pipe(
                        switchMap((resData: { data: UtilityOptionModel[] }) => {
                            return of(new SharedActions.LoadSkillUtilityOptionsSuccess({
                                utilityOptions: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadSkillUtilityOptionsFailed());
                        }),
                    )
            }),
        );
    });

    loadSkillUtilityOption = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_SKILL_UTILITY_OPTION),
            switchMap((action: SharedActions.LoadSkillUtilityOption) => {

                return this.http.get(environment.apiUrl + `/skills/${action.payload.skillId}/utility-options/${action.payload.optionId}`)
                    .pipe(
                        switchMap((resData: { data: UtilityOptionModel }) => {
                            return of(new SharedActions.LoadSkillUtilityOptionSuccess({
                                utilityOption: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadSkillUtilityOptionFailed());
                        }),
                    )
            }),
        );
    });

    createSkillUtilityOption = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.CREATE_SKILL_UTILITY_OPTION),
            switchMap((action: SharedActions.CreateSkillUtilityOption) => {

                return this.http.post(environment.apiUrl + `/skills/${action.payload.skillId}/utility-options`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: UtilityOptionModel }) => {
                            this._snackBar.open('Option is successfully created!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.CreateSkillUtilityOptionSuccess({
                                option: resData.data,
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to create option. Please, try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.CreateSkillUtilityOptionFailed());
                        }),
                    )
            }),
        );
    });

    updateSkillUtilityOption = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.UPDATE_SKILL_UTILITY_OPTION),
            switchMap((action: SharedActions.UpdateSkillUtilityOption) => {

                return this.http.post(environment.apiUrl + `/skills/${action.payload.skillId}/utility-options/${action.payload.optionId}`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: UtilityOptionModel }) => {
                            this._snackBar.open('Option is successfully updated!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.UpdateSkillUtilityOptionSuccess({
                                option: resData.data,
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to update option. Please, try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.UpdateSkillUtilityOptionFailed());
                        }),
                    )
            }),
        );
    });

    deleteSkillUtilityOption = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.DELETE_SKILL_UTILITY_OPTION),
            switchMap((action: SharedActions.DeleteSkillUtilityOption) => {

                return this.http.delete(environment.apiUrl + `/skills/${action.payload.skillId}/utility-options/${action.payload.optionId}`)
                    .pipe(
                        switchMap((resData: { data: UtilityOptionModel }) => {
                            this._snackBar.open('Option is successfully removed!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.DeleteSkillUtilityOptionSuccess({
                                option: resData.data,
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to remove option. Please, try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new SharedActions.DeleteSkillUtilityOptionFailed());
                        }),
                    )
            }),
        );
    });

    loadReportTypes = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.LOAD_REPORT_TYPES),
            switchMap((action: SharedActions.LoadReportTypes) => {
                return this.http.get(environment.apiUrl + `/report-types`)
                    .pipe(
                        switchMap((resData: { data: ReportTypeModel[] }) => {
                            return of(new SharedActions.LoadReportTypesSuccess({
                                reports: resData.data,
                            }));
                        }),
                        catchError(error => {
                            return of(new SharedActions.LoadReportTypesFailed());
                        }),
                    )
            }),
        );
    });

}
