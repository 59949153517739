<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <h1 *ngIf="employee?.firstName">{{'home.title.' + partOfTheDay | translate: {user: this?.employee?.firstName} }}</h1>
                <h1 *ngIf="!employee?.firstName">{{'home.title-no-user.' + partOfTheDay | translate }}</h1>
                <div class="make-booking">
                    <div class="content">
                        <ng-container *ngIf="employee.customers.length > 0; else createCompanyFirst">
                            <h2 *ngIf="assignments.length == 0;else makeNewBooking">{{'home.make-booking' | translate}}</h2>
                            <ng-template #makeNewBooking><h2>{{'home.make-new-booking' | translate}}</h2></ng-template>
                            <div class="button booking-button" routerLink="/components/assignments/create-assignment/step1">
                                <span *ngIf="assignments.length == 0;else makeNewBookingBtn">{{'home.start-booking' | translate}}</span>
                                <ng-template #makeNewBookingBtn>
                                    <span>{{'home.new-booking' | translate}}</span>
                                </ng-template>
                            </div>
                        </ng-container>
                        <ng-template #createCompanyFirst>
                            <h2>{{'home.Create Company' | translate}}</h2>
                            <div class="button booking-button" routerLink="/components/company">
                                <span>{{'Create' | translate}}</span>
                            </div>
                        </ng-template>

                    </div>
                    <img src="/assets/img/desni vizual.png" alt="">
                    <div class="image-wrapper"></div>
                </div>
                <div class="todo-list">
                    <div class="todo-list-header">
                        <div class="flexed">
                            <h3>{{'home.todo' | translate}}</h3>
                            <div class="num-items-circle" *ngIf="customerTodos.length">
                                <span>{{customerTodos.length}}</span>
                            </div>
                        </div>
                    </div>

                    <!--<div class="line"></div>-->
                    <!--<div *ngIf = "this?.assignments.length == 0" class="all-done">-->
                        <!--<p class="all-done-p">{{'home.all-done' | translate}}</p>-->
                    <!--</div>-->
                    <div *ngIf="customerTodos.length; else noTodos">
                        <div *ngFor="let customerTodo of customerTodos" class="list-item">
                            <div class="row">
                                <div class="col-12 col-lg-9 text-left">
                                    <p class="title">{{customerTodo.todoObject.title}}</p>
                                    <p class="description">{{customerTodo.todoObject.body}}</p>
                                </div>
                                <div class="col-12 col-lg-3 text-right item-action">
                                    <div class="flexed" style="float: right;"
                                         (click)="onTodoItemNavigate(customerTodo)">
                                        <p class="action">{{customerTodo.todoObject.button}}</p>
                                        <img src="/assets/img/Vector blue.svg">
                                    </div>
                                </div>
                            </div>
                            <!--<div *ngIf="!isLast" class="line"></div>-->
                        </div>
                    </div>
                    <ng-template #noTodos>
                        <p style="padding: 20px;margin: 0;">{{'home.No action required.' | translate}}</p>
                    </ng-template>
                </div>
                <div class="group">
                    <div class="row">
                        <div *ngIf = "assignments.length != 0"
                             class="row-item-1">
                            <h2>{{'home.same-assignment' | translate}}</h2>
                            <div class="assignments">
                                <div class="frame" *ngFor="let assignment of assignments.slice(0, 3)">
                                    <p (click)="onCreateFromHistory(assignment.id)"
                                       role="button">{{assignment.jobPlace.name}}, {{assignment.startAt | date: 'dd MMM'}}</p>
                                </div>
                            </div>
                            <div class="flexed-bottom"
                                 role="button"
                                 routerLink="/components/assignments/create-assignment/step1">
                                <p>{{'home.create-assignment' | translate}}</p>
                            </div>
                            <img class="arrow" src="/assets/img/Vector white.svg">
                            <img src="/assets/img/Vector S.svg">
                        </div>
                        <div  *ngIf = "this?.assignments?.length == 0" class="row-item-1-empty">
                            <h2>{{'home.create-first-assignment' | translate}}</h2>
                            <div class="flexed-bottom">
                                <p>{{'home.create-assignment' | translate}}</p>
                            </div>
                            <img class="arrow" src="/assets/img/Vector white.svg">
                            <img src="/assets/img/Vector S.svg">
                        </div>
                        <div *ngIf = "assignmentStatistics"
                             class="row-item-2"
                             role="button"
                             routerLink="/components/assignments">
                            <h2>{{'home.ongoing' | translate:{number: assignmentStatistics.ongoingAssignmentsCount} }}</h2>
                            <div class="flexed-bottom" >
                                <p>{{'home.see-ongoing' | translate}}</p>
                            </div>
                            <img class="arrow" src="/assets/img/Vector white.svg">
                            <img src="/assets/img/Vector green.svg">
                        </div>
                        <div  class="row-item-2-purple"
                              role="button"
                              routerLink="/components/company/invite-users">
                            <h2>{{'home.collaborate' | translate}}</h2>
                            <div class="flexed-bottom">
                                <p>{{'home.invite' | translate}}</p>
                            </div>
                            <img class="arrow" src="/assets/img/Vector white.svg">
                            <img src="/assets/img/Group purple.svg">
                        </div>
                        <div class="row-item-3"
                             role="button"
                             routerLink="/components/my-staff">
                            <h2>{{'home.invite-staff' | translate}}</h2>
                            <div class="flexed-bottom">
                                <p>{{'home.setup-staff' | translate}}</p>
                            </div>
                            <img class="arrow" src="/assets/img/Vector white.svg">
                            <img style="opacity: 0.3" src="/assets/img/Vector blue-grey.svg">
                        </div>
                    </div>
                </div>
                <div class="news">
                    <h2>{{'home.news' | translate}}</h2>
                    <div class="news-list">
                        <div class="row">
                            <a *ngFor="let n of news" class="news-item" href="{{n.url}}" target="_blank">
                                <div class="flexed">
                                    <img src="{{n.imagePath || '/assets/img/news img.svg'}}">
                                    <div class="info">
                                        <p class="date">{{n.createdAt | date: 'dd MMM Y'}}</p>
                                        <h4>{{n.title}}</h4>
                                        <p class="desc"></p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div *ngIf="helpOptionsOpened" class="help-options">
                    <div class="row" routerLink="/components/help">
                        <div class="col-10">
                            <span>{{'home.safetly-help' | translate}}</span>
                        </div>
                        <div class="col-2">
                            <img src="/assets/img/Arrow-right.svg" alt="">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-10">
                            <span>{{'home.support' | translate}}</span>
                        </div>
                        <div class="col-2">
                            <img src="/assets/img/Arrow-right.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <!--<div class="btn my-button help-button btn-primary" (click)="helpOptionsOpened = !helpOptionsOpened">-->
                        <!--<img src="/assets/img/question-circle 1.svg">-->
                        <!--<span>{{'help' | translate}}</span>-->
                    <!--</div>-->
                    <a routerLink="/components/help" class="btn my-button help-button btn-primary">
                        <img src="/assets/img/question-circle 1.svg">
                        <span>{{'help' | translate}}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
