import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GuardModel } from '../../models/guard.model';

@Component({
    selector: 'app-guard-detail-modal',
    templateUrl: './guard-detail-modal.component.html',
    styleUrls: ['./guard-detail-modal.component.scss'],
})
export class GuardDetailModalComponent implements OnInit {
    @Input('guard') guard: GuardModel;
    @Input('action') action: string;
    @Output() closeModal = new EventEmitter();
    @Output() onClickAction = new EventEmitter<{
        guard: GuardModel,
        action: string
    } | {
        guardId: number,
        isChecked: boolean,
    }>();

    actionOptions = {
        addToFavourites: 'addToFavourites',
        removeFromFavourites: 'removeFromFavourites',
        pickPerson: 'pickPerson',
        unpickPerson: 'unpickPerson',
        defineNumberOfPersonnel: 'defineNumberOfPersonnel',
        defineNumberOfLeaders: 'defineNumberOfLeaders',
    };

    constructor() { }

    ngOnInit(): void {
    }

}
