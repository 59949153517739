<div class="top">
    <div class="flexed">
        <div class="back" routerLink="/components/reports/reports-details">
            <img src="/assets/img/back arrow.svg">
            <p class="back-p">{{'back' | translate}}</p>
        </div>
        <p class="faded-p">{{'navigation.reports' | translate}}</p>
        <img class="small-arrow" src="/assets/img/arrow small.svg">
        <p class="faded-p">{{report.subject}}</p>
    </div>
    <h1>{{report.subject}} - {{report.assignment.jobPlace.name}}</h1>
    <div class="row">
        <div class="col-12 col-lg-4">
            <p class="title">{{'report-details.Incident time' | translate}}</p>
            <p>{{report.incidentTime | date: 'd MMM Y, '}}<br>{{report.incidentTime | date: 'HH:mm'}}</p>
        </div>
        <div class="col-12 col-lg-4">
            <p class="title">{{'report-details.# Assignment' | translate}}</p>
            <a routerLink="/components/assignments/assignment/{{report.assignment.id}}">{{report.assignment.id}}</a>
        </div>
        <div class="col-12 col-lg-4">
            <p class="title">{{'report-details.Object' | translate}}</p>
            <p>{{report.assignment.jobPlace.name}} <br> {{report.assignment.jobPlace.address}}</p>
        </div>
    </div>
</div>
<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="info">
                    <h3>{{'report-details.Arrest' | translate}}</h3>
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.No. arrested' | translate}}</p>
                            <p>{{report.numberOfArrests}} {{'report-details.people' | translate}}</p>
                        </div>
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.Arrested time' | translate}}</p>
                            <p>{{report.arrestedAt | date: 'HH:mm'}}</p>
                        </div>
                    </div>
                    <div class="line"></div>
                    <h3>{{'report-details.Police' | translate}}</h3>
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.Police called at' | translate}}</p>
                            <!--<p>{{report.policeCalledAt | date: 'HH:mm'}}</p>-->
                            <p>{{report.policeCalledAt | date: 'HH:mm' }}</p>
                        </div>
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.Police arrived at' | translate}}</p>
                            <p>{{report.policeArrivedAt | date: 'HH:mm'}}</p>
                        </div>
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.Police number' | translate}}</p>
                            <p>{{report.policeNumber}}</p>
                        </div>
                    </div>
                    <div class="line"></div>
                    <h3>{{'report-details.Ambulance' | translate}}</h3>
                    <div class="row" style="margin-top: 15px;">
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.Is called ambulance?' | translate}}</p>
                            <p class="faded">{{report.isCalledAmbulance
                                ? 'report-details.Ambulance called.'
                                : 'report-details.No ambulance called.' | translate}}</p>
                        </div>
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.Ambulance called at' | translate}}</p>
                            <p>{{report.ambulanceCalledAt | date: 'HH:mm'}}</p>
                        </div>
                        <div class="col-12 col-lg-4">
                            <p class="title">{{'report-details.Ambulance arrived at' | translate}}</p>
                            <p>{{report.ambulanceArrivedAt | date: 'HH:mm'}}</p>
                        </div>
                    </div>
                    <!--<p>{{report.ambulanceCalledAt}}</p>-->
                    <!--<p class="faded">{{// 'report-details.No ambulance called.' | translate}}</p>-->
                </div>
                <div class="report-details">
                    <h3>{{'report-details.Report details' | translate}}</h3>
                    <div class="report-subject">
                        <p class="title">{{'report-details.Report subject' | translate}}</p>
                        <p>{{report.subject}}</p>
                    </div>
                    <div class="report-desc">
                        <p class="title">{{'report-details.Report description' | translate}}</p>
                        <p>{{report.description}}</p>
                    </div>
                    <div class="attachments">
                            <div *ngIf="report.images.length;else noImages">
                                <p class="title">Attachments ({{report.images.length}})</p>
                                <div class="row">
                                    <div *ngFor="let image of report.images" class="attachment col-6">
                                        <div class="flexed-attach">
                                            <img src="{{image.imageUrl}}">
                                            <!--<p class="img-name"></p>-->
                                        </div>
                                    </div>
                                </div>
                            </div>


                        <ng-template #noImages>
                            <p class="title">{{'report-details.Attachments' | translate}}</p>
                            <p>{{'report-details.No attached images.' | translate}}</p>
                        </ng-template>
                    </div>

                </div>
                <div class="personnel-signatures">
                    <h3>{{'report-details.Personnel signatures' | translate}}</h3>
                    <div class="row" style="margin-top: 15px;">
                        <div class="signatures">
                            <div class="flexed-signature">
                                <div class="img-wrapper">
                                    <img src="{{report.guard.image || '/assets/img/empty-profile-picture.png'}}">
                                </div>
                                <div class="basic-info">
                                    <p>{{report.guard.firstName}}</p>
                                    <!--<p>{{report.guard.firstName + ' ' + report.guard.lastName}}</p>-->

                                    <p class="faded" *ngIf="report.guard.roleId === 1">{{'report-details.Guard' | translate}}</p>
                                    <p class="faded" *ngIf="report.guard.roleId === 2">{{'report-details.Guard Leader' | translate}}</p>
                                </div>
                                <div class="datetime">
                                    <p>{{report.incidentTime | date: 'd MMM'}}</p>
                                    <p class="faded">{{report.incidentTime | date: 'HH:mm'}}</p>
                                </div>
                            </div>
                        </div>

                        <!--<div *ngFor="let i of [1,2]" class="signatures">-->
                            <!--<div class="flexed-signature">-->
                                <!--<img src="/assets/img/Ellipse 34.svg">-->
                                <!--<div class="basic-info">-->
                                    <!--<p>Aaron Persson</p>-->
                                    <!--<p class="faded">Security guard</p>-->
                                <!--</div>-->
                                <!--<div class="datetime">-->
                                    <!--<p>17 March</p>-->
                                    <!--<p class="faded">13:22</p>-->
                                <!--</div>-->
                            <!--</div>-->
                        <!--</div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
