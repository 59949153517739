<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="flexed">
                    <div class="back" routerLink="/components/company/users">
                        <img src="/assets/img/back arrow.svg">
                        <p class="back-p">{{'back' | translate}}</p>
                    </div>
                    <p class="faded-p">{{this?.customer?.companyName}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{'company.invite.users' | translate}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{'company.invite.invite' | translate}}</p>
                </div>
                <h1>{{'company.invite.title' | translate}}</h1>
                <div class="invite-link-wrapper d-block d-md-flex justify-content-between gap-1">
                    <div>
                        <p class="faded">{{'company.invite.invite-link' | translate}}</p>
                        <div class=" d-block d-lg-flex justify-content-start flex-column gap-3">
                            <p class="link" *ngIf="this?.customer?.inviteToken; else noToken">{{appUrl}}/invite?t={{this?.customer?.inviteToken}}</p>
                            <ng-template #noToken>
                                <p class="copy" (click)="onGenerateInviteLink()">{{'company.invite.generate-link' | translate}}</p>
                            </ng-template>
                            <div  *ngIf="this?.customer?.inviteToken" class="d-flex justify-content-start copy" (click)="onCopyInviteLink()">
                                <img class="link-icon" src="/assets/img/link.svg">
                                <p class="copy">{{'company.invite.copy' | translate}}</p>
                            </div>
                        </div>
                    </div>
                    <p class="deactivate-p"
                       *ngIf="customer.inviteToken"
                       (click)="onDeactivateLink()">{{'company.invite.deactivate-link' | translate}}</p>
                </div>
                <!--Editable table-->
                <table class="table table-bordered table-responsive-md text-center">
                    <tr>
                        <th class="text-center index"><div class="flexed">#</div></th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.email' | translate}}
                                <div class="blue-circle"></div>
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.firstName' | translate}}
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.lastName' | translate}}
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.phone' | translate}}
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.role' | translate}}
                            </div>
                        </th>
                    </tr>
                    <tr *ngFor="let i of [1,2,3,4,5]; let id = index">
                        <td class="index">
                            <span style="color: #C1C1C1;">{{i}}</span>
                        </td>
                        <td> <!--[ngClass]=" isValidEmail(this?.inviteObject[id]?.email) ? '' : 'not-valid'"-->
                            <div class="editable" (keyup)="isValidEmail($event.target.textContent); addToObject(id, 'email',$event.target.textContent)" contenteditable="true"></div>
                        </td>
                        <td>
                            <div class="editable" (keyup)="addToObject(id, 'firstName',$event.target.textContent)"  contenteditable="true"></div>
                        </td>
                        <td>
                            <div class="editable" (keyup)="addToObject(id, 'lastName',$event.target.textContent)"  contenteditable="true"></div>
                        </td>
                        <td>
                            <div class="editable" (keyup)="addToObject(id, 'phone',$event.target.textContent)"  contenteditable="true"></div>
                        </td>
                        <td>
                            <select class="form-select select" (change)="addToObject(id, 'role', $event.target.value)">
                                <ng-container *ngIf="customer?.employeeRole.id == 1">
                                    <option value="1">{{'user-role.1' | translate}}</option>
                                    <option value="2">{{'user-role.2' | translate}}</option>
                                </ng-container>

                                <option selected value="3">{{'user-role.3' | translate}}</option>
                            </select>
                        </td>
                    </tr>
                </table>
                <!--/Editable table-->
                <div class="d-flex mt-4">
                    <div class="blue-circle"></div>
                    <p class="mandatory-filed-p">- {{'company.invite.mandatory-filed' | translate}}</p>
                </div>
                <div class="btn btn-primary my-button" (click)="onInviteUsers()">
                    <p>{{'company.invite.invite' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
