import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {CustomerModel} from "../../../models/customer.model";
import {Store} from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SkillModel} from "../../../models/skill.model";

@Component({
    selector: 'app-invite-staff',
    templateUrl: './invite-staff.component.html',
    styleUrls: ['./invite-staff.component.scss']
})
export class InviteStaffComponent implements OnInit, OnDestroy {

    showSkillForm = false;
    // public competenceModal = false;

    public subscription: Subscription;
    public customer: CustomerModel;
    public skills: SkillModel[];

    // public form = new FormGroup({
    //     name: new FormControl('', Validators.required),
    //     base: new FormControl('', Validators.required),
    //     description: new FormControl('', Validators.required),
    //     salary: new FormControl('', Validators.required),
    //     certificate: new FormControl('', Validators.required),
    //     bonusMF: new FormControl('', Validators.required),
    //     bonusSSday: new FormControl('', Validators.required),
    //     bonusSSnight: new FormControl('', Validators.required),
    //     bonusC: new FormControl('', Validators.required),
    //     bonusD: new FormControl('', Validators.required),
    // });

    constructor(private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.skills = appState.shared.skills.flatMap((skill) => {
                return [...skill.children];
            });

        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    // public onSubmitCompetence(): void {
    //     // console.log(this.form.get('name').value);
    //     // console.log(this.form.get('base').value);
    //     // console.log(this.form.get('description').value);
    //     // console.log(this.form.get('salary').value);
    //     // console.log(this.form.get('certificate').value);
    //     // console.log(this.form.get('bonusMF').value);
    //     // console.log(this.form.get('bonusSSday').value);
    //     // console.log(this.form.get('bonusSSnight').value);
    //     // console.log(this.form.get('bonusC').value);
    //     // console.log(this.form.get('bonusD').value);
    //     //dispatch
    //     this.competenceModal = false;
    // }

}
