import { Action } from '@ngrx/store';
import { PaginationModel } from '../../../models/pagination.model';
import { GuardModel } from '../../../models/guard.model';
import { GuardInviteModel } from '../../../models/guardInvite.model';
import { PayslipItemModel } from '../../../models/payslip-item.model';

export const LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF = '[MyStaff] Load Payslip Items For Guard My Staff';
export const LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF_SUCCESS = '[MyStaff] Load Payslip Items For Guard My Staff Success';
export const LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF_FAILED = '[MyStaff] Load Payslip Items For Guard My Staff Failed';

export const LOAD_GUARDS_INVITES = '[MyStaff] Load Customer Guards Invites';
export const LOAD_GUARDS_INVITES_SUCCESS = '[MyStaff] Load Customer Guards Invites Success';
export const LOAD_GUARDS_INVITES_FAILED = '[MyStaff] Load Customer Guards Invites Failed';

export const LOAD_GUARDS_WITH_PAYROLLS = '[MyStaff] Load Customer Guards With Payrolls';
export const LOAD_GUARDS_WITH_PAYROLLS_SUCCESS = '[MyStaff] Load Customer Guards With Payrolls Success';
export const LOAD_GUARDS_WITH_PAYROLLS_FAILED = '[MyStaff] Load Customer Guards With Payrolls Failed';

export const LOAD_STAFF = '[MyStaff] Load Customer Guards';
export const LOAD_STAFF_SUCCESS = '[MyStaff] Load Customer Guards Success';
export const LOAD_STAFF_FAILED = '[MyStaff] Load Customer Guards Failed';

export const LOAD_GUARD = '[MyStaff] Load Customer Guard';
export const LOAD_GUARD_SUCCESS = '[MyStaff] Load Customer Guard Success';
export const LOAD_GUARD_FAILED = '[MyStaff] Load Customer Guard Failed';

export const EDIT_GUARD = '[MyStaff] Edit Guard';
export const EDIT_GUARD_SUCCESS = '[MyStaff] Edit Guard Success';
export const EDIT_GUARD_FAILED = '[MyStaff] Edit Guard Failed';

export const LOAD_SKILL_MEMBERS = '[MyStaff] Load Skill Members';
export const LOAD_SKILL_MEMBERS_SUCCESS = '[MyStaff] Load Skill Members Success';
export const LOAD_SKILL_MEMBERS_FAILED = '[MyStaff] Load Skill Members Failed';

export const DELETE_SKILL_MEMBER = '[MyStaff] Delete Skill Member';
export const DELETE_SKILL_MEMBER_SUCCESS = '[MyStaff] Delete Skill Member Success';
export const DELETE_SKILL_MEMBER_FAILED = '[MyStaff] Delete Skill Member Failed';

export const ADD_SKILL_MEMBERS = '[MyStaff] Add Skill Members';
export const ADD_SKILL_MEMBERS_SUCCESS = '[MyStaff] Add Skill Members Success';
export const ADD_SKILL_MEMBERS_FAILED = '[MyStaff] Add Skill Members Failed';

export const DELETE_GUARD = '[MyStaff] Delete Guard';
export const DELETE_GUARD_SUCCESS = '[MyStaff] Delete Guard Success';
export const DELETE_GUARD_FAILED = '[MyStaff] Delete Guard Failed';

export const ACCEPT_INVITE = '[MyStaff] Guard Accept Invite';
export const ACCEPT_INVITE_SUCCESS = '[MyStaff] Guard Accept Invite Success';
export const ACCEPT_INVITE_FAILED = '[MyStaff] Guard Accept Invite Failed';

export const SEND_INVITES = '[MyStaff] Send Invites';
export const SEND_INVITES_SUCCESS = '[MyStaff] Send Invites Success';
export const SEND_INVITES_FAILED = '[MyStaff] Send Invites Failed';

export const CLEAR_GUARDS = '[MyStaff] Clear Guards';
export const CLEAR_STAFF = '[MyStaff] Clear Staff';

export class LoadPayslipItemsForGuardMyStaff implements Action {
    readonly type = LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF;

    constructor(public payload: {
        guardId: number,
        page: number,
        customerId: number
    }) {
    }
}

export class LoadPayslipItemsForGuardMyStaffSuccess implements Action {
    readonly type = LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF_SUCCESS;

    constructor(public payload: { payslipItems: PayslipItemModel[], pagination: PaginationModel }) {
    }
}

export class LoadPayslipItemsForGuardMyStaffFailed implements Action {
    readonly type = LOAD_PAYSLIP_ITEMS_FOR_GUARD_MY_STAFF_FAILED;
}

export class LoadGuardsWithPayrolls implements Action {
    readonly type = LOAD_GUARDS_WITH_PAYROLLS;

    constructor(public payload: {
        customerId: number,
        page: number,
        body?: {},
        params?: {
            key: string,
            value: string
        }[]
    }) {
    }
}

export class LoadGuardsWithPayrollsSuccess implements Action {
    readonly type = LOAD_GUARDS_WITH_PAYROLLS_SUCCESS;

    constructor(public payload: { guards: GuardModel[], pagination: PaginationModel }) {
    }
}

export class LoadGuardsWithPayrollsFailed implements Action {
    readonly type = LOAD_GUARDS_WITH_PAYROLLS_FAILED;
}

export class LoadGuards implements Action {
    readonly type = LOAD_GUARDS_INVITES;

    constructor(public payload: { customerId: number, page: number, params?: { key: string, value: string }[] }) {
    }
}

export class LoadGuardsSuccess implements Action {
    readonly type = LOAD_GUARDS_INVITES_SUCCESS;

    constructor(public payload: { guards: GuardInviteModel[], pagination: PaginationModel }) {
    }
}

export class LoadGuardsFailed implements Action {
    readonly type = LOAD_GUARDS_INVITES_FAILED;
}

export class LoadStaff implements Action {
    readonly type = LOAD_STAFF;

    constructor(public payload: { customerId: number, params?: { key: string, value: string }[], guardIds?: number[] }) {
    }
}

export class LoadStaffSuccess implements Action {
    readonly type = LOAD_STAFF_SUCCESS;

    constructor(public payload: { guards: GuardModel[], pagination: PaginationModel }) {
    }
}

export class LoadStaffFailed implements Action {
    readonly type = LOAD_STAFF_FAILED;
}

export class LoadGuard implements Action {
    readonly type = LOAD_GUARD;

    constructor(public payload: { guardId: number }) {
    }
}

export class LoadGuardSuccess implements Action {
    readonly type = LOAD_GUARD_SUCCESS;

    constructor(public payload: { guard: GuardModel }) {
    }
}

export class LoadGuardFailed implements Action {
    readonly type = LOAD_GUARD_FAILED;
}


export class ClearGuards implements Action {
    readonly type = CLEAR_GUARDS;
}

export class ClearStaff implements Action {
    readonly type = CLEAR_STAFF;
}

export class LoadMembers implements Action {
    readonly type = LOAD_SKILL_MEMBERS;

    constructor(public payload: { customerId: number, skillId: number, page: number, params?: { key: string, value: string }[] }) {
    }
}

export class LoadMembersSuccess implements Action {
    readonly type = LOAD_SKILL_MEMBERS_SUCCESS;

    constructor(public payload: { members: GuardModel[], pagination: PaginationModel }) {
    }
}

export class LoadMembersFailed implements Action {
    readonly type = LOAD_SKILL_MEMBERS_FAILED;
}

export class SendInvites implements Action {
    readonly type = SEND_INVITES;

    constructor(public payload: {
        customerId: number,
        data: any
    }) {
    }
}

export class SendInvitesSuccess implements Action {
    readonly type = SEND_INVITES_SUCCESS;

    constructor(public payload: { data: any }) {
    }
}

export class SendInvitesFailed implements Action {
    readonly type = SEND_INVITES_FAILED;
}

export class AcceptInvite implements Action {
    readonly type = ACCEPT_INVITE;

    constructor(public payload: { inviteToken: string }) {
    }
}

export class AcceptInviteSuccess implements Action {
    readonly type = ACCEPT_INVITE_SUCCESS;

    constructor(public payload: { guard: GuardModel[] }) {
    }
}

export class AcceptInviteFailed implements Action {
    readonly type = ACCEPT_INVITE_FAILED;
}

export class EditGuard implements Action {
    readonly type = EDIT_GUARD;

    constructor(public payload: {
        guardId: number,
        customerId: number,
        data: any
    }) {
    }
}

export class EditGuardSuccess implements Action {
    readonly type = EDIT_GUARD_SUCCESS;

    constructor(public payload: { guard: GuardModel }) {
    }
}

export class EditGuardFailed implements Action {
    readonly type = EDIT_GUARD_FAILED;
}

export class DeleteGuard implements Action {
    readonly type = DELETE_GUARD;

    constructor(public payload: { customerId: number, guardId: number }) {
    }
}

export class DeleteGuardSuccess implements Action {
    readonly type = DELETE_GUARD_SUCCESS;

    constructor(public payload: { guard: GuardModel }) {
    }
}

export class DeleteGuardFailed implements Action {
    readonly type = DELETE_GUARD_FAILED;
}

export class AddSkillMembers implements Action {
    readonly type = ADD_SKILL_MEMBERS;

    constructor(public payload: { customerId: number, skillId: number, guardIds: number[] }) {
    }
}

export class AddSkillMembersSuccess implements Action {
    readonly type = ADD_SKILL_MEMBERS_SUCCESS;

    constructor(public payload: { guards: GuardModel[] }) {
    }
}

export class AddSkillMembersFailed implements Action {
    readonly type = ADD_SKILL_MEMBERS_FAILED;
}

export class DeleteSkillMember implements Action {
    readonly type = DELETE_SKILL_MEMBER;

    constructor(public payload: { customerId: number, skillId: number, guardId: number }) {
    }
}

export class DeleteSkillMemberSuccess implements Action {
    readonly type = DELETE_SKILL_MEMBER_SUCCESS;

    constructor(public payload: { guard: GuardModel }) {
    }
}

export class DeleteSkillMemberFailed implements Action {
    readonly type = DELETE_SKILL_MEMBER_FAILED;
}

export type MyStaffActions = LoadGuards
    | LoadGuardsSuccess
    | LoadGuardsFailed
    | ClearGuards
    | SendInvites
    | SendInvitesSuccess
    | SendInvitesFailed
    | AcceptInvite
    | AcceptInviteSuccess
    | AcceptInviteFailed
    | LoadGuard
    | LoadGuardSuccess
    | LoadGuardFailed
    | EditGuard
    | EditGuardSuccess
    | EditGuardFailed
    | DeleteGuard
    | DeleteGuardSuccess
    | DeleteGuardFailed
    | LoadMembers
    | LoadMembersSuccess
    | LoadMembersFailed
    | LoadStaff
    | LoadStaffSuccess
    | LoadStaffFailed
    | ClearStaff
    | AddSkillMembers
    | AddSkillMembersSuccess
    | AddSkillMembersFailed
    | DeleteSkillMember
    | DeleteSkillMemberSuccess
    | DeleteSkillMemberFailed
    | LoadGuardsWithPayrolls
    | LoadGuardsWithPayrollsSuccess
    | LoadGuardsWithPayrollsFailed
    | LoadPayslipItemsForGuardMyStaff
    | LoadPayslipItemsForGuardMyStaffSuccess
    | LoadPayslipItemsForGuardMyStaffFailed
    ;
