import {Component, OnDestroy, OnInit, HostListener} from '@angular/core';
import * as MessageActions from './store/messages.actions';
import {FormBuilder} from "@angular/forms";
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import {Subscription} from "rxjs";
import {ConversationModel} from "../../models/conversation.model";
import {PaginationModel} from "../../models/pagination.model";
import {MessageModel} from "../../models/message.model";
import {MessagingService} from "../../service/messaging.service";
import { CustomerModel } from '../../models/customer.model';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    customer: CustomerModel;
    public conversations: ConversationModel[];
    public isMoreConversations;
    public pagination: PaginationModel;
    public messages: MessageModel[];
    public paginationMessages: PaginationModel;
    public isLastPage;

    public messageForConversation: number;

    public isFirstLoad = true;

    public messageToSend: '';
    public selectedConversationId: number;
    public selectedConversation: ConversationModel;

    showConversationInfo = false;

    public newMessage = null;
    private messageSub: Subscription;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private messageService: MessagingService,
                private store: Store<fromApp.AppState>,) {
    }

    ngOnInit(): void {
        let conversationId = null;
        this.messageSub = this.messageService.messageEvent.subscribe(res => {
            this.pushNewMessageToConversation(res)
        });

        this.activatedRoute.queryParams.subscribe(queryParams => {
            conversationId = queryParams.conversationId;
        });

        this.subscription = this.store.subscribe(appState => {
            this.conversations = appState.messages.conversations;
            this.pagination = appState.messages.pagination;
            this.messages = appState.messages.messages;
            this.paginationMessages = appState.messages.messagesPagination;
            this.isLastPage = appState.messages.messagesMeta.isLastPage;
            this.isMoreConversations = !appState.messages.conversationsMeta.isLastPage;
            this.customer = appState.auth.customer;

            if (this.isFirstLoad && this.conversations.length > 0) {
                this.isFirstLoad = false;
                this.store.dispatch(new MessageActions.LoadMessages({
                    customerId: this.customer.id,
                    conversationId: conversationId || this.conversations[0].id
                }));
                this.selectedConversation = this.conversations.find(conversation => {
                    return conversation.id == conversationId;
                }) || this.conversations[0];
                this.selectedConversationId = conversationId || this?.conversations[0].id;
            }
        });
    }

    ngOnDestroy(): void {
        this.messageSub.unsubscribe();
        this.subscription.unsubscribe();
        this.store.dispatch(new MessageActions.ClearMessages());
        this.store.dispatch(new MessageActions.ClearConversations());
    }

    onScrollMessages(event): void {
        const height = document.getElementById('chat').scrollHeight;
        const elementHeight = document.getElementById('chat').offsetHeight;
        const scrollBottom = -document.getElementById('chat').scrollTop;

        if (!this.isLastPage && (scrollBottom >= (height - elementHeight) * 0.7)) {
            this.store.dispatch(new MessageActions.LoadMessages({
                customerId: this.customer.id,
                conversationId: this.selectedConversationId
            }));
        }
    }

    onScrollConversations(event): void {
        const height = document.getElementById('conversations').scrollHeight;
        const elementHeight = document.getElementById('conversations').offsetHeight;
        const scrollTop = document.getElementById('conversations').scrollTop;

        if (this.isMoreConversations && (scrollTop >= (height - elementHeight) * 0.7)) {
            this.store.dispatch(new MessageActions.LoadConversations({
                customerId: this.customer.id,
                conversationId: null
            }));
        }
    }

    public onLoadMessages(conversationId: number): void {
        if (this.selectedConversationId !== conversationId) {
            this.store.dispatch(new MessageActions.ClearMessages());
            this.selectedConversationId = conversationId;
            this.store.dispatch(new MessageActions.LoadMessages({
                customerId: this.customer.id,
                conversationId: conversationId
            }));
            this.store.dispatch(new MessageActions.ResetConversationUnseenCount({
                conversationId: conversationId
            }));
        }
    }

    public onSendMessage(conversationId: number) {
        if (this.messageToSend !== '' && conversationId) {
            this.store.dispatch(new MessageActions.SendMessage({
                customerId: this.customer.id,
                conversationId: conversationId,
                body: this.messageToSend
            }));

            this.messageToSend = '';
        }
    }

    public onSelectedConversation(conversation: ConversationModel): void {
        this.selectedConversation = conversation;
        if (this.selectedConversation.id == this.messageForConversation) {
            this.messageForConversation = null;
        }
    }

    private pushNewMessageToConversation(data: any) {
        this.newMessage = data;

        this.messageForConversation = this.newMessage.data.value.split('|')[0];

        let message = new MessageModel();
        message.id = this.newMessage.data.value.split('|')[1];
        message.body = this.newMessage.data.body;
        message.sender = this.newMessage.data.user;
        message.conversationId = this.messageForConversation;
        message.createdAt = new Date().toISOString();

        if (this.selectedConversation.id == this.messageForConversation) {
            this.messageForConversation = null;
            this.store.dispatch(new MessageActions.NewMessage(message));
            this.store.dispatch(new MessageActions.MessageSeen({
                messageId: message.id,
                customerId: this.customer.id,
                conversationId: message.conversationId
            }))
        } else {
            this.store.dispatch(new MessageActions.UpdateConversationUnseenCount({
                conversationId: message.conversationId,
                count: 1,
            }));
        }
    }
}
