import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-assignment-ordered',
    templateUrl: './assignment-ordered.component.html',
    styleUrls: ['./assignment-ordered.component.scss']
})
export class AssignmentOrderedComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
