import * as MessagesActions from './messages.actions';
import {PaginationModel} from "../../../models/pagination.model";
import {ConversationModel} from "../../../models/conversation.model";
import {MessageModel} from "../../../models/message.model";

export interface State {
    conversations: ConversationModel[];
    conversationsMeta: {
        page: number,
        isLastPage: boolean,
        perPage: number
    };
    totalMessagesUnseenCount: number;
    pagination: PaginationModel;
    messages: MessageModel[];
    messagesMeta: {
        page: number,
        isLastPage: boolean,
        perPage: number
    };
    messagesPagination: PaginationModel;
    message: MessageModel;
    loading: boolean;
}

const initialState: State = {
    conversations: [],
    conversationsMeta: {
        page: 0,
        isLastPage: true,
        perPage: 15
    },
    totalMessagesUnseenCount: 0,
    pagination: null,
    messages: [],
    messagesMeta: {
        page: 0,
        isLastPage: true,
        perPage: 20
    },
    messagesPagination: null,
    message: null,
    loading: false
}

export function messagesReducer(state = initialState, action: MessagesActions.MessagesActions): State {
    switch (action.type) {
        case MessagesActions.UPDATE_CONVERSATION_UNSEEN_COUNT:
            return {
                ...state,
                conversations: state.conversations.map(conversation => {
                    if(conversation.id == action.payload.conversationId) {
                        return {
                            ...conversation,
                            unseenCount: conversation.unseenCount + action.payload.count,
                        };
                    }
                    return conversation;
                }),
                totalMessagesUnseenCount: state.totalMessagesUnseenCount + action.payload.count
            };
        case MessagesActions.RESET_CONVERSATION_UNSEEN_COUNT:
            return {
                ...state,
                conversations: state.conversations.map(conversation => {
                    if(conversation.id == action.payload.conversationId) {
                        return {
                            ...conversation,
                            unseenCount: 0,
                        };
                    }
                    return conversation;
                }),
            };
        case MessagesActions.NEW_MESSAGE:
            return {
                ...state,
                messages: [action.payload, ...state.messages]
            };
        case MessagesActions.LOAD_CONVERSATIONS:
            return {
                ...state,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    page: state.conversationsMeta.page + 1,
                    isLastPage: true
                },
                loading: true
            };
        case MessagesActions.LOAD_CONVERSATIONS_SUCCESS:
            return {
                ...state,
                conversations: [...state.conversations, ...action.payload.conversations],
                pagination: action.payload.pagination,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    isLastPage: action.payload.pagination.lastPage === action.payload.pagination.currentPage
                },
                totalMessagesUnseenCount: action.payload.totalMessagesUnseenCount,
                loading: false
            };
        case MessagesActions.LOAD_CONVERSATIONS_FAILED:
            return {
                ...state,
                loading: false
            };
        case MessagesActions.CLEAR_CONVERSATIONS:
            return {
                ...state,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    page: 0,
                    isLastPage: true
                },
                conversations: [],
                loading: true
            };
        case MessagesActions.LOAD_MESSAGES:
            return {
                ...state,
                messagesMeta: {
                    ...state.messagesMeta,
                    page: state.messagesMeta.page + 1,
                    isLastPage: true
                },
                loading: true
            };
        case MessagesActions.LOAD_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: [...state.messages, ...action.payload.messages],
                messagesPagination: action.payload.pagination,
                messagesMeta: {
                    ...state.messagesMeta,
                    isLastPage: action.payload.pagination.lastPage === action.payload.pagination.currentPage,
                },
                conversations: state.conversations.map(x => {
                    if(x.id == action.payload.conversationId) {
                        return {
                            ...x,
                            unseenCount: 0,
                        }
                    }
                    return x;
                }),
                totalMessagesUnseenCount: action.payload.totalMessagesUnseenCount,
                loading: false,
            };
        case MessagesActions.LOAD_MESSAGES_FAILED:
            return {
                ...state,
                loading: false
            };
        case MessagesActions.CLEAR_MESSAGES:
            return {
                ...state,
                messagesMeta: {
                    ...state.messagesMeta,
                    page: 0,
                    isLastPage: true
                },
                messages: [],
                loading: true
            };
        case MessagesActions.SEND_MESSAGE:
            return {
                ...state,
                loading: true
            };
        case MessagesActions.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                messages: [action.payload.message, ...state.messages],
                message: action.payload.message,
                loading: false
            };
        case MessagesActions.SEND_MESSAGE_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
