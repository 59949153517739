<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

                <div class="d-flex mb-4">
                    <p class="faded-p">{{'staff.title' | translate}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{'staff.invite-staff.title' | translate}}</p>
                </div>

                <h1>{{'staff.invite-staff.title' | translate}}</h1>

                <p class="header-p mt-4">{{'staff.invite-staff.info' | translate}}</p>

                <div class="wrapper">
                    <h3>{{'staff.invite-staff.select-group' | translate}}</h3>
                    <!-- TABLE -->
                    <div class="table-responsive scrolling">
                        <table class="table table-hover">
                            <thead>
                            <tr>
                                <th>{{'table.thead.competence' | translate}}</th>
                                <th>{{'table.thead.members' | translate}}</th>
                                <th>{{'table.thead.hourly' | translate}}</th>
                                <th>{{'table.thead.certificate' | translate}}</th>
                                <th class="text-right" style="opacity: 0">arrow</th>
                            </tr>
                            </thead>
                            <tbody *ngFor="let skill of this?.skills">
                            <tr routerLink="/components/my-staff/invite/{{skill?.id}}">
                                <td>
                                    <div class="d-flex justify-content-start gap-2">
                                        <img *ngIf="!skill?.imagePath" class="competence-icon"
                                             src="/assets/img/competence.png" alt="">
                                        <img *ngIf="skill?.imagePath" class="competence-icon" src="{{skill?.imagePath}}"
                                             alt="">
                                        <div class="mt-auto mb-auto">
                                            <p><b>{{skill?.name}}</b></p>
                                            <!--<p class="faded-p">{{'staff.competences.base' | translate}}</p>-->
                                        </div>
                                    </div>
                                </td>
                                <td><p>{{skill.numberOfGuards}}</p></td>
                                <td><p>{{skill.price.baseSalary}} SEK</p></td>
                                <td><p>{{skill.price.isRequiredCertificate ? 'Yes' : 'No'}}</p></td>
                                <td>
                                    <div class="arrow-icon"></div>
                                </td>
                            </tr>
                            <!--                            <tr *ngFor = "let i of [1,2]" routerLink="/components/my-staff/invite/{{1}}">-->
                            <!--                                <td>-->
                            <!--                                    <div class="ml-5 d-flex justify-content-start gap-2">-->
                            <!--                                        <img class="competence-icon" src="/assets/img/subcategory.png" alt="">-->
                            <!--                                        <div class="mt-auto mb-auto">-->
                            <!--                                            <p><b>Security guard</b></p>-->
                            <!--                                            <p class="faded-p">Base competence</p>-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                </td>-->
                            <!--                                <td><p>15</p></td>-->
                            <!--                                <td><p>350 SEK</p></td>-->
                            <!--                                <td><p>No</p></td>-->
                            <!--                                <td>-->
                            <!--                                    <div class="arrow-icon"></div>-->
                            <!--                                </td>-->
                            <!--                            </tr>-->
                            </tbody>
                        </table>
                    </div>

                    <!-- /TABLE -->
                    <p class="blue-p pointer mb-0" (click)="showSkillForm = true">
                        + {{'staff.invite-staff.new' | translate}}</p>

                </div>

            </div>
        </div>
    </div>
</div>

<!---Create skill modal--->
<app-skill-form *ngIf="showSkillForm"
                [action]="'store'"
                (destroyComponent)="showSkillForm = false"></app-skill-form>
<!--<app-modal [modalType]="1" *ngIf="competenceModal">-->
    <!--<div class="popup-header">-->
        <!--<div class="d-flex justify-content-between gap-2">-->
            <!--<h3 class="modal-title">Create competence</h3>-->
            <!--<span (click)="competenceModal = false" class="close">-->
                <!--<img src="/assets/img/Close.svg" alt="">-->
            <!--</span>-->
        <!--</div>-->
    <!--</div>-->
    <!--<div class="popup-body mt-3 mb-3">-->
        <!--<form [formGroup]="form" id="form" (ngSubmit)="onSubmitCompetence()">-->

            <!--<div class="form-group">-->
                <!--<label for="name">Competence name</label>-->
                <!--<input formControlName="name" class="form-control" type="text" id="name"/>-->
            <!--</div>-->
            <!--<div class="form-group">-->
                <!--<label for="base">Base competence</label>-->
                <!--<select class="form-select" formControlName="base" id="base">-->
                    <!--<option *ngFor="let skill of this?.skills" [value]="skill?.id">{{skill?.name}}</option>-->
                <!--</select>-->
            <!--</div>-->
            <!--<div class="form-group">-->
                <!--<label for="desc">Competence description</label>-->
                <!--<textarea formControlName="description" class="form-control" type="text" id="desc"></textarea>-->
            <!--</div>-->

            <!--<p>Salary</p>-->

            <!--<div class="d-block d-md-flex justify-content-between gap-1">-->
                <!--<div class="form-group">-->
                    <!--<label for="salary">Base salary (SEK)</label>-->
                    <!--<input formControlName="salary" class="form-control" type="text" id="salary"/>-->
                <!--</div>-->
                <!--<div class="form-group flex-grow-1">-->
                    <!--<label for="certificate">Require certificate</label>-->
                    <!--<select id="certificate" formControlName="certificate" class="form-select">-->
                        <!--<option value="0">No</option>-->
                        <!--<option value="1">Yes</option>-->
                    <!--</select>-->
                <!--</div>-->
            <!--</div>-->

            <!--<p>Unsocial hour bonus</p>-->

            <!--<div class="form-group">-->
                <!--<label for="bonusMF">Monday - Friday (18.00 - 06.00)</label>-->
                <!--<input formControlName="bonusMF" class="form-control" type="text" id="bonusMF"/>-->
            <!--</div>-->
            <!--<div class="form-group">-->
                <!--<label for="bonusSSday">Saturday - Sunday (06.00 - 18.00)</label>-->
                <!--<input formControlName="bonusSSday" class="form-control" type="text" id="bonusSSday"/>-->
            <!--</div>-->
            <!--<div class="form-group">-->
                <!--<label for="bonusSSnight">Saturday - Sunday (18.00 - 06.00)</label>-->
                <!--<input formControlName="bonusSSnight" class="form-control" type="text" id="bonusSSnight"/>-->
            <!--</div>-->
            <!--<div class="form-group">-->
                <!--<label for="bonusC">C-dates (whole day)</label>-->
                <!--<input formControlName="bonusC" class="form-control" type="email" id="bonusC"/>-->
            <!--</div>-->
            <!--<div class="form-group">-->
                <!--<label for="bonusD">D-dates (whole day)</label>-->
                <!--<input formControlName="bonusD" class="form-control" type="email" id="bonusD"/>-->
            <!--</div>-->

        <!--</form>-->
    <!--</div>-->
    <!--<div class="popup-footer">-->
        <!--<div class="d-flex justify-content-end gap-2">-->
            <!--<span class="cancel-btn" (click)="competenceModal = false">{{'buttons.cancel' | translate}}</span>-->
            <!--<input type="submit"-->
                   <!--form="form"-->
                   <!--[disabled]="!this.form.valid"-->
                   <!--value="{{'buttons.save-changes' | translate}}"-->
                   <!--class="btn save-btn btn-primary"-->
            <!--/>-->
        <!--</div>-->
    <!--</div>-->
<!--</app-modal>-->
