import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../store/app.reducer';
import { createData } from '../create-object';
import * as AssignmentsActions from '../../store/assignments.actions';
import * as CompanyActions from '../../../company/store/company.actions';
import * as SharedActions from '../../../../shared-store/shared.actions';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../../models/customer.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { JobPlaceModel } from '../../../../models/jobPlace.model';
import { SkillModel } from '../../../../models/skill.model';
import { UniformModel } from '../../../../models/uniform.model';
import { UtilityOptionModel } from '../../../../models/utilityOption.model';
import { PriceModel } from '../../../../models/price.model';
import { EmployeeModel } from '../../../../models/employee.model';
import { BillingInformationModel } from '../../../../models/billingInformation.model';
import { filter, take } from 'rxjs/operators';
import * as moment from 'moment';
import * as AuthActions from '../../../../auth/store/auth.actions';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-step7',
    templateUrl: './step7.component.html',
    styleUrls: ['./step7.component.scss'],
})
export class Step7Component implements OnInit, OnDestroy {

    public assignmentHoursModal: boolean = false;
    public descriptionModal: boolean = false;
    public costBreakdownModal: boolean = false;

    public subscription: Subscription;
    public customer: CustomerModel;
    public employee: EmployeeModel;

    public price: PriceModel;
    public object: JobPlaceModel;
    public description: string;
    public numberOfPersonnel: number;
    public numberOfLeaders: number;
    public skill: SkillModel;
    public skills: SkillModel[];
    public utilityOptions: UtilityOptionModel[];
    public uniform: UniformModel;
    public startDate: string;
    public endDate: string;
    // public billing: BillingInformationModel;
    public billingInfoModal = false;

    public changeBillingForm = this.fb.group({
        companyName: ['', [Validators.required]],
        companyNumber: ['', [
            Validators.required,
            Validators.pattern("[0-9]{10}"),
        ]],
        address: ['', [Validators.required]],
        addressSecond: ['', []],
        postalCode: ['', [Validators.required]],
        city: ['', [Validators.required]],
        contactName: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
    });

    public assignmentHours: {
        date: string,
        startTime: string,
        endTime: string
    }[];

    public contactForm = new FormGroup({
        name: new FormControl('', Validators.required),
        phone: new FormControl('', Validators.required),
    });

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.store.select('auth', 'employee')
            .pipe(
                filter(x => x != null),
                take(1),
            )
            .subscribe(employee => {
                this.employee = employee;

                let name = '';
                if (employee.firstName && employee.lastName) {
                    name = employee.firstName + ' ' + employee.lastName
                } else if (employee.firstName) {
                    name = employee.firstName;
                } else if (employee.lastName) {
                    name = employee.lastName
                }
                this.contactForm.patchValue({
                    name: name,
                    phone: employee.phone,
                });
            });

        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.object = appState.company.object;
            this.price = appState.assignments.price;
            this.uniform = appState.shared.uniform;
            this.skills = appState.shared.skills;
            this.utilityOptions = appState.shared.utilityOptions;
            // this.billing = this.customer.billingInformation;

            if (createData.utilityOptionIds) {
                this.utilityOptions = this.utilityOptions.filter(option => createData.utilityOptionIds.includes(option?.id));
            }
            if (createData.skillId) {
                this.skill = this.skills.filter(el => el.id == createData.skillId)[0];
            }
            if (this?.employee?.firstName && this?.employee?.phone) {
                this.contactForm.controls['name'].setValue(this.employee.firstName + ' ' + this.employee.lastName);
                this.contactForm.controls['phone'].setValue(this.employee.phone);
            }
        });

        if (createData.jobPlaceId) {
            this.store.dispatch(new CompanyActions.LoadObject({
                customerId: this.customer.id,
                objectId: createData.jobPlaceId,
            }));
        }
        if (createData.description) {
            this.description = createData.description;
        }
        if (createData.numberOfPersonnel >= 0) {
            this.numberOfPersonnel = createData.numberOfPersonnel;
        }
        if (createData.numberOfLeaders >= 0) {
            this.numberOfLeaders = createData.numberOfLeaders;
        }
        if (createData.uniformId) {
            this.store.dispatch(new SharedActions.LoadUniformDetails({
                customerId: this?.customer?.id,
                uniformId: createData.uniformId,
            }));
        }
        if (createData.contactPersonPhone) {
            this.contactForm.controls['phone'].setValue(createData.contactPersonPhone);
        }
        if (createData.contactPersonName) {
            this.contactForm.controls['name'].setValue(createData.contactPersonName);
        }
        if (createData.utilityOptionIds) {
            this.utilityOptions = this.utilityOptions.filter(option => createData.utilityOptionIds.includes(option?.id));
        }
        if (createData.dates) {
            this.assignmentHours = createData.dates;
            this.startDate = this.assignmentHours[0].date;
            this.endDate = this.assignmentHours[this.assignmentHours.length - 1].date;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onSaveAndContinue(): void {
        if (this.contactForm.invalid) {
            this._snackBar.open('Please fill name and phone fields.', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });
            return;
        }
        createData.contactPersonName = this.contactForm.get('name').value;
        createData.contactPersonPhone = this.contactForm.get('phone').value;

        if (this.customer.billingInformation) {
            this.store.dispatch(new AssignmentsActions.AddAssignment({
                customerId: this.customer.id,
                data: createData,
            }));
        } else {
            this.store.dispatch(new AssignmentsActions.UpdateStep(8));
        }
    }

    public onStep(step: number): void {
        this.store.dispatch(new AssignmentsActions.UpdateStep(step));
    }

    public toDate(string): Date {
        return new Date(string);
    }

    public calculateTime(date, time1, time2): number {
        if ((!time1 && !time2) || !date) {
            return 4;
        }

        let dateTime1 = this.toDate(date.date + ' ' + time1);
        let dateTime2 = this.toDate(date.date + ' ' + time2);
        let diff = dateTime2.getTime() - dateTime1.getTime();

        return (diff / 3600000);
    }

    public calculateTimeHuman(date, time1, time2): string {

        let dateTime1 = this.toDate(date.date + ' ' + time1);
        let dateTime2 = this.toDate(date.date + ' ' + time2);
        let MdateTime1 = moment(dateTime1);
        let MdateTime2 = moment(dateTime2);


        if (MdateTime1.diff(MdateTime2) > 0) {
            MdateTime2.add(1, 'days');
        }

        return moment.duration(MdateTime2.diff(MdateTime1)).format('HH:mm');
    }

    public onEditBillingInfo(): void {
        this.billingInfoModal = true;

        this.changeBillingForm.patchValue({
            companyName: this.customer.billingInformation.companyName,
            companyNumber: this.customer.billingInformation.companyNumber,
            address: this.customer.billingInformation.address,
            addressSecond: this.customer.billingInformation.addressSecond,
            postalCode: this.customer.billingInformation.postalCode,
            city: this.customer.billingInformation.city,
            contactName: this.customer.billingInformation.contactName,
            phone: this.customer.billingInformation.phone,
            email: this.customer.billingInformation.email,
        });
    }

    public onChangeBillingInfoSubmit(): void {
        if (this.changeBillingForm.invalid) {
            Object.keys(this.changeBillingForm.controls).forEach(key => {
                this.changeBillingForm.controls[key].markAsDirty();
            });
            return;
        }

        let billingInformation = new BillingInformationModel();

        this.changeBillingForm['_forEachChild']((control, name) => {
            if (control.dirty) {
                billingInformation[name] = control.value;
            }
        });

        if (this.customer.billingInformation) {
            this.store.dispatch(new AuthActions.UpdateBillingInfo({
                customerId: this.customer.id,
                billingId: this.customer.billingInformation.id,
                data: billingInformation,
            }));
        } else {
            this.store.dispatch(new AuthActions.CreateBillingInfo({
                customerId: this.customer.id,
                ...billingInformation,
            }));
        }

        this.billingInfoModal = false;

    }

}
