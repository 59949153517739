<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="settings">
                    <h3>{{'settings.Permits & Certificates' | translate}}</h3>
                    <p class="faded">{{'settings.Serving license' | translate}}</p>
                    <!--<div class="d-flex">-->
                        <!--<p>{{'settings.Uploaded' | translate}} 2021-02-01</p>-->
                        <!--<p class="delete-p">{{'settings.Delete' | translate}}</p>-->
                    <!--</div>-->
                    <!--<p class="view">{{'settings.View' | translate}}</p>-->
                </div>
            </div>
        </div>
    </div>
</div>
