<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

                <div class="d-md-flex justify-content-between d-block justify-content-center text-left info-block mt-5">
                    <h1>{{'assignments.title' | translate}}</h1>
                    <div class="btn btn-primary my-button" routerLink="/components/assignments/create-assignment/step1">
                        <div class="d-flex">
                            <img src="/assets/img/plus white.svg">
                            <p>{{'assignments.create' | translate}}</p>
                        </div>
                    </div>
                </div>

                <form [formGroup]="filterForm" class="mt-5">
                    <div class="d-lg-flex justify-content-between d-block justify-content-center text-left info-block flex-wrap">
                        <div class="d-flex justify-content-start gap-2 mt-1">
                            <p class="select-label">{{'assignments.filters.object' | translate}}</p>
                            <select class="form-select" formControlName="objects"
                                    (change)="onFilter('job_place_id',$event.target.value)">
                                <option [value]="0">{{'assignments.filters.all-objects' | translate}}</option>
                                <option *ngFor="let object of this?.objects" [value]="object?.id">{{object?.name}}
                                    , {{object?.address}}</option>
                            </select>
                        </div>
                        <!--<div class="d-flex justify-content-start gap-2 mt-1">-->
                            <!--<p class="select-label">{{'assignments.filters.period' | translate}}</p>-->
                            <!--<select class="form-select" formControlName="period">-->
                                <!--<option [value]="0">March 2021</option>-->
                                <!--<option *ngFor="let period of [1,2,3,4,5]">{{period}}</option>-->
                            <!--</select>-->
                        <!--</div>-->
                        <div class="d-flex justify-content-start gap-2 mt-1">
                            <p class="select-label">{{'assignments.filters.competence' | translate}}</p>
                            <select class="form-select" formControlName="skills"
                                    (change)="onFilter('skill_id',$event.target.value)">
                                <option [value]="0">{{'assignments.filters.all-competences' | translate}}</option>
                                <option *ngFor="let skill of this?.skills" [value]="skill?.id">{{skill?.name}}</option>
                            </select>
                        </div>
                    </div>
                </form>

                <div class="table-responsive">
                    <!-- TABLE -->
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>{{'table.thead.date' | translate}}</th>  <!--[mdbTableSort]="elements" sortBy="id" -->
                            <th>{{'table.thead.object' | translate}}</th>
                            <th>{{'table.thead.competence' | translate}}</th>
                            <th>{{'table.thead.no-personnel' | translate}}</th>
                            <th>{{'table.thead.cost' | translate}}</th>
                            <th>{{'table.thead.status' | translate}}</th>
                            <th style="opacity: 0;">arrow</th>
                        </tr>
                        </thead>
                        <tbody>
                        <!--                            <tr *ngIf="dataLoading && this?.assignments?.length == 0">-->
                        <!--                                <td colspan="7">-->
                        <!--                                <app-loader [loading]="dataLoading"></app-loader>-->
                        <!--                                </td>-->
                        <!--                            </tr>-->
                        <tr *ngFor="let assignment  of assignments"
                            routerLink="/components/assignments/assignment/{{assignment.id}}">
                            <td><p>{{assignment.startAt | date:"d MMM"}}
                                - {{assignment.endAt  | date:"d MMM, YYYY"}}</p></td>
                            <td *ngIf="assignment?.jobPlace?.name; else noData"><p>{{assignment?.jobPlace?.name}}</p></td>
                            <td><p>{{assignment.skill?.name }}</p></td>
                            <td *ngIf="assignment?.numberOfPersonnel; else noData">
                                <p>{{assignment?.numberOfPersonnel}}</p></td>
                            <td *ngIf="assignment?.totalCost; else noData"><p>{{assignment?.totalCost}}</p></td>
                            <td>
                                <div [ngClass]="{
                                'pill-draft' : assignment?.status === 1,
                                'pill-published': assignment?.status === 2,
                                'pill-ongoing' : assignment?.status === 3,
                                'pill-finished' : assignment?.status === 4
                                }">

                                    <span *ngIf="assignment?.status === 1;">{{'assignment-status.1' | translate}}</span>
                                    <span *ngIf="assignment?.status === 2;">{{'assignment-status.2' | translate}}</span>
                                    <span *ngIf="assignment?.status === 3;">{{'assignment-status.3' | translate}}</span>
                                    <span *ngIf="assignment?.status === 4;">{{'assignment-status.4' | translate}}</span>
                                </div>
                                <!--<div class="pill-ready" *ngIf="assignment?.status == 2;">-->
                                    <!--<span>{{'assignment-status.2' | translate}}</span>-->
                                <!--</div>-->
                            </td>
                            <td><img src="/assets/img/Arrow-right.svg"></td>
                            <ng-template #noData>
                                <td><p>-</p></td>
                            </ng-template>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="7">
                                <div class="showing">
                                    <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                                         class="left-arrows" src="/assets/img/Arrow-left end.svg"
                                         (click)="onFirstPage()">
                                    <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                                         class="left-arrows" src="/assets/img/Arrow-left.svg"
                                         (click)="onPreviousPage()">
                                    <p *ngIf="pagination.total > 0;else noRecords">
                                        {{'pagination' | translate:
                                        {
                                            from: this?.pagination?.from,
                                            to: this?.pagination?.to,
                                            total: this?.pagination?.total
                                        }
                                        }}
                                    </p>
                                    <ng-template #noRecords>
                                        <p>{{'pagination-no-records' | translate}}</p>
                                    </ng-template>
                                    <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                                         class="right-arrows" src="/assets/img/Arrow-right.svg" (click)="onNextPage()">
                                    <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                                         class="right-arrows" src="/assets/img/Arrow-right end.svg"
                                         (click)="onLastPage()">
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    <!-- /TABLE -->
                </div>
            </div>
        </div>
    </div>
</div>

