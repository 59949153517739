import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-login-email-step2',
    templateUrl: './login-email-step2.component.html',
    styleUrls: ['./login-email-step2.component.scss']
})
export class LoginEmailStep2Component implements OnInit, OnDestroy {

    subscription: Subscription;
    public email = '';

    constructor(private router: Router,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            if (appState.auth.email) {
                this.email = appState.auth.email
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
