import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as AuthActions from '../store/auth.actions';

@Component({
    selector: 'app-login-email',
    templateUrl: './login-email.component.html',
    styleUrls: ['./login-email.component.scss']
})
export class LoginEmailComponent implements OnInit {

    public LoginEmailForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
    });

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
    }

    get email() {
        return this.LoginEmailForm.get('email');
    }

    onLogin(): void {
        this.store.dispatch(new AuthActions.SendLoginLink({email: this.LoginEmailForm.get('email')?.value}));
    }

}
