import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import {createData} from "../create-object";
import * as AssignmentsActions from '../../store/assignments.actions';
import {JobPlaceModel} from "../../../../models/jobPlace.model";
import * as CompanyActions from '../../../company/store/company.actions';
import {Subscription} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RegionModel} from "../../../../models/region.model";
import {HereSuggesstionModel} from "../../../../models/hereSuggesstion.model";
import {GoogleMap} from "@angular/google-maps";
import { CustomerModel } from '../../../../models/customer.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StateModel } from '../../../../models/state.model';

@Component({
    selector: 'app-step4',
    templateUrl: './step4.component.html',
    styleUrls: ['./step4.component.scss']
})
export class Step4Component implements OnInit, OnDestroy {

    @ViewChild(GoogleMap, {static: false}) map: GoogleMap;

    searchAddressTimeout: any = null;
    center: google.maps.LatLngLiteral;
    options: google.maps.MapOptions = {
        zoomControl: false,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        mapTypeId: 'terrain',
    };
    zoom = 15
    marker = {
        position: {lat: 59.33707555933145, lng: 18.07140391688358},
    };

    customer: CustomerModel;
    objects: JobPlaceModel[];
    subscription: Subscription;
    description = '';
    form = new FormGroup({
        object: new FormControl('')
    });

    //for create object
    objectsTabActive: boolean = true;
    teamTabActive: boolean = true;
    addTeamField: boolean = false;
    addRegionField: boolean = false;
    regions: RegionModel[];
    createObjectModal: boolean = false;
    regionsInList: RegionModel[] = [];
    regionsInSelectBox: RegionModel[];
    regionInput: "";
    regionSelectList = false;
    addressSelectList = false;
    filteredRegions: RegionModel[];
    hereSuggestions: HereSuggesstionModel[];
    pickedAddress: any;
    createObjectForm = this.formBuilder.group({
        name: ['', [Validators.required]],
        state: ['', [Validators.required]],
        address: ['', [Validators.required]]
    });
    createdObject;
    statesSubscription: Subscription;
    states: StateModel[];
    isCreatingAssignmentFromHistory: boolean;

    // /for create object


    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private formBuilder: FormBuilder,) {
        this.createdObject = createData;
        // console.log(this.createdObject)
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.isCreatingAssignmentFromHistory = appState.assignments.isAssignmentCreatingFromHistory;
            this.objects = appState.company.objects;
            this.regions = appState.company.regions;
            this.regionsInSelectBox = appState.company.regions;
            this.hereSuggestions = appState.company.hereSuggestions;
            this.customer = appState.auth.customer;

            if (this?.objects?.length > 0 && !createData.jobPlaceId) {
                this.initPosition();
            }
        });

        if (this.objects?.length > 0 && createData.jobPlaceId) {
            this.marker.position = {
                lat: Number(this.objects.find(e => e.id == createData.jobPlaceId).lat),
                lng: Number(this.objects.find(e => e.id == createData.jobPlaceId).lng)
            };
            this.center = {
                lat: Number(this.objects.find(e => e.id == createData.jobPlaceId).lat),
                lng: Number(this.objects.find(e => e.id == createData.jobPlaceId).lng)
            };
            this.form.controls['object'].setValue(createData.jobPlaceId);
        }
        if (createData.description) {
            this.description = createData.description;
        }

        this.statesSubscription = this.store.select('shared', 'states')
                                      .subscribe(states => {

                                          this.states = states;

                                          this.createObjectForm.patchValue({
                                              state: this.states[0],
                                          });
                                      });

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.statesSubscription.unsubscribe();
    }

    public initPosition(): void {
        createData.jobPlaceId = this.objects[0].id;
        this.marker.position = {
            lat: Number(this.objects[0].lat),
            lng: Number(this.objects[0].lng)
        }
        this.center = {
            lat: Number(this.objects[0].lat),
            lng: Number(this.objects[0].lng)
        }
        this.form.controls['object'].setValue(createData.jobPlaceId);
        // this.form.patchValue({
        //     object: createData.jobPlaceId,
        // });
    }

    public onChangeObject($event): void {
        createData.jobPlaceId = this.form.controls['object'].value;
        // console.log(createData.jobPlaceId)
        this.marker.position = {
            lat: Number(this.objects.find(e => e.id == createData.jobPlaceId).lat),
            lng: Number(this.objects.find(e => e.id == createData.jobPlaceId).lng)
        }
        this.center = {
            lat: Number(this.objects.find(e => e.id == createData.jobPlaceId).lat),
            lng: Number(this.objects.find(e => e.id == createData.jobPlaceId).lng)
        }
    }

    //for create object
    public onCreateObjectClicked(): void {
        this.createObjectModal = true;
        this.addRegionField = false;
        this.addTeamField = false;
    }

    public onAddRegion(): void {
        this.addRegionField = true;
        this.regionsInSelectBox = this.regions;
        this.regionInput = '';
    }

    public onAddRegionToList(region: RegionModel): void {
        this.regionsInList.push(region);
        this.regionSelectList = false;
        this.addRegionField = false;
    }

    public onRemoveRegionFromList(region: RegionModel): void {
        this.regionsInList = this.regionsInList.filter(r => r.id != region.id);
    }

    public onCreateObjectSubmit(): void {
        let data: any = {};
        data.name = this.createObjectForm.get('name').value;

        if (!this.pickedAddress) {
            this._snackBar.open('Invalid address', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });

            return;
        }

        // data.regionsIds = regionsIds;
        data.googlePlaceId = this.pickedAddress.place_id;
        data.googlePlace = this.pickedAddress;
        // data.stateId = this.createObjectForm.get('state').value.id;

        this.store.dispatch(new CompanyActions.AddNewObject({
            customerId: this?.customer?.id,
            data: data,
        }));

        this.createObjectModal = false;
        this.addRegionField = false;
        this.addTeamField = false;
        this.regionSelectList = false;
        this.regionsInList = [];
        this.createObjectForm.reset();
    }

    public onAddTeamMember(): void {
        this.addTeamField = true;
    }

    public onObjectsTab(): void {
        this.objectsTabActive = true;
        this.teamTabActive = false;
        this.addTeamField = false;
    }

    public onTeamTab(): void {
        this.objectsTabActive = false;
        this.teamTabActive = true;
    }

    public keyUpRegions(event: any): void {
        if (event.target.value == '') {
            this.regionsInSelectBox = this.regions;
            return;
        }
        this.regionsInSelectBox = this.regions.filter(region => region.name.includes(event.target.value));
    }

    public keyUpAddress(event: any): void {
        if (event.target.value == '') {
            this.addressSelectList = false;
            return;
        }

        clearTimeout(this.searchAddressTimeout);
        this.searchAddressTimeout = setTimeout(() => {
            let county = this.createObjectForm.get('state').value;
            let address = 'Sverige, ' + county.name + ', ' + event.target.value;
            this.pickedAddress = null;
            this.addressSelectList = true;
            this.store.dispatch(new CompanyActions.AddressAutocomplete({
                address,
            }));
        }, 1000);
        // if (event.target.value == '') {
        //     this.addressSelectList = false;
        //     return;
        // }
        // clearTimeout(this.searchAddressTimeout);
        // this.searchAddressTimeout = setTimeout(() => {
        //     console.log(event.target.value);
        //     this.addressSelectList = true;
        //     this.store.dispatch(new CompanyActions.AddressAutocomplete({address: event.target.value}));
        // }, 1000);
    }

    public onPickAddress(address: HereSuggesstionModel): void {
        // this.createObjectForm.controls['address'].setValue(address?.address?.street);
        // this.addressSelectList = false;
        // this.pickedAddress = address;
        this.createObjectForm.controls['address'].setValue(address.label);
        this.addressSelectList = false;
        this.pickedAddress = address;
        if (address.locationId) {
            this.store.dispatch(new CompanyActions.LoadHereLocationByLocationId({
                locationId: address.locationId,
            }));
        }
    }

    onChangeState() {
        this.createObjectForm.patchValue({
            address: null,
        });
        this.pickedAddress = null;
        this.addressSelectList = false;
        // this.center = null;
        // this.marker = null;
    }

    // / for create object

    public onSaveAndContinue(): void {
        createData.description = this.description;

        if(!createData.jobPlaceId) {
            this._snackBar.open('Select object or create new one.', 'Close', {
                duration: 3000,
                panelClass: ['snackbar'],
            });
            return;
        }
        if (this.isCreatingAssignmentFromHistory) {
            if (!createData.numberOfPersonnel) {
                this.store.dispatch(new AssignmentsActions.UpdateStep(5));
                return;
            }
            this.store.dispatch(new AssignmentsActions.UpdateStep(7));
        } else {
            this.store.dispatch(new AssignmentsActions.UpdateStep(5));
        }
    }

    handleAddressChange(address: any) {
        this.pickedAddress = address;

        this.marker = {
            position: {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng(),
            },
        };

        this.center = {
            lat: address.geometry.location.lat(),
            lng: address.geometry.location.lng(),
        };
    }
}
