<div class="step-main-wrapper">
    <h1>{{'create-assignment.personnel-type' | translate}}</h1>

    <div class="wrapper">
        <div class="row">
            <div class="col-12 col-sm-8 col-left">
                <h3>{{'create-assignment.competence' | translate}}</h3>
                <p class="type-p">{{'create-assignment.type' | translate}}</p>
                <div class="type-wrapper">
                    <div class="d-flex justify-content-between gap-2 bordered p-3">
                        <div class="d-flex justify-content-start gap-3">
                            <img src="/assets/img/professions-man-office-1 1.svg" alt="">
                            <p class="type-title" *ngIf="!this.choose">{{this?.selectedSkill?.name}}</p>
                            <p class="type-title" *ngIf="this.choose">Choose competence</p>
                        </div>
                        <p class="change-p"
                           (click)="competenceModal = true;">{{'create-assignment.change' | translate}}</p>
                    </div>
                    <div class="d-flex justify-content-between gap-2 p-3" *ngIf="uniforms.length">
                        <div class="d-flex justify-content-start gap-3">
                            <img src="/assets/img/shirt.svg" alt="">
                            <p class="type-title" *ngIf="!this.choose">{{this?.selectedUniform?.name}}</p>
                            <p class="type-title" *ngIf="this.choose">Choose competence first</p>
                        </div>
                        <p class="change-p"
                           *ngIf="!this.choose"
                           (click)="clothingModal = true;">{{'create-assignment.change' | translate}}</p>
                    </div>
                </div>
                <p class="type-p">{{'create-assignment.description' | translate}}</p>
                <p class="description-p" *ngIf="selectedSkill">
                    <!--{{'create-assignment.step2.description' | translate}}-->
                    {{selectedSkill.description}}
                </p>

                <!--<p *ngIf="uniforms.length === 0 || utilityOptions.length === 0" class="text-danger">-->
                    <!--{{'create-assignment.*You need to add clothing option and utility options to your competence to be able to create assignment.' | translate}}-->
                <!--</p>-->
            </div>
            <!--<div class="col-4 image-wrapper">-->
                <!--<img src="/assets/img/Size700-removebg-preview 1.png" alt="">-->
            <!--</div>-->
        </div>
    </div>

    <div class="options-wrapper" *ngIf="utilityOptions.length">
        <h3>{{'create-assignment.options' | translate}}</h3>
        <p *ngIf="this?.selectedUtilityOptions?.length == 0 && !this.choose"
           [innerHTML]="'create-assignment.no-options' | translate"></p>

        <p *ngIf="this.choose">Choose competence first</p>
        <div class="row">
            <div class="option d-flex justify-content-between gap-2"
                 *ngFor="let option of this?.selectedUtilityOptions">
                <div class="d-flex justify-content-start gap-2">
                    <img src="{{option?.imagePath}}" alt="">
                    <p>{{option?.name}}</p>
                </div>
                <img class="close-icon" src="/assets/img/Close.svg" alt="" (click)="onRemoveOption(option)">
            </div>
        </div>
        <p class="edit-options-p"
           *ngIf="!this?.choose"
           (click)="this.editOptionsModal = true">{{'create-assignment.edit-options' | translate}}</p>
    </div>


    <div class="btn btn-primary my-button" (click)="onSaveAndContinue()">
        <div class="flexed">
            <p>{{'buttons.save&continue' | translate}}</p>
            <img src="/assets/img/arrow white.svg">
        </div>
    </div>

</div>

<!--Edit options Modal-->
<app-modal *ngIf="this.editOptionsModal" class="overlay">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title mt-auto mb-auto">{{'create-assignment.edit-options' | translate}}</h3>
            <span (click)="this.editOptionsModal = false" class="close mt-auto mb-auto">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body">
        <div class="scroll-div" style="height: 400px">
            <div class="row">
                <div [ngClass]="isSelected(option.id) ? 'selected' : '' " class="equipment-wrapper"
                     *ngFor="let option of this.utilityOptions">
                    <div class="d-flex justify-content-between gap-2">
                        <div class="d-flex justify-content-start gap-2">
                            <img src="{{option?.imagePath}}" alt="">
                            <p class="name">{{option?.name}}</p>
                        </div>
                        <input class="checkbox" type="checkbox" value="{{option.id}}"
                               [checked]="isSelected(option.id)"
                               (change)="onChangeOption(option.id , $event.target.checked, option)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.editOptionsModal = false; onCancel()">Cancel</span>
                    <input type="button"
                           value="{{'create-assignment.save-options' | translate}}"
                           (click)="onSaveOptions()"
                           class="btn save-btn btn-primary"/>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Competence Modal-->
<app-modal *ngIf="this.competenceModal && this.chosenCategory != 5" class="overlay">
    <div class="popup-body">
        <div class="scroll-div" style="height: 400px">
            <div *ngFor="let skill of this.skills">
                <div [ngClass]="selectedSkill?.id == skill?.id ? 'selected' : ''"
                     (click)="onSelectedCompetence(skill)"
                     class="competence d-flex justify-content-start">
                    <!--<img class="skill-img" src="{{skill?.imagePath}}" alt="">-->
                    <div class="info ml-3">
                        <p class="title mb-0">{{skill?.name}}</p>
                        <p class="description mb-0">A security guard is in a person appointed by the Police
                            Authority to assist in maintaining public order...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Competence Modal For My Staff-->
<app-modal *ngIf="this.competenceModal && this.chosenCategory == 5" class="overlay">
    <div class="popup-body">
        <div class="scroll-div" style="height: 400px">
            <div *ngFor="let skill of this.skills">
                <div class="basic-competence d-flex justify-content-start">
                    <!--<img class="skill-img" src="{{skill?.imagePath}}" alt="">-->
                    <div class="info ml-3">
                        <p class="title mb-0">{{skill?.name}}</p>
                        <p class="description mb-0">A security guard is in a person appointed by the Police
                            Authority to assist in maintaining public order...</p>
                    </div>
                </div>
                <div *ngFor="let child of skill?.children"
                     [ngClass]="selectedSkill?.id == child?.id ? 'selected' : ''"
                     (click)="onSelectedCompetence(child)"
                     class="subcategories d-flex justify-content-start">
                    <img class="skill-img" src="/assets/img/subcategories.png" alt="">
                    <p class="mt-auto mb-auto ml-2">{{child?.name}}</p>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Clothing Modal-->
<app-modal *ngIf="this.clothingModal" class="overlay">
    <div class="popup-header2"></div>
    <div class="popup-body">
        <div class="scroll-div" style="height: 400px">
            <div class="row clothing">
                <div [ngClass]="selectedUniform?.id == uniform?.id ? 'selected' : ''"
                     class="item d-flex flex-column justify-content-between gap-1 m-auto mb-3"
                     *ngFor="let uniform of this?.uniforms"
                     (click)="onSelectedUniform(uniform)">
                    <img src="{{uniform?.imagePath}}" alt="" *ngIf="uniform?.imagePath">
                    <p>{{uniform?.name}}</p>
                </div>
            </div>
        </div>
    </div>
</app-modal>
