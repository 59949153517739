import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {EmployeeModel} from "../models/employee.model";
import { Observable, Subscription } from 'rxjs';
import {Store} from "@ngrx/store";
import * as fromApp from "../store/app.reducer";
import {CustomerModel} from "../models/customer.model";
import * as AuthActions from '../auth/store/auth.actions';
import {MessagingService} from "../service/messaging.service";
import { filter, map, take } from 'rxjs/operators';
import * as MessageActions from './messages/store/messages.actions';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit, OnDestroy {

    public employee: EmployeeModel;
    public customers: CustomerModel[];
    storeSub: Subscription;

    public opened: boolean = true;
    public innerWidth: any;
    public mode: string = "side";
    public closeIcon: boolean = false;
    public navButton: boolean = false;

    public customer: CustomerModel;

    public switchAccountOptions: boolean = false;

    public home: boolean = false;
    public profile: boolean = false;
    public company: boolean = false;
    public staff: boolean = false;
    public messages: boolean = false;
    public reports: boolean = false;
    public assignments: boolean = false;


    public reportTypes: boolean = false;

    public favourites: boolean = false;
    public createAssignment: boolean = false;

    public competences: boolean = false;
    public payroll: boolean = false;
    public inviteStaff: boolean = false;
    messagesCount$: Observable<number>;

    constructor(public router: Router,
                private store: Store<fromApp.AppState>,
                private messageService: MessagingService,) {
        this.innerWidth = window.innerWidth;
    }

    ngOnInit(): void {

        this.storeSub = this.store.subscribe(appState => {
            this.employee = appState.auth.employee;
            this.customers = appState.auth.customers;
            this.customer = appState.auth.customer;
            //
            //
            // this.messagesCount = appState.messages.totalMessagesUnseenCount;
        });


        this.store.select('auth', 'customer')
            .pipe(
                filter(x => x != null),
            )
            .subscribe(x => {
                this.store.dispatch(new MessageActions.LoadConversations({
                    customerId: x.id,
                    conversationId: null
                }));
            });
        this.messagesCount$ = this.store.select('messages', 'totalMessagesUnseenCount')
            .pipe(
                map(x => x),
            );

        this.innerWidth = window.innerWidth;
        if (this.innerWidth <= 800) {
            this.mode = "over";
            this.closeIcon = true;
        } else {
            this.mode = "side";
            this.closeIcon = false;
        }
        //paths
        if (this.router.url == "/components/home") {
            this.home = true;
        }
        if (this.router.url == "/components/profile") {
            this.profile = true;
        }
        if (this.router.url.includes("components/company")) {
            this.company = true;
        }
        if (this.router.url.includes("components/reports")) {
            this.reports = true;
        }
        if (this.router.url.includes("report-types")) {
            this.reportTypes = true;
        }
        if (this.router.url.includes("assignments")) {
            this.assignments = true;
        }
        if (this.router.url.includes("favourites")) {
            this.favourites = true;
        }
        if (this.router.url.includes("create-assignment")) {
            this.createAssignment = true;
        }
        if (this.router.url.includes("messages")) {
            // this.messagesCount = appState.messages.conversations
            //                              .map(x => x.unseenCount)
            //                              .reduce((a: number,b: number) => a + b);
            this.messages = true;
        }
        if (this.router.url.includes("my-staff")) {
            this.staff = true;
        }
        if (this.router.url.includes("competences")) {
            this.competences = true;
        }
        if (this.router.url.includes("payroll")) {
            this.payroll = true;
        }
        if (this.router.url.includes("my-staff/invite") || this.router.url.includes("my-staff/send-invites")) {
            this.inviteStaff = true;
        }
    }

    ngOnDestroy(): void {
        this.storeSub.unsubscribe();
    }

    onHomeClicked(): void {
        this.home = true;
        this.profile = false;
        this.company = false;
        this.staff = false;
        this.messages = false;
        this.reports = false;
        this.assignments = false;
        this.reportTypes = false;
        this.favourites = false;
        this.createAssignment = false;
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = false;
    }

    onProfileClicked(): void {
        this.home = false;
        this.profile = true;
        this.company = false;
        this.staff = false;
        this.messages = false;
        this.reports = false;
        this.assignments = false;
        this.reportTypes = false;
        this.favourites = false;
        this.createAssignment = false;
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = false;
    }

    onCompanyClicked(): void {
        this.home = false;
        this.profile = false;
        this.company = true;
        this.staff = false;
        this.messages = false;
        this.reports = false;
        this.assignments = false;
        this.reportTypes = false;
        this.favourites = false;
        this.createAssignment = false;
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = false;
    }

    onReportsClicked(): void {
        this.home = false;
        this.profile = false;
        this.company = false;
        this.staff = false;
        this.messages = false;
        this.reports = true;
        this.reportTypes = false;
        this.assignments = false;
        this.favourites = false;
        this.createAssignment = false;
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = false;
    }

    onAssignmentsClicked(): void {
        this.home = false;
        this.profile = false;
        this.company = false;
        this.staff = false;
        this.messages = false;
        this.reports = false;
        this.reportTypes = false;
        this.assignments = true;
        this.favourites = false;
        this.createAssignment = false;
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = false;
    }

    onMessagesClicked(): void {
        this.home = false;
        this.profile = false;
        this.company = false;
        this.staff = false;
        this.messages = true;
        this.reports = false;
        this.assignments = false;
        this.reportTypes = false;
        this.favourites = false;
        this.createAssignment = false;
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = false;
    }

    onStaffClicked(): void {
        this.home = false;
        this.profile = false;
        this.company = false;
        this.staff = true;
        this.messages = false;
        this.reports = false;
        this.reportTypes = false;
        this.assignments = false;
        this.favourites = false;
        this.createAssignment = false;
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = false;
    }

    onResize() {
        this.innerWidth = window.innerWidth;
        if (this.innerWidth <= 800) {
            this.mode = "over";
            this.closeIcon = true;
            this.navButton = true;
        } else {
            this.mode = "side";
            this.closeIcon = false;
            this.navButton = false;
            this.opened = true;
        }
    }

    onReportTypes(): void {
        this.reportTypes = true;
        this.router.navigate(['/components/reports/report-types']);
    }

    onFavourites(): void {
        this.favourites = true;
        this.createAssignment = false;
        this.router.navigate(['/components/assignments/favourites']);
    }

    onCreateAssignment(): void {
        this.createAssignment = true;
        this.favourites = false;
        this.router.navigate(['/components/assignments/create-assignment/step1']);
    }

    onCompetences(): void {
        this.competences = true;
        this.payroll = false;
        this.inviteStaff = false;
        this.router.navigate(['/components/my-staff/competences']);
    }

    onPayroll(): void {
        this.competences = false;
        this.payroll = true;
        this.inviteStaff = false;
        this.router.navigate(['/components/my-staff/payroll']);
    }

    onInviteStaff(): void {
        this.competences = false;
        this.payroll = false;
        this.inviteStaff = true;
        this.router.navigate(['/components/my-staff/invite']);
    }

    public onSwitchCustomer(customer: CustomerModel): void {
        localStorage.setItem('customer', customer.id.toString());
        if (this.customer == customer) {
            return;
        }
        this.store.dispatch(new AuthActions.SwitchCompanyAccount({customer: customer}));
        this.store.dispatch(new MessageActions.LoadConversations({
            customerId: customer.id,
            conversationId: null
        }));
        this.switchAccountOptions = false;
        window.location.reload();
    }
}

