<div class="step-main-wrapper">
	<h1>{{'create-assignment.personnel' | translate}}</h1>

	<div class="wrapper">
		<form [formGroup]="form">
			<div class="row">
				<div class="col-12 col-lg-6">
					<h3>{{'create-assignment.num-personnel' | translate}}</h3>
					<input type="number" class="form-control" formControlName="noPersonnel"
					       min="{{this.form.get('noGrLeaders').value}}">
					<!--<p class="faded-p">{{'create-assignment.recommendation-personnel' | translate}}</p>-->
				</div>
				<div class="col-12 col-lg-6">
					<h3>{{'create-assignment.num-leaders' | translate}}</h3>
					<input type="number" class="form-control" formControlName="noGrLeaders"
					       max="{{this.form.get('noPersonnel').value}}" min="0">
					<p class="faded-p">{{'create-assignment.recommendation-leaders' | translate}}</p>
				</div>
			</div>
		</form>
	</div>

	<div class="wrapper" *ngIf="!this?.recurringEvent && this.handPick">
		<h3>{{'create-assignment.pick-personnel' | translate}}</h3>
		<!--        <div class="flexed">-->
		<!--            <input class="checkbox" type="checkbox" [(ngModel)]="handPick">-->
		<!--            <div class="text">-->
		<!--                <p>{{'create-assignment.hand-pick' | translate}}</p>-->
		<!--                <p class="faded-p">{{'create-assignment.hand-pick-desc' | translate}}</p>-->
		<!--            </div>-->
		<!--        </div>-->

		<div class="filters" *ngIf="this.handPick">
			<div class="d-sm-flex justify-content-sm-start d-block justify-content-center text-left gap-2">
				<p class="filter-p">{{'create-assignment.filter' | translate}}</p>
				<div [ngClass]="this.filterEveryone ? 'active': '' " class="border-wrapper"
				     (click)="onFilterEveryone()">
					<p>{{'create-assignment.everyone' | translate}}</p>
				</div>
				<div [ngClass]="this.filterFavourites  ? 'active': '' " class="border-wrapper"
				     (click)="onFilterFavourites()">
					<p>{{'create-assignment.favourites' | translate}}</p>
				</div>
			</div>
		</div>

		<div class="personnels" *ngIf="this.handPick">
			<p class="section-p">
				{{'create-assignment.personnels' | translate:{
				selected: this.selectedPersonnel.length,
				chosen: this.form.get('noPersonnel').value - this.form.get('noGrLeaders').value
				} }}
			</p>
			<div class="scroll-div" id="personnel" (scroll)="onScrollGuards($event)">
				<div class="row">
					<div [ngClass]="isSelectedPersonnel(guard?.id) ? 'selected' : ''"
					     class="personnel-wrapper"
					     *ngFor="let guard of guards; let inx = index">
						<div class="d-flex justify-content-between flex-row gap-2">
							<div class="d-flex justify-content-start gap-1">
								<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
								     class="profile-img"
								     (click)="onClickGuardDetails(guard, 'guard')"
								     role="button">
								<div class="basic-info">
									<p (click)="onClickGuardDetails(guard, 'guard')"
									   role="button">{{guard?.firstName}}</p>
									<!--<p>{{guard?.firstName}} {{guard?.lastName}}</p>-->
									<div class="d-flex">
										<ngb-rating max="5" [rate]="guard?.averageRate" [readonly]="true"></ngb-rating>
										<p class="faded-p mt-auto mb-auto">{{guard?.averageRate}}</p>
										<img class="faded-check-icon" src="/assets/img/Group 596.png" alt="">
										<p class="faded-p check-p mt-auto mb-auto">{{guard.ratingCount}}</p>
									</div>
								</div>
							</div>
							<input class="checkbox mt-auto mb-auto" type="checkbox"
							       [value]="guard.id"
							       [checked]="isSelectedPersonnel(guard.id)"
							       (change)="onChangePersonnel($event.target.value, $event.target.checked)">
						</div>
					</div>
				</div>
			</div>

			<ng-container *ngIf="form.get('noGrLeaders').value > 0">
				<p class="section-p" style="margin-top: 25px;">{{'create-assignment.leaders' | translate:{
					selected: this.selectedLeaders.length,
					chosen: this.form.get('noGrLeaders').value
					} }}
				</p>
				<div class="scroll-div" id="leaders" (scroll)="onScrollGuards($event)">
					<div class="row">
						<ng-container *ngFor="let guard of guards; let inx = index">
							<div *ngIf="guard.isLeader"
							     [ngClass]="isSelectedLeader(guard.id) ? 'selected' : ''"
							     class="personnel-wrapper">
								<div class="d-flex justify-content-between flex-row gap-2">
									<div class="d-flex justify-content-start gap-1">
										<img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}"
										     class="profile-img"
										     (click)="onClickGuardDetails(guard, 'leader')"
										     role="button">
										<div class="basic-info">
											<p (click)="onClickGuardDetails(guard, 'leader')"
											   role="button">{{guard?.firstName}}</p>
											<div class="d-flex">
												<ngb-rating max="5" [rate]="guard?.averageRate"
												            [readonly]="true"></ngb-rating>
												<p class="faded-p mt-auto mb-auto">{{guard?.averageRate}}</p>
												<img class="faded-check-icon" src="/assets/img/Group 596.png" alt="">
												<p class="faded-p check-p mt-auto mb-auto">{{guard.ratingCount}}</p>
											</div>
										</div>
									</div>
									<input class="checkbox mt-auto mb-auto" type="checkbox"
									       [value]="guard.id"
									       (change)="onChangeLeader($event.target.value, $event.target.checked)"
									       [checked]="isSelectedLeader(guard.id)">
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</div>

		<!--        <p class="faded-p bottom-p">{{'create-assignment.remaining' | translate}}</p>-->
	</div>

	<div class="btn btn-primary my-button" (click)="onSaveAndContinue()">
		<div class="flexed">
			<p>{{'buttons.save&continue' | translate}}</p>
			<img src="/assets/img/arrow white.svg">
		</div>
	</div>

</div>
<app-guard-detail-modal [guard]="guardDetail"
                        [action]="guardDetailAction"
                        (onClickAction)="onResolveGuardDetailAction($event)"
                        (closeModal)="guardDetail = null"
                        *ngIf="guardDetail"></app-guard-detail-modal>