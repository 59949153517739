import {PaginationModel} from "../../../models/pagination.model";
import * as AssignmentsActions from "./assignments.actions";
import {AssignmentModel} from "../../../models/assignment.model";
import {AssignmentCategoryModel} from "../../../models/assignmentCategory.model";
import {GuardModel} from "../../../models/guard.model";
import {PriceModel} from "../../../models/price.model";
import {createSelector} from "@ngrx/store";
import { RATE_GUARD_FOR_SHIFT_SUCCESS } from './assignments.actions';
import { SELECT_SHIFT_ON_OPEN_ASSIGNMENT } from './assignments.actions';
import { ShiftModel } from '../../../models/shift.model';
import { ReportModel } from '../../../models/report.model';
import { LOAD_REPORTS_SUCCESS } from './assignments.actions';
import { LOAD_ASSIGNMENTS_STATISTICS_SUCCESS } from './assignments.actions';
import { AssignmentStatisticsModel } from '../../../models/assignment.statistics.model';
import { CustomerTodoModel } from '../../../models/customer.todo.model';
import { ReportStatistic } from '../../../models/reportStatistic';


export interface State {
    assignment: AssignmentModel;
    assignmentStatistics: AssignmentStatisticsModel;
    customerTodos: CustomerTodoModel[];
    reports: ReportModel[];
    reportStatistics: ReportStatistic;
    selectedReport: ReportModel;
    selectedShift: ShiftModel;
    price: PriceModel;
    categories: AssignmentCategoryModel[];
    assignments: AssignmentModel[];
    assignmentsLoading: boolean;
    pagination: PaginationModel;
    guards: GuardModel[];
    guardsPagination: PaginationModel;
    guardsMeta: {
        page: number,
        perPage: number,
        isLastPage: boolean
    };
    loading: boolean;
    step: number;
    isAssignmentCreatingFromHistory: boolean;
    favouriteGuards: GuardModel[];
    favouritesMeta: {
        page: number,
        perPage: number,
        isLastPage: boolean
    };
    favouritesPagination: PaginationModel
    favouriteGuardsToAdd: GuardModel[];
    guardsToAddMeta: {
        page: number,
        perPage: number,
        isLastPage: boolean
    };
    guardsToAddPagination: PaginationModel
}

const initialState: State = {
    assignment: null,
    assignmentStatistics: null,
    customerTodos: [],
    selectedShift: null,
    price: null,
    categories: [],
    reports: [],
    reportStatistics: null,
    selectedReport: null,
    assignments: [],
    assignmentsLoading: false,
    pagination: null,
    guards: [],
    guardsPagination: null,
    guardsMeta: {
        page: 0,
        perPage: 20,
        isLastPage: true
    },
    step: 1,
    isAssignmentCreatingFromHistory: false,
    loading: false,
    favouriteGuards: [],
    favouritesMeta: {
        page: 0,
        perPage: 15,
        isLastPage: true
    },
    favouritesPagination: null,
    favouriteGuardsToAdd: [],
    guardsToAddMeta: {
        page: 0,
        perPage: 10,
        isLastPage: true
    },
    guardsToAddPagination: null,
}


export function assignmentsReducer(state = initialState, action: AssignmentsActions.AssignmentsActions): State {
    switch (action.type) {
        case AssignmentsActions.LOAD_REPORTS_STATISTICS_SUCCESS:
            return {
                ...state,
                reportStatistics: action.payload.data,
            };
        case AssignmentsActions.CREATE_ASSIGNMENT_FROM_HISTORY:
            return {
                ...state,
                isAssignmentCreatingFromHistory: action.payload.isFromHistory,
            };
        case AssignmentsActions.SHIFT_APPROVE_GUARD_HOURS_SUCCESS:
            return {
                ...state,
                selectedShift: action.payload,
                assignment: {
                    ...state.assignment,
                    shifts: state.assignment.shifts.map(shift => {
                        if(shift.id == action.payload.id) {
                            return action.payload;
                        }
                        return shift
                    })
                }
            };
        case AssignmentsActions.LOAD_CUSTOMER_TODOS_SUCCESS:
            return {
                ...state,
                pagination: action.payload.pagination,
                customerTodos: action.payload.data,
            };
        case AssignmentsActions.LOAD_EMPLOYEE_TODOS_SUCCESS:
            return {
                ...state,
                customerTodos: action.payload.data,
            };
        case AssignmentsActions.LOAD_ASSIGNMENTS_STATISTICS_SUCCESS:
            return {
                ...state,
                assignmentStatistics: action.payload,
            };
        case AssignmentsActions.LOAD_REPORTS_SUCCESS:
            return {
                ...state,
                // reports: [...state.reports, ...action.payload.data],
                reports: action.payload.data,
                pagination: action.payload.pagination,
            };
        case AssignmentsActions.LOAD_REPORT_SUCCESS:
            return {
                ...state,
                selectedReport: action.payload.data,
            };
        case AssignmentsActions.SELECT_SHIFT_ON_OPEN_ASSIGNMENT:
            return {
                ...state,
                selectedShift: state.assignment.shifts.find(shift => {
                    return shift.id === action.payload
                })
            };
        case AssignmentsActions.CLEAR_SELECTED_SHIFT:
            return {
                ...state,
                selectedShift: null
            };
        case AssignmentsActions.RATE_GUARD_FOR_SHIFT_SUCCESS:
            return {
                ...state,
                selectedShift: {
                    ...state.selectedShift,
                    guards: {
                        ...state.selectedShift.guards,
                        hired: state.selectedShift.guards.hired.map(guard => {
                            if(guard.id === action.payload.guardId) {
                                return {
                                    ...guard,
                                    guardShift: {
                                        ...guard.guardShift,
                                        rating: action.payload.rate
                                    }
                                };
                            }
                            return guard;
                        })
                    }
                },
                assignment: {
                    ...state.assignment,
                    shifts: state.assignment.shifts.map(shift => {
                        if(shift.id === action.payload.shiftId) {
                            return {
                                ...shift,
                                guards: {
                                    ...shift.guards,
                                    hired: shift.guards.hired.map(guard => {
                                        if(guard.id === action.payload.guardId) {
                                            return {
                                                ...guard,
                                                guardShift: {
                                                    ...guard.guardShift,
                                                    rating: action.payload.rate
                                                }
                                            };
                                        }
                                        return guard;
                                    })
                                }
                            };
                        }
                        return shift;
                    })

                }
            };
        case AssignmentsActions.LOAD_ASSIGNMENTS:
            return {
                ...state,
                assignmentsLoading: true
            };
        case AssignmentsActions.UPDATE_ASSIGNMENT_SHIFT:
            return {
                ...state,
                assignment: {
                    ...state.assignment,
                    shifts: state.assignment.shifts.map(shift => {
                        if(shift.id === action.payload.id) {
                            return action.payload;
                        }
                        return shift;
                    }),
                },
                selectedShift: action.payload
            };
        case AssignmentsActions.LOAD_ASSIGNMENTS_SUCCESS:
            return {
                ...state,
                assignments: action.payload.assignments,
                pagination: action.payload.pagination,
                assignmentsLoading: false
            };
        case AssignmentsActions.LOAD_ASSIGNMENTS_FAILED:
            return {
                ...state,
                assignmentsLoading: false
            };
        case AssignmentsActions.CLEAR_ASSIGNMENTS:
            return {
                ...state,
                assignments: []
            };
        case AssignmentsActions.CLEAR_ASSIGNMENT:
            return {
                ...state,
                assignment: null
            };
        case AssignmentsActions.LOAD_ASSIGNMENT_DETAILS:
            return {
                ...state,
                loading: true
            };
        case AssignmentsActions.LOAD_ASSIGNMENT_DETAILS_SUCCESS:
            return {
                ...state,
                assignment: action.payload.assignment,
                loading: false
            };
        case AssignmentsActions.CANCEL_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                assignment: action.payload.assignment,
                loading: false
            };
        case AssignmentsActions.LOAD_ASSIGNMENT_DETAILS_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.ADD_ASSIGNMENT:
            return {
                ...state,
                loading: true
            };
        case AssignmentsActions.ADD_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                assignments: [action.payload.assignment, ...state.assignments],
                loading: false
            };
        case AssignmentsActions.ADD_ASSIGNMENT_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.ADD_ASSIGNMENT_AS_DRAFT_SUCCESS:
            return {
                ...state,
                assignments: [action.payload.assignment, ...state.assignments],
                loading: false
            };
        case AssignmentsActions.ADD_ASSIGNMENT_AS_DRAFT_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.CALCULATE_PRICE:
            return {
                ...state,
                loading: true
            };
        case AssignmentsActions.CALCULATE_PRICE_SUCCESS:
            return {
                ...state,
                price: action.payload.price,
                loading: false
            };
        case AssignmentsActions.CALCULATE_PRICE_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.LOAD_ASSIGNMENT_CATEGORIES:
            return {
                ...state,
                loading: true
            };
        case AssignmentsActions.LOAD_ASSIGNMENT_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload.categories,
                loading: false
            };
        case AssignmentsActions.LOAD_ASSIGNMENT_CATEGORIES_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.UPDATE_STEP:
            return {
                ...state,
                step: action.payload
            };
        case AssignmentsActions.LOAD_OBJECT_GUARDS:
            return {
                ...state,
                guardsMeta: {
                    ...state.guardsMeta,
                    page: state.guardsMeta.page + 1,
                    isLastPage: true
                },
                loading: true
            };
        case AssignmentsActions.LOAD_OBJECT_GUARDS_SUCCESS:
            return {
                ...state,
                guards: [...state.guards, ...action.payload.guards],
                guardsPagination: action.payload.pagination,
                guardsMeta: {
                    ...state.guardsMeta,
                    isLastPage: action.payload.pagination.currentPage == action.payload.pagination.lastPage
                },
                loading: false
            };
        case AssignmentsActions.LOAD_OBJECT_GUARDS_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.CLEAR_GUARDS:
            return {
                ...state,
                guards: [],
                guardsMeta: {
                    ...state.guardsMeta,
                    page: 0,
                    isLastPage: true
                },
                loading: false
            };
        case AssignmentsActions.LOAD_FAVOURITE_GUARDS:
            return {
                ...state,
                favouritesMeta: {
                    ...state.favouritesMeta,
                    page: state.favouritesMeta.page + 1,
                    isLastPage: true
                },
                loading: true
            };
        case AssignmentsActions.LOAD_FAVOURITE_GUARDS_SUCCESS:
            return {
                ...state,
                favouriteGuards: [...state.favouriteGuards, ...action.payload.favouriteGuards],
                favouritesPagination: action.payload.pagination,
                favouritesMeta: {
                    ...state.favouritesMeta,
                    isLastPage: action.payload.pagination.currentPage == action.payload.pagination.lastPage
                },
                loading: false
            };
        case AssignmentsActions.LOAD_FAVOURITE_GUARDS_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.CLEAR_FAVOURITE_GUARDS:
            return {
                ...state,
                favouriteGuards: [],
                favouritesMeta: {
                    ...state.favouritesMeta,
                    page: 0,
                    isLastPage: true
                },
            };
        case AssignmentsActions.DELETE_FAVOURITE_GUARD:
            return {
                ...state,
                loading: true
            };
        case AssignmentsActions.DELETE_FAVOURITE_GUARD_SUCCESS:
            return {
                ...state,
                favouriteGuards: state.favouriteGuards.filter(g => g.id != action.payload.deletedGuard.id),
                loading: false
            };
        case AssignmentsActions.DELETE_FAVOURITE_GUARD_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.ADD_GUARDS_TO_FAVOURITES:
            return {
                ...state,
                loading: true
            };
        case AssignmentsActions.ADD_GUARDS_TO_FAVOURITES_SUCCESS:
            return {
                ...state,
                favouriteGuards: [...action.payload.guards, ...state.favouriteGuards],
                favouriteGuardsToAdd: state.favouriteGuardsToAdd.filter(
                    x => !action.payload.guards.map(x => x.id).includes(x.id)),
                loading: false
            };
        case AssignmentsActions.ADD_GUARDS_TO_FAVOURITES_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.LOAD_FAVOURITE_GUARDS_TO_ADD:
            return {
                ...state,
                guardsToAddMeta: {
                    ...state.guardsToAddMeta,
                    page: state.guardsToAddMeta.page + 1,
                    isLastPage: true
                },
                loading: true
            };
        case AssignmentsActions.LOAD_FAVOURITE_GUARDS_TO_ADD_SUCCESS:
            return {
                ...state,
                favouriteGuardsToAdd: [...state.favouriteGuardsToAdd, ...action.payload.guards],
                guardsToAddPagination: action.payload.pagination,
                guardsToAddMeta: {
                    ...state.guardsToAddMeta,
                    isLastPage: action.payload.pagination.currentPage == action.payload.pagination.lastPage
                },
                loading: false
            };
        case AssignmentsActions.LOAD_FAVOURITE_GUARDS_TO_ADD_FAILED:
            return {
                ...state,
                loading: false
            };
        case AssignmentsActions.CLEAR_FAVOURITE_GUARDS_TO_ADD:
            return {
                ...state,
                favouriteGuardsToAdd: [],
                guardsToAddMeta: {
                    ...state.guardsToAddMeta,
                    page: 0,
                    isLastPage: true
                },
            };
        default:
            return state;
    }
}
