import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from './assignments.reducer';
import * as fromApp from '../../../store/app.reducer';
import * as AssignmentsActions from './assignments.actions';
import { environment } from '../../../../environments/environment';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginationModel } from '../../../models/pagination.model';
import { AssignmentModel } from '../../../models/assignment.model';
import { AssignmentCategoryModel } from '../../../models/assignmentCategory.model';
import { GuardModel } from '../../../models/guard.model';
import { PriceModel } from '../../../models/price.model';
import { ShiftModel } from '../../../models/shift.model';
import { RateModel } from '../../../models/rate.model';
import { LOAD_REPORTS_SUCCESS } from './assignments.actions';
import { ReportModel } from '../../../models/report.model';
import { AppState } from '../../../store/app.reducer';
import { CustomerModel } from '../../../models/customer.model';
import { AssignmentStatisticsModel } from '../../../models/assignment.statistics.model';
import { CustomerTodoModel } from '../../../models/customer.todo.model';
import { AssignmentStatusConst } from '../../../constants/assignment.status.const';
import { ReportStatistic } from '../../../models/reportStatistic';


@Injectable()
export class AssignmentsEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private _snackBar: MatSnackBar,
        private assignmentStatus: AssignmentStatusConst,
        private store: Store<fromApp.AppState>,
    ) {
    }

    loadAssignments = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_ASSIGNMENTS),
            switchMap((action: AssignmentsActions.LoadAssignments) => {
                let params = new HttpParams();
                params = params.append('page', action.payload.page);
                params = params.append('per_page', 10);
                params = params.append('sort', 'desc');
                params = params.append('only', 'id,startAt,endAt,jobPlace,numberOfPersonnel,totalCost,status,skill');
                params = params.append('status', [this.assignmentStatus.PUBLISH, this.assignmentStatus.ONGOING, this.assignmentStatus.FINISHED].toString());

                if (action.payload.params) {
                    action.payload.params.forEach(param => {
                        params = params.append(param.key, param.value);
                    });
                }

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/assignments`, {params})
                           .pipe(
                               switchMap((resData: { data: AssignmentModel[], pagination: PaginationModel }) => {
                                   return of(new AssignmentsActions.LoadAssignmentsSuccess({
                                       assignments: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadAssignmentsFailed());
                               }),
                           );
            }),
        );
    });

    loadAssignmentsStatistic = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_ASSIGNMENTS_STATISTICS),
            withLatestFrom(this.store.select(state => state.auth.customer)),
            switchMap(([action, customer]: [AssignmentsActions.LoadAssignmentsStatistics, CustomerModel]) => {
                return this.http.get(environment.apiUrl + `/customers/${customer.id}/assignments/statistics`)
                           .pipe(
                               switchMap((resData: AssignmentStatisticsModel) => {
                                   return of(new AssignmentsActions.LoadAssignmentsStatisticsSuccess(resData));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadAssignmentsFailed());
                               }),
                           );
            }),
        );
    });

    loadTodos = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_CUSTOMER_TODOS),
            withLatestFrom(this.store.select(state => state.auth.customer)),
            switchMap(([action, customer]: [AssignmentsActions.LoadCustomerTodos, CustomerModel]) => {
                return this.http.get(environment.apiUrl + `/customers/${customer.id}/todos?page=${action.payload.page}`)
                           .pipe(
                               switchMap((resData: {
                                   data: CustomerTodoModel[],
                                   pagination: PaginationModel,
                               }) => {
                                   return of(new AssignmentsActions.LoadCustomerTodosSuccess(resData));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadCustomerTodosFailed());
                               }),
                           );
            }),
        );
    });

    loadEmployeeTodos = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_EMPLOYEE_TODOS),
            switchMap(() => {
                return this.http.get(environment.apiUrl + `/employees/todos`)
                           .pipe(
                               switchMap((resData: {
                                   data: CustomerTodoModel[]
                               }) => {
                                   return of(new AssignmentsActions.LoadEmployeeTodosSuccess(resData));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadEmployeeTodosFailed());
                               }),
                           );
            }),
        );
    });

    updateShift = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.UPDATE_SHIFT),
            switchMap((action: AssignmentsActions.UpdateShift) => {
                return this.http.post(environment.apiUrl + `/shifts/${action.payload.id}`, action.payload)
                           .pipe(
                               switchMap((response: {
                                   data: ShiftModel
                               }) => {
                                   this._snackBar.open('Shift updated successfully!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new AssignmentsActions.UpdateShiftSuccess(response));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.UpdateShiftFailed());
                               }),
                           );
            }),
        );
    });

    shiftApproveGuardHours = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.SHIFT_APPROVE_GUARD_HOURS),
            // withLatestFrom(this.store.select(state => state.auth.customer)),
            switchMap((action: AssignmentsActions.ShiftApproveGuardHours) => {
                return this.http.post(environment.apiUrl + `/shifts/${action.payload.shiftId}/approve-hours`, {
                               status: action.payload.status,
                               guardId: action.payload.guardId,
                           })
                           .pipe(
                               switchMap((resData: {
                                   data: ShiftModel
                               }) => {
                                   return of(new AssignmentsActions.ShiftApproveGuardHoursSuccess(resData.data));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.ShiftApproveGuardHoursFailed());
                               }),
                           );
            }),
        );
    });

    deleteTodo = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.DELETE_CUSTOMER_TODO),
            withLatestFrom(this.store.select(state => state.auth.customer)),
            switchMap(([action, customer]: [AssignmentsActions.DeleteCustomerTodo, CustomerModel]) => {
                return this.http.delete(environment.apiUrl + `/customers/${customer.id}/todos/${action.payload.customerTodoId}`)
                           .pipe(
                               switchMap((resData: {
                                   data: CustomerTodoModel
                               }) => {
                                   return of(new AssignmentsActions.DeleteCustomerTodoSuccess(resData.data));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.DeleteCustomerTodoFailed());
                               }),
                           );
            }),
        );
    });

    loadReports = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_REPORTS),
            switchMap((action: AssignmentsActions.LoadReports) => {
                let params = new HttpParams();
                params = params.append('page', action.payload.page);

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/reports`, {
                               ...action.payload.params,
                                incident_time: {
                                    toTime: action.payload.toTime,
                                    fromTime: action.payload.fromTime,
                                }
                           }, {
                               params: params,
                           })
                           .pipe(
                               switchMap((resData: { data: ReportModel[], pagination: PaginationModel }) => {
                                   return of(new AssignmentsActions.LoadReportsSuccess(resData));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadReportsFailed());
                               }),
                           );
            }),
        );
    });

    loadReportsStatistics = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_REPORTS_STATISTICS),
            switchMap((action: AssignmentsActions.LoadReportsStatistics) => {
                let params = new HttpParams();
                // params = params.append('page', action.payload.page);

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/reports-statistics`, action.payload)
                           .pipe(
                               switchMap((resData: { data: ReportStatistic }) => {
                                   return of(new AssignmentsActions.LoadReportsStatisticsSuccess(resData));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadReportsStatisticsFailed());
                               }),
                           );
            }),
        );
    });

    loadReport = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_REPORT),
            switchMap((action: AssignmentsActions.LoadReport) => {
                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/reports/${action.payload.reportId}`)
                           .pipe(
                               switchMap((resData: { data: ReportModel }) => {
                                   return of(new AssignmentsActions.LoadReportSuccess(resData));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadReportFailed());
                               }),
                           );
            }),
        );
    });

    acceptGuardsForShift = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.ACCEPT_GUARDS_FOR_SHIFT),
            switchMap((action: AssignmentsActions.AcceptGuardsForShift) => {

                return this.http.post(environment.apiUrl + `/shifts/${action.payload.shiftId}/accept`, {
                               guardIds: action.payload.guardIds,
                           })
                           .pipe(
                               switchMap((resData: { data: ShiftModel }) => {
                                   this._snackBar.open('Guards picked successfully!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return [
                                       new AssignmentsActions.UpdateAssignmentShift(resData.data),
                                   ];
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadAssignmentsFailed());
                               }),
                           );
            }),
        );
    });

    rateGuardForShift = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.RATE_GUARD_FOR_SHIFT),
            switchMap((action: AssignmentsActions.RateGuardForShift) => {

                return this.http.post(environment.apiUrl + `/shifts/${action.payload.shiftId}/guard-rates`, {
                               guardId: action.payload.guardId,
                               rate: action.payload.rate,
                           })
                           .pipe(
                               switchMap((resData: { data: RateModel }) => {
                                   this._snackBar.open('Rated successfully!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return [
                                       new AssignmentsActions.RateGuardForShiftSuccess({
                                           rate: resData.data,
                                           guardId: action.payload.guardId,
                                           shiftId: action.payload.shiftId,
                                       }),
                                       new AssignmentsActions.SelectShiftOnOpenAssignment(action.payload.shiftId),
                                   ];
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.RateGuardForShiftFailed());
                               }),
                           );
            }),
        );
    });

    loadAssignmentDetails = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_ASSIGNMENT_DETAILS),
            switchMap((action: AssignmentsActions.LoadAssignmentDetails) => {

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/assignments/${action.payload.assignmentsId}`)
                           .pipe(
                               switchMap((resData: { data: AssignmentModel }) => {
                                   return of(new AssignmentsActions.LoadAssignmentDetailsSuccess({
                                       assignment: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadAssignmentDetailsFailed());
                               }),
                           );
            }),
        );
    });

    cancelAssignment = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.CANCEL_ASSIGNMENT),
            switchMap((action: AssignmentsActions.CancelAssignment) => {

                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/assignments/${action.payload.assignmentId}/cancel`)
                           .pipe(
                               switchMap((resData: { data: AssignmentModel }) => {
                                   this._snackBar.open('Assignment canceled successfully', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });

                                   return of(new AssignmentsActions.CancelAssignmentSuccess({
                                       assignment: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   this._snackBar.open('Something went wrong, please try again.', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new AssignmentsActions.CancelAssignmentFailed());
                               }),
                           );
            }),
        );
    });

    addAssignment = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.ADD_ASSIGNMENT),
            switchMap((action: AssignmentsActions.AddAssignment) => {

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/assignments`, action.payload.data)
                           .pipe(
                               switchMap((resData: { data: AssignmentModel }) => {
                                   if (action.payload.data.status) {
                                       this._snackBar.open('Saved as draft!', 'Close', {
                                           duration: 3000,
                                           panelClass: ['snackbar'],
                                       });
                                   }
                                   return of(new AssignmentsActions.AddAssignmentSuccess({
                                       assignment: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   if (action.payload.data.status) {
                                       this._snackBar.open('Failed to save as draft!', 'Close', {
                                           duration: 3000,
                                           panelClass: ['snackbar'],
                                       });
                                   }
                                   else {
                                       this._snackBar.open('Failed to create this assignment! Please try again later', 'Close', {
                                           duration: 3000,
                                           panelClass: ['snackbar'],
                                       });
                                   }
                                   return of(new AssignmentsActions.AddAssignmentFailed());
                               }),
                           );
            }),
        );
    });

    addAssignmentAsDraft = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.ADD_ASSIGNMENT_AS_DRAFT),
            switchMap((action: AssignmentsActions.AddAssignmentAsDraft) => {
                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/assignments-as-draft`, action.payload.data)
                           .pipe(
                               switchMap((resData: { data: AssignmentModel }) => {

                                   if (action.payload.data.status) {
                                       this._snackBar.open('Saved as draft!', 'Close', {
                                           duration: 3000,
                                           panelClass: ['snackbar'],
                                       });
                                   }
                                   this.router.navigate(['/components/assignments/create-assignment/step1']);
                                   return of(new AssignmentsActions.AddAssignmentAsDraftSuccess({
                                       assignment: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   if (action.payload.data.status) {
                                       this._snackBar.open('Failed to save as draft!', 'Close', {
                                           duration: 3000,
                                           panelClass: ['snackbar'],
                                       });
                                   }
                                   else {
                                       this._snackBar.open('Failed to create this assignment! Please try again later', 'Close', {
                                           duration: 3000,
                                           panelClass: ['snackbar'],
                                       });
                                   }
                                   return of(new AssignmentsActions.AddAssignmentAsDraftFailed());
                               }),
                           );
            }),
        );
    });

    assignmentCreatedSuccess = createEffect(
        () => this.actions$.pipe(
            ofType(AssignmentsActions.ADD_ASSIGNMENT_SUCCESS),
            tap(() => this.router.navigate(['/components/assignments/assignment-ordered'])),
        ),
        {dispatch: false},
    );

    calculatePrice = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.CALCULATE_PRICE),
            withLatestFrom(this.store.select('assignments')),
            switchMap(([action, State]: [AssignmentsActions.CalculatePrice, State]) => {

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/assignments/calculate-price`, {
                               skillId: action.payload.skillId,
                               guardsNumber: action.payload.guardsNumber,
                               dates: action.payload.dates,
                               timezone: action.payload.timezone,
                               jobPlaceId: action.payload.jobPlaceId,
                           })
                           .pipe(
                               switchMap((resData: PriceModel) => {
                                   return of(new AssignmentsActions.CalculatePriceSuccess({
                                       price: resData,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.CalculatePriceFailed());
                               }),
                           );
            }),
        );
    });

    loadCategories = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_ASSIGNMENT_CATEGORIES),
            switchMap((action: AssignmentsActions.LoadAssignmentCategories) => {

                return this.http.get(environment.apiUrl + `/assignment-categories?sort=asc`)
                           .pipe(
                               switchMap((resData: { data: AssignmentCategoryModel[] }) => {
                                   return of(new AssignmentsActions.LoadAssignmentCategoriesSuccess({
                                       categories: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadAssignmentCategoriesFailed());
                               }),
                           );
            }),
        );
    });

    loadGuards = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_OBJECT_GUARDS),
            withLatestFrom(this.store.select('assignments')),
            switchMap(([action, State]: [AssignmentsActions.LoadObjectGuards, State]) => {
                let params = new HttpParams();
                params = params.append('page', State.guardsMeta.page);
                params = params.append('per_page', State.guardsMeta.perPage);

                return this.http.post(environment.apiUrl + `/job-places/${action.payload.jobPlaceId}/guards-available-for-assignment`, {
                               skillId: action.payload.skillId,
                               uniformId: action.payload.uniformId,
                               dates: action.payload.dates,
                               timezone: action.payload.timezone,
                           }, {params})
                           .pipe(
                               switchMap((resData: { data: GuardModel[], pagination: PaginationModel }) => {
                                   return of(new AssignmentsActions.LoadObjectGuardsSuccess({
                                       guards: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadObjectGuardsFailed());
                               }),
                           );
            }),
        );
    });

    loadFavGuards = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_FAVOURITE_GUARDS),
            withLatestFrom(this.store.select('assignments')),
            switchMap(([action, State]: [AssignmentsActions.LoadFavouriteGuards, State]) => {

                let params = new HttpParams();
                params = params.append('page', State.favouritesMeta.page);
                params = params.append('per_page', State.favouritesMeta.perPage);

                if (action.payload.params) {
                    action.payload.params.forEach(param => {
                        params = params.append(param.key, param.value);
                    });
                }
                return this.http.get(environment.apiUrl + `/job-places/${action.payload.jobPlaceId}/guards`, {params})
                           .pipe(
                               switchMap((resData: { data: GuardModel[], pagination: PaginationModel }) => {
                                   return of(new AssignmentsActions.LoadFavouriteGuardsSuccess({
                                       favouriteGuards: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadFavouriteGuardsFailed());
                               }),
                           );
            }),
        );
    });

    loadFavGuardsToAdd = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.LOAD_FAVOURITE_GUARDS_TO_ADD),
            withLatestFrom(this.store.select('assignments')),
            switchMap(([action, State]: [AssignmentsActions.LoadFavouriteGuardsToAdd, State]) => {

                let params = new HttpParams();
                params = params.append('page', State.guardsToAddMeta.page);
                params = params.append('per_page', State.guardsToAddMeta.perPage);

                if (action.payload.params) {
                    action.payload.params.forEach(param => {
                        params = params.append(param.key, param.value);
                    });
                }

                return this.http.get(environment.apiUrl + `/job-places/${action.payload.jobPlaceId}/available-guards`, {params})
                           .pipe(
                               switchMap((resData: { data: GuardModel[], pagination: PaginationModel }) => {
                                   return of(new AssignmentsActions.LoadFavouriteGuardsToAddSuccess({
                                       guards: resData.data,
                                       pagination: resData.pagination,
                                   }));
                               }),
                               catchError(error => {
                                   return of(new AssignmentsActions.LoadFavouriteGuardsToAddFailed());
                               }),
                           );
            }),
        );
    });

    deleteFavGuard = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.DELETE_FAVOURITE_GUARD),
            withLatestFrom(this.store.select('assignments')),
            switchMap(([action, State]: [AssignmentsActions.DeleteFavouriteGuard, State]) => {

                return this.http.delete(environment.apiUrl + `/job-places/${action.payload.jobPlaceId}/guards/${action.payload.guardId}`)
                           .pipe(
                               switchMap((resData: { data: GuardModel }) => {
                                   this._snackBar.open('Guard successfully removed!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new AssignmentsActions.DeleteFavouriteGuardSuccess({
                                       deletedGuard: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   this._snackBar.open('Failed to remove this guard!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new AssignmentsActions.DeleteFavouriteGuardFailed());
                               }),
                           );
            }),
        );
    });

    addFavGuard = createEffect(() => {
        return this.actions$.pipe(
            ofType(AssignmentsActions.ADD_GUARDS_TO_FAVOURITES),
            withLatestFrom(this.store.select('assignments')),
            switchMap(([action, State]: [AssignmentsActions.AddGuardsToFavourites, State]) => {

                return this.http.put(environment.apiUrl + `/job-places/${action.payload.jobPlaceId}/guards`, {
                               guardsIds: action.payload.guardsIds,
                           })
                           .pipe(
                               switchMap((resData: { data: GuardModel[] }) => {
                                   this._snackBar.open('Successfully added as favourites!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new AssignmentsActions.AddGuardsToFavouritesSuccess({
                                       guards: resData.data,
                                   }));
                               }),
                               catchError(error => {
                                   this._snackBar.open('Failed to add as favourites!', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar'],
                                   });
                                   return of(new AssignmentsActions.AddGuardsToFavouritesFailed());
                               }),
                           );
            }),
        );
    });

}
