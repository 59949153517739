<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="flexed">
                    <div class="back" routerLink="/components/my-staff/invite">
                        <img src="/assets/img/back arrow.svg">
                        <p class="back-p">{{'back' | translate}}</p>
                    </div>
                    <p class="faded-p">{{'staff.title' | translate}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{'staff.invite-staff.title' | translate}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{'staff.invite-staff.enter-details' | translate}}</p>
                </div>
                <h1>{{'staff.invite-staff.enter-details' | translate}}</h1>
<!--                <p class="mt-3">Inviting to “Security guard”.</p>-->

                <!-- <div class="d-flex justify-content-start gap-2 import-wrapper mt-4" (click)="uploadCsvFileModal = true">-->
                <!--     <img src="/assets/img/upload-icon.png" alt="">-->
                <!--    <p>Import with CSV-file</p>-->
                <!-- </div>-->

                <!--Editable table-->
                <table class="table table-bordered table-responsive-md text-center">
                    <tr>
                        <th class="text-center index">
                            <div class="flexed">#</div>
                        </th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.firstName' | translate}}
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.lastName' | translate}}
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed">
                                {{'table.thead.email' | translate}}
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed justify-content-between">
                                {{'table.thead.person-id' | translate}} ({{'12 characters' | translate}})
                                <div class="blue-circle"></div>
                            </div>
                        </th>
                        <th class="text-center">
                            <div class="flexed justify-content-between">
                                {{'table.thead.phone' | translate}}
                                <div class="blue-circle"></div>
                            </div>

                        </th>
                    </tr>
                    <tr *ngFor="let i of [1,2,3,4,5]; let id = index">
                        <td class="index">
                            <span style="color: #C1C1C1;">{{i}}</span>
                        </td>
                        <td>
                            <div class="editable" (keyup)="addToObject(id, 'firstName',$event.target.textContent)"
                                 contenteditable="true"></div>
                        </td>
                        <td>
                            <div class="editable" (keyup)="addToObject(id, 'lastName',$event.target.textContent)"
                                 contenteditable="true"></div>
                        </td>
                        <td [ngClass]="isInInvalidEmails(id) ? 'invalid-filed' : ''">
                            <div class="editable"
                                 (keyup)="isValidEmail($event.target.textContent); addToObject(id, 'email',$event.target.textContent)"
                                 contenteditable="true"></div>
                        </td>
                        <td [ngClass]="isInInvalidIds(id) ? 'invalid-filed' : ''">
                            <div class="editable" (keyup)="addToObject(id, 'personId',$event.target.textContent)"
                                 contenteditable="true"></div>
                        </td>
                        <td>
                            <div class="editable" (keyup)="addToObject(id, 'phone',$event.target.textContent)"
                                 contenteditable="true"></div>
                        </td>
                    </tr>
                </table>
                <!--/Editable table-->
                <div class="d-flex mt-4">
                    <div class="blue-circle"></div>
                    <p class="mandatory-filed-p">- {{'company.invite.mandatory-filed' | translate}}</p>
                </div>
                <div [ngClass]="isDisabledButton() ? 'disabled' : ''" class="btn btn-primary my-button"
                     (click)="onNextStep()">
                    <p>{{'staff.invite-staff.send' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<!---Csv file modal--->
<app-modal [modalType]="1" *ngIf="uploadCsvFileModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title">Upload CSV-file</h3>
            <span (click)="uploadCsvFileModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <div class="upload-wrapper">
            <p>Uploading a file will clear already entered information.</p>

            <div class="upload-error">
                <p class="mb-0">
                    Error: We couldn’t process the file.<b>Unrecognized column “First name”.</b>
                    Make sure to format the file as the guide below and try again.
                    Need help? <b>Contact support</b>
                </p>
            </div>

            <input type="file" #browse class="d-none" accept="text/csv">
            <div class="browse-button" (click)="browse.click()">
                <img src="/assets/img/cloud-upload.png" alt="">
                <p>Browse for file</p>
            </div>
        </div>
        <h3>How to format your file</h3>
        <p>
            Bring to the table win-win survival strategies to ensure proactive domination.
            At the end of the day, going forward, a new normal that has evolved.
            Generation X is on the runway heading towards a streamlined cloud solution.
            User generated content in real-time will have multiple touchpoints for offshoring.
        </p>
        <img src="/assets/img/csv-example.png" alt="">

    </div>

</app-modal>
