<div class="main-wrapper">
    <div class="row main-row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="header">
                    <div class="flexed">
                        <img src="/assets/img/safetly logo.png">
                        <h3>{{'safetly' | translate}}</h3>
                    </div>
                </div>
                <div class="body">
                    <h1>{{'auth.login.check-email' | translate}}</h1>
                    <p class="suggestion-p">
                        {{'auth.login.sent-link' | translate}} {{this?.email}}
                    </p>
                    <p class="suggestion-p-2">
                        {{'auth.login.click-on-link' | translate}}
                    </p>
                    <p class="login-password-p" routerLink="/login-password">{{'auth.login.login-password' | translate}}</p>
                </div>
                <div class="footer">
                    <div class="btn my-button help-button btn-primary">
                        <img src="/assets/img/question-circle 1.svg">
                        <span>{{'help' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
