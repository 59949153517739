import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AssignmentStatusConst {
    public DRAFT = 1;
    public PUBLISH = 2;
    public ONGOING = 3;
    public FINISHED = 4;
}