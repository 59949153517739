import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Form, FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import {CustomerModel} from "../../models/customer.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";
import {SkillModel} from "../../models/skill.model";
import * as MyStaffActions from './store/my-staff.actions';
import {GuardModel} from "../../models/guard.model";
import {PaginationModel} from "../../models/pagination.model";
import {GuardInviteModel} from "../../models/guardInvite.model";

@Component({
    selector: 'app-my-staff',
    templateUrl: './my-staff.component.html',
    styleUrls: ['./my-staff.component.scss']
})
export class MyStaffComponent implements OnInit, OnDestroy {

    public subscription: Subscription;
    public customer: CustomerModel;
    public skills: SkillModel[];
    public guards: GuardInviteModel[];
    public pagination: PaginationModel;

    public params: {
        key: string,
        value: string
    }[] = [];

    public filterForm = new FormGroup({
        status: new FormControl('0'),
        skills: new FormControl('0'),
    });

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.skills = appState.shared.skills;
            this.guards = appState.staff.guards;
            this.pagination = appState.staff.pagination;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onNextPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new MyStaffActions.LoadGuards({
                customerId: this.customer.id,
                page: this.pagination.currentPage + 1,
                params: this.params
            }));
        }
    }

    onPreviousPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new MyStaffActions.LoadGuards({
                customerId: this.customer.id,
                page: this.pagination.currentPage - 1,
                params: this.params
            }));
        }
    }

    onFirstPage(): void {
        if (this.pagination.currentPage !== 1) {
            this.store.dispatch(new MyStaffActions.LoadGuards({
                customerId: this.customer.id,
                page: 1,
                params: this.params
            }));
        }
    }

    onLastPage(): void {
        if (this.pagination.currentPage !== this.pagination.lastPage) {
            this.store.dispatch(new MyStaffActions.LoadGuards({
                customerId: this.customer.id,
                page: this.pagination.lastPage,
                params: this.params
            }));
        }
    }

    public onFilter(key: string, value: any): void {
        if (value == 0) {
            this.params = this.params.filter(el => el.key != key);
        } else {
            this.params = this.params.filter(el => el.key != key);
            this.params.push({key: key, value: value})
        }
        this.store.dispatch(new MyStaffActions.ClearGuards());
        this.store.dispatch(new MyStaffActions.LoadGuards({
            customerId: this?.customer?.id,
            page: 1,
            params: this.params
        }));
    }

    public onClearFilters(): void {
        this.params = [];
        this.filterForm.controls['skills'].setValue(0);
        this.filterForm.controls['status'].setValue(0);
        this.store.dispatch(new MyStaffActions.ClearGuards());
        this.store.dispatch(new MyStaffActions.LoadGuards({
            customerId: this?.customer?.id,
            page: 1,
            params: this.params
        }));
    }

    public onGuardClicked(guardInvite: GuardInviteModel): void {
        if(guardInvite.guard && guardInvite.status == 2){
            this.router.navigate([`/components/my-staff/profile/${guardInvite.guard.id}`]);
        }
    }

}
