<div class="step-main-wrapper">
    <h1>{{'create-assignment.about' | translate}}</h1>
    <div class="wrapper">
        <div class="row">
            <div class="col-12 col-lg-6">
                <h3>{{'create-assignment.object' | translate}}</h3>
                <form [formGroup]="this.form">
                    <select class="form-select" formControlName="object" (change)="onChangeObject($event)">
                        <option *ngFor="let object of this?.objects"
                                [ngValue]="object.id">
                            {{object?.name}}, {{object?.address}}</option>
                    </select>
                </form>
                <div class="flexed-add" (click)="onCreateObjectClicked()">
                    <img src="/assets/img/plus.svg" alt="">
                    <p class="add-p">{{'create-assignment.add-object' | translate}}</p>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <google-map height="160px" width="100%"
                            [zoom]="zoom"
                            [options]="options"
                            [center]="center"
                            (mapClick)="onChangeObject($event)">
                    <map-marker [position]="marker.position"></map-marker>
                </google-map>
            </div>
        </div>
        <div class="d-flex">
            <h3>{{'create-assignment.assignment-description' | translate}}</h3>
            <p class="faded-p">&nbsp;-&nbsp;{{'create-assignment.optional' | translate}}</p>
        </div>
        <textarea [(ngModel)]="description"></textarea>
    </div>
    <div class="btn btn-primary my-button" (click)="onSaveAndContinue()">
        <div class="d-flex">
            <p>{{'buttons.save&continue' | translate}}</p>
            <img src="/assets/img/arrow white.svg">
        </div>
    </div>
</div>

<!--Create object Modal-->
<app-modal *ngIf="this.createObjectModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">{{'create-assignment.create-object' | translate}}</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.createObjectModal = false"
                      class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">
        <form [formGroup]="createObjectForm" (ngSubmit)="onCreateObjectSubmit()"
              id="createObjectForm">
            <div class="form-group">
                <label for="name">{{'create-assignment.object-name' | translate}}</label>
                <input class="form-control"
                       type="text"
                       id="name"
                       formControlName="name"
                       autocomplete="nope"/>
            </div>
            <!--<div class="form-group">-->
                <!--<label for="state">{{'create-assignment.object-state' | translate}}</label>-->
                <!--<select class="form-select"-->
                        <!--formControlName="state"-->
                        <!--id="state"-->
                        <!--(change)="onChangeState()">-->
                    <!--<option *ngFor="let state of states; let i = index"-->
                            <!--[ngValue]="state">{{state.name}}</option>-->
                <!--</select>-->
            <!--</div>-->
            <div class="form-group position-relative">
                <label for="address">{{'create-assignment.object-address' | translate}}</label>
                <label for="address">{{'company.objects.address' | translate}}</label>
                <input ngx-google-places-autocomplete
                       class="form-control"
                       type="text"
                       id="address"
                       formControlName="address"
                       [options]="{componentRestrictions: {country: 'SE'}, types: ['address']}"
                       (onAddressChange)="handleAddressChange($event)" />
                <!--<input class="form-control" type="text" id="address"-->
                       <!--(keyup)="keyUpAddress($event)"-->
                       <!--autocomplete="nope"-->
                       <!--formControlName="address"/>-->

                <!--<div class="select-list-address" *ngIf="addressSelectList">-->
                        <!--<div *ngFor="let here of this?.hereSuggestions" class="select-item"-->
                             <!--(click)="onPickAddress(here)">-->
                            <!--<div role="button">-->
                                <!--<p class="item-title">{{here?.label}}</p>-->
                                <!--&lt;!&ndash;                            <p class="item-title">{{here?.address?.street}}</p>&ndash;&gt;-->
                                <!--&lt;!&ndash;                            <p class="item-location">{{here?.address?.city}}</p>&ndash;&gt;-->
                            <!--</div>-->
                            <!--&lt;!&ndash;&#45;&#45;*ngIf="this?.regionsInSelectBox?.length > 0" &#45;&#45;^ &ndash;&gt;-->
                        <!--</div>-->
                    <!--</div>-->
            </div>
        </form>
        <!--TAB-->
        <ng-template *ngIf="false">
            <div class="tab">
                <div class="row">
                    <!--                            <div class="col-12 col-lg-3">-->
                    <!--                                <p [ngClass]="this.objectsTabActive ? 'tab-active' : ''"-->
                    <!--                                   class="tab-item-title" (click)="onObjectsTab()">In regions ({{this?.regionsInList?.length}})</p>-->
                    <!--                                <div *ngIf="this.objectsTabActive" class="blue-line"></div>-->
                    <!--                            </div>-->
                    <div class="col-12 col-lg-3">
                        <p [ngClass]="this.teamTabActive ? 'tab-active' : ''"
                           class="tab-item-title" (click)="onTeamTab()">{{'create-assignment.team' | translate}}
                            (6)</p>
                        <div *ngIf="this.teamTabActive" class="blue-line"></div>
                    </div>
                </div>
                <div class="line"></div>
            </div>
            <!--/TAB-->
            <!--regions-->
            <!--                    <div *ngIf="this.objectsTabActive" class="objects-list">-->
            <!--                        <div class="list">-->
            <!--                            <div *ngFor="let region of this?.regionsInList" class="list-item">-->
            <!--                                <div class="d-flex justify-content-between flex-row gap-1">-->
            <!--                                    <p class="item-title">{{region?.name}}</p>-->
            <!--                                    <img src="/assets/img/Close.svg" (click)="onRemoveRegionFromList(region)">-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                            &lt;!&ndash;add&ndash;&gt;-->
            <!--                            <div *ngIf="this.addRegionField" class="list-item add">-->
            <!--                                <div class="d-flex justify-content-between gap-1">-->
            <!--                                    <input [(ngModel)]="regionInput" (keyup)="keyUpRegions($event)"-->
            <!--                                           type="text"-->
            <!--                                           class="add-obj-input"-->
            <!--                                           placeholder="Type here"-->
            <!--                                           (focus)="regionSelectList = true">-->
            <!--                                    <img src="/assets/img/Close.svg" (click)="this.addRegionField = false;">-->

            <!--                                    <div class="select-list" *ngIf="regionSelectList">-->
            <!--                                        <div *ngFor="let region of this?.regionsInSelectBox" class="select-item" (click)="onAddRegionToList(region)">-->
            <!--                                            <div *ngIf="this?.regionsInSelectBox?.length > 0">-->
            <!--                                                <p class="item-title">{{region?.name}}</p>-->
            <!--                                            </div>-->
            <!--                                            <div *ngIf="this?.regionsInSelectBox?.length == 0">-->
            <!--                                                <p class="item-location">No results</p>-->
            <!--                                            </div>-->
            <!--                                        </div>-->
            <!--                                    </div>-->
            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                        <div class="d-flex justify-content-start gap-1 mt-2 add" (click)="onAddRegion()">-->
            <!--                            <img src="/assets/img/plus.svg">-->
            <!--                            <p class="add-p">Add region</p>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--team-->
            <div *ngIf="this.teamTabActive" class="objects-list">
                <div class="list">
                    <div *ngFor="let i of [1,2,3,4,5]" class="list-item">
                        <div class="d-flex justify-content-between flex-row gap-1">
                            <div>
                                <p class="item-title">Rikard Malm</p>
                                <p class="item-location">rikardmalm@safetly.se</p>
                            </div>
                            <img src="/assets/img/Close.svg">
                        </div>
                    </div>
                    <!--add-->
                    <div *ngIf="this.addTeamField" class="list-item add">
                        <div class="d-flex justify-content-between gap-1">
                            <input type="text"
                                   class="add-obj-input"
                                   placeholder="Type to add team member">
                            <img src="/assets/img/Close.svg" (click)="this.addTeamField = false;">
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-start gap-1 mt-2 add" (click)="onAddTeamMember()">
                    <img src="/assets/img/plus.svg">
                    <p class="add-p">{{'create-assignment.add-team-member' | translate}}</p>
                </div>
            </div>
        </ng-template>

    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.createObjectModal = false;">{{'buttons.cancel'| translate}}</span>
                    <input type="submit"
                           form="createObjectForm"
                           value="{{'buttons.save-changes'| translate}}"
                           [disabled]="!this.createObjectForm.valid"
                           class="btn save-btn btn-primary"/>
                    <!---|| this.regionsInList.length == 0--->
                </div>
            </div>
        </div>
    </div>
</app-modal>
