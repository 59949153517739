<div class="overlay">
    <ng-container [ngSwitch]="modalType">
        <ng-container *ngSwitchCase="1">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
