import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromApp from './../../store/app.reducer';
import * as AuthActions from '../../auth/store/auth.actions';
import {EmployeeModel} from "../../models/employee.model";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

    public employee: EmployeeModel;
    storeSub: Subscription;

    public changeNameModal: boolean = false;
    public changeEmailModal: boolean = false;
    public changePhoneModal: boolean = false;
    public changePasswordModal: boolean = false;
    public passwordMatching: boolean = true;

    public showPasswords: boolean = false;

    public changeNameForm = this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
    });
    public changeEmailForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
    });
    public changePhoneForm = this.formBuilder.group({
        phone: ['', [Validators.required]]
    });
    public changePasswordForm = this.formBuilder.group({
        currentPassword: ['', [Validators.required, Validators.minLength(6)]],
        newPassword: ['', [Validators.required, Validators.minLength(6)]],
        newPassword2: ['', [Validators.required, Validators.minLength(6)]],
    });

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.storeSub = this.store.subscribe(appState => {
            this.employee = appState.auth.employee;
        });
    }

    ngOnDestroy(): void {
        this.storeSub.unsubscribe();
    }

    get firstName() {
        return this.changeNameForm.get('firstName');
    }

    get lastName() {
        return this.changeNameForm.get('lastName');
    }

    get email() {
        return this.changeEmailForm.get('email');
    }

    get phone() {
        return this.changePhoneForm.get('phone');
    }

    get currentPassword() {
        return this.changePasswordForm.get('currentPassword');
    }

    get newPassword() {
        return this.changePasswordForm.get('newPassword');
    }

    get newPassword2() {
        return this.changePasswordForm.get('newPassword2');
    }

    public onOpenEditNameModal(): void {
        this.changeNameModal = true;
        if (this?.employee?.firstName) {
            this.changeNameForm.controls['firstName'].setValue(this?.employee?.firstName);
            this.changeNameForm.controls['lastName'].setValue(this?.employee?.lastName);
        }
    }

    public onChangeName(): void {
        this.store.dispatch(new AuthActions.UpdateProfile({
            employeeId: this?.employee?.id,
            firstName: this.changeNameForm.get('firstName').value,
            lastName: this.changeNameForm.get('lastName').value
        }));
        this.changeNameModal = false;
    }

    public onOpenEditEmailModal(): void {
        this.changeEmailForm.controls['email'].setValue(this?.employee?.email);
        this.changeEmailModal = true;
    }

    public onChangeEmail(): void {
        this.store.dispatch(new AuthActions.VerifyNewEmail({
            employeeId: this.employee.id,
            email: this.changeEmailForm.get('email').value
        }));
        this.changeEmailModal = false;
    }

    public onOpenEditPhoneModal(): void {
        if (this?.employee?.phone) {
            this.changePhoneForm.controls['phone'].setValue(this?.employee?.phone);
        }
        this.changePhoneModal = true;
    }

    public onChangePhone(): void {
        this.store.dispatch(new AuthActions.UpdateProfile({
            employeeId: this?.employee?.id,
            phone: this.changePhoneForm.get('phone').value
        }));
        this.changePhoneModal = false;
    }

    public onOpenPasswordModal(): void {
        this.changePasswordForm.reset();
        this.passwordMatching = true;
        this.changePasswordModal = true;
    }

    public onChangePassword(): void {
        if (this.changePasswordForm.get('newPassword').value !== this.changePasswordForm.get('newPassword2').value) {
            this.passwordMatching = false;
            return;
        }
        // console.log(this.changePasswordForm.get('currentPassword').value, this.changePasswordForm.get('newPassword').value, this.changePasswordForm.get('newPassword2').value)
        this.store.dispatch(new AuthActions.ChangePassword({
            employeeId: this?.employee?.id,
            oldPassword: this.changePasswordForm.get('currentPassword').value,
            password: this.changePasswordForm.get('newPassword').value,
            confirmPassword: this.changePasswordForm.get('newPassword2').value,
        }));
        this.changePasswordModal = false;
    }

    public onChangeApplicationLanguage(language: string): void{
        this.store.dispatch(new AuthActions.UpdateProfile({
            employeeId: this?.employee?.id,
            locale: language
        }));
    }

    public onLogout(): void {
        this.store.dispatch(new AuthActions.Logout());
        this.router.navigate(['/login-password']);
    }
}
