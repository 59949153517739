import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../models/customer.model';
import { SkillModel } from '../../../models/skill.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GuardModel } from '../../../models/guard.model';
import * as MyStaffActions from '../store/my-staff.actions';
import * as moment from 'moment';
import { PaginationModel } from '../../../models/pagination.model';

@Component({
    selector: 'app-payroll',
    templateUrl: './payroll.component.html',
    styleUrls: ['./payroll.component.scss'],
})
export class PayrollComponent implements OnInit, OnDestroy {

    @ViewChild('searchInput') searchInput: ElementRef;
    public subscription: Subscription;
    public customer: CustomerModel;
    public skills: SkillModel[];
    public hiddenRow = false;
    public openedRow = null;
    pagination: PaginationModel = null;
    guards: GuardModel[] = [];
    public params: {
        key: string,
        value: string
    }[] = [];
    page = 1;
    public filterForm = new FormGroup({
        period: new FormControl('0'),
        skills: new FormControl('0'),
    });

    periods = [
        {
            id: 0,
            label: 'All',
        },
        {
            id: 1,
            label: 'This month',
            fromTime: moment().startOf('month').unix(),
            toTime: moment().endOf('month').unix(),
        },
        {
            id: 2,
            label: 'Last month',
            fromTime: moment().subtract(1, 'month').startOf('month').unix(),
            toTime: moment().subtract(1, 'month').endOf('month').unix(),
        },
        {
            id: 3,
            label: 'This year',
            fromTime: moment().startOf('year').unix(),
            toTime: moment().endOf('year').unix(),
        },
        {
            id: 4,
            label: 'Last year',
            fromTime: moment().subtract(1, 'year').startOf('month').unix(),
            toTime: moment().subtract(1, 'year').endOf('month').unix(),
        },
    ];

    filters = {
        searchTerm: null,
        fromTime: null,
        toTime: null,
        skillId: null,
    };


    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.skills = appState.shared.skills.flatMap((item) => {
                return [...item.children];
            });
            this.guards = appState.staff.guardsWithPayrolls;
            this.pagination = appState.staff.pagination;

        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onClickPagination(page) {
        this.dispatchLoadGuardsWithPayrollsAction(page);
    }
    onNextPage(): void {
        // if (this.pagination.currentPage !== this.pagination.lastPage) {
        //     this.store.dispatch(new AssignmentsActions.LoadAssignments({
        //         customerId: this.customer.id,
        //         page: this.pagination.currentPage + 1,
        //         params: this.params
        //     }));
        // }
    }

    onPreviousPage(): void {
        // if (this.pagination.currentPage !== 1) {
        //     this.store.dispatch(new AssignmentsActions.LoadAssignments({
        //         customerId: this.customer.id,
        //         page: this.pagination.currentPage - 1,
        //         params: this.params
        //     }));
        // }
    }

    onFirstPage(): void {
        // if (this.pagination.currentPage !== 1) {
        //     this.store.dispatch(new AssignmentsActions.LoadAssignments({
        //         customerId: this.customer.id,
        //         page: 1,
        //         params: this.params
        //     }));
        // }
    }

    onLastPage(): void {
        // if (this.pagination.currentPage !== this.pagination.lastPage) {
        //     this.store.dispatch(new AssignmentsActions.LoadAssignments({
        //         customerId: this.customer.id,
        //         page: this.pagination.lastPage,
        //         params: this.params
        //     }));
        // }
    }

    // public onRowClicked(id: number): void {
    //     // this.hiddenRow = !this.hiddenRow;
    //     if (this.openedRow != null && this.openedRow == id) {
    //         this.openedRow = null;
    //     } else {
    //         this.openedRow = id;
    //     }
    // }

    public onFilter(key: string, value: any): void {

        switch (key) {
            case 'period':
                this.filters.fromTime = this.periods[value].fromTime;
                this.filters.toTime = this.periods[value].toTime;
                break;
            default:
                this.filters[key] = value > 0 ? value : null;
                break;
        }

        // if (value == 0) {
        //     this.params = this.params.filter(el => el.key != key);
        // } else {
        //     this.params = this.params.filter(el => el.key != key);
        //     this.params.push({key: key, value: value})
        // }


        this.dispatchLoadGuardsWithPayrollsAction(1);


        // this.store.dispatch(new AssignmentsActions.ClearAssignments());
        // this.store.dispatch(new AssignmentsActions.LoadAssignments({customerId: 1, page: 1, params: this.params}));
    }

    public onClearFilters(): void {
        this.params = [];
        this.filterForm.controls['skills'].setValue(0);
        this.filterForm.controls['period'].setValue(0);
        for(const property in this.filters) {
            this.filters[property] = null;
        }

        this.searchInput.nativeElement.value = null;

        this.page = 1;
        this.dispatchLoadGuardsWithPayrollsAction(1);
        // this.store.dispatch(new AssignmentsActions.ClearAssignments());
        // this.store.dispatch(new AssignmentsActions.LoadAssignments({customerId: 1, page: 1, params: this.params}));
    }

    searchTermTimeout: any = null;

    keyUpSearch(event: any) {
        if (event.target.value == '') {
            this.searchTermTimeout = false;
            return;
        }

        clearTimeout(this.searchTermTimeout);
        this.searchTermTimeout = setTimeout(() => {
            this.filters.searchTerm = event.target.value;
            this.dispatchLoadGuardsWithPayrollsAction(1);
        }, 1000);
    }

    getBodyFilters() {
        return Object.fromEntries(Object.entries(this.filters).filter(([_, v]) => v != null))
    }

    dispatchLoadGuardsWithPayrollsAction(page) {
        this.store.dispatch(new MyStaffActions.LoadGuardsWithPayrolls({
            customerId: this.customer.id,
            page: page,
            body: this.getBodyFilters(),
        }));
    }
}
