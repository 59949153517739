import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import {Subscription} from "rxjs";
import {CustomerModel} from "../../models/customer.model";

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit,  OnDestroy {

    public users: boolean = false;
    public billing: boolean = false;
    public regions: boolean = false;
    public settings: boolean = false;

    public customer: CustomerModel;
    public subscription: Subscription;

    constructor(private router: Router,
                private store: Store<fromApp.AppState>,) {
    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
        });
        //paths
        if (this.router.url == "/components/company/users") {
            this.users = true;
        }
        if (this.router.url == "/components/company/billing") {
            this.billing = true;
        }
        if (this.router.url.includes("components/company/regions")) {
            this.regions = true;
        }
        if (this.router.url.includes("components/company/settings")) {
            this.settings = true;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onUsersClicked(): void {
        this.users = true;
        this.billing = false;
        this.regions = false;
        this.settings = false;
    }

    public onBillingClicked(): void {
        this.users = false;
        this.billing = true;
        this.regions = false;
        this.settings = false;
    }

    public onRegionsClicked(): void {
        this.users = false;
        this.billing = false;
        this.regions = true;
        this.settings = false;
    }

    public onSettingsClicked(): void {
        this.users = false;
        this.billing = false;
        this.regions = false;
        this.settings = true;
    }


}
