<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

                <div class="d-flex">
                    <div class="back" routerLink="/components/my-staff">
                        <img src="/assets/img/back arrow.svg">
                        <p class="back-p">{{'back' | translate}}</p>
                    </div>
                    <p class="faded-p">{{'staff.title' | translate}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{guard?.firstName}} {{guard?.lastName}}</p>
                </div>

                <div class="d-block d-sm-flex justify-content-between gap-3 mt-4">
                    <div class="d-flex justify-content-start gap-sm-3 gap-1">
                        <h1>{{guard?.firstName}} {{guard?.lastName}}</h1>
                        <div class="pill active" *ngIf="guard?.active">
                            <span>{{'staff.invite-status.2' | translate}}</span>
                        </div>
                    </div>
                    <p class="delete-p" (click)="deleteModal = true;">{{'staff.profile.delete' | translate}}</p>
                </div>

<!--                <div class="blue-wrapper d-lg-flex d-block justify-content-between gap-3">-->
<!--                    <div class="mt-auto mb-auto">-->
<!--                        <h4><b>Approve certificate to make personnel active.</b></h4>-->
<!--                        <div class="d-flex justify-content-start gap-1 mt-1">-->
<!--                            <img src="/assets/img/download-file.svg" alt="">-->
<!--                            <p class="mt-auto mb-auto blue-p">View certificate</p>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                    <div class="d-block d-sm-flex justify-content-start gap-2 mt-auto mb-auto">-->
<!--                        <p class="faded-p mt-auto mb-auto pointer" (click)="declineCertificateModal = true">Decline-->
<!--                            certificate</p>-->
<!--                        <div class="btn btn-primary my-button mt-auto mb-auto" (click)="approveCertificateModal = true">-->
<!--                            <p>Approve certificate</p>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="wrapper d-flex justify-content-start gap-3 gap-md-5 mt-4">
                    <div class="content-left">
                        <img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}" class="profile-img">
                        <!--<img class="profile-img" src="/assets/img/mystaff-profile.png" alt="">-->
<!--                        <div class="d-none d-md-flex justify-content-start gap-1 mt-4">-->
<!--                            <img src="/assets/img/download-file.svg" alt="">-->
<!--                            <p class="mt-auto mb-auto blue-p">View certificate</p>-->
<!--                        </div>-->
                    </div>
                    <div class="content-right">
                        <h4><b>{{'staff.profile.about' | translate}}</b></h4>
                        <div class="d-flex justify-content-start gap-3 mt-2 mb-2 w-100 flex-wrap">
                            <div class="mr-5">
                                <p class="faded-p mt-0 mb-0">{{'staff.profile.id' | translate}}</p>
                                <p class="mt-0 mb-0">{{guard?.id}}</p>
                            </div>
                            <div class="mr-5">
                                <p class="faded-p mt-0 mb-0">{{'staff.profile.phone' | translate}}</p>
                                <p class="mt-0 mb-0" *ngIf="guard?.phone; else noData">{{guard?.phone}}</p>
                            </div>
                            <div class="mr-5">
                                <p class="faded-p mt-0 mb-0">{{'staff.profile.email' | translate}}</p>
                                <p class="mt-0 mb-0" *ngIf="guard?.email; else noData">{{guard?.email}}</p>
                            </div>
                            <p class="blue-p mt-auto mb-auto" (click)="onEditContactInfo()"><b>{{'staff.profile.edit' | translate}}</b></p>
                        </div>

                        <h4 class="mt-4"><b>{{'staff.profile.competences' | translate}}</b></h4>
                        <div class="d-flex justify-content-start gap-3 mt-2 mb-2 w-100 flex-wrap">
                            <div class="skill" *ngFor="let skill of guard?.skills">
                                <span>{{skill?.name}}</span>
                            </div>
                            <p class="blue-p mt-auto mb-auto" (click)="onEditCompetences()"><b>{{'staff.profile.edit' | translate}}</b></p>
                        </div>

                        <h4 class="mt-4 mb-2"><b>{{'staff.profile.billing' | translate}}</b></h4>
                        <div class="d-flex justify-content-start gap-3  w-100 flex-wrap">
                            <div class="mr-5">
                                <p class="faded-p mt-0 mb-0">{{'staff.profile.address' | translate}}</p>
                                <p class="mt-0 mb-0" *ngIf="guard?.address; else noData">{{guard?.address}}</p>
                            </div>
                            <!--<div class="mr-5">-->
                                <!--<p class="faded-p mt-0 mb-0">{{'staff.profile.postal-code' | translate}}</p>-->
                                <!--<p class="mt-0 mb-0">125 33</p>-->
                            <!--</div>-->
                            <div class="mr-5">
                                <p class="faded-p mt-0 mb-0">{{'staff.profile.city' | translate}}</p>
                                <p class="mt-0 mb-0" *ngIf="guard?.city; else noData">{{guard?.city}}</p>
                            </div>
                            <div class="mr-5">
                                <p class="faded-p mt-0 mb-0">{{'staff.profile.bank-account' | translate}}</p>
                                <p class="mt-0 mb-0">{{guard.bankAccount}}</p>
                            </div>
                            <div class="mr-5">
                                <p class="faded-p mt-0 mb-0">{{'staff.profile.bank-name' | translate}}</p>
                                <p class="mt-0 mb-0">{{guard.bankName}}</p>
                            </div>
                        </div>
                        <p class="blue-p mt-4" (click)="onEditBilling()"><b>{{'staff.profile.edit-billing' | translate}}</b></p>
                    </div>
                </div>

                <div class="wrapper">
                    <h3>{{'staff.profile.assignments' | translate}}</h3>
                    <!-- TABLE -->
                    <table class="table table-hover">
                        <tbody>
                        <tr *ngFor="let payslipItem of guardPayslipItems">
                            <td>{{payslipItem.startAt | date: 'd MMMM y'}}</td>
                            <td>{{payslipItem.startAt | date: 'HH:mm'}} - {{payslipItem.endAt | date: 'HH:mm'}}</td>
                            <td>{{payslipItem.skill.name}}</td>
                            <td>{{payslipItem.hourlyRate}} SEK</td>
                            <td class="text-right">{{payslipItem.brutoSalary}} SEK</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan="5">
                                <div class="showing">
                                    <img class="left-arrows" src="/assets/img/Arrow-left end.svg">
                                    <img class="left-arrows" src="/assets/img/Arrow-left.svg">
                                    <p>{{'pagination' | translate:{
                                        from: pagination.from,
                                        to: pagination.to,
                                        total: pagination.total
                                    } }}
                                    </p>
                                    <img class="right-arrows" src="/assets/img/Arrow-right.svg">
                                    <img class="right-arrows" src="/assets/img/Arrow-right end.svg">
                                </div>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    <!-- /TABLE -->
                </div>

            </div>
        </div>
    </div>
</div>

<ng-template #noData>
    <p class="mt-0 mb-0">-</p>
</ng-template>

<!---Delete Staff member modal--->
<app-modal [modalType]="1" *ngIf="deleteModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title">{{'staff.profile.delete' | translate}}</h3>
            <span (click)="deleteModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <p class="faded-p">{{'staff.profile.delete-approve' | translate}}</p>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-end gap-2">
            <span class="cancel-btn" (click)="deleteModal = false">{{'buttons.cancel' | translate}}</span>
            <input type="button"
                   value="{{'staff.profile.delete-btn' | translate}}"
                   (click)="onDelete()"
                   class="btn save-btn btn-primary"
            />
        </div>
    </div>
</app-modal>

<!---Approve Certificate modal--->
<app-modal [modalType]="1" *ngIf="approveCertificateModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title">Approve certificate</h3>
            <span (click)="approveCertificateModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <p class="faded-p">Do you want to approve at the end of the day, going forward, a new normal that has
            evolved.</p>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-end gap-2">
            <span class="cancel-btn" (click)="approveCertificateModal = false">{{'buttons.cancel' | translate}}</span>
            <input type="button"
                   value="Approve certificate"
                   (click)="onApproveCertificate()"
                   class="btn save-btn btn-primary"
            />
        </div>
    </div>
</app-modal>

<!---Decline Certificate modal--->
<app-modal [modalType]="1" *ngIf="declineCertificateModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title">Decline certificate</h3>
            <span (click)="declineCertificateModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <p class="faded-p">Do you want to approve at the end of the day, going forward, a new normal that has
            evolved.</p>
        <p class="faded-p mt-4">Decline reason</p>
        <textarea class="form-control" [(ngModel)]="declineReason"></textarea>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-end gap-2">
            <span class="cancel-btn" (click)="declineCertificateModal = false">{{'buttons.cancel' | translate}}</span>
            <input type="button"
                   value="Decline certificate"
                   (click)="onDeclineCertificate()"
                   class="btn save-btn btn-primary"
            />
        </div>
    </div>
</app-modal>

<!---Change Billing modal--->
<app-modal [modalType]="1" *ngIf="billingModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title">{{'staff.profile.edit-billing-info' | translate}}</h3>
            <span (click)="billingModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <form [formGroup]="billingForm" id="form2" (ngSubmit)="onSubmitBilling()">

            <p>{{'staff.profile.form.address' | translate}}</p>

            <div class="form-group">
                <label for="address">{{'staff.profile.form.home' | translate}}</label>
                <input formControlName="address" class="form-control" type="text" id="address"/>
            </div>
            <!--<div class="form-group">-->
                <!--<label for="address2">{{'staff.profile.form.home-2' | translate}}</label>-->
                <!--<input formControlName="address2" class="form-control" type="text" id="address2"/>-->

            <!--</div>-->
            <!--<div class="form-group">-->
                <!--<label for="postalCode">{{'staff.profile.form.postal' | translate}}</label>-->
                <!--<input formControlName="postalCode" class="form-control" type="text" id="postalCode"/>-->
            <!--</div>-->
            <div class="form-group">
                <label for="city">{{'staff.profile.form.city' | translate}}</label>
                <input formControlName="city" class="form-control" type="text" id="city"/>
            </div>

            <p>{{'staff.profile.form.bank-details' | translate}}</p>

            <div class="form-group">
                <label for="account">{{'staff.profile.form.bank-account' | translate}}</label>
                <input formControlName="bankAccount" class="form-control" type="text" id="account"/>
            </div>
            <div class="form-group">
                <label for="bank-name">{{'staff.profile.form.bank-name' | translate}}</label>
                <input formControlName="bankName" class="form-control" type="text" id="bank-name"/>
            </div>
        </form>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-end gap-2">
            <span class="cancel-btn" (click)="billingModal = false">{{'buttons.cancel' | translate}}</span>
            <input type="submit"
                   form="form2"
                   [disabled]="!billingForm.valid"
                   value="{{'buttons.save-changes' | translate}}"
                   class="btn save-btn btn-primary"
            />
        </div>
    </div>
</app-modal>

<!---Change Contact Info modal--->
<app-modal [modalType]="1" *ngIf="contactModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title">{{'staff.profile.edit-contact-info' | translate}}</h3>
            <span (click)="contactModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <form [formGroup]="contactForm" id="form3" (ngSubmit)="onSubmitContactInfo()">
            <div class="form-group">
                <label for="phone">{{'staff.profile.form.phone' | translate}}</label>
                <input formControlName="phone" class="form-control" type="text" id="phone"/>
            </div>
            <!--<div class="form-group">-->
                <!--<label for="email">{{'staff.profile.form.email' | translate}}</label>-->
                <!--<input formControlName="email" class="form-control" type="text" id="email"/>-->
            <!--</div>-->

        </form>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-end gap-2">
            <span class="cancel-btn" (click)="contactModal = false">{{'buttons.cancel' | translate}}</span>
            <input type="submit"
                   form="form3"
                   [disabled]="!contactForm.valid"
                   value="{{'buttons.save-changes' | translate}}"
                   class="btn save-btn btn-primary"/>
        </div>
    </div>
</app-modal>

<!---Edit Competences modal--->
<app-modal [modalType]="1" *ngIf="editCompetencesModal">
    <form [formGroup]="skillForm" id="form4" (ngSubmit)="onSubmitSkillForm()">
        <div class="popup-header">
            <div class="d-flex justify-content-between gap-2">
                <h3 class="modal-title">{{'staff.profile.edit-competences' | translate}}</h3>
                <span (click)="editCompetencesModal = false" class="close">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
        <div class="popup-body mt-3 mb-3 scrolling">
            <div *ngFor="let skill of skillsFromArray.controls; let i = index" formArrayName="skillIds">
                <div class="competence-wrapper d-flex justify-content-between gap-2">
                    <!--<input type="checkbox" name="skillIds[]" class="my-checkbox" [checked]="guardSkillIds.includes(skill.id)">-->
                    <p><label for="skill-id-{{i}}"><b>{{skills[i].name}} {{skills[i].id}}</b></label></p>
                    <input type="checkbox" [formControlName]="i" id="skill-id-{{i}}"/>
                </div>
                <!--<div class="subcompetence-wrapper d-flex justify-content-between gap-2" *ngFor="let i of [1,2]">-->
                    <!--<p>City South</p>-->
                    <!--<input type="checkbox" class="my-checkbox">-->
                <!--</div>-->
            </div>
        </div>
        <div class="popup-footer">
            <div class="d-flex justify-content-end gap-2">
                <span class="cancel-btn" (click)="editCompetencesModal = false">{{'buttons.cancel' | translate}}</span>
                <input type="submit"
                       value="{{'buttons.save-changes' | translate}}"
                       class="btn save-btn btn-primary"/>
            </div>
        </div>
    </form>
</app-modal>
