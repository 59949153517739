import * as AuthActions from './auth.actions';
import {CustomerModel} from '../../models/customer.model';
import {EmployeeModel} from "../../models/employee.model";
import {PaginationModel} from "../../models/pagination.model";

export interface State {
    employee: EmployeeModel;
    customers: CustomerModel[];
    pagination: PaginationModel;
    customer: CustomerModel;
    email: string;
    jwt: any;
    inviteToken: string;
    loading: boolean;
}

const initialState: State = {
    employee: null,
    customers: [],
    pagination: null,
    customer: null,
    email: null,
    jwt: null,
    inviteToken: null,
    loading: false
}

export function authReducer(state = initialState, action: AuthActions.AuthActions): State {
    switch (action.type) {
        case AuthActions.UPDATE_BILLING_INFO:
            return {
                ...state,
                loading: true
            };
        case AuthActions.UPDATE_BILLING_INFO_SUCCESS:
            return {
                ...state,
                customer: {
                    ...state.customer,
                    billingInformation: action.payload.billing
                },
                loading: false
            };
        case AuthActions.UPDATE_BILLING_INFO_FAILED:
            return {
                ...state,
                loading: false
            };
        case AuthActions.CREATE_BILLING_INFO:
            return {
                ...state,
                loading: true
            };
        case AuthActions.CREATE_BILLING_INFO_SUCCESS:
            return {
                ...state,
                // billings: [action.payload.billing, ...this.state.billings],
                customer: {
                    ...state.customer,
                    billingInformation: action.payload.billing
                },
                loading: false
            };
        case AuthActions.CREATE_BILLING_INFO_FAILED:
            return {
                ...state,
                loading: false
            };

        case AuthActions.SIGNUP_START:
            return {
                ...state,
                email: action.payload.email,
                loading: true
            };
        case AuthActions.SIGNUP_CODE_SENT:
            return {
                ...state,
                loading: true
            };
        case AuthActions.SIGNUP_CODE:
            return {
                ...state,
                loading: true
            };
        case AuthActions.LOGIN_LINK:
            return {
                ...state,
                loading: true,
                email: action.payload.email
            };
        case AuthActions.LOGIN_LINK_SENT:
            return {
                ...state,
                loading: false
            };
        case AuthActions.LOGIN_START:
            return {
                ...state,
                loading: true
            };
        case AuthActions.LOGIN_SUCCESS:
            let customerId = localStorage.getItem('customer');
            let customer = action.payload.employee.customers.find(x => x.id == +customerId);
            if (!customer) {
                customer = action.payload.employee.customers[0] !== null
                    ? action.payload.employee.customers[0]
                    : state.customer
            }
            return {
                ...state,
                employee: action.payload.employee,
                jwt: action.payload.jwt,
                customers: action.payload.employee.customers,
                customer: customer,
                loading: false,
            };
        case AuthActions.LOGIN_FAIL:
            return {
                ...state,
                loading: false
            };
        case AuthActions.LOGOUT:
            localStorage.clear();
            return {
                ...state,
                jwt: null,
                employee: null,
                email: null,
                loading: false
            };
        case AuthActions.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                employee: action.payload.employee
            };
        case AuthActions.UPDATE_PROFILE:
            return {
                ...state,
                loading: true
            };
        case AuthActions.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                employee: action.payload.employee,
                loading: false
            };
        case AuthActions.UPDATE_PROFILE_FAILED:
            return {
                ...state,
                loading: false
            };
        // case AuthActions.LOAD_CUSTOMERS:
        //     return {
        //         ...state,
        //         loading: true
        //     };
        // case AuthActions.LOAD_CUSTOMERS_SUCCESS:
        //     return {
        //         ...state,
        //         customers: action.payload.customers,
        //         customer: action.payload.customers[0] !== null ? action.payload.customers[0] : state.customer,
        //         // pagination: action.payload.pagination,
        //         loading: false
        //     };
        // case AuthActions.LOAD_CUSTOMERS_FAILED:
        //     return {
        //         ...state,
        //         loading: false
        //     };
        case AuthActions.SWITCH_COMPANY_ACCOUNT:
            return {
                ...state,
                customer: action.payload.customer
            };
        case AuthActions.CREATE_CUSTOMER:
            return {
                ...state,
                loading: true
            };
        case AuthActions.CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload.customer,
                customers: [action.payload.customer, ...state.customers],
                employee: {
                    ...state.employee,
                    customers: [...state.employee.customers, action.payload.customer]
                },
                loading: false
            };
        case AuthActions.CREATE_CUSTOMER_FAILED:
            return {
                ...state,
                loading: false
            };
        case AuthActions.SET_INVITE_TOKEN:
            return {
                ...state,
                inviteToken: action.payload.inviteToken
            };
        case AuthActions.CLEAR_INVITE_TOKEN:
            return {
                ...state,
                inviteToken: null
            };
        case AuthActions.TRANSFER_OWNERSHIP:
            return {
                ...state,
                employee: {
                    ...state.employee,
                    employeeRole: {
                        ...state.employee.employeeRole,
                        id: 2,
                        name: 'Administrator'
                    }
                }
            };
        case AuthActions.UPDATE_CUSTOMER:
            return {
                ...state,
                loading: true
            };
        case AuthActions.UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload.customer,
                loading: false
            };
        case AuthActions.UPDATE_CUSTOMER_FAILED:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
}
