<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

                <div class="d-block d-md-flex justify-content-between">
                    <h1>Safetly help</h1>
                    <div class="search-wrapper mt-3 mt-md-0">
                        <img class="search-icon" src="/assets/img/search 1.svg">
                        <input class=" search-input" type="search" placeholder="Search safetly help"/>
                    </div>
                </div>

                <div class="wrapper">
                    <div class="d-block d-sm-flex justify-content-center justify-content-sm-between gap-2">
                        <div>
                            <div class="d-flex">
                                <img src="/assets/img/icon-home.png" alt="">
                                <div>
                                    <h3>Getting started</h3>
                                    <p>Setting up your account</p>
                                    <p>Inviting your co-workers</p>
                                    <p>Entering billing information</p>
                                    <p>Safetly basics</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex">
                                <img src="/assets/img/icon-regions.png" alt="">
                                <div>
                                    <h3>Your company</h3>
                                    <p>Setting up your account</p>
                                    <p>Inviting your co-workers</p>
                                    <p>Entering billing information</p>
                                    <p>Safetly basics</p>
                                    <p>Inviting your co-workers</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex">
                                <img src="/assets/img/icon-staff.png" alt="">
                                <div>
                                    <h3>My Staff</h3>
                                    <p>Setting up your account</p>
                                    <p>Inviting your co-workers</p>
                                    <p>Entering billing information</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-block d-sm-flex justify-content-center justify-content-sm-between gap-2 mt-3">
                        <div>
                            <div class="d-flex">
                                <img src="/assets/img/icon-reports.png" alt="">
                                <div>
                                    <h3>Reports</h3>
                                    <p>Setting up your account</p>
                                    <p>Inviting your co-workers</p>
                                    <p>Entering billing information</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex">
                                <img src="/assets/img/icon-assignments.png" alt="">
                                <div>
                                    <h3>Assignments</h3>
                                    <p>Setting up your account</p>
                                    <p>Inviting your co-workers</p>
                                    <p>Entering billing information</p>
                                    <p>Safetly basics</p>
                                    <p>Inviting your co-workers</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="d-flex">
                                <img src="/assets/img/icon-profile.png" alt="">
                                <div>
                                    <h3>Something else</h3>
                                    <p>Setting up your account</p>
                                    <p>Inviting your co-workers</p>
                                    <p>Entering billing information</p>
                                    <p>Setting up your account</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-wrapper" *ngIf="false">
                    <h4>Can’t find what you’re looking for?</h4>
                    <div class="btn btn-primary my-button">
                        <p>Chat with our support</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
