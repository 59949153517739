<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <h1>{{'messages.title' | translate}}</h1>
                <div class="wrapper d-flex">
                    <div class="conversations scroll-div" id="conversations" (scroll)="onScrollConversations($event)">
                        <h6>{{'messages.conversations' | translate}}</h6>
                        <div [ngClass]="conversation.id == selectedConversationId ? 'active': ''"
                             *ngFor="let conversation of conversations"
                             (click)="onLoadMessages(conversation.id); onSelectedConversation(conversation)"
                             class="conversation d-flex justify-content-between gap-2 mb-2">

                            <div class=" d-flex justify-content-start gap-2 mb-2">
                                <img src="{{conversation?.jobPlace?.image
                                ? conversation?.jobPlace?.image
                                : '/assets/img/empty-profile-picture.png'}}"
                                     alt="">
                                <!--<img *ngIf="!conversation?.jobPlace?.image" src="/assets/img/empty-profile-picture.png" alt="">-->
                                <!--<img *ngIf="conversation?.jobPlace?.image;" src="{{conversation?.jobPlace?.image}}" alt="">-->
                                <div class="conversation-info">
                                    <p *ngIf="conversation?.name; else noName">{{conversation?.name}}</p>
                                    <ng-template #noName>
                                        <p>{{'unk' | translate}}</p>
                                    </ng-template>
                                    <!--<span>{{'messages.guard' | translate}}</span>-->
                                </div>
                            </div>

                            <!--<div *ngIf="(messageForConversation == conversation?.id) &&-->
                             <!--(conversation?.id != selectedConversation.id)" class="msg-badge">{{conversation.unseenCount}}</div>-->
                            <div *ngIf="conversation.unseenCount > 0" class="msg-badge">{{conversation.unseenCount}}</div>

                        </div>
                    </div>
                    <div class="chat d-flex flex-column">
                        <div class="chat-header d-flex justify-content-between">
                            <div class="d-flex justify-content-start gap-2">
                                <img *ngIf="!selectedConversation?.jobPlace?.image" src="/assets/img/empty-profile-picture.png" alt="">
                                <img *ngIf="selectedConversation?.jobPlace?.image" src="{{selectedConversation?.jobPlace?.image}}" alt="">
                                <div class="mt-auto mb-auto">
                                    <p class="conversation-name">{{this?.selectedConversation?.name}}</p>
                                    <div class="conversation-info">
                                        <img src="/assets/img/information.png"
                                             class="conversation-info-icon"
                                             (mouseout)="showConversationInfo = false"
                                             (mouseover)="showConversationInfo = true">
                                        <ul *ngIf="showConversationInfo" class="conversation-info">
                                            <li>Active guards in chat:</li>
                                            <li *ngFor="let participant of selectedConversation.participants;">{{participant.user.firstName}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!--<p class="view-profile mt-auto mb-auto">{{'messages.view' | translate}}</p>-->
                        </div>
                        <div class="chat-body" id="chat" (scroll)="onScrollMessages($event)">
                            <div *ngFor="let message of this.messages" [ngClass]="message.isMine ? 'mine' : 'not-mine'" class="message-wrapper">
                                <div [ngClass]="message.isMine ? 'mine' : 'not-mine'" class="message">
                                    {{message?.body}}
                                </div>
                                <span class="date-time">{{message.createdAt | date:'dd MMM, HH:mm'}}</span>
                                <img *ngIf="!message.sender?.image" src="/assets/img/empty-profile-picture.png" alt="">
                                <img *ngIf="message.sender?.image" src="{{message.sender?.image}}" alt="">
                            </div>
                        </div>
                        <div class="chat-footer d-flex justify-content-start gap-3 mt-2">
                            <input (keyup.enter)="onSendMessage(this?.selectedConversationId)" [(ngModel)]="messageToSend" type="text" placeholder="{{'messages.write' | translate}}">
                            <img src="/assets/img/icon-send-message.png" alt="" (click)="onSendMessage(this?.selectedConversationId)">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
