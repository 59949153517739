<div class="main-wrapper">
    <div class="row main-row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="header">
                    <div class="flexed">
                        <img src="/assets/img/safetly logo.png">
                        <h3>{{'safetly' | translate}}</h3>
                    </div>
                </div>
                <div class="body">
                    <h1>{{'auth.register.check-for-code' | translate}}</h1>
                    <p class="suggestion-p">{{'auth.register.code-sent' | translate}}</p>
                    <p class="suggestion-p-2">{{'auth.register.check-spam' | translate}}</p>
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="SecurityCodeForm" (ngSubmit)="onContinue()" id='code-form'>
                                <div class="form-group">
                                    <label for="code">{{'auth.register.code' | translate}}</label>
                                    <input class="form-control form-input" type="text" id="code"
                                           formControlName='code'/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <input type="submit"
                           value="{{'buttons.continue' | translate}}"
                           class="btn my-button btn-primary "
                           form="code-form"
                           [disabled]="!this.SecurityCodeForm.valid"/>
                </div>
                <div class="footer">
                    <div class="btn my-button help-button btn-primary">
                        <img src="/assets/img/question-circle 1.svg">
                        <span>{{'help' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
