<div class="wrapper">
    <div class="content">
        <div class="circle">
            <img src="/assets/img/check-circle 1.png" alt="">
        </div>
        <h1>{{'create-assignment.ordered' | translate}}</h1>
        <p class="text">
            {{'create-assignment.ordered-text' | translate}}
        </p>
        <div class="btn btn-primary my-button" routerLink="/components/assignments">
            <div class="flexed">
                <p>{{'create-assignment.view-assignments' | translate}}</p>
                <img src="/assets/img/arrow white.svg">
            </div>
        </div>
    </div>
</div>
