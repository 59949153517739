<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <h1>{{this?.customer?.companyName}}</h1>
                <div class="tab">

                    <div class="d-flex justify-content-xl-start justify-content-center gap-5 gap-xl-4">
                        <div class="on-hover">
                            <div class="flexed" (click)="onUsersClicked()" routerLink = "/components/company/users" routerLinkActive = 'tab-item-active'>
                                <div class="users-icon" [ngClass]="this.users ? 'active': ''"></div>
                                <p class="tab-item-title">{{'company.tab.users' | translate}}</p>
                            </div>
                            <div *ngIf = "this.users==true"  class="blue-line"></div>
                        </div>
                        <div class="on-hover">
                            <div class="flexed" (click) = "onBillingClicked()" routerLink = "/components/company/billing" routerLinkActive = 'tab-item-active'>
                                <div class="billing-icon" [ngClass]="this.billing ? 'active': ''"></div>
                                <p class="tab-item-title">{{'company.tab.billing' | translate}}</p>
                            </div>
                            <div *ngIf = "this.billing==true"  class="blue-line"></div>
                        </div>
                        <div class="on-hover">
                            <div class="flexed" (click)="onRegionsClicked()" routerLink = "/components/company/regions" routerLinkActive = 'tab-item-active'>
                                <div class="region-icon" [ngClass]="this.regions ? 'active': ''"></div>
                                <p class="tab-item-title">{{'company.tab.objects' | translate}}</p>
                            </div>
                            <div *ngIf = "this.regions==true"  class="blue-line"></div>
                        </div>
                        <div class="on-hover">
                            <div class="flexed" (click)="onSettingsClicked()" routerLink = "/components/company/settings" routerLinkActive = 'tab-item-active'>
                                <div class="settings-icon" [ngClass]="this.settings ? 'active': ''"></div>
                                <p class="tab-item-title">{{'company.tab.settings' | translate}}</p>
                            </div>
                            <div *ngIf = "this.settings==true"  class="blue-line"></div>
                        </div>
                    </div>

                    <div class="line"></div>
                </div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
