<div class="main-wrapper">
    <div class="row main-row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="header">
                    <div class="flexed">
                        <img src="/assets/img/safetly logo.png">
                        <h3>{{'safetly' | translate}}</h3>
                    </div>
                </div>
                <div class="body">
                    <h1>{{'auth.enter-password' | translate}}</h1>
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup] = "form" (ngSubmit)="onSubmit()" id='form'>
                                <div class="form-group" style="margin-top: 40px;">
                                    <label for="password">{{'auth.password' | translate}}</label>
                                    <input class="form-control form-input"  type="{{this.showPasswords==true ? 'text' : 'password'}}" id="password" formControlName='password'/>
                                </div>
                                <div class="form-group" style="margin-top: 40px;">
                                    <label for="password">{{'auth.confirm-password' | translate}}</label>
                                    <input class="form-control form-input"  type="{{this.showPasswords==true ? 'text' : 'password'}}" id="password2" formControlName='confirmPassword'/>
                                </div>
                            </form>
                        </div>
                    </div>
                    <p  *ngIf="!showPasswords" class="faded-p" (click)="this.showPasswords = true">
                        <span><img class="show-password-icon" src="/assets/img/show-password-icon.svg" alt=""></span>
                        {{'auth.show' | translate}}
                    </p>
                    <p *ngIf="showPasswords" class="faded-p" (click)="this.showPasswords = false">
                        <span><img class="hide-password-icon" src="/assets/img/hide-password-icon.svg" alt=""></span>
                        {{'auth.hide' | translate}}
                    </p>
                    <input type="submit"
                           value="{{'buttons.continue' | translate}}"
                           class="btn my-button btn-primary "
                           form="form"
                           [disabled]="!this.form.valid"/>
                </div>
            </div>
        </div>
    </div>
</div>
