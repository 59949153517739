import {Component, OnDestroy, OnInit} from '@angular/core';
import {CustomerModel} from "../../../../models/customer.model";
import {EmployeeModel} from "../../../../models/employee.model";
import {Subscription} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {Store} from "@ngrx/store";
import * as fromApp from "../../../../store/app.reducer";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import * as MyStaffActions from '../../store/my-staff.actions';

@Component({
    selector: 'app-invite-manual',
    templateUrl: './invite-manual.component.html',
    styleUrls: ['./invite-manual.component.scss']
})
export class InviteManualComponent implements OnInit, OnDestroy {

    public editField: string;
    public customer: CustomerModel;
    public employee: EmployeeModel;
    public subscription: Subscription;

    public invalidIdFormatIds: Set<number> = new Set();
    public invalidEmailFormatIds: Set<number> = new Set();
    public skillId;

    public uploadCsvFileModal = false;

    public inviteObject: {
        id: number,
        email?: string,
        firstName?: string,
        lastName?: string,
        phone?: string,
        personalId?: string,
        skillId?: number
    }[] = [];


    constructor(private formBuilder: FormBuilder,
                private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit(): void {
        this.skillId = this.route.snapshot.params.id;
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.employee = appState.auth.employee;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public isDisabledButton(): boolean {
        return this.invalidIdFormatIds.size > 0 || this.invalidEmailFormatIds.size > 0;
    }

    public onNextStep(): void {

        if (this.isDisabledButton()) {
            return;
        }

        let isValid = true;

        this.inviteObject.forEach(element => {

            for (let elementKey in element) {
                if (element[elementKey] === "") {
                    delete element[elementKey];
                }
            }

            if (Object.keys(element).length === 1) {
                return; //prazan red u tabeli (samo id)
            }

            if (element.personalId == undefined || element.phone == undefined) {
                isValid = false;
                return;
            }

            element.skillId = this.skillId;
        });

        if (!isValid) {
            this._snackBar.open('Please, fill in mandatory fields.', 'Close', {
                duration: 3000,
                panelClass: ['snackbar']
            });
            return;
        }
        this.inviteObject.forEach(element => {
            delete element.id;
        });
        this.inviteObject = this.inviteObject.filter(element => Object.keys(element).length !== 0);
        if (this.inviteObject.length == 0) {
            this._snackBar.open('Please, fill in mandatory fields.', 'Close', {
                duration: 3000,
                panelClass: ['snackbar']
            });
            return;
        }
        this.store.dispatch(new MyStaffActions.SendInvites({
            customerId: this.customer.id,
            data: this.inviteObject
        }));

        // this.router.navigate(['/components/my-staff/send-invites']);
    }

    public isValidEmail(email: string): boolean {
        if (!email) {
            return true;
        }
        let isValid = /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/.test(email);
        return isValid;
    }

    public isValidPersonId(personId: string): boolean {
        if (!personId) {
            return false;
        }
        // let isValid = /[0-9]{6}-[0-9]{4}/.test(personId);
        let isValid = /[0-9]{12}/.test(personId);
        return isValid && personId.length == 12;
    }

    public addToObject(id: number, field: string, value: string): void {

        if (this.inviteObject.find(el => el.id === id)) {
            if (field === 'email') {
                this.inviteObject.find(el => el.id === id).email = value;
                if (!this.isValidEmail(value) && value != '') {
                    this.invalidEmailFormatIds.add(id);
                } else {
                    this.invalidEmailFormatIds.delete(id);
                }
            }
            if (field === 'firstName') {
                this.inviteObject.find(el => el.id === id).firstName = value;
            }
            if (field === 'lastName') {
                this.inviteObject.find(el => el.id === id).lastName = value;
            }
            if (field === 'phone') {
                this.inviteObject.find(el => el.id === id).phone = value;
            }
            if (field === 'personId') {
                this.inviteObject.find(el => el.id === id).personalId = value;
                if (!this.isValidPersonId(value) && value != '') {
                    this.invalidIdFormatIds.add(id);
                } else {
                    this.invalidIdFormatIds.delete(id);
                }
            }
        } else {
            if (field === 'email') {
                this.inviteObject.push({id: id, email: value});
                if (!this.isValidEmail(value) && value != '') {
                    this.invalidEmailFormatIds.add(id);
                } else {
                    this.invalidEmailFormatIds.delete(id);
                }
            }
            if (field === 'firstName') {
                this.inviteObject.push({id: id, firstName: value});
            }
            if (field === 'lastName') {
                this.inviteObject.push({id: id, lastName: value});
            }
            if (field === 'phone') {
                this.inviteObject.push({id: id, phone: value});
            }
            if (field === 'personId') {
                this.inviteObject.push({id: id, personalId: value});
                if (!this.isValidPersonId(value) && value != '') {
                    this.invalidIdFormatIds.add(id);
                } else {
                    this.invalidIdFormatIds.delete(id);
                }
            }
        }

    }

    public isInInvalidIds(id: number) {
        return this.invalidIdFormatIds.has(id);
    }

    public isInInvalidEmails(id: number) {
        return this.invalidEmailFormatIds.has(id);
    }

}
