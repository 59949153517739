<div class="main-wrapper">
	<div class="row">
		<div class="col-12">
			<div class="inner-wrapper">

				<div class="d-block d-md-flex justify-content-start justify-content-md-between gap-md-2">
					<div class="search-wrapper">
						<img class="search-icon" src="/assets/img/search 1.svg">
						<input class="search-input" type="search" placeholder="Search 10 users"/>
					</div>
					<ng-container *ngIf="customer.employeeRole.id != 3">
						<div class="btn btn-primary my-button mt-md-0 mt-2"
						     routerLink="/components/company/invite-users">
							<div class="flexed">
								<img src="/assets/img/plus white.svg">
								<p>{{'company.users.invite' | translate}}</p>
							</div>
						</div>
					</ng-container>

				</div>

				<div class="table-responsive">
					<!-- TABLE -->
					<table class="table table-hover">
						<thead>
						<tr>
							<th class="sort-col">{{'table.thead.name'|translate}}</th>
							<th>{{'table.thead.email'|translate}}</th>
							<th>{{'table.thead.phone'|translate}}</th>
							<th>{{'table.thead.role'|translate}}</th>
							<th style="opacity: 0;">Options</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let user of this?.users">
							<td *ngIf="user?.firstName; else noName"><p
									class="black">{{user?.firstName}} {{user?.lastName}}</p></td>
							<ng-template #noName>
								<td><p class="black">-</p></td>
							</ng-template>

							<td><p class="table-faded">{{user?.email}}</p></td>

							<td *ngIf="user?.phone; else noPhone"><p class="table-faded">{{user?.phone}}</p></td>
							<ng-template #noPhone>
								<td><p class="table-faded">-</p></td>
							</ng-template>

							<td>
								<div class="pill-owner" *ngIf="user?.employeeRole?.name === 'Owner'">
									<span>{{'user-role.1'|translate}}</span>
								</div>
								<div class="pill-administrator" *ngIf="user?.employeeRole?.name === 'Administrator'">
									<span>{{'user-role.2'|translate}}</span>
								</div>
								<div class="pill-user"
								     *ngIf="user?.employeeRole?.name !== 'Administrator' && user?.employeeRole?.name !== 'Owner'">
									<span>{{'user-role.3'|translate}}</span>
								</div>
							</td>

							<td class="position-relative">

								<ng-container *ngIf="customer.employeeRole.id != 3">
									<!--<div *ngIf="(this?.customer?.employeeRole?.id == 1 || this?.customer?.employeeRole?.id == 2) &&-->
									<!--user?.employeeRole?.id != 1 &&-->
									<!--user?.id != loggedEmployee?.id"-->
									<div
										[ngClass]="this.editUser && user?.id === this?.userOptionsId
										? 'active'
										: ''"
										(click)="this.editUser = !this.editUser; openOptions(user)"
										class="dots">
									</div>


									<div *ngIf="this.editUser && user?.id === this?.userOptionsId"
									     (clickOutside)="editUser = false"
									     class="editUser">
										<p *ngIf="this?.customer?.employeeRole?.id === 1" (click)="onEditUser(user)">
											{{'company.users.edit' | translate}}</p>
										<!--<p *ngIf="user?.employeeRole?.id != 1"-->
										<p (click)="onDeleteUser(user)">{{'company.users.delete' | translate}}</p>
									</div>
								</ng-container>


							</td>
						</tr>
						</tbody>
						<tfoot>
						<tr>
							<td colspan="5">
								<div class="showing">
									<img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
									     class="left-arrows" src="/assets/img/Arrow-left end.svg"
									     (click)="onFirstPage()">
									<img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
									     class="left-arrows" src="/assets/img/Arrow-left.svg"
									     (click)="onPreviousPage()">
									<p *ngIf="pagination.total > 0;else noRecords">
										{{'pagination' | translate:{
										from:this?.pagination?.from,
										to:this?.pagination?.to,
										total:this?.pagination?.total} }}
									</p>
									<ng-template #noRecords>
										<p>{{'pagination-no-records' | translate}}</p>
									</ng-template>
									<img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
									     class="right-arrows" src="/assets/img/Arrow-right.svg" (click)="onNextPage()">
									<img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
									     class="right-arrows" src="/assets/img/Arrow-right end.svg"
									     (click)="onLastPage()">
								</div>
							</td>
						</tr>
						</tfoot>
					</table>
					<!-- /TABLE -->
				</div>

			</div>
		</div>
	</div>
</div>

<!--Edit user info Modal-->
<app-modal *ngIf="this.editUserModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">
					{{'buttons.edit' | translate}} {{this?.userToEdit?.firstName}} {{this?.userToEdit?.lastName}}</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.editUserModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<form [formGroup]="editUserForm" (ngSubmit)="onEditUserSubmit()" id="editUserForm">
			<div class="form-group">
				<label for="role">{{'company.users.role' | translate}}</label>
				<select id="role" class="form-select" formControlName="role">
					<option *ngFor="let role of this.allRoles"
					        value="{{role.id}}">{{role?.name}}
					</option>
				</select>
			</div>
			<p class="invoicing-contact-p">{{'company.users.contact-info' | translate}}</p>
			<div class="row">
				<div class="col-12 col-lg-6">
					<div class="form-group" style="margin-right: 20px;">
						<label for="firstName">{{'company.users.name' | translate}}</label>
						<input readonly formControlName="firstName" class="form-control"
						       type="text" id="firstName"/>
					</div>
				</div>
				<div class="col-12 col-lg-6">
					<div class="form-group">
						<label for="lastName">{{'company.users.surname' | translate}}</label>
						<input readonly formControlName="lastName" class="form-control"
						       type="text" id="lastName"/>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label for="email">{{'company.users.email' | translate}}</label>
				<input readonly formControlName="email" class="form-control" type="email"
				       id="email"/>
			</div>
			<div class="form-group">
				<label for="phone">{{'company.users.phone' | translate}}</label>
				<input readonly formControlName="phone" class="form-control" type="text"
				       id="phone"/>
			</div>
		</form>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.editUserModal = false">{{'buttons.cancel' | translate}}
                    </span>
					<input type="submit"
					       value="{{'buttons.save-changes' | translate}}"
					       form="editUserForm"
					       class="btn save-btn btn-primary"
					       [ngClass]="editUserForm.valid ? '' : 'disabled'"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>

<!--Delete user Modal-->
<app-modal *ngIf="this.deleteUserModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">
					{{'buttons.delete' | translate}} {{this?.userToDelete?.firstName}} {{this?.userToDelete?.lastName}}
				</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.deleteUserModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<p class="delete-p">{{'company.users.delete-msg' | translate}}</p>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.deleteUserModal = false">{{'buttons.cancel' | translate}}
                    </span>
					<input type="button"
					       value="{{'company.users.delete' | translate}}"
					       (click)="onSubmitDelete()"
					       class="btn save-btn btn-primary"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>

<!--Transfer ownership Modal-->
<app-modal *ngIf="this.transferOwnershipModal" class="overlay">
	<div class="popup-header">
		<div class="row">
			<div class="col-8 text-left">
				<h3 class="modal-title">{{'company.users.ownership' | translate}}</h3>
			</div>
			<div class="col-4 text-right">
                <span (click)="this.transferOwnershipModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
			</div>
		</div>
	</div>
	<div class="popup-body">
		<p class="delete-p" *ngIf="this?.userToEdit?.firstName; else noName">
			{{'company.users.ownership-msg' | translate:{
			firstName: this?.userToEdit?.firstName,
			lastName: this?.userToEdit?.lastName
			} }}
		</p>
		<ng-template #noName>
			<p class="delete-p">{{'company.users.ownership-msg-no-user' | translate}}</p>
		</ng-template>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="onCancelTransferOwnership()">{{'buttons.cancel' | translate}}
                    </span>
					<input type="button"
					       value="{{'company.users.ownership' | translate}}"
					       (click)="onTransferOwnership()"
					       class="btn save-btn btn-primary"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>
