<div class="step-main-wrapper">
	<h1>{{'create-assignment.assignment-date' | translate}}</h1>
	<div class="wrapper">
		<ejs-calendar [values]='initDates'
		              locale={{locale}}
		              [isMultiSelection]="multiselect"
		              depth="Month"
		              [firstDayOfWeek]="1"
		              [min]="minDatePicker"
		              (change)="onChangeCalendar($event.values)">
		</ejs-calendar>
	</div>

	<div class="wrapper">
		<h3>{{'create-assignment.select-type' | translate}}</h3>
		<div class="row">
			<div class="col-12 col-md-6">
				<div [ngClass]="this.assignmentType == '1' ? 'selected' : ''"
				     (click)="assignmentType = '1'; onChangeType()"
				     class="box-left"
				     role="button">
					<div class="d-flex gap-2">
						<input type="radio" class="radio" [(ngModel)]="assignmentType" name="radios" checked value="1"
						       (change)="onChangeType()">
						<div>
							<p>{{'create-assignment.one-time' | translate}}</p>
							<p class="faded-p">{{'create-assignment.event-days' | translate}}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div [ngClass]="this.assignmentType == '2' ? 'selected' : ''"
				     (click)="assignmentType = '2';  onChangeType()"
				     class="box-right"
				     role="button">
					<div class="d-flex gap-2">
						<input type="radio" class="radio" [(ngModel)]="assignmentType" name="radios" value="2"
						       (change)="onChangeType()">
						<div>
							<p>{{'create-assignment.recurring' | translate}}</p>
							<p class="faded-p">{{'create-assignment.recurring-days' | translate}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="recurring" *ngIf="this.assignmentType == '2'">
			<h3>{{'create-assignment.freq' | translate}}</h3>
			<form [formGroup]="this.recurringPatternForm">
				<div class="row">
					<div class="col-3 col-sm-2">
						<label class="repeat-label">{{'create-assignment.interval' | translate}}</label>
					</div>
					<div class="col-9 col-sm-10">
						<div class="flexed">
							<input min="1" formControlName="interval" class="form-control" type="number" value="1"
							       (change)="onChangeInterval($event.target.value)">
							<select formControlName="freq" class="form-select"
							        (change)="onChangeSelect($event.target.value)">
								<option value="MONTHLY">{{'create-assignment.month' | translate}}</option>
								<option value="WEEKLY">{{'create-assignment.weeks' | translate}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="row" style="margin-top: 11px;" *ngIf="this.repeatEvery == 'MONTHLY'">
					<div class="col-3 col-sm-2">
						<label class="repeat-label">{{'create-assignment.repeat' | translate}}</label>
					</div>
					<div class="col-9 col-sm-10">
						<div class="flexed">
							<select formControlName="byMonthDay" class="form-select"
							        (change)="onChangeMonthDay($event.target.value)">
								<option *ngFor="let item of [].constructor(31);let i = index"
								        value="{{i+1}}">
									{{'create-assignment.step3.monthly on day' | translate : {day: i + 1 } }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</form>
			<h3>{{'create-assignment.recurring-details' | translate}}</h3>
			<div class="d-flex justify-content-start gap-2" *ngIf="this.repeatEvery == 'MONTHLY'">
				<input class="my-checkbox" type="checkbox" checked>
				<div class="text">
					<p>{{'create-assignment.anonymous' | translate}}</p>
					<p class="faded-p">{{'create-assignment.anonymous-desc' | translate}}</p>
				</div>
			</div>
			<div class="d-flex justify-content-between gap-1" *ngIf="this.repeatEvery == 'WEEKLY'">

				<!--<div class="d-flex justify-content-start gap-2">-->
				<!--<input class="my-checkbox" type="checkbox" checked>-->
				<!--<div class="text">-->
				<!--<p>{{'create-assignment.hand-pick' | translate}}</p>-->
				<!--<p class="faded-p">{{'create-assignment.hand-pick-desc' | translate}}</p>-->
				<!--</div>-->
				<!--</div>-->


				<div class="d-flex justify-content-start gap-2">
					<input class="my-checkbox" type="checkbox" checked>
					<div class="text">
						<p>{{'create-assignment.anonymous' | translate}}</p>
						<p class="faded-p">{{'create-assignment.anonymous-desc' | translate}}</p>
					</div>
				</div>

			</div>
		</div>
	</div>

	<div class="personnel-pick-wrapper d-flex justify-content-between"
	     *ngIf="this.assignmentType == '1'"
	     (click)="handPick = '1'"
	     role="button">
		<div class="p-4">
			<div class="d-flex justify-content-start gap-2">
				<input type="radio" name="personnel" value="1" [(ngModel)]="handPick">
				<h6>{{'create-assignment.step3.hand-pick-personnel.label' | translate}}</h6>
			</div>
			<p>{{'create-assignment.step3.hand-pick-personnel.description' | translate}}</p>
		</div>
		<img src="/assets/img/hand-pick.png" alt="">
	</div>

	<div class="personnel-pick-wrapper d-flex justify-content-between"
	     (click)="handPick = '0'"
	     role="button"
	     *ngIf="this.assignmentType == '1'">
		<div class="p-4">
			<div class="d-flex justify-content-start gap-2">
				<input type="radio" name="personnel" value="0" [(ngModel)]="handPick">
				<h6>{{'create-assignment.step3.personnel-apply.label' | translate}}</h6>
			</div>
			<p>{{'create-assignment.step3.personnel-apply.description' | translate}}</p>
		</div>
		<img src="/assets/img/apply.png" alt="">
	</div>

	<div class="personnel-pick-wrapper d-flex justify-content-between" *ngIf="this.assignmentType == '2'">
		<div class="p-4">
			<div class="d-flex justify-content-start gap-2">
				<input type="radio" name="personnel" checked [disabled]="true">
				<h6>Personnel apply for the assignment</h6>
			</div>
			<p>The personnel apply for the assignment, instead of having to manually select them.</p>
		</div>
		<img src="/assets/img/apply.png" alt="">
	</div>


	<div class="wrapper">
		<h3>{{'create-assignment.hours' | translate}} ({{dates.length}})</h3>
		<p>{{'Minimum shift duration:' | translate: {number: minShiftDuration} }}</p>
		<p>{{'Maximum shift duration:' | translate: {number: maxShiftDuration} }}</p>
		<ng-template #noHours>
			<p>{{'create-assignment.no-hours' | translate}}</p>
		</ng-template>
		<div class="scroll-div" *ngIf="dates.length > 0; else noHours">
			<div class="hour-date" *ngFor="let date of dates">
				<div class="d-flex justify-content-sm-between justify-content-between text-center">
					<p class="date-p">{{date.date | date:"MMMM d, y"}}</p>
					<!--<p class="date-p">{{this.toDate(date.date) | date:"MMMM d, y"}}</p>-->
					<div class="d-flex">
						<input [ngClass]="this.calculateTime(date, date?.startTime, date?.endTime) < minShiftDuration
						|| this.calculateTime(date, date?.startTime, date?.endTime) > maxShiftDuration
						? 'invalid' : ''"
						       class="form-control" type="time" name="start-time"
						       value="{{date?.startTime ? date.startTime : '16:00'}}"
						       (change)="onSetDateStartTime($event, date.date)">
						<img src="/assets/img/Line horizontal.svg" alt="">
						<input [ngClass]="this.calculateTime(date, date?.startTime, date?.endTime) < minShiftDuration
						|| this.calculateTime(date, date?.startTime, date?.endTime) > maxShiftDuration
						? 'invalid' : ''"
						       class="form-control" type="time" name="end-time"
						       value="{{date?.endTime ? date.endTime : '20:00'}}"
						       (change)="onSetDateEndTime($event.target.value, date.date)">
					</div>
					<!--<p [ngClass]="this.calculateTime(date, date?.startTime, date?.endTime) < 4 ? 'less-than-4-hours' : ''"-->
					<!--class="hours-p">{{this.calculateTime(date, date?.startTime, date?.endTime)}} hours</p>-->
					<p [ngClass]="this.calculateTime(date, date?.startTime, date?.endTime) < minShiftDuration
					|| this.calculateTime(date, date?.startTime, date?.endTime) > maxShiftDuration
					? 'less-than-4-hours'
					: ''"
					   class="hours-p">{{calculateTimeHuman(date, date?.startTime, date?.endTime)}}</p>
				</div>

			</div>
			<div class="recurring-dates" *ngIf="this.assignmentType == '2'">
				<p class="section-p">{{'create-assignment.next-assignment' | translate}}</p>
				<div class="recurring-hour-date" *ngFor="let date of dates">

					<div class="d-flex justify-content-sm-between justify-content-between text-center">
						<p class="date-p">{{NextRecurringEvent(date.date) | date:"MMMM d, y"}}</p>
						<div class="d-flex">
							<p>{{date.startTime}}</p>
							<img src="/assets/img/Line horizontal.svg" alt="">
							<p>{{date.endTime}}</p>
						</div>
						<p [ngClass]="this.calculateTime(date, date?.startTime, date?.endTime) < minShiftDuration
                        ? 'less-than-4-hours'
                        : ''"
						   class="hours-p">{{calculateTimeHuman(date, date?.startTime, date?.endTime)}}</p>
					</div>

				</div>
			</div>
		</div>
	</div>

	<div class="btn btn-primary my-button" (click)="onSaveAndContinue()">
		<div class="flexed">
			<p>{{'buttons.save&continue' | translate}}</p>
			<img src="/assets/img/arrow white.svg">
		</div>
	</div>

</div>
