import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ShiftsStatusConst {
    readonly PUBLISH = 1;
    readonly READY = 2;
    readonly ONGOING = 3;
    readonly FINISHED = 4;
    readonly CANCELED = 5;
}