<!--<div class="map-container">-->
    <!--<agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">-->
        <!--<agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>-->
    <!--</agm-map>-->
<!--</div>-->

<!--<div id="pac-container">-->
    <!--<input #search id="pac-input" type="text" placeholder="Enter a location">-->
<!--</div>-->
<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

<!--                <div class="regions">-->

<!--                    <div class="d-block d-md-flex justify-content-between gap-1">-->
<!--                        <h3>Regions</h3>-->
<!--                        <div class="btn btn-primary my-button" (click)="onCreateRegionClicked()">-->
<!--                            <div class="flexed">-->
<!--                                <img src="/assets/img/plus white.svg">-->
<!--                                <p>Create region</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                    <div class="table-responsive scrolling">-->
<!--                        &lt;!&ndash; TABLE &ndash;&gt;-->
<!--                        <table class="table table-hover">-->
<!--                            <thead>-->
<!--                            <tr>-->
<!--                                <th class="sort-col">Name</th>  &lt;!&ndash;[mdbTableSort]="elements" sortBy="id" &ndash;&gt;-->
<!--                                <th>Objects</th>-->
<!--                                <th>Team</th>-->
<!--                                <th style="opacity: 0;">Arrow</th>-->
<!--                            </tr>-->
<!--                            </thead>-->
<!--                            <tbody>-->
<!--                            <tr *ngFor="let region of this?.regions">-->
<!--                                <td *ngIf="region?.name; else noData">{{region?.name}}</td>-->
<!--                                <td *ngIf="region?.jobPlaces?.length > 2">-->
<!--                                    <div class="d-flex flex-row justify-content-start gap-2">-->
<!--                                        <span class="mt-auto mb-auto">{{region?.jobPlaces[0]?.name}}</span>-->
<!--                                        <span class="mt-auto mb-auto">{{region?.jobPlaces[1]?.name}}</span>-->
<!--                                        <div class="num-objects"><span>+{{region?.jobPlaces?.length - 2}}</span></div>-->
<!--                                    </div>-->
<!--                                </td>-->
<!--                                <td *ngIf="region?.jobPlaces?.length <= 2 && region?.jobPlaces?.length > 0">-->
<!--                                    <div class="d-flex flex-row justify-content-start gap-2">-->
<!--                                        <span class="mt-auto mb-auto">{{region?.jobPlaces[0]?.name}}</span>-->
<!--                                        <span class="mt-auto mb-auto">{{region?.jobPlaces[1]?.name}}</span>-->
<!--                                    </div>-->
<!--                                </td>-->
<!--                                <td *ngIf="!region?.jobPlaces || region?.jobPlaces?.length == 0">-</td>-->
<!--                                <td><p class="black">5 users</p></td>-->
<!--                                <td><img class="arrow" src="/assets/img/Arrow-right.svg"></td>-->
<!--                                <ng-template #noData>-->
<!--                                    <td>-</td>-->
<!--                                </ng-template>-->
<!--                            </tr>-->
<!--                            </tbody>-->
<!--                        </table>-->
<!--                        &lt;!&ndash; /TABLE &ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->

                <div class="objects">

                    <div class="d-block d-md-flex justify-content-between gap-1">
                        <h3>{{'company.objects.objects' | translate}}</h3>
                        <div class="btn btn-primary my-button" (click)="onCreateObjectClicked()">
                            <div class="flexed">
                                <img src="/assets/img/plus white.svg">
                                <p>{{'company.objects.create' | translate}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive scrolling">
                        <!-- TABLE -->
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="sort-col">{{'table.thead.name-address' | translate}}</th>  <!--[mdbTableSort]="elements" sortBy="id" -->
<!--                                    <th>Regions</th>-->
                                    <th>{{'table.thead.team' | translate}}</th>
                                    <th style="opacity: 0;">Arrow</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let object of objects"
                                    role="button"
                                    routerLink="/components/assignments/favourites/{{object.id}}">
                                    <td *ngIf="object.name; else noData">
                                        <p class="black mb-0">{{object.name}}</p>
                                        <p class="mt-0 address-p">{{object.address}}</p>
                                    </td>
<!--                                    <td *ngIf="object?.regions?.length > 2">-->
<!--                                        <div class="d-flex flex-row justify-content-start gap-2">-->
<!--                                            <span class="mt-auto mb-auto">{{object?.regions[0]?.name}}</span>-->
<!--                                            <span class="mt-auto mb-auto">{{object?.regions[1]?.name}}</span>-->
<!--                                            <div class="num-objects"><span>+{{object?.regions?.length - 2}}</span></div>-->
<!--                                        </div>-->
<!--                                    </td>-->
<!--                                    <td *ngIf="object?.regions?.length <= 2 && object?.regions?.length > 0">-->
<!--                                        <div class="d-flex flex-row justify-content-start gap-2">-->
<!--                                            <span class="mt-auto mb-auto">{{object?.regions[0]?.name}}</span>-->
<!--                                            <span class="mt-auto mb-auto">{{object?.regions[1]?.name}}</span>-->
<!--                                        </div>-->
<!--                                    </td>-->
<!--                                    <td *ngIf="!object?.regions || object?.regions?.length == 0">-</td>-->
                                    <td>
                                        <p class="black">{{object.favouritesCount}} users</p>
                                    </td>
                                    <td><img class="arrow" src="/assets/img/Arrow-right.svg"></td>
                                    <ng-template #noData>
                                        <td>-</td>
                                    </ng-template>
                                </tr>
                            </tbody>
                        </table>
                        <!-- /TABLE -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!--Create region Modal-->
<app-modal *ngIf="this.createRegionModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">Create region</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.createRegionModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">

        <form [formGroup]="createRegionForm" (ngSubmit)="onCreateRegionSubmit()"
              id="createRegionForm">
            <div class="form-group">
                <label for="region-name">Region Name</label>
                <input class="form-control" type="text" id="region-name"
                       formControlName="name"/>
            </div>
        </form>

        <!--TAB-->
        <div class="tab">
            <div class="row">
                <div class="col-12 col-lg-3">
                    <p [ngClass]="this.objectsTabActive ? 'tab-active' : ''"
                       class="tab-item-title" (click)="onObjectsTab()">Objects ({{this?.objectsInList?.length}})</p>
                    <div *ngIf="this.objectsTabActive" class="blue-line"></div>
                </div>
                <div class="col-12 col-lg-3">
                    <p [ngClass]="this.teamTabActive ? 'tab-active' : ''"
                       class="tab-item-title" (click)="onTeamTab()">Team (5)</p>
                    <div *ngIf="this.teamTabActive" class="blue-line"></div>
                </div>
            </div>
            <div class="line"></div>
        </div>
        <!--/TAB-->

        <!--objects-->
        <div *ngIf="this.objectsTabActive" class="objects-list">
            <div class="list">
                <div *ngFor="let object of this?.objectsInList" class="list-item">
                    <div class="d-flex justify-content-between flex-row gap-1">
                        <div>
                            <p class="item-title">{{object?.name}}</p>
                            <p class="item-location">{{object?.address}}</p>
                        </div>
                        <img src="/assets/img/Close.svg" (click)="onRemoveObjectFromList(object)">
                    </div>
                </div>
                <!--add-->
                <div *ngIf="this.addObjectField" class="list-item add">
                    <div class="d-flex justify-content-between gap-1">
                        <input [(ngModel)]="objectInput" (keyup)="keyUpObjects($event)"
                               type="text"
                               class="add-obj-input"
                               placeholder="Type to add object"
                               (focus)="objectSelectList = true">
                        <img src="/assets/img/Close.svg" (click)="this.addObjectField = false;">

                        <div class="select-list"  *ngIf="objectSelectList">
                            <div *ngFor="let object of this?.objectsInSelectBox" class="select-item" (click)="onAddObjectToList(object)">
                                <div *ngIf="this?.objectsInSelectBox?.length > 0">
                                    <p class="item-title">{{object?.name}}</p>
                                    <p class="item-location">{{object?.address}}</p>
                                </div>
                                <div *ngIf="this?.objectsInSelectBox?.length == 0">
                                    <p class="item-location">No results</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-start gap-1 mt-2 add" (click)="onAddObject()">
                <img src="/assets/img/plus.svg">
                <p class="add-p">Add object</p>
            </div>
        </div>

        <!--team-->
        <div *ngIf="this.teamTabActive" class="objects-list">
            <div class="list">
                <div *ngFor="let i of [1,2,3,4,5]" class="list-item">
                    <div class="d-flex justify-content-between flex-row gap-1">
                        <div>
                            <p class="item-title">Rikard Malm</p>
                            <p class="item-location">rikardmalm@safetly.se</p>
                        </div>
                        <img src="/assets/img/Close.svg">
                    </div>
                </div>
                <!--add-->
                <div *ngIf="this.addTeamField" class="list-item add">
                    <div class="d-flex justify-content-between gap-1">
                        <input type="text"
                               class="add-obj-input"
                               placeholder="Type to add team member" >
                        <img src="/assets/img/Close.svg" (click)="this.addTeamField = false;">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-start gap-1 mt-2 add" (click)="onAddTeamMember()">
                <img src="/assets/img/plus.svg">
                <p class="add-p">Add team member</p>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                        <span class="cancel-btn"
                              (click)="this.createRegionModal = false;this.addObjectField = false;">Cancel
                        </span>
                    <input type="submit"
                           form="createRegionForm"
                           value="Save changes"
                           [disabled]="!this.createRegionForm.valid || this.objectsInList.length == 0"
                           class="btn save-btn btn-primary"/>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<!--Create object Modal-->
<app-modal *ngIf="this.createObjectModal" class="overlay">
    <div class="popup-header">
        <div class="row">
            <div class="col-8 text-left">
                <h3 class="modal-title">{{'company.objects.create' | translate}}</h3>
            </div>
            <div class="col-4 text-right">
                <span (click)="this.createObjectModal = false" class="close"
                      style="float: right;">
                    <img src="/assets/img/Close.svg" alt="">
                </span>
            </div>
        </div>
    </div>
    <div class="popup-body">

        <form [formGroup]="createObjectForm" (ngSubmit)="onCreateObjectSubmit()"
              id="createObjectForm">
            <div class="form-group">
                <label for="name">{{'company.objects.name' | translate}}</label>
                <input class="form-control" type="text" id="name" formControlName="name" autocomplete="nope"/>
            </div>
            <!--<div class="form-group">-->
                <!--<label for="state">{{'create-assignment.object-state' | translate}}</label>-->
                <!--<select class="form-select"-->
                        <!--formControlName="state"-->
                        <!--id="state"-->
                        <!--(change)="onChangeState()">-->
                    <!--<option *ngFor="let state of states; let i = index"-->
                            <!--[ngValue]="state">{{state.name}}</option>-->
                <!--</select>-->
            <!--</div>-->
            <div class="form-group position-relative">
                <label for="address">{{'company.objects.address' | translate}}</label>
                <input ngx-google-places-autocomplete
                       class="form-control"
                       type="text"
                       id="address"
                       formControlName="address"
                       [options]="{componentRestrictions: {country: 'SE'}, types: ['address']}"
                       (onAddressChange)="handleAddressChange($event)" />

                        <!--<p>Address: {{ userAddress }}</p>-->
                        <!--<p>Latitude: {{ userLatitude }}</p>-->
                        <!--<p>Longitude: {{ userLongitude }}</p>-->
                <!--<input class="form-control" type="text" id="address"-->
                       <!--(keyup)="keyUpAddress($event)"-->
                       <!--autocomplete="nope"-->
                       <!--formControlName="address"/>-->

                <!--<div class="select-list-address" *ngIf="hereSuggestions.length > 0 && addressSelectList">-->
                    <!--<div *ngFor="let here of hereSuggestions" class="select-item" (click)="onPickAddress(here)">-->
                        <!--<div role="button"> &lt;!&ndash; *ngIf="this?.regionsInSelectBox?.length > 0"-&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="item-title">{{here?.address?.street}}</p>&ndash;&gt;-->
<!--&lt;!&ndash;                            <p class="item-location">{{here?.address?.city}}</p>&ndash;&gt;-->
                            <!--<p class="item-title">{{here.label}}</p>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            </div>
        </form>

        <div class="row mb-2" *ngIf="marker && center && pickedAddress">
            <div class="col-12">
                <google-map height="160px" width="100%"
                            [zoom]="zoom"
                            [options]="options"
                            [center]="center">
                            <!--(mapClick)="onChangeObject($event)">-->
                    <map-marker [position]="marker.position"></map-marker>
                </google-map>
            </div>
        </div>

        <!--TAB-->
        <ng-container *ngIf="false">
            <div class="tab">
                <div class="row">
                    <!--                                            <div class="col-12 col-lg-3">-->
                    <!--                                                <p [ngClass]="this.objectsTabActive ? 'tab-active' : ''"-->
                    <!--                                                   class="tab-item-title" (click)="onObjectsTab()">In regions ({{this?.regionsInList?.length}})</p>-->
                    <!--                                                <div *ngIf="this.objectsTabActive" class="blue-line"></div>-->
                    <!--                                            </div>-->
                    <div class="col-12 col-lg-3">
                        <p [ngClass]=" this.teamTabActive ? 'tab-active' : ''"
                           class="tab-item-title" (click)="onTeamTab()">{{'company.objects.team' | translate}} (6)</p>
                        <div *ngIf="this.teamTabActive" class="blue-line"></div>
                    </div>
                </div>
                <div class="line"></div>
            </div>

        </ng-container>
        <!--/TAB-->

        <!--regions-->
        <!--                                    <div *ngIf="this.objectsTabActive" class="objects-list">-->
        <!--                                        <div class="list">-->
        <!--                                            <div *ngFor="let region of this?.regionsInList" class="list-item">-->
        <!--                                                <div class="d-flex justify-content-between flex-row gap-1">-->
        <!--                                                    <p class="item-title">{{region?.name}}</p>-->
        <!--                                                    <img src="/assets/img/Close.svg" (click)="onRemoveRegionFromList(region)">-->
        <!--                                                </div>-->
        <!--                                            </div>-->
        <!--                                            &lt;!&ndash;add&ndash;&gt;-->
        <!--                                            <div *ngIf="this.addRegionField" class="list-item add">-->
        <!--                                                <div class="d-flex justify-content-between gap-1">-->
        <!--                                                    <input [(ngModel)]="regionInput" (keyup)="keyUpRegions($event)"-->
        <!--                                                                type="text"-->
        <!--                                                               class="add-obj-input"-->
        <!--                                                               placeholder="Type here"-->
        <!--                                                               (focus)="regionSelectList = true">-->
        <!--                                                    <img src="/assets/img/Close.svg" (click)="this.addRegionField = false;">-->

        <!--                                                    <div class="select-list" *ngIf="regionSelectList">-->
        <!--                                                        <div *ngFor="let region of this?.regionsInSelectBox" class="select-item" (click)="onAddRegionToList(region)">-->
        <!--                                                            <div *ngIf="this?.regionsInSelectBox?.length > 0">-->
        <!--                                                                <p class="item-title">{{region?.name}}</p>-->
        <!--                                                            </div>-->
        <!--                                                            <div *ngIf="this?.regionsInSelectBox?.length == 0">-->
        <!--                                                                <p class="item-location">No results</p>-->
        <!--                                                            </div>-->
        <!--                                                        </div>-->
        <!--                                                    </div>-->
        <!--                                                </div>-->
        <!--                                            </div>-->
        <!--                                        </div>-->
        <!--                                        <div class="d-flex justify-content-start gap-1 mt-2 add" (click)="onAddRegion()">-->
        <!--                                            <img src="/assets/img/plus.svg">-->
        <!--                                            <p class="add-p">Add region</p>-->
        <!--                                        </div>-->
        <!--                                    </div>-->

        <ng-container *ngIf="false">

        <!--team-->
            <div *ngIf="this.teamTabActive" class="objects-list">
            <div class="list">
                <div *ngFor="let i of [1,2,3,4,5]" class="list-item">
                    <div class="d-flex justify-content-between flex-row gap-1">
                        <div>
                            <p class="item-title">Rikard Malm</p>
                            <p class="item-location">rikardmalm@safetly.se</p>
                        </div>
                        <img src="/assets/img/Close.svg">
                    </div>
                </div>
                <!--add-->
                <div *ngIf="this.addTeamField" class="list-item add">
                    <div class="d-flex justify-content-between gap-1">
                        <input type="text"
                               class="add-obj-input"
                               placeholder="{{'company.objects.type' | translate}}" >
                        <img src="/assets/img/Close.svg" (click)="this.addTeamField = false;">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-start gap-1 mt-2 add" (click)="onAddTeamMember()">
                <img src="/assets/img/plus.svg">
                <p class="add-p">{{'company.objects.add-member' | translate}}</p>
            </div>
        </div>
        </ng-container>

    </div>
    <div class="popup-footer">
        <div class="row">
            <div class="col-12 text-right">
                <div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.createObjectModal = false;this.addObjectField = false;">{{'buttons.cancel' | translate}}
                    </span>
                    <input type="submit"
                           form="createObjectForm"
                           value="{{'buttons.save-changes' | translate}}"
                           [disabled]="!this.createObjectForm.valid"
                           class="btn save-btn btn-primary"/>
                    <!--|| this.regionsInList.length == 0 ----^ --->
                </div>
            </div>
        </div>
    </div>
</app-modal>
