import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as CompanyActions from '../store/company.actions';
import {NgxSpinnerService} from "ngx-spinner";
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-accept-invite',
    templateUrl: './accept-invite.component.html',
    styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit, OnDestroy {

    private token: string;
    private subscription: Subscription;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private spinner: NgxSpinnerService,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.token = params.t;

            this.store.dispatch(new CompanyActions.AcceptInvite({inviteToken: this.token}));
        });

        this.subscription = this.store.subscribe(appState => {
            if (appState.company.loading) {
                this.spinner.show();
            } else {
                this.spinner.hide();
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
