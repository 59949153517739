<div class="top">
	<h1>{{'navigation.reports' | translate}}</h1>
	<div *ngIf="true">
		<div class="header-controls-wrapper d-block d-lg-flex justify-content-between gap-2 mt-5">
			<div class="button report-types-button" (click)="onChooseReportTypes()">
				<div class="d-flex justify-content-center">
					<img src="/assets/img/graph-edit.svg">
					<p class="rep-types-p">{{'report-details.Choose report types' | translate}}</p>
				</div>
			</div>

			<div class="d-flex">
				<div class="d-flex">
					<img src="/assets/img/download-file.svg">
					<p class="export-p">{{'report-details.Export' | translate}}</p>
				</div>
				<div class="period" (clickOutside)="showCalendar = false">
					<div class="flexed">
						<img src="/assets/img/calendar 3.svg">
						<div class="date" (click)="showCalendar = !showCalendar">
							<p class="bold">{{calendarLabel}}</p>
							<p class="faded">{{fromTime | date: 'MMM d, y'}} — {{toTime | date: 'MMM d, y'}}</p>
						</div>
						<div class="arrow-wrapper">
							<img class="left" src="/assets/img/Vector blue left.svg">
							<img class="right" src="/assets/img/Vector blue right.svg">
						</div>
					</div>
					<div *ngIf="showCalendar" class="calendar">
						<form>
							<div class="row">
								<div class="col-10 col-lg-5">
									<div class="form-group">
										<input class="form-control" name="start" type="date" id="start" (change)="onCustomDatePicker(start, end)" #start>
										<label class="form-check-label" for="start">{{'report-details.start-date' | translate}}</label>
									</div>
								</div>
								<div class="col-0 col-lg-2 separator">
									<span>—</span>
								</div>
								<div class="col-10 col-lg-5">
									<div class="form-group">
										<input class="form-control" name="end" type="date" id="end" (change)="onCustomDatePicker(start, end)" #end>
										<label class="form-check-label" for="end">{{'report-details.end-date' | translate}}</label>
									</div>
								</div>
							</div>
						</form>
						<div class="line"></div>
						<p (click)="onSelectDate('thisMonth')">{{'report-details.this-month' | translate}}</p>
						<div class="line"></div>
						<p (click)="onSelectDate('lastMonth')">{{'report-details.last-month' | translate}}</p>
						<div class="line"></div>
						<p (click)="onSelectDate('thisYear')">{{'report-details.this-year' | translate}}</p>
						<div class="line"></div>
						<p (click)="onSelectDate('previousYear')">{{'report-details.previous-year' | translate}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>

</div>

<div class="main-wrapper">
	<div class="row">
		<div class="col-12">
			<div class="inner-wrapper">
				<form [formGroup]="filterForm">
					<div class="d-lg-flex justify-content-start gap-5 d-block text-left info-block flex-wrap">
						<div class="d-flex justify-content-start gap-2">
							<p class="select-label">{{'assignments.filters.object' | translate}}</p>
							<select class="form-select" formControlName="objects"
							        (change)="onFilter('assignment.jobPlace',$event.target.value)">
								<option [value]="0">{{'assignments.filters.all-objects' | translate}}</option>
								<option *ngFor="let object of objects" [value]="object?.id">{{object?.name}},
									{{object?.address}}
								</option>
							</select>
						</div>
						<div class="d-flex justify-content-start gap-2">
							<p class="select-label">{{'assignments.filters.competence' | translate}}</p>
							<select class="form-select" formControlName="skills"
							        (change)="onFilter('assignment.skill',$event.target.value)">
								<option [value]="0">{{'assignments.filters.all-competences' | translate}}</option>
								<option *ngFor="let skill of this?.skills" [value]="skill?.id">{{skill?.name}}</option>
							</select>
						</div>
					</div>
				</form>

				<ng-container *ngIf="reportsStatistics">
					<div class="info">
						<div class="row">
							<div class="info-box"
							     id="report-type-{{report.id}}"
							     *ngFor="let report of reportTypes"
							     [hidden]="report.hidden"
							     (click)="onClickReportType($event, reportsStatistics[report.field].chart)">
								<p class="title">{{report.name}}</p>
								<div class="bottom">
									<div class="flexed">
										<p class="value-29">{{reportsStatistics[report.field].report.now}}
											{{report.unit}} &nbsp;</p>
										<p *ngIf="reportsStatistics[report.field].report.percentDifference != null"
										   [ngClass]="reportsStatistics[report.field].report.percentDifference > 0
										? 'percentage-plus' : 'percentage-minus' ">
											<span *ngIf="reportsStatistics[report.field].report.percentDifference > 0">+</span>{{reportsStatistics[report.field].report.percentDifference}}
											%
										</p>
									</div>
									<p class="bottom-p"
									   *ngIf="reportsStatistics[report.field].report.before != null">
										{{reportsStatistics[report.field].report.before}} {{report.label}}
									</p>
								</div>
							</div>

							<!--<div class="info-box">-->
							<!--<p class="title">Total assignments cost</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value">14 733 kr &nbsp;</p>-->
							<!--<p class="percentage-plus">+ 12 %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">13 023 kr prev. period</p>-->
							<!--</div>-->
							<!--</div>-->
							<!--<div class="info-box">-->
							<!--<p class="title">My Staff salaries</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value">3 752 kr &nbsp;</p>-->
							<!--<p class="percentage-minus">- 52 %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">6 423 kr prev period</p>-->
							<!--</div>-->
							<!--</div>-->
							<!--<div class="info-box">-->
							<!--<p class="title">Safety’s personnel cost</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value">10 981 kr &nbsp;</p>-->
							<!--<p class="percentage-plus">+ 44 %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">8 235 kr prev period</p>-->
							<!--</div>-->
							<!--</div>-->
							<!--<div class="info-box" (click)="onClickReportType($event, reportsStatistics.assignmentHoursReport.chart)">-->
							<!--<p class="title">No. assignment hours</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value-29">{{reportsStatistics.assignmentHoursReport.report.now}} h &nbsp;</p>-->
							<!--<p [ngClass]="reportsStatistics.assignmentHoursReport.report.percentDifference > 0-->
							<!--? 'percentage-plus' : 'percentage-minus' ">{{reportsStatistics.assignmentHoursReport.report.percentDifference}} %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">{{reportsStatistics.assignmentHoursReport.report.before}} h prev period</p>-->
							<!--</div>-->
							<!--</div>-->
							<!--<div class="info-box" (click)="onClickReportType($event, reportsStatistics.arrestReport.chart)">-->
							<!--<p class="title">Arrest</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value-29">{{reportsStatistics.arrestReport.report.now}} &nbsp;</p>-->
							<!--<p [ngClass]="reportsStatistics.arrestReport.report.percentDifference > 0-->
							<!--? 'percentage-plus' : 'percentage-minus' ">{{reportsStatistics.arrestReport.report.percentDifference}} %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">{{reportsStatistics.arrestReport.report.before}} prev period</p>-->
							<!--</div>-->
							<!--</div>-->
							<!--<div class="info-box" (click)="onClickReportType($event, reportsStatistics.custodyReport.chart)">-->
							<!--<p class="title">Taken into custody</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value-29">{{reportsStatistics.custodyReport.report.now}} &nbsp;</p>-->
							<!--<p [ngClass]="reportsStatistics.custodyReport.report.percentDifference > 0-->
							<!--? 'percentage-plus' : 'percentage-minus' ">{{reportsStatistics.custodyReport.report.percentDifference}} %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">{{reportsStatistics.custodyReport.report.before}} prev period</p>-->
							<!--</div>-->
							<!--</div>-->
							<!--<div class="info-box" (click)="onClickReportType($event, reportsStatistics.vandalismReport.chart)">-->
							<!--<p class="title">Vandalism/Graffiti</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value-29">{{reportsStatistics.vandalismReport.report.now}} &nbsp;</p>-->
							<!--<p [ngClass]="reportsStatistics.vandalismReport.report.percentDifference > 0-->
							<!--? 'percentage-plus' : 'percentage-minus' ">{{reportsStatistics.vandalismReport.report.percentDifference}} %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">{{reportsStatistics.vandalismReport.report.before}} prev period</p>-->
							<!--</div>-->
							<!--</div>-->
							<!--<div class="info-box" (click)="onClickReportType($event, reportsStatistics.vandalismReport.chart)">-->
							<!--<p class="title">Banished</p>-->
							<!--<div class="bottom">-->
							<!--<div class="flexed">-->
							<!--<p class="value-29">{{reportsStatistics.vandalismReport.report.now}} &nbsp;</p>-->
							<!--<p [ngClass]="reportsStatistics.vandalismReport.report.percentDifference > 0-->
							<!--? 'percentage-plus' : 'percentage-minus' ">{{reportsStatistics.vandalismReport.report.percentDifference}} %</p>-->
							<!--</div>-->
							<!--<p class="bottom-p">{{reportsStatistics.vandalismReport.report.before}} prev period</p>-->
							<!--</div>-->
							<!--</div>-->
						</div>
					</div>
				</ng-container>

				<!--chart-->
				<div class="chart-wrapper">
					<div id="chart" #chartDiv [hidden]="chartOptions.series[0].data.length == 0">
						<apx-chart
								#chart
								[series]="chartOptions.series"
								[chart]="chartOptions.chart"
								[dataLabels]="chartOptions.dataLabels"
								[plotOptions]="chartOptions.plotOptions"
								[yaxis]="chartOptions.yaxis"
								[legend]="chartOptions.legend"
								[fill]="chartOptions.fill"
								[stroke]="chartOptions.stroke"
								[tooltip]="chartOptions.tooltip"
								[xaxis]="chartOptions.xaxis"
						></apx-chart>
					</div>
					<h3>Reports</h3>
					<div class="table-responsive">
						<!-- TABLE -->
						<table class="table table-hover">
							<thead>
							<tr>
								<th>{{'report-details.Subject' | translate}}</th>
								<th>{{'report-details.Date' | translate}}</th>  <!--[mdbTableSort]="elements" sortBy="id" -->
								<th>{{'report-details.Time' | translate}}</th>
								<th>{{'report-details.# Assignment' | translate}}</th>
								<th>{{'report-details.Object' | translate}}</th>
								<th style="opacity: 0;">Arrow</th>
							</tr>
							</thead>
							<tbody>
							<!--March 12, 2021-->
							<tr *ngFor="let report of reports" routerLink="/components/reports/report/{{report.id}}">
								<td><p>{{report.subject}}</p></td>
								<td><p class="black">{{report.incidentTime | date: 'MMM d, Y'}}</p></td>
								<td><p>{{report.incidentTime | date: 'HH:mm'}}</p></td>
								<td><p>{{report.assignment.id}}</p></td>
								<td><p>{{report.assignment.jobPlace.name}}</p></td>
								<!--<td><p>Arrest #5</p></td>-->
								<td><img src="/assets/img/Arrow-right.svg">
								</td>
							</tr>
							</tbody>
							<tfoot>
							<tr>
								<td colspan="7">
									<div class="showing">
										<img [ngClass]="pagination.currentPage === 1 ? 'disabled' : '' "
										     class="left-arrows" src="/assets/img/Arrow-left end.svg"
										     (click)="onFirstPage()">
										<img [ngClass]="pagination.currentPage === 1 ? 'disabled' : '' "
										     class="left-arrows" src="/assets/img/Arrow-left.svg"
										     (click)="onPreviousPage()">
										<p *ngIf="pagination.total > 0;else noRecords">
											{{'pagination' | translate:
											{
											from: pagination.from,
											to: pagination.to,
											total: pagination.total
											}
											}}
										</p>
										<ng-template #noRecords>
											<p>{{'pagination-no-records' | translate}}</p>
										</ng-template>
										<img [ngClass]="pagination.currentPage === pagination.lastPage ? 'disabled' : '' "
										     class="right-arrows" src="/assets/img/Arrow-right.svg"
										     (click)="onNextPage()">
										<img [ngClass]="pagination?.currentPage === pagination.lastPage ? 'disabled' : '' "
										     class="right-arrows" src="/assets/img/Arrow-right end.svg"
										     (click)="onLastPage()">
									</div>
								</td>
							</tr>
							</tfoot>
						</table>
						<!-- /TABLE -->
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<!--Choose report Types Modal-->
<app-modal *ngIf="this.reportTypesModal">
	<div class="popup-body">
		<!--<p class="faded">Max 8 types can be selected.</p>-->
		<form [formGroup]="reportTypesForm" (ngSubmit)="onApplyTypes()" id="types-form"
		      style="margin-top: 20px;">
			<div class="form-group">
				<div *ngFor="let report of reportTypes; let i = index"
				     class="report-types-checkbox"
				     formArrayName="reportTypeIds">
					<span><img src="/assets/img/6dots.png" alt=""></span>
					<input type="checkbox" [formControlName]="i"
					       id="report-type-id-{{report.id}}"
					       style="margin-right: 8px"/>
					<label for="report-type-id-{{report.id}}">{{report.name}}</label>
				</div>
			</div>
		</form>
	</div>
	<div class="popup-footer">
		<div class="row">
			<div class="col-12 text-right">
				<div class="flexed-buttons">
                    <span class="cancel-btn"
                          (click)="this.reportTypesModal = false">Cancel
                    </span>
					<input type="submit"
					       form="types-form"
					       value="Save"
					       class="btn save-btn btn-primary"/>
				</div>
			</div>
		</div>
	</div>
</app-modal>
