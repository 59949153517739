import {Component, Inject, Input, OnInit, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {

    @Input() modalType = 1;

    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.document.body.style.overflowY = 'hidden';

    }

    ngOnDestroy(): void {
        this.document.body.style.overflowY = 'auto';
    }

}
