import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import { exhaustMap, take} from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class Interceptor implements HttpInterceptor{
    constructor(private store: Store<fromApp.AppState>){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{

        if (localStorage.getItem('jwt')){
            req = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + localStorage.getItem('jwt'),
                },
            });
        }
        return next.handle(req);
    }
}
