import {Action} from '@ngrx/store';
import {PaginationModel} from '../../../models/pagination.model';
import {ConversationModel} from "../../../models/conversation.model";
import {MessageModel} from "../../../models/message.model";

export const LOAD_CONVERSATIONS = '[Messages] Load Conversations';
export const LOAD_CONVERSATIONS_SUCCESS = '[Messages] Load Conversations Success';
export const LOAD_CONVERSATIONS_FAILED = '[Messages] Load Conversations Failed';
export const CLEAR_CONVERSATIONS= '[Messages] Clear Conversations';

export const LOAD_MESSAGES = '[Messages] Load Conversation Messages';
export const LOAD_MESSAGES_SUCCESS = '[Messages] Load Conversation Messages Success';
export const LOAD_MESSAGES_FAILED = '[Messages] Load Conversation Messages Failed';
export const CLEAR_MESSAGES = '[Messages] Clear Messages';

export const SEND_MESSAGE = '[Messages] Send Message';
export const SEND_MESSAGE_SUCCESS = '[Messages] Send Message Success';
export const SEND_MESSAGE_FAILED = '[Messages] Send Message Failed';

export const NEW_MESSAGE = '[Messages] New Message';
export const MESSAGE_SEEN = '[Messages] Message Seen';
export const ALL_MESSAGES_COUNTER = '[Messages] All Messages Counter';

export const UPDATE_CONVERSATION_UNSEEN_COUNT = '[Messages] Update Conversation Unseen Count';
export const RESET_CONVERSATION_UNSEEN_COUNT = '[Messages] Reset Conversation Unseen Count';

export class UpdateConversationUnseenCount implements Action {
    readonly type = UPDATE_CONVERSATION_UNSEEN_COUNT;

    constructor(public payload: {
        conversationId: number,
        count: any,
    }) {
    }
}

export class MessageSeen implements Action {
    readonly type = MESSAGE_SEEN;

    constructor(public payload: {
        conversationId: number,
        messageId: number,
        customerId: number,
    }) {
    }
}
export class ResetConversationUnseenCount implements Action {
    readonly type = RESET_CONVERSATION_UNSEEN_COUNT;

    constructor(public payload: {
        conversationId: number,
    }) {
    }
}

export class LoadConversations implements Action {
    readonly type = LOAD_CONVERSATIONS;

    constructor(public payload: {
        customerId: number,
        conversationId: any,
    }) {
    }
}

export class LoadConversationsSuccess implements Action {
    readonly type = LOAD_CONVERSATIONS_SUCCESS;

    constructor(public payload: {
        conversations: ConversationModel[],
        pagination: PaginationModel,
        totalMessagesUnseenCount: number
    }) {
    }
}

export class LoadConversationsFailed implements Action {
    readonly type = LOAD_CONVERSATIONS_FAILED;
}

export class ClearConversations implements Action {
    readonly type = CLEAR_CONVERSATIONS;
}

export class LoadMessages implements Action {
    readonly type = LOAD_MESSAGES;

    constructor(public payload: {
        customerId: number,
        conversationId: number
    }) {
    }
}

export class LoadMessagesSuccess implements Action {
    readonly type = LOAD_MESSAGES_SUCCESS;

    constructor(public payload: {
        messages: MessageModel[],
        pagination: PaginationModel,
        totalMessagesUnseenCount: number,
        conversationId: number,
    }) {
    }
}

export class LoadMessagesFailed implements Action {
    readonly type = LOAD_MESSAGES_FAILED;
}

export class ClearMessages implements Action {
    readonly type = CLEAR_MESSAGES;
}

export class SendMessage implements Action {
    readonly type = SEND_MESSAGE;

    constructor(public payload: {
        customerId: number,
        conversationId: number,
        body: string
    }) {
    }
}

export class SendMessageSuccess implements Action {
    readonly type = SEND_MESSAGE_SUCCESS;

    constructor(public payload: { message: MessageModel }) {
    }
}

export class SendMessageFailed implements Action {
    readonly type = SEND_MESSAGE_FAILED;
}

export class NewMessage implements Action {
    readonly type = NEW_MESSAGE;
    constructor(public payload: MessageModel) {
    }
}

export type MessagesActions = LoadConversations
    | LoadConversationsSuccess
    | LoadConversationsFailed
    | LoadMessages
    | LoadMessagesSuccess
    | LoadMessagesFailed
    | SendMessage
    | SendMessageSuccess
    | SendMessageFailed
    | ClearMessages
    | ClearConversations
    | NewMessage
    | UpdateConversationUnseenCount
    | ResetConversationUnseenCount
    | MessageSeen
    ;
