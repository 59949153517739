import { Component, OnInit } from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router,  Event} from "@angular/router";

@Component({
  selector: 'app-progress-bar-request',
  templateUrl: './progress-bar-request.component.html',
  styleUrls: ['./progress-bar-request.component.scss']
})
export class ProgressBarRequestComponent implements OnInit {

    progressValue = 0;
    progressColor = 'primary';

    progressTimer: number;

    stopProgress = false;

    constructor(private router: Router) {
        this.router.events.subscribe((event: Event) => {
            this.navigationObserver(event);
        });
    }

    ngOnInit(): void {
    }

    private navigationObserver(event: Event): void {
        if (event instanceof NavigationStart) {
            this.progressValue = 0;
            this.progressTimer = setInterval(() => {
                this.loading();
            }, 25);
        }

        if (event instanceof NavigationEnd) {

            // When the navigation finishes, fill the bar completely
            this.progressValue = 99;
            setTimeout(() => {
                this.progressValue = 100;
            }, 500);

        }

        if (event instanceof NavigationCancel) {
            this.progressValue = 0;
            clearInterval(this.progressTimer);
        }

        if (event instanceof NavigationError) {
            this.progressValue = 0;
            clearInterval(this.progressTimer);
        }

    }

    // Function to increase the value of the progress bar
    private loading(): void {
        if (this.progressValue >= 95 || this.stopProgress) {
            clearInterval(this.progressTimer);
        } else {
            this.progressValue++;
        }
    }

}
