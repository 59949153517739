import {CustomerModel} from '../../models/customer.model';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from './auth.reducer';
import * as fromApp from '../../store/app.reducer';
import * as AuthActions from './auth.actions';
import * as SharedActions from '../../shared-store/shared.actions';
import {environment} from '../../../environments/environment';
import {catchError, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {EmployeeModel} from '../../models/employee.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PaginationModel} from "../../models/pagination.model";
import { TranslateService } from '@ngx-translate/core';
import * as CompanyActions from '../../components/company/store/company.actions';
import { BillingInformationModel } from '../../models/billingInformation.model';
import { ErrorModel } from '../../models/error.model';

interface AuthResponseData {
    jwt: any,
    employee: EmployeeModel;
}

@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private _snackBar: MatSnackBar,
        private translate: TranslateService,
        private store: Store<fromApp.AppState>,
    ) {
    }

    sendRegistrationCode = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.SIGNUP_START),
            switchMap((storeData: AuthActions.SignupStart) => {
                return this.http.post(environment.apiUrl + '/auth/employees/send-registration-code', {
                        email: storeData.payload.email
                    })
                    .pipe(
                        switchMap(() => {
                            this.router.navigate(['/register/security-code']);
                            return of(new AuthActions.SignupCodeSent());
                        }),
                        catchError(error => {
                            this._snackBar.open(error.error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.LoginFailed());
                        }),
                    )
            }),
        );
    });

    register = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.SIGNUP_CODE),
            withLatestFrom(this.store.select('auth')),
            switchMap(([action, state]: [AuthActions.SignupCode, State]) => {
                let data: any = {};
                data.email = state.email;
                data.code = action.payload.code;
                if (state.inviteToken) {
                    data.inviteToken = state.inviteToken
                }
                return this.http.post(environment.apiUrl + '/auth/employees/register', data)
                    .pipe(
                        switchMap((resData: { data: EmployeeModel, jwt: { accessToken: string, expiresIn: number, tokenType: string } }) => {
                            localStorage.setItem('jwt', resData.jwt.accessToken);

                            this.setLocaleLanguage(resData.data);

                            this.router.navigate(['/components/home']);

                            return [
                                new AuthActions.LoginSuccess({employee: resData.data, jwt: resData.jwt}),
                                // new AuthActions.LoadCustomers({employeeId: resData.data.id}),
                                new AuthActions.ClearInviteToken()
                            ];
                        }),
                        catchError(error => {
                            this._snackBar.open(error.error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.LoginFailed());
                        }),
                    )
            }),
        );
    });

    loginLink = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.LOGIN_LINK),
            switchMap((storeData: AuthActions.SendLoginLink) => {
                return this.http.post(environment.apiUrl + '/auth/employees/send-login-email', {
                        email: storeData.payload.email,
                    })
                    .pipe(
                        switchMap(() => {
                            this.router.navigate(['/login-email-step2']);
                            this._snackBar.open('Link send successfully! Check your email', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.LoginLinkSent());
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to send login link! Please try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.LoginFailed());
                        }),
                    )
            }),
        );
    });

    login = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.LOGIN_START),
            withLatestFrom(this.store.select('auth')),
            switchMap(([action, state]: [AuthActions.LoginStart, State]) => {
                let data: any = {};
                data.email = action.payload.email;
                data.password = action.payload.password;
                if (state.inviteToken) {
                    data.inviteToken = state.inviteToken
                }
                return this.http.post(environment.apiUrl + '/auth/employees/login', data)
                    .pipe(
                        switchMap((resData: { data: EmployeeModel, jwt: { accessToken: string, expiresIn: number, tokenType: string } }) => {
                            localStorage.setItem('jwt', resData.jwt.accessToken);
                            this.router.navigate(['/components/home']);

                            this.setLocaleLanguage(resData.data);

                            this._snackBar.open('You have successfully logged in', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });

                            return [
                                new AuthActions.LoginSuccess({employee: resData.data, jwt: resData.jwt}),
                                // new AuthActions.LoadCustomersSuccess({customers: resData.data.customers}),
                                new AuthActions.ClearInviteToken(),
                                new SharedActions.SendFirebaseTokenToServer(),
                            ];
                        }),
                        catchError(error => {
                            this._snackBar.open(error.error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.LoginFailed());
                        }),
                    )
            }),
        );
    });

    autologin = createEffect(() => {
        return this.actions$.pipe(
            // ofType(AuthActions.AUTOLOGIN, ROOT_EFFECTS_INIT),
            ofType(AuthActions.AUTOLOGIN),
            switchMap(() => {
                    const token = localStorage.getItem('jwt');

                    return this.http.get(environment.apiUrl + '/auth/employees/autologin', {
                            headers: {
                                Authorization: 'Bearer ' + token,
                            },
                        })
                        .pipe(
                            switchMap((resData: { data: EmployeeModel, jwt: { accessToken: string, expiresIn: number, tokenType: string } }) => {
                                localStorage.setItem('jwt', resData.jwt.accessToken);

                                this.setLocaleLanguage(resData.data);
                                // console.log(resData.jwt.accessToken);
                                return [
                                    new AuthActions.LoginSuccess({
                                        jwt: resData.jwt,
                                        employee: resData.data,
                                    }),
                                    // new AuthActions.LoadCustomersSuccess({customers: resData.data.customers}),
                                    new SharedActions.SendFirebaseTokenToServer(),
                                ];
                            }),
                            catchError(error => {
                                localStorage.removeItem('jwt');
                                this.router.navigate(['/']);
                                this._snackBar.open(error.error.error, 'Close', {
                                    duration: 3000,
                                    panelClass: ['snackbar']
                                });
                                return of(new AuthActions.LoginFailed());
                            }),
                        );
                },
            ),
        );
    });

    changePassword = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.CHANGE_PASSWORD),
            switchMap((storeData: AuthActions.ChangePassword) => {
                return this.http.put(environment.apiUrl + '/employees/' + storeData.payload.employeeId + '/update-password', {
                        oldPassword: storeData.payload.oldPassword,
                        password: storeData.payload.password,
                        // confirmPassword: storeData.payload.confirmPassword
                        password_confirmation: storeData.payload.confirmPassword
                    })
                    .pipe(
                        switchMap((resData: { data: EmployeeModel }) => {
                            this._snackBar.open('Password is successfully changed', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.ChangePasswordSuccess({employee: resData.data}));
                        }),
                        catchError(error => {
                            this._snackBar.open(error.error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.ChangePasswordFailed());
                        }),
                    )
            }),
        );
    });

    updateProfile = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.UPDATE_PROFILE),
            switchMap((storeData: AuthActions.UpdateProfile) => {

                let data: any = {};
                if (storeData.payload.firstName)
                    data.firstName = storeData.payload.firstName;
                if (storeData.payload.lastName)
                    data.lastName = storeData.payload.lastName;
                if (storeData.payload.email)
                    data.email = storeData.payload.email;
                if (storeData.payload.locale)
                    data.locale = storeData.payload.locale;
                if (storeData.payload.phone)
                    data.phone = storeData.payload.phone;

                return this.http.put(environment.apiUrl + '/employees/' + storeData.payload.employeeId, data)
                    .pipe(
                        switchMap((resData: { data: EmployeeModel }) => {
                            this.setLocaleLanguage(resData.data);
                            this._snackBar.open('Profile successfully updated', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.UpdateProfileSuccess({employee: resData.data}));
                        }),
                        catchError(error => {
                            this._snackBar.open(error.error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.UpdateProfileFailed());
                        }),
                    )
            }),
        );
    });

    verifyEmail = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.VERIFY_NEW_EMAIL),
            switchMap((storeData: AuthActions.VerifyNewEmail) => {

                return this.http.post(environment.apiUrl + '/employees/' + storeData.payload.employeeId + '/request-email-change', {
                        email: storeData.payload.email,
                    })
                    .pipe(
                        switchMap(() => {
                            this._snackBar.open('Check your email to confirm the change!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.VerifyNewEmailSuccess());
                        }),
                        catchError(error => {
                            this._snackBar.open(error.error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.UpdateProfileFailed());
                        }),
                    )
            }),
        );
    });

    updateEmail = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.UPDATE_EMAIL),
            switchMap((storeData: AuthActions.UpdateEmail) => {

                return this.http.put(environment.apiUrl + '/employees/' + storeData.payload.token + '/update-email', {
                        password: storeData.payload.password,
                    })
                    .pipe(
                        switchMap((resData: { data: EmployeeModel }) => {
                            this._snackBar.open('Email successfully changed', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            this.router.navigate(['/components/profile'])
                            return of(new AuthActions.UpdateProfileSuccess({employee: resData.data}));
                        }),
                        catchError(error => {
                            this._snackBar.open(error.error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.UpdateProfileFailed());
                        }),
                    )
            }),
        );
    });

    // loadCustomers = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(AuthActions.LOAD_CUSTOMERS),
    //         switchMap((storeData: AuthActions.LoadCustomers) => {
    //
    //             return this.http.get(environment.apiUrl + '/customers')
    //                 .pipe(
    //                     switchMap((resData: { data: CustomerModel[], pagination: PaginationModel }) => {
    //                         return of(new AuthActions.LoadCustomersSuccess({
    //                             customers: resData.data,
    //                             // pagination: resData.pagination
    //                         }));
    //                     }),
    //                     catchError(error => {
    //                         return of(new AuthActions.LoadCustomersFailed());
    //                     }),
    //                 )
    //         }),
    //     );
    // });

    createCustomer = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.CREATE_CUSTOMER),
            switchMap((action: AuthActions.CreateCustomer) => {
                // console.log(storeData.);
                // let data: any = {};
                // if (storeData.payload.email)
                //     data.email = storeData.payload.email;
                // if (storeData.payload.password)
                //     data.password = storeData.payload.password;
                // if (storeData.payload.idNumber)
                //     data.idNumber = storeData.payload.idNumber;
                // if (storeData.payload.phone)
                //     data.phone = storeData.payload.phone;
                // if (storeData.payload.companyName)
                //     data.companyName = storeData.payload.companyName;
                // if (storeData.payload.image)
                //     data.image = storeData.payload.image;

                return this.http.post(environment.apiUrl + '/customers', action.payload)
                    .pipe(
                        switchMap((resData: {data: CustomerModel}) => {
                            this._snackBar.open('Company created successfully!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.CreateCustomerSuccess({
                                customer: resData.data,
                            }));
                        }),
                        catchError((httpErrorResponse: HttpErrorResponse) => {
                            let error = httpErrorResponse.error as ErrorModel;
                            this._snackBar.open(error.error, 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.CreateCustomerFailed());
                        }),
                    )
            }),
        );
    });

    updateCustomer = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.UPDATE_CUSTOMER),
            switchMap((storeData: AuthActions.UpdateCustomer) => {

                let data: any = {};
                if (storeData.payload.inviteToken)
                    data.inviteToken = storeData.payload.inviteToken;

                return this.http.put(environment.apiUrl + '/customers/' + storeData.payload.customerId, data)
                    .pipe(
                        switchMap((resData: {data: CustomerModel}) => {
                            this._snackBar.open('Action success!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.CreateCustomerSuccess({
                                customer: resData.data,
                            }));
                        }),
                        catchError(error => {
                            this._snackBar.open('Action failed!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new AuthActions.CreateCustomerFailed());
                        }),
                    )
            }),
        );
    });

    // loadBillings = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(AuthActions.LOAD_BILLING_COLLECTION),
    //         switchMap((storeData: CompanyActions.LoadBillingInfoCollection) => {
    //             return this.http.get(environment.apiUrl + '/customers/' + storeData.payload.customerId + '/billing-information')
    //                        .pipe(
    //                            switchMap((resData: { data: BillingInformationModel[] }) => {
    //                                return of(new CompanyActions.LoadBillingInfoCollectionSuccess({billings: resData.data}));
    //                            }),
    //                            catchError(error => {
    //                                return of(new CompanyActions.LoadBillingInfoCollectionFailed());
    //                            }),
    //                        )
    //         }),
    //     );
    // });

    // loadBilling = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(CompanyActions.LOAD_BILLING_INFO),
    //         switchMap((action: CompanyActions.LoadBillingInfo) => {
    //             return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/billing-information/${action.payload.billingId}`)
    //                        .pipe(
    //                            switchMap((resData: { data: BillingInformationModel }) => {
    //                                return of(new CompanyActions.LoadBillingInfoSuccess({billing: resData.data}));
    //                            }),
    //                            catchError(error => {
    //                                return of(new CompanyActions.LoadBillingInfoFailed());
    //                            }),
    //                        )
    //         }),
    //     );
    // });

    updateBilling = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.UPDATE_BILLING_INFO),
            switchMap((action: AuthActions.UpdateBillingInfo) => {

                return this.http.put(environment.apiUrl + `/customers/${action.payload.customerId}/billing-information/${action.payload.billingId}`,
                    action.payload.data)
                           .pipe(
                               switchMap((resData: { data: BillingInformationModel }) => {
                                   this._snackBar.open('Billing information is successfully updated', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar']
                                   });
                                   return of(new AuthActions.UpdateBillingInfoSuccess({billing: resData.data}));
                               }),
                               catchError(error => {
                                   this._snackBar.open('Failed to update billing information', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar']
                                   });
                                   return of(new AuthActions.UpdateBillingInfoFailed());
                               }),
                           )
            }),
        );
    });

    createBilling = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.CREATE_BILLING_INFO),
            switchMap((action: AuthActions.CreateBillingInfo) => {

                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/billing-information`, {
                               customerId: action.payload.customerId,
                               companyName: action.payload.companyName,
                               companyNumber: action.payload.companyNumber,
                               address: action.payload.address,
                               addressSecond: action.payload.addressSecond,
                               postalCode: action.payload.postalCode,
                               city: action.payload.city,
                               contactName: action.payload.contactName,
                               phone: action.payload.phone,
                               email: action.payload.email
                           })
                           .pipe(
                               switchMap((resData: { data: BillingInformationModel }) => {
                                   this._snackBar.open('Billing information is successfully created', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar']
                                   });
                                   return of(new AuthActions.CreateBillingInfoSuccess({billing: resData.data}));
                               }),
                               catchError(error => {
                                   this._snackBar.open('Failed to create billing information', 'Close', {
                                       duration: 3000,
                                       panelClass: ['snackbar']
                                   });
                                   return of(new AuthActions.CreateBillingInfoFailed());
                               }),
                           )
            }),
        );
    });

    private setLocaleLanguage(employee: EmployeeModel) {
        localStorage.setItem('locale', employee.locale);

        this.translate.setDefaultLang('en');
        this.translate.use(employee.locale);
    }
}
