import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByDate'
})
export class GroupByDatePipe implements PipeTransform {

    transform(collection: Array<any>, property: string = 'date'): Array<any> {

        if (!collection) {
            return null;
        }

        const c = collection.reduce((groups, item) => {
            let propertyName = item[property];

            const group = (groups[propertyName] || []);
            group.push(item);
            groups[propertyName] = group;
            return groups;
        }, {});

        const res = [];
        for (const key in c) {
            if (c.hasOwnProperty(key)) {
                res.push({
                    groupBy: key,
                    data: c[key]
                });
            }
        }

        return res;

    }

}
