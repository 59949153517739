import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {CustomerModel} from "../../../models/customer.model";
import {Store} from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import {MatSnackBar} from "@angular/material/snack-bar";
import { FormBuilder, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from "@angular/router";
import * as AssignmentsActions from "../store/assignments.actions";
import {AssignmentModel} from "../../../models/assignment.model";
import {GuardModel} from "../../../models/guard.model";
import {PaginationModel} from "../../../models/pagination.model";
import { SelectShiftOnOpenAssignment } from '../store/assignments.actions';
import { GuardShiftStatusConst } from '../../../constants/guard.shift.status.const';
import { ReportModel } from '../../../models/report.model';
import * as moment from 'moment';
import { ShiftModel } from '../../../models/shift.model';
import { shiftDurationValidator } from '../../../validators/shift-duration.validator';
import { ShiftsStatusConst } from '../../../constants/shifts-status.const';
import { Actions, ofType } from '@ngrx/effects';
import { first, withLatestFrom } from 'rxjs/operators';
@Component({
    selector: 'app-assignment-details',
    templateUrl: './assignment-details.component.html',
    styleUrls: ['./assignment-details.component.scss']
})
export class AssignmentDetailsComponent implements OnInit, OnDestroy {
    minShiftDuration = 5;
    maxShiftDuration = 13;

    momentClass = moment;
    subscription: Subscription;
    customer: CustomerModel;
    assignment: AssignmentModel;

    pickTeamModal = false;

    //for pick team
    selectedLeaders: number[] = [];
    selectedPersonnel: number[] = [];
    guards: GuardModel[];
    pagination: PaginationModel;
    isLastPage: boolean;
    // /for pick team

    rate: 4;
    checked = false;
    selectedShift = null;
    selectedShiftId: number;
    readyForAproveHoursStatuses = [
        this.guardShiftStatusConst.CHECKEDOUT
    ];
    showCancelAssignmentModal = false;
    guardDetail: GuardModel = null;

    editShiftHoursModal = false;

    updateShiftHoursForm = this.fb.group({
        startTime: ['', Validators.required],
        endTime: ['', Validators.required],
        shift: [{}],
        shiftDuration: this.fb.control({
            value: '',
            readonly: true
        }, [Validators.required, shiftDurationValidator])
    });

    constructor(private store: Store<fromApp.AppState>,
                private route: ActivatedRoute,
                private router: Router,
                private fb: FormBuilder,
                private actions$: Actions,
                public shiftStatusConst: ShiftsStatusConst,
                public guardShiftStatusConst: GuardShiftStatusConst) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            if (params.shiftId) {
                this.store.dispatch(new AssignmentsActions.SelectShiftOnOpenAssignment(+params.shiftId));
            }
        });
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.assignment = appState.assignments.assignment;
            this.guards = appState.assignments.guards;

            this.selectedShift = appState.assignments.selectedShift;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public toDate(string): Date {
        return new Date(string);
    }

    //pick team
    onScrollGuards(event): void {
        const height = document.getElementById('leaders').scrollHeight;
        const elementHeight = document.getElementById('leaders').offsetHeight;
        const scrollBottom = -document.getElementById('leaders').scrollTop;

        const height2 = document.getElementById('personnel').scrollHeight;
        const elementHeight2 = document.getElementById('personnel').offsetHeight;
        const scrollBottom2 = -document.getElementById('personnel').scrollTop;

        // if (!this.isLastPage && ((scrollBottom >= (height - elementHeight) * 0.7) || (scrollBottom2 >= (height2 - elementHeight2) * 0.7))) {
        //     this.store.dispatch(new AssignmentsActions.LoadObjectGuards({
        //         jobPlaceId: this?.assignment?.jobPlace?.id,
        //         skillId: this?.assignment?.skill?.id,
        //         uniformId: this?.assignment?.uniform?.id,
        //         dates: createData.dates,
        //         timezone: 'Europe/Belgrade',
        //     }));
        // }
    }

    public onChangeLeader(event: number, checked: boolean): void {

        if (checked) {

            if (this.selectedLeaders.length == this?.assignment?.numberOfLeaders) {
                return;
            }

            if (this.selectedPersonnel.find(p => p == event)) {
                let index = this.selectedPersonnel.indexOf(event);
                this.selectedPersonnel.splice(index, 1);
            }
            this.selectedLeaders.push(event);
        } else {
            let index = this.selectedLeaders.indexOf(event);
            this.selectedLeaders.splice(index, 1);
        }
    }

    public onChangePersonnel(event: number, checked: boolean): void {
        if (checked) {

            if (this.selectedPersonnel.length == this?.assignment?.numberOfPersonnel) {
                return;
            }

            if (this.selectedLeaders.find(p => p == event)) {
                let index = this.selectedLeaders.indexOf(event);
                this.selectedLeaders.splice(index, 1);
            }
            this.selectedPersonnel.push(event);
        } else {
            let index = this.selectedPersonnel.indexOf(event);
            this.selectedPersonnel.splice(index, 1);
        }
    }

    public isSelectedLeader(id: number) {
        return this.selectedLeaders.find(l => l == id);
    }

    public isSelectedPersonnel(id: number) {
        return this.selectedPersonnel.find(l => l == id);
    }

    public isDisabledLeader(id: number): boolean {
        return this.selectedLeaders.length == this?.assignment?.numberOfLeaders && !this.selectedLeaders.find(e => e == id);
    }

    public isDisabledPersonnel(id: number): boolean {
        return this.selectedPersonnel.length == (this?.assignment?.numberOfPersonnel - this?.assignment?.numberOfLeaders) && !this.selectedPersonnel.find(e => e == id)
    }

    public onSaveTeam(): void {
        // console.log(this.selectedLeaders);
        // console.log(this.selectedPersonnel);
    }
    // /pick team

    public onSelectShift(shift) {

        // this.selectedShift = shift;
        this.selectedPersonnel = [];
        // console.log(this.selectedShift);

        this.store.dispatch(new AssignmentsActions.SelectShiftOnOpenAssignment(shift.id));

    }

    public onPickApplicants() {
        this.store.dispatch(new AssignmentsActions.AcceptGuardsForShift({
            shiftId: this.selectedShift.id,
            guardIds: this.selectedPersonnel,
        }));

        this.pickTeamModal = false;

    }

    public onRateGuard(rate, guardId) {
        if (isNaN(rate)) {
            return;
        }

        this.store.dispatch(new AssignmentsActions.RateGuardForShift({
            shiftId: this.selectedShift.id,
            guardId,
            rate
        }))
    }

    onApproveHours(shiftId, guardId, status) {
        this.store.dispatch(new AssignmentsActions.ShiftApproveGuardHours({
            shiftId,
            guardId,
            status,
        }))
    }

    onCancelAssignment() {
        this.store.dispatch(new AssignmentsActions.CancelAssignment({
            assignmentId: this.assignment.id,
            customerId: this.customer.id,
        }))
        this.showCancelAssignmentModal = false;
    }

    onEditShiftHours(event: Event, shift: ShiftModel): void {
        event.stopPropagation();
        this.editShiftHoursModal = true;
        this.updateShiftHoursForm.patchValue({
            startTime: moment(shift.startAt).format('HH:mm'),
            endTime: moment(shift.endAt).format('HH:mm'),
            shift: shift,
            shiftDuration: moment.duration(moment(shift.endAt).diff(moment(shift.startAt))).format('HH:mm')
        })
    }

    onUpdateShiftHours(): void {
        if (this.updateShiftHoursForm.invalid) {
            return;
        }
        let shift = this.updateShiftHoursForm.get('shift').value as ShiftModel;
        let date = moment(shift.startAt, 'YYYY-MM-DD');
        let startTime = moment(this.updateShiftHoursForm.get('startTime').value, 'HH:mm');
        let endTime = moment(this.updateShiftHoursForm.get('endTime').value, 'HH:mm');

        let startAt = date.clone().set({
            hour: startTime.get('hour'),
            minute: startTime.get('minute'),
            second: 0,
        });

        let endAt = date.clone().set({
            hour: endTime.get('hour'),
            minute: endTime.get('minute'),
            second: 0,
        });

        if (startAt.isAfter(endAt)) {
            endAt.add(1, 'days');
        }

        let payload = {
            id: shift.id,
            startAt: startAt.toISOString(),
            endAt: endAt.toISOString(),
        };

        this.store.dispatch(new AssignmentsActions.UpdateShift(payload));
        this.editShiftHoursModal = false;

        this.actions$.pipe(
            ofType(AssignmentsActions.UPDATE_SHIFT_SUCCESS, AssignmentsActions.UPDATE_SHIFT_FAILED),
            withLatestFrom(this.store.select('auth', 'customer')),
            first()
        ).subscribe(([action, customer]) => {
            this.store.dispatch(new AssignmentsActions.LoadAssignmentDetails({
                customerId: customer.id,
                assignmentsId: this.assignment.id
            }));
        })
    }

    onChangeShiftTime(shift: ShiftModel): void {
        let date = moment(shift.startAt, 'YYYY-MM-DD');
        let startTime = moment(this.updateShiftHoursForm.get('startTime').value, 'HH:mm');
        let endTime = moment(this.updateShiftHoursForm.get('endTime').value, 'HH:mm');

        let startAt = date.clone().set({
            hour: startTime.get('hour'),
            minute: startTime.get('minute'),
            second: 0,
        });

        let endAt = date.clone().set({
            hour: endTime.get('hour'),
            minute: endTime.get('minute'),
            second: 0,
        });

        if (startAt.isAfter(endAt)) {
            endAt.add(1, 'days');
        }

        this.updateShiftHoursForm.patchValue({
            shiftDuration: moment.duration(endAt.diff(startAt)).format('HH:mm'),
        });
    }

}
