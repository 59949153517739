import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromApp from './../../store/app.reducer';
import * as AuthActions from './../store/auth.actions';

@Component({
    selector: 'app-login-password',
    templateUrl: './login-password.component.html',
    styleUrls: ['./login-password.component.scss']
})
export class LoginPasswordComponent implements OnInit {

    public LoginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
    });
    private inviteToken: string = null;

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private store: Store<fromApp.AppState>) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.inviteToken = params.t;
            if (this.inviteToken) {
                this.store.dispatch(new AuthActions.SetInviteToken({inviteToken: this.inviteToken}));
            }

        });
    }

    get email() {
        return this.LoginForm.get('email');
    }

    get password() {
        return this.LoginForm.get('password');
    }

    onLogin(): void {
        let data: any = {};
        data.email = this.LoginForm.get('email').value;
        data.password = this.LoginForm.get('password').value;
        // if (this.inviteToken) {
        //     data.inviteToken = this.inviteToken
        // }
        this.store.dispatch(new AuthActions.LoginStart(data));
    }

}
