import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import { Actions, ofType } from '@ngrx/effects';
import { audit, delay, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import * as AssignmentsActions from './assignments/store/assignments.actions';
import * as CompanyActions from './company/store/company.actions';
import * as MyStaffActions from './my-staff/store/my-staff.actions';
import * as SharedActions from '../shared-store/shared.actions';
import { createData } from './assignments/create-assignment/create-object';
import * as MessageActions from './messages/store/messages.actions';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class EmployeeResolverResolver implements Resolve<boolean> {

    constructor(private store: Store<fromApp.AppState>, private actions$: Actions, private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        // return of(true);
        switch (route.routeConfig.path) {
            case 'home':
                this.store.dispatch(new AssignmentsActions.LoadEmployeeTodos({
                    page: 1,
                }));
                return this.store.select('auth').pipe(
                    filter((authState) => authState.employee !== null),
                    take(1),
                    switchMap(authState => {
                        this.store.dispatch(new SharedActions.LoadNews());
                        if (authState.customer) {
                            this.store.dispatch(new AssignmentsActions.LoadAssignmentsStatistics());
                            this.store.dispatch(new AssignmentsActions.LoadAssignments({
                                customerId: authState.customer.id,
                                page: 1,
                            }));
                            this.store.dispatch(new AssignmentsActions.LoadCustomerTodos({
                                page: 1,
                            }));
                        }
                        return of(true);
                    }));


            // ).subscribe((authState) => {
            //
            //
            //     if (authState.employee.customers.length > 0) {
            //         // this.store.dispatch(new Em)
            //         let customerId = authState.employee.customers[0].id;
            //         this.store.dispatch(new AssignmentsActions.LoadAssignmentsStatistics());
            //         this.store.dispatch(new AssignmentsActions.LoadAssignments({
            //             customerId: customerId,
            //             page: 1,
            //         }));
            //         this.store.dispatch(new AssignmentsActions.LoadCustomerTodos({
            //             page: 1,
            //         }));
            //     }
            //
            //
            // });
            // return this.actions$.pipe(
            //     ofType(AssignmentsActions.LOAD_ASSIGNMENTS_SUCCESS, AssignmentsActions.LOAD_ASSIGNMENTS_FAILED),
            //     take(1),
            // );
            case 'report/:id':
                return this.store
                           .pipe(
                               filter(state => state.auth.customer != null),
                               take(1),
                               switchMap(state => {
                                   let report = state.assignments.reports.find(item => {
                                       return item.id == route.params.id;
                                   });
                                   if (report) {
                                       this.store.dispatch(new AssignmentsActions.LoadReportSuccess({data: report}));
                                       return of(true);
                                   }
                                   this.store.dispatch(new AssignmentsActions.LoadReport({
                                       reportId: route.params.id,
                                       customerId: state.auth.customer.id,
                                   }));

                                   return this.actions$.pipe(
                                       ofType(AssignmentsActions.LOAD_REPORT_SUCCESS, AssignmentsActions.LOAD_REPORT_FAILED),
                                       take(1),
                                   );
                               }),
                           );
            case 'reports-details':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new AssignmentsActions.LoadReports({
                            customerId: authState.customer.id,
                            page: 1,
                            params: {},
                            fromTime: moment().subtract(1, 'month').startOf('month').unix(),
                            toTime: moment().subtract(1, 'month').endOf('month').unix(),
                        }));
                        this.store.dispatch(new AssignmentsActions.LoadReportsStatistics({
                            customerId: authState.customer.id,
                            fromTime: moment().subtract(1, 'month').startOf('month').unix(),
                            toTime: moment().subtract(1, 'month').endOf('month').unix(),
                            subBetween: 'subMonth',
                            isCustom: false,
                            skillIds: [],
                            jobPlaceIds: [],
                        }));
                    }
                });
                return this.actions$.pipe(
                    ofType(AssignmentsActions.LOAD_REPORTS_SUCCESS, AssignmentsActions.LOAD_REPORTS_FAILED),
                    take(1),
                );
            case 'messages':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new MessageActions.LoadConversations({
                            customerId: authState.customer.id,
                            conversationId: route.queryParams.conversationId,
                        }));
                    }
                });
                return this.actions$.pipe(
                    ofType(MessageActions.LOAD_CONVERSATIONS_SUCCESS, MessageActions.LOAD_CONVERSATIONS_FAILED),
                    take(1),
                );
            case 'assignments':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new AssignmentsActions.LoadAssignments({
                            customerId: authState.customer.id,
                            page: 1,
                        }));
                        this.store.dispatch(new CompanyActions.LoadObjects({customerId: authState.customer.id}));
                        this.store.dispatch(new SharedActions.LoadSkills());
                    }
                });
                return this.actions$.pipe(
                    ofType(AssignmentsActions.LOAD_ASSIGNMENTS_SUCCESS, AssignmentsActions.LOAD_ASSIGNMENTS_FAILED),
                    take(1),
                );
            case 'assignments/assignment/:id':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new AssignmentsActions.ClearSelectedShift());
                        this.store.dispatch(new AssignmentsActions.LoadAssignmentDetails({
                            customerId: authState.customer.id,
                            assignmentsId: +route.params.id,
                        }));
                    }
                });
                return this.actions$.pipe(
                    ofType(AssignmentsActions.LOAD_ASSIGNMENT_DETAILS_SUCCESS, AssignmentsActions.LOAD_ASSIGNMENT_DETAILS_FAILED),
                    take(1),
                );
            case 'assignments/favourites':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new CompanyActions.LoadObjects({customerId: authState.customer.id}));
                    }
                });
                return this.actions$.pipe(
                    ofType(CompanyActions.LOAD_OBJECTS_SUCCESS, CompanyActions.LOAD_OBJECTS_FAILED),
                    take(1),
                );
            case 'assignments/favourites/:id':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new AssignmentsActions.LoadFavouriteGuards({jobPlaceId: +route.params.id}));
                        this.store.dispatch(new CompanyActions.LoadObject({
                            customerId: authState.customer.id,
                            objectId: +route.params.id,
                        }));
                        this.store.dispatch(new SharedActions.LoadSkills());
                    }
                });
                return this.actions$.pipe(
                    ofType(CompanyActions.LOAD_OBJECT_SUCCESS, CompanyActions.LOAD_OBJECT_FAILED),
                    take(1),
                );
            case 'step1':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new AssignmentsActions.LoadAssignments({
                            customerId: authState.customer.id,
                            page: 1,
                        }));
                    }
                });
                this.store.dispatch(new AssignmentsActions.LoadAssignmentCategories());
                return this.actions$.pipe(
                    ofType(AssignmentsActions.LOAD_ASSIGNMENTS_SUCCESS, AssignmentsActions.LOAD_ASSIGNMENTS_FAILED),
                    take(1),
                );
            // case 'step2':
            //     this.store.select('auth').pipe(
            //         filter((authState) => authState.customer !== null),
            //         take(1),
            //     ).subscribe((authState) => {
            //         if (authState.customer) {
            //             // this.store.dispatch(new SharedActions.LoadUniform());
            //             // this.store.dispatch(new SharedActions.LoadUtilityOptions());
            //             // this.store.dispatch(new SharedActions.LoadSkills());
            //         }
            //     });
            //     return this.actions$.pipe(
            //         ofType(SharedActions.LOAD_UNIFORM_SUCCESS, SharedActions.LOAD_UNIFORM_FAILED,
            //             SharedActions.LOAD_SKILLS_SUCCESS, SharedActions.LOAD_SKILLS_FAILED),
            //         take(1),
            //     );
            case 'step4':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new CompanyActions.LoadObjects({customerId: authState.customer.id}));
                        // this.store.dispatch(new CompanyActions.LoadRegions({customerId: authState.customer.id}));
                    }
                });
                return this.actions$.pipe(
                    ofType(CompanyActions.LOAD_OBJECTS_SUCCESS, CompanyActions.LOAD_OBJECTS_FAILED,
                        CompanyActions.LOAD_REGIONS_SUCCESS, CompanyActions.LOAD_REGIONS_FAILED),
                    take(1),
                );
            case 'step5':
                this.store.dispatch(new AssignmentsActions.LoadObjectGuards({
                    jobPlaceId: createData.jobPlaceId,
                    skillId: createData.skillId,
                    uniformId: createData.uniformId,
                    dates: createData.dates,
                    timezone: createData.timezone,
                }));
                return this.actions$.pipe(
                    ofType(AssignmentsActions.LOAD_OBJECT_GUARDS_SUCCESS, AssignmentsActions.LOAD_OBJECT_GUARDS_FAILED),
                    take(1),
                );
            case 'step6':
                this.store.dispatch(new SharedActions.LoadReportTypes());
                return this.actions$.pipe(
                    ofType(SharedActions.LOAD_REPORT_TYPES_SUCCESS, SharedActions.LOAD_REPORT_TYPES_FAILED),
                    take(1),
                );
            case 'step7':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new SharedActions.LoadUtilityOptions());
                        this.store.dispatch(new SharedActions.LoadSkills());
                        // this.store.dispatch(new CompanyActions.LoadBillingInfoCollection({customerId: authState.customer.id}));
                        // this.store.dispatch(new CompanyActions.LoadBillingInfo({customerId: authState.customer.id}));
                        this.store.dispatch(new AssignmentsActions.CalculatePrice({
                            customerId: authState.customer.id,
                            dates: createData.dates,
                            skillId: createData.skillId,
                            timezone: createData.timezone,
                            guardsNumber: createData.numberOfPersonnel,
                            jobPlaceId: createData.jobPlaceId,
                        }));
                    }
                });
                return this.actions$.pipe(
                    ofType(AssignmentsActions.CALCULATE_PRICE_SUCCESS, AssignmentsActions.CALCULATE_PRICE_FAILED),
                    take(1),
                );
            // case 'step8':
            //     this.store.select('auth').pipe(
            //         filter((authState) => authState.customer !== null),
            //         take(1),
            //     ).subscribe((authState) => {
            //         if (authState.customer) {
            //             this.store.dispatch(new CompanyActions.LoadBillingInfoCollection({customerId: authState.customer.id}));
            //         }
            //     });
            //     return this.actions$.pipe(
            //         ofType(CompanyActions.LOAD_BILLING_COLLECTION_SUCCESS, CompanyActions.LOAD_BILLING_COLLECTION_FAILED),
            //         take(1),
            //     );
            case 'users':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new CompanyActions.LoadEmployees({
                            customerId: authState.customer.id,
                            page: 1,
                        }));
                    }
                });
                return this.actions$.pipe(
                    ofType(CompanyActions.LOAD_EMPLOYEES_SUCCESS, CompanyActions.LOAD_EMPLOYEES_FAILED),
                    take(1),
                );
            case 'billing':
                this.store.select('auth', 'customer').pipe(
                    filter((customer) => customer !== null),
                    take(1),
                ).subscribe((customer) => {
                    this.store.dispatch(new CompanyActions.LoadInvoices({
                        customerId: customer.id,
                        page: 1,
                    }));

                    // if (authState.customer) {
                    //
                    //     // this.store.dispatch(new CompanyActions.LoadBillingInfoCollection({customerId: authState.customer.id}));
                    // }
                });
                return this.actions$.pipe(
                    ofType(CompanyActions.LOAD_INVOICES_SUCCESS, CompanyActions.LOAD_INVOICES_FAILED),
                    take(1),
                );
            case 'regions':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        // this.store.dispatch(new CompanyActions.LoadRegions({customerId: authState.customer.id}));
                        this.store.dispatch(new CompanyActions.LoadObjects({customerId: authState.customer.id}));
                    }
                });
                return this.actions$.pipe(
                    ofType(CompanyActions.LOAD_REGIONS_SUCCESS, CompanyActions.LOAD_REGIONS_FAILED,
                        CompanyActions.LOAD_OBJECTS_SUCCESS, CompanyActions.LOAD_OBJECTS_FAILED),
                    take(1),
                );
            case 'my-staff':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new MyStaffActions.LoadGuards({
                            customerId: authState.customer.id,
                            page: 1,
                        }));
                        this.store.dispatch(new SharedActions.LoadSkills());
                    }
                });
                return this.actions$.pipe(
                    ofType(MyStaffActions.LOAD_GUARDS_INVITES_SUCCESS, MyStaffActions.LOAD_GUARDS_INVITES_FAILED),
                    take(1),
                );
            case 'my-staff/profile/:id':
                this.store.select('auth', 'customer')
                    .pipe(
                        filter(x => x != null),
                        take(1),
                    )
                    .subscribe(customer => {
                        this.store.dispatch(new MyStaffActions.LoadGuard({guardId: +route.params.id}));
                        this.store.dispatch(new MyStaffActions.LoadPayslipItemsForGuardMyStaff({
                            page: 1,
                            guardId: +route.params.id,
                            customerId: customer.id,
                        }));
                    });
                // /customers/{customer}/my-staff-guards/{guard}/payslips

                // this.store.dispatch(new MyStaffActions.LoadGuardsWithPayrolls({
                //     guardId: +route.params.id,
                //     page: 1,
                //     customerId:
                // }));
                return this.actions$.pipe(
                    ofType(MyStaffActions.LOAD_GUARD_SUCCESS, MyStaffActions.LOAD_GUARD_FAILED),
                    take(1),
                );
            case 'my-staff/payroll':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    this.store.dispatch(new MyStaffActions.LoadGuardsWithPayrolls({
                        customerId: authState.customer.id,
                        page: 1,
                    }));
                    this.store.dispatch(new SharedActions.LoadSkills());
                });

                return this.actions$.pipe(
                    ofType(SharedActions.LOAD_SKILLS_SUCCESS, SharedActions.LOAD_SKILLS_FAILED),
                    take(1),
                );
            case 'my-staff/competences':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new SharedActions.ClearSkills());
                        this.store.dispatch(new SharedActions.LoadSkills());
                    }
                });
                return this.actions$.pipe(
                    ofType(SharedActions.LOAD_SKILLS_SUCCESS, SharedActions.LOAD_SKILLS_FAILED),
                    take(1),
                );
            case 'my-staff/competences/:id':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new SharedActions.LoadSkill({
                            customerId: authState.customer.id,
                            skillId: +route.params.id,
                        }));
                        this.store.dispatch(new MyStaffActions.LoadMembers({
                            customerId: authState.customer.id,
                            skillId: +route.params.id,
                            page: 1,
                        }));
                        this.store.dispatch(new SharedActions.LoadSkillUniforms({skillId: +route.params.id}));
                        this.store.dispatch(new SharedActions.LoadSkillUtilityOptions({skillId: +route.params.id}));
                        this.store.dispatch(new SharedActions.ClearSkills());
                        this.store.dispatch(new SharedActions.LoadSkills());
                    }
                });
                return this.actions$.pipe(
                    ofType(SharedActions.LOAD_SKILL_SUCCESS, SharedActions.LOAD_SKILL_FAILED,
                        MyStaffActions.LOAD_SKILL_MEMBERS_SUCCESS, MyStaffActions.LOAD_SKILL_MEMBERS_FAILED),
                    take(1),
                );
            case 'my-staff/invite':
                this.store.select('auth').pipe(
                    filter((authState) => authState.customer !== null),
                    take(1),
                ).subscribe((authState) => {
                    if (authState.customer) {
                        this.store.dispatch(new SharedActions.LoadSkills());
                    }
                });
                return this.actions$.pipe(
                    ofType(SharedActions.LOAD_SKILLS_SUCCESS, SharedActions.LOAD_SKILLS_FAILED),
                    take(1),
                );
        }
    }
}
