import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import * as AuthActions from './auth/store/auth.actions';
import {MessagingService} from './service/messaging.service';
import { TranslateService } from '@ngx-translate/core';

// ejs-globalization
import { loadCldr } from '@syncfusion/ej2-base';
import { SwUpdate } from '@angular/service-worker';
declare var require: any;

loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),

    require('cldr-data/main/sv/ca-gregorian.json'),
    require('cldr-data/main/sv/numbers.json'),
    require('cldr-data/main/sv/timeZoneNames.json'),

    require('cldr-data/main/en/ca-gregorian.json'),
    require('cldr-data/main/en/numbers.json'),
    require('cldr-data/main/en/timeZoneNames.json')
);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'safetly-web';
    message;

    constructor(private store: Store<fromApp.AppState>,
                private translate: TranslateService,
                private swUpdate: SwUpdate,
                private messagingService: MessagingService) {

        this.swUpdate.available.subscribe(event => {
        });

        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.message = this.messagingService.currentMessage;

        if (localStorage.getItem('jwt')) {
            this.store.dispatch(new AuthActions.Autologin());
        }
        this.translate.setDefaultLang('sv');
        if (localStorage.getItem('locale')) {
            this.translate.use(localStorage.getItem('locale'));
        }
    }
}
