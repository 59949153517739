import { Action } from '@ngrx/store';
import { PaginationModel } from '../../../models/pagination.model';
import { AssignmentModel } from '../../../models/assignment.model';
import { AssignmentCategoryModel } from '../../../models/assignmentCategory.model';
import { GuardModel } from '../../../models/guard.model';
import { PriceModel } from '../../../models/price.model';
import { ShiftModel } from '../../../models/shift.model';
import { RateModel } from '../../../models/rate.model';
import { ReportModel } from '../../../models/report.model';
import { CustomerTodoModel } from '../../../models/customer.todo.model';
import { AssignmentStatisticsModel } from '../../../models/assignment.statistics.model';
import { ReportStatistic } from '../../../models/reportStatistic';

export const LOAD_REPORTS = '[Assignments] Load Reports';
export const LOAD_REPORTS_SUCCESS = '[Assignments] Load Reports Success';
export const LOAD_REPORTS_FAILED = '[Assignments] Load Reports Failed';

export const LOAD_REPORTS_STATISTICS = '[Assignments] Load Reports Statistics ';
export const LOAD_REPORTS_STATISTICS_SUCCESS = '[Assignments] Load Reports  Statistics Success';
export const LOAD_REPORTS_STATISTICS_FAILED = '[Assignments] Load Reports  Statistics Failed';

export const CANCEL_ASSIGNMENT = '[Assignments] Cancel Assignment';
export const CANCEL_ASSIGNMENT_SUCCESS = '[Assignments] Cancel Assignment Success';
export const CANCEL_ASSIGNMENT_FAILED = '[Assignments] Cancel Assignment Failed';

export const UPDATE_SHIFT = '[Assignments] Update Shift';
export const UPDATE_SHIFT_SUCCESS = '[Assignments] Update Shift Success';
export const UPDATE_SHIFT_FAILED = '[Assignments] Update Shift Failed';

export const SHIFT_APPROVE_GUARD_HOURS = '[Assignments] Shift Approve Guard Hours';
export const SHIFT_APPROVE_GUARD_HOURS_SUCCESS = '[Assignments] Shift Approve Guard Hours Success';
export const SHIFT_APPROVE_GUARD_HOURS_FAILED = '[Assignments] Shift Approve Guard Hours Failed';

export const LOAD_ASSIGNMENTS_STATISTICS = '[Assignments] Load Assignments Statistics';
export const LOAD_ASSIGNMENTS_STATISTICS_SUCCESS = '[Assignments] Load Assignments Statistics Success';
export const LOAD_ASSIGNMENTS_STATISTICS_FAILED = '[Assignments] Load Assignments Statistics Failed';

export const LOAD_CUSTOMER_TODOS = '[Assignments] Load Customer Todos';
export const LOAD_CUSTOMER_TODOS_SUCCESS = '[Assignments] Load Customer Todos Success';
export const LOAD_CUSTOMER_TODOS_FAILED = '[Assignments] Load Customer Todos Failed';

export const LOAD_EMPLOYEE_TODOS = '[Assignments] Load Employee Todos';
export const LOAD_EMPLOYEE_TODOS_SUCCESS = '[Assignments] Load Employee Todos Success';
export const LOAD_EMPLOYEE_TODOS_FAILED = '[Assignments] Load Employee Todos Failed';

export const DELETE_CUSTOMER_TODO = '[Assignments] Delete Customer Todo';
export const DELETE_CUSTOMER_TODO_SUCCESS = '[Assignments] Delete Customer Todo Success';
export const DELETE_CUSTOMER_TODO_FAILED = '[Assignments] Delete Customer Todo Failed';

export const LOAD_REPORT = '[Assignments] Load Report'; //single
export const LOAD_REPORT_SUCCESS = '[Assignments] Load Report Success';//single
export const LOAD_REPORT_FAILED = '[Assignments] Load Report Failed';//single

export const LOAD_ASSIGNMENTS = '[Assignments] Load All Customer Assignments';
export const LOAD_ASSIGNMENTS_SUCCESS = '[Assignments] Load All Customer Assignments Success';
export const LOAD_ASSIGNMENTS_FAILED = '[Assignments] Load All Customer Assignments Failed';

export const SELECT_SHIFT_ON_OPEN_ASSIGNMENT = '[Assignments] Select Shift on Open Assignment';
export const CLEAR_SELECTED_SHIFT = '[Assignments] CLEAR_SELECTED_SHIFT';

export const ACCEPT_GUARDS_FOR_SHIFT = '[Assignments] Accept Guards For Shift';
export const ACCEPT_GUARDS_FOR_SHIFT_SUCCESS = '[Assignments] Accept Guards For Shift Success';
export const ACCEPT_GUARDS_FOR_SHIFT_FAILED = '[Assignments] Accept Guards For Shift Failed';

export const CLEAR_ASSIGNMENTS = '[Assignments] Clear Customer Assignments';
export const CLEAR_ASSIGNMENT = '[Assignments] Clear Customer One Assignment';

export const LOAD_ASSIGNMENT_DETAILS = '[Assignments] Load Customer Assignment Details';
export const LOAD_ASSIGNMENT_DETAILS_SUCCESS = '[Assignments] Load Customer Assignment Details Success';
export const LOAD_ASSIGNMENT_DETAILS_FAILED = '[Assignments] Load Customer Assignment Details Failed';

export const ADD_ASSIGNMENT = '[Assignments] Add New Assignment';
export const ADD_ASSIGNMENT_SUCCESS = '[Assignments] Add New Assignment Success';
export const ADD_ASSIGNMENT_FAILED = '[Assignments] Add New Assignment Failed';

export const ADD_ASSIGNMENT_AS_DRAFT = '[Assignments] Add Assignment as Draft';
export const ADD_ASSIGNMENT_AS_DRAFT_SUCCESS = '[Assignments] Add Assignment as Draft Success';
export const ADD_ASSIGNMENT_AS_DRAFT_FAILED = '[Assignments] Add Assignment as Draft Failed';

export const CALCULATE_PRICE = '[Assignments] Calculate Price';
export const CALCULATE_PRICE_SUCCESS = '[Assignments] Calculate Price Success';
export const CALCULATE_PRICE_FAILED = '[Assignments] Calculate Price Failed';

export const LOAD_ASSIGNMENT_CATEGORIES = '[Assignments] Load Assignment Categories';
export const LOAD_ASSIGNMENT_CATEGORIES_SUCCESS = '[Assignments] Load Assignment Categories Success';
export const LOAD_ASSIGNMENT_CATEGORIES_FAILED = '[Assignments] Load Assignment Categories Failed';

export const LOAD_OBJECT_GUARDS = '[Assignments] Load Object Guards';
export const LOAD_OBJECT_GUARDS_SUCCESS = '[Assignments] Load Object Guards Success';
export const LOAD_OBJECT_GUARDS_FAILED = '[Assignments] Load Object Guards Failed';

export const CLEAR_GUARDS = '[Assignments] Clear Guards';

export const LOAD_FAVOURITE_GUARDS = '[Assignments] Load Favourite Guards For Job Place';
export const LOAD_FAVOURITE_GUARDS_SUCCESS = '[Assignments] Load Favourite Guards For Job Place Success';
export const LOAD_FAVOURITE_GUARDS_FAILED = '[Assignments] Load Favourite Guards For Job Place Failed';
export const CLEAR_FAVOURITE_GUARDS = '[Assignments] Clear Favourite Guards';

export const LOAD_FAVOURITE_GUARDS_TO_ADD = '[Assignments] Load Available Guards To Add As Favourites';
export const LOAD_FAVOURITE_GUARDS_TO_ADD_SUCCESS = '[Assignments] Load Available Guards To Add As Favourites Success';
export const LOAD_FAVOURITE_GUARDS_TO_ADD_FAILED = '[Assignments] Load Available Guards To Add As Favourites Failed';
export const CLEAR_FAVOURITE_GUARDS_TO_ADD = '[Assignments] Clear Available Guards To Add As Favourites';

export const ADD_GUARDS_TO_FAVOURITES = '[Assignments] Add Guards To Favourites For Job Place';
export const ADD_GUARDS_TO_FAVOURITES_SUCCESS = '[Assignments] Add Guards To Favourites For Job Place Success';
export const ADD_GUARDS_TO_FAVOURITES_FAILED = '[Assignments] Add Guards To Favourites For Job Place Failed';

export const DELETE_FAVOURITE_GUARD = '[Assignments] Delete Favourite Guard For Job Place';
export const DELETE_FAVOURITE_GUARD_SUCCESS = '[Assignments] Delete Favourite Guard For Job Place Success';
export const DELETE_FAVOURITE_GUARD_FAILED = '[Assignments] Delete Favourite Guard For Job Place Failed';

export const UPDATE_STEP = '[Assignments] Update Step';
export const CREATE_ASSIGNMENT_FROM_HISTORY = '[Assignments] Create assignment from history';

export const UPDATE_ASSIGNMENT_SHIFT = '[Assignments] Update Assignment Shift';

export const RATE_GUARD_FOR_SHIFT = '[Assignments] Rate Guard For Shift';
export const RATE_GUARD_FOR_SHIFT_SUCCESS = '[Assignments] Rate Guard For Shift Success';
export const RATE_GUARD_FOR_SHIFT_FAILED = '[Assignments] Rate Guard For Shift Failed';

export class CancelAssignment implements Action {
    readonly type = CANCEL_ASSIGNMENT;

    constructor(public payload: {
        assignmentId: number,
        customerId: number,
    }) {
    }
}

export class CancelAssignmentSuccess implements Action {
    readonly type = CANCEL_ASSIGNMENT_SUCCESS;

    constructor(public payload: {
        assignment: AssignmentModel
    }) {
    }
}

export class CancelAssignmentFailed implements Action {
    readonly type = CANCEL_ASSIGNMENT_FAILED;
}
export class UpdateShift implements Action {
    readonly type = UPDATE_SHIFT;

    constructor(public payload: any) {
    }
}

export class UpdateShiftSuccess implements Action {
    readonly type = UPDATE_SHIFT_SUCCESS;

    constructor(public payload: {
        data: ShiftModel
    }) {
    }
}

export class UpdateShiftFailed implements Action {
    readonly type = UPDATE_SHIFT_FAILED;
}

export class ShiftApproveGuardHours implements Action {
    readonly type = SHIFT_APPROVE_GUARD_HOURS;

    constructor(public payload: {
        shiftId: number,
        guardId: number,
        status: number,
    }) {
    }
}

export class ShiftApproveGuardHoursSuccess implements Action {
    readonly type = SHIFT_APPROVE_GUARD_HOURS_SUCCESS;

    constructor(public payload: ShiftModel) {
    }
}

export class ShiftApproveGuardHoursFailed implements Action {
    readonly type = SHIFT_APPROVE_GUARD_HOURS_FAILED;
}



export class LoadAssignmentsStatistics implements Action {
    readonly type = LOAD_ASSIGNMENTS_STATISTICS;

    constructor() {
    }
}

export class LoadAssignmentsStatisticsSuccess implements Action {
    readonly type = LOAD_ASSIGNMENTS_STATISTICS_SUCCESS;

    constructor(public payload: AssignmentStatisticsModel) {
    }
}

export class LoadAssignmentsStatisticsFailed implements Action {
    readonly type = LOAD_ASSIGNMENTS_STATISTICS_FAILED;
}


export class LoadCustomerTodos implements Action {
    readonly type = LOAD_CUSTOMER_TODOS;

    constructor(public payload: {
        page: number
    }) {
    }
}

export class LoadCustomerTodosSuccess implements Action {
    readonly type = LOAD_CUSTOMER_TODOS_SUCCESS;

    constructor(public payload: {
        data: CustomerTodoModel[],
        pagination: PaginationModel
    }) {
    }
}

export class LoadCustomerTodosFailed implements Action {
    readonly type = LOAD_CUSTOMER_TODOS_FAILED;
}

export class LoadEmployeeTodos implements Action {
    readonly type = LOAD_EMPLOYEE_TODOS;

    constructor(public payload: {
        page: number
    }) {
    }
}

export class LoadEmployeeTodosSuccess implements Action {
    readonly type = LOAD_EMPLOYEE_TODOS_SUCCESS;

    constructor(public payload: {
        data: CustomerTodoModel[]
    }) {
    }
}

export class LoadEmployeeTodosFailed implements Action {
    readonly type = LOAD_EMPLOYEE_TODOS_FAILED;
}

export class DeleteCustomerTodo implements Action {
    readonly type = DELETE_CUSTOMER_TODO;

    constructor(public payload: {
        customerTodoId: number
    }) {
    }
}

export class DeleteCustomerTodoSuccess implements Action {
    readonly type = DELETE_CUSTOMER_TODO_SUCCESS;

    constructor(public payload: CustomerTodoModel) {
    }
}

export class DeleteCustomerTodoFailed implements Action {
    readonly type = DELETE_CUSTOMER_TODO_SUCCESS;
}


export class LoadReports implements Action {
    readonly type = LOAD_REPORTS;

    constructor(public payload: {
        customerId: number,
        page: number,
        params: any,
        fromTime: number,
        toTime: number,
    }) {
    }
}

export class LoadReportsSuccess implements Action {
    readonly type = LOAD_REPORTS_SUCCESS;

    constructor(public payload: {
        data: ReportModel[],
        pagination: PaginationModel
    }) {
    }
}

export class LoadReportsFailed implements Action {
    readonly type = LOAD_REPORTS_FAILED;
}


export class LoadReportsStatistics implements Action {
    readonly type = LOAD_REPORTS_STATISTICS;

    constructor(public payload: {
        customerId: number,
        fromTime: number,
        toTime: number,
        isCustom: boolean;
        subBetween: string;
        skillIds: number[],
        jobPlaceIds: number[]
    }) {
    }
}

export class LoadReportsStatisticsSuccess implements Action {
    readonly type = LOAD_REPORTS_STATISTICS_SUCCESS;

    constructor(public payload: {
        data: ReportStatistic,
    }) {
    }
}

export class LoadReportsStatisticsFailed implements Action {
    readonly type = LOAD_REPORTS_STATISTICS_FAILED;
}

export class LoadReport implements Action {
    readonly type = LOAD_REPORT;

    constructor(public payload: {
        reportId: number,
        customerId: number,
    }) {}
}

export class LoadReportSuccess implements Action {
    readonly type = LOAD_REPORT_SUCCESS;

    constructor(public payload: {
        data: ReportModel,
    }) {
    }
}

export class LoadReportFailed implements Action {
    readonly type = LOAD_REPORT_FAILED;
}

export class SelectShiftOnOpenAssignment implements Action {
    readonly type = SELECT_SHIFT_ON_OPEN_ASSIGNMENT;

    constructor(public payload: number) {
    }
}

export class ClearSelectedShift implements Action {
    readonly type = CLEAR_SELECTED_SHIFT;
}

export class UpdateAssignmentShift implements Action {
    readonly type = UPDATE_ASSIGNMENT_SHIFT;

    constructor(public payload: ShiftModel) {
    }
}

export class LoadAssignments implements Action {
    readonly type = LOAD_ASSIGNMENTS;

    constructor(public payload: { customerId: number, page: number, params?: { key: string, value: string }[] }) {
    }
}

export class LoadAssignmentsSuccess implements Action {
    readonly type = LOAD_ASSIGNMENTS_SUCCESS;

    constructor(public payload: { assignments: AssignmentModel[], pagination: PaginationModel }) {
    }
}

export class RateGuardForShift implements Action {
    readonly type = RATE_GUARD_FOR_SHIFT;

    constructor(public payload: {
        shiftId: number,
        guardId: number,
        rate: number,
    }) {
    }
}

export class RateGuardForShiftSuccess implements Action {
    readonly type = RATE_GUARD_FOR_SHIFT_SUCCESS;

    constructor(public payload: {
        guardId: number,
        shiftId: number,
        rate: RateModel,
    }) {
    }
}

export class RateGuardForShiftFailed implements Action {
    readonly type = RATE_GUARD_FOR_SHIFT_FAILED;
}

export class LoadAssignmentsFailed implements Action {
    readonly type = LOAD_ASSIGNMENTS_FAILED;
}

export class AcceptGuardsForShift implements Action {
    readonly type = ACCEPT_GUARDS_FOR_SHIFT;

    constructor(public payload: {
        shiftId: number,
        guardIds: number[]
    }) {
    }
}

export class AcceptGuardsForShiftSuccess implements Action {
    readonly type = ACCEPT_GUARDS_FOR_SHIFT_SUCCESS;

    constructor(public payload) {
    }
}

export class AcceptGuardsForShiftFailed implements Action {
    readonly type = ACCEPT_GUARDS_FOR_SHIFT_FAILED;
}

export class ClearAssignments implements Action {
    readonly type = CLEAR_ASSIGNMENTS;
}

export class ClearAssignment implements Action {
    readonly type = CLEAR_ASSIGNMENT;
}

export class LoadAssignmentDetails implements Action {
    readonly type = LOAD_ASSIGNMENT_DETAILS;

    constructor(public payload: { customerId: number, assignmentsId: number }) {
    }
}

export class LoadAssignmentDetailsSuccess implements Action {
    readonly type = LOAD_ASSIGNMENT_DETAILS_SUCCESS;

    constructor(public payload: { assignment: AssignmentModel }) {
    }
}

export class LoadAssignmentDetailsFailed implements Action {
    readonly type = LOAD_ASSIGNMENT_DETAILS_FAILED;
}

export class AddAssignment implements Action {
    readonly type = ADD_ASSIGNMENT;

    constructor(public payload: {
        customerId: number,
        data: any
    }) {
    }
}

export class AddAssignmentSuccess implements Action {
    readonly type = ADD_ASSIGNMENT_SUCCESS;

    constructor(public payload: { assignment: AssignmentModel }) {
    }
}

export class AddAssignmentFailed implements Action {
    readonly type = ADD_ASSIGNMENT_FAILED;
}

export class AddAssignmentAsDraft implements Action {
    readonly type = ADD_ASSIGNMENT_AS_DRAFT;

    constructor(public payload: {
        customerId: number,
        data: any
    }) {
    }
}

export class AddAssignmentAsDraftSuccess implements Action {
    readonly type = ADD_ASSIGNMENT_AS_DRAFT_SUCCESS;

    constructor(public payload: { assignment: AssignmentModel }) {
    }
}

export class AddAssignmentAsDraftFailed implements Action {
    readonly type = ADD_ASSIGNMENT_AS_DRAFT_FAILED;
}

export class CalculatePrice implements Action {
    readonly type = CALCULATE_PRICE;

    constructor(public payload: {
        customerId: number,
        skillId: number,
        guardsNumber: number,
        dates: {
            date: string,
            startTime: string,
            endTime: string
        }[],
        timezone: string,
        jobPlaceId: number,
    }) {
    }
}

export class CalculatePriceSuccess implements Action {
    readonly type = CALCULATE_PRICE_SUCCESS;

    constructor(public payload: { price: PriceModel }) {
    }
}

export class CalculatePriceFailed implements Action {
    readonly type = CALCULATE_PRICE_FAILED;
}

export class LoadAssignmentCategories implements Action {
    readonly type = LOAD_ASSIGNMENT_CATEGORIES;
}

export class LoadAssignmentCategoriesSuccess implements Action {
    readonly type = LOAD_ASSIGNMENT_CATEGORIES_SUCCESS;

    constructor(public payload: { categories: AssignmentCategoryModel[] }) {
    }
}

export class LoadAssignmentCategoriesFailed implements Action {
    readonly type = LOAD_ASSIGNMENT_CATEGORIES_FAILED;
}

export class UpdateStep implements Action {
    readonly type = UPDATE_STEP;

    constructor(public payload: number) {
    }
}

export class CreateAssignmentFromHistory implements Action {
    readonly type = CREATE_ASSIGNMENT_FROM_HISTORY;

    constructor(public payload: {
        isFromHistory: boolean,
    }) {
    }
}

export class LoadObjectGuards implements Action {
    readonly type = LOAD_OBJECT_GUARDS;

    constructor(public payload: {
        jobPlaceId: number,
        skillId: number,
        uniformId: number,
        dates: {
            date: string,
            startTime: string,
            endTime: string
        }[],
        timezone: string,
    }) {
    }
}

export class LoadObjectGuardsSuccess implements Action {
    readonly type = LOAD_OBJECT_GUARDS_SUCCESS;

    constructor(public payload: { guards: GuardModel[], pagination: PaginationModel }) {
    }
}

export class LoadObjectGuardsFailed implements Action {
    readonly type = LOAD_OBJECT_GUARDS_FAILED;
}

export class ClearGuards implements Action {
    readonly type = CLEAR_GUARDS;
}

export class LoadFavouriteGuards implements Action {
    readonly type = LOAD_FAVOURITE_GUARDS;

    constructor(public payload: { jobPlaceId: number, params?: { key: string, value: string }[] }) {
    }
}

export class LoadFavouriteGuardsSuccess implements Action {
    readonly type = LOAD_FAVOURITE_GUARDS_SUCCESS;

    constructor(public payload: { favouriteGuards: GuardModel[], pagination: PaginationModel }) {
    }
}

export class LoadFavouriteGuardsFailed implements Action {
    readonly type = LOAD_FAVOURITE_GUARDS_FAILED;
}

export class ClearFavouriteGuards implements Action {
    readonly type = CLEAR_FAVOURITE_GUARDS;
}

export class LoadFavouriteGuardsToAdd implements Action {
    readonly type = LOAD_FAVOURITE_GUARDS_TO_ADD;

    constructor(public payload: { jobPlaceId: number, params?: { key: string, value: string }[] }) {
    }
}

export class LoadFavouriteGuardsToAddSuccess implements Action {
    readonly type = LOAD_FAVOURITE_GUARDS_TO_ADD_SUCCESS;

    constructor(public payload: { guards: GuardModel[], pagination: PaginationModel }) {
    }
}

export class LoadFavouriteGuardsToAddFailed implements Action {
    readonly type = LOAD_FAVOURITE_GUARDS_TO_ADD_FAILED;
}


export class ClearFavouriteToAdd implements Action {
    readonly type = CLEAR_FAVOURITE_GUARDS_TO_ADD;
}

export class DeleteFavouriteGuard implements Action {
    readonly type = DELETE_FAVOURITE_GUARD;

    constructor(public payload: { jobPlaceId: number, guardId: number }) {
    }
}

export class DeleteFavouriteGuardSuccess implements Action {
    readonly type = DELETE_FAVOURITE_GUARD_SUCCESS;

    constructor(public payload: { deletedGuard: GuardModel }) {
    }
}

export class DeleteFavouriteGuardFailed implements Action {
    readonly type = DELETE_FAVOURITE_GUARD_FAILED;
}

export class AddGuardsToFavourites implements Action {
    readonly type = ADD_GUARDS_TO_FAVOURITES;

    constructor(public payload: {
        jobPlaceId: number,
        guardsIds: number[]
    }) {
    }
}

export class AddGuardsToFavouritesSuccess implements Action {
    readonly type = ADD_GUARDS_TO_FAVOURITES_SUCCESS;

    constructor(public payload: { guards: GuardModel[] }) {
    }
}

export class AddGuardsToFavouritesFailed implements Action {
    readonly type = ADD_GUARDS_TO_FAVOURITES_FAILED;
}


export type AssignmentsActions = LoadAssignments
    | LoadAssignmentsSuccess
    | LoadAssignmentsFailed
    | ClearAssignments
    | ClearAssignment
    | AddAssignment
    | AddAssignmentSuccess
    | AddAssignmentFailed
    | AddAssignmentAsDraft
    | AddAssignmentAsDraftSuccess
    | AddAssignmentAsDraftFailed
    | LoadAssignmentCategories
    | LoadAssignmentCategoriesSuccess
    | LoadAssignmentCategoriesFailed
    | UpdateStep
    | LoadObjectGuards
    | LoadObjectGuardsSuccess
    | LoadObjectGuardsFailed
    | ClearGuards
    | LoadFavouriteGuards
    | LoadFavouriteGuardsSuccess
    | LoadFavouriteGuardsFailed
    | DeleteFavouriteGuard
    | DeleteFavouriteGuardSuccess
    | DeleteFavouriteGuardFailed
    | LoadFavouriteGuardsToAdd
    | LoadFavouriteGuardsToAddSuccess
    | LoadFavouriteGuardsToAddFailed
    | AddGuardsToFavourites
    | AddGuardsToFavouritesSuccess
    | AddGuardsToFavouritesFailed
    | ClearFavouriteToAdd
    | ClearFavouriteGuards
    | CalculatePrice
    | CalculatePriceSuccess
    | CalculatePriceFailed
    | LoadAssignmentDetails
    | LoadAssignmentDetailsSuccess
    | LoadAssignmentDetailsFailed
    | AcceptGuardsForShift
    | AcceptGuardsForShiftSuccess
    | AcceptGuardsForShiftFailed
    | UpdateAssignmentShift
    | RateGuardForShift
    | RateGuardForShiftSuccess
    | RateGuardForShiftFailed
    | SelectShiftOnOpenAssignment
    | ClearSelectedShift
    | LoadReports
    | LoadReportsSuccess
    | LoadReportsFailed
    | LoadReport
    | LoadReportSuccess
    | LoadReportFailed
    | LoadAssignmentsStatistics
    | LoadAssignmentsStatisticsSuccess
    | LoadAssignmentsStatisticsFailed
    | LoadCustomerTodos
    | LoadCustomerTodosSuccess
    | LoadCustomerTodosFailed
    | DeleteCustomerTodo
    | DeleteCustomerTodoSuccess
    | DeleteCustomerTodoFailed
    | ShiftApproveGuardHours
    | ShiftApproveGuardHoursSuccess
    | ShiftApproveGuardHoursFailed
    | CreateAssignmentFromHistory
    | CancelAssignment
    | CancelAssignmentSuccess
    | CancelAssignmentFailed
    | LoadReportsStatistics
    | LoadReportsStatisticsSuccess
    | LoadReportsStatisticsFailed
    | LoadEmployeeTodos
    | LoadEmployeeTodosSuccess
    | LoadEmployeeTodosFailed
    | UpdateShift
    | UpdateShiftSuccess
    | UpdateShiftFailed
    ;
