<div class="top">
    <div class="d-flex">
        <div class="back" routerLink="/components/my-staff/competences">
            <img src="/assets/img/back arrow.svg">
            <p class="back-p">{{'back' | translate}}</p>
        </div>
        <p class="faded-p">{{'staff.title' | translate}}</p>
        <img class="small-arrow" src="/assets/img/arrow small.svg">
        <p class="faded-p">{{'staff.competences.title' | translate}}</p>
        <img class="small-arrow" src="/assets/img/arrow small.svg">
        <p class="faded-p">{{skill?.name}}</p>
    </div>
    <h1>{{skill?.name}}</h1>
    <p class="description">
        {{skill?.description}}
    </p>
    <div class="d-block d-sm-flex justify-content-start gap-5 flex-row flex-wrap">
        <div class="mr-5">
            <p class="title">{{'staff.competences.form.salary-base' | translate}}</p>
            <p><i>{{skill?.price?.baseSalary}}</i></p>
        </div>
        <div class="mr-5">
            <p class="title">{{'staff.competences.form.bonus' | translate}}</p>
            <p><i>{{skill?.price?.unsocialHoursBonusDdates}}</i></p>
        </div>
        <div>
            <p class="title">{{'staff.competences.form-certificate' | translate}}</p>
            <p><i>{{skill?.price?.isRequiredCertificate ? 'Yes' : 'No'}}</i></p>
        </div>
        <p class="blue-p mt-auto mb-auto" (click)="showSkillForm = true">{{'staff.competences.edit' | translate}}</p>
    </div>
</div>

<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">

                <div class="tab">
                    <div class="d-flex justify-content-xl-start justify-content-center gap-5 gap-xl-4">
                        <div class="on-hover">
                            <div class="d-flex gap-1" (click)="properties = true; members = false;"
                                 [ngClass]="properties==true ? 'tab-item-active' : ''">
                                <div class="shirt-icon" [ngClass]="this.properties ? 'active ': ''"></div>
                                <p class="tab-item-title">{{'staff.competences.properties' | translate}}</p>
                            </div>
                            <div *ngIf="this.properties==true" class="blue-line"></div>
                        </div>
                        <div class="on-hover">
                            <div class="d-flex gap-1" (click)="properties = false; members = true;"
                                 [ngClass]="members==true ? 'tab-item-active' : ''">
                                <div class="users-icon" [ngClass]="this.members ? 'active tab-item-active': ''"></div>
                                <p class="tab-item-title">{{'staff.competences.members' | translate}}</p>
                            </div>
                            <div *ngIf="this.members==true" class="blue-line"></div>
                        </div>
                    </div>
                    <div class="line"></div>
                </div>

                <ng-container *ngIf="properties">
                    <div class="wrapper">

                        <h3>{{'staff.competences.clothing' | translate}}</h3>
                        <div class="items-wrapper">
                            <div class="item-wrapper" *ngFor="let uniform of this?.uniforms">
                                <div class="image-wrapper">
                                    <img *ngIf="uniform?.imagePath" src="{{uniform?.imagePath}}" alt="">
                                </div>
                                <div class="footer-wrapper">
                                    <p>{{uniform?.name}}</p>
                                    <p class="blue-p" (click)="onCreateEditClothing('EDIT', uniform)">{{'buttons.edit' | translate}}</p>
                                </div>
                            </div>
                            <div class="item-wrapper-add" (click)="onCreateEditClothing('CREATE')">
                                <p class="blue-p">+ {{'staff.competences.new-clothing' | translate}}</p>
                            </div>
                        </div>

                        <h3>{{'staff.competences.options' | translate}}</h3>
                        <div class="items-wrapper">
                            <div class="item-wrapper" *ngFor="let option of this?.options">
                                <div class="image-wrapper">
                                    <img *ngIf="option?.imagePath" src="{{option?.imagePath}}" alt="">
                                </div>
                                <div class="footer-wrapper">
                                    <p>{{option?.name}}</p>
                                    <p class="blue-p" (click)="onCreateEditOption('EDIT', option)">{{'buttons.edit' | translate}}</p>
                                </div>
                            </div>
                            <div class="item-wrapper-add" (click)="onCreateEditOption('CREATE')">
                                <p class="blue-p">+ {{'staff.competences.new-option' | translate}}</p>
                            </div>
                        </div>
                    </div>


                    <p class="delete-p" (click)="deleteCompetenceModal = true">{{'staff.competences.delete' | translate}}</p>
                </ng-container>

                <ng-container *ngIf="members">
                    <div class="table-responsive">
                        <div class="d-block d-md-flex justify-content-start justify-content-md-between gap-md-2 mt-4 header-wrapper">
                            <div class="search-wrapper">
                                <img class="search-icon" src="/assets/img/search 1.svg">
                                <input class="search-input" type="search" placeholder="Search staff members"/>
                            </div>
                            <div class="btn btn-primary my-button mt-md-0 mt-2" (click)="onAddMembersClicked()">
                                <div class="d-flex">
                                    <img src="/assets/img/plus white.svg">
                                    <p>{{'staff.competences.add-members' | translate}}</p>
                                </div>
                            </div>
                        </div>
                        <!-- TABLE -->
                        <table class="table table-hover">
                            <thead>
                            <tr>
                                <th class="sort-col">{{'table.thead.name' | translate}}</th>
                                <th>{{'table.thead.contact' | translate}}</th>
                                <th>{{'table.thead.staff-groups' | translate}}</th>
                                <th class="text-right">{{'table.thead.status' | translate}}</th>
                                <th style="opacity: 0">x</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let guard of guards">
                                <td>
                                    <div class="d-flex justify-content-between gap-2 name-wrapper">
                                        <img *ngIf="!guard?.image" src="/assets/img/empty-profile-picture.png" alt="">
                                        <img *ngIf="guard?.image" src="{{guard?.image}}" alt="">
                                        <div>
                                            <p><b>{{guard?.firstName}} {{guard?.lastName}}</b></p>
                                            <p class="faded-p">{{guard?.id}}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p>{{guard?.email}}</p>
                                    <p>{{guard?.phone}}</p>
                                </td>
                                <td>
                                    <div class="d-flex justify-content-start gap-2 skill-wrapper">
                                        <ng-container *ngIf="guard?.skills.length > 0; else noData">
                                            <div class="skill">
                                                <span>{{guard?.skills[0]?.name}}</span>
                                            </div>
                                            <div class="skill" *ngIf="guard?.skills?.length > 1">
                                                <span>+ {{guard?.skills?.length - 1}}</span>
                                            </div>
                                        </ng-container>

                                    </div>
                                </td>
                                <td>
                                    <div class="pill waiting"> <!---DELETE THIS-->
                                        <span>{{'staff.invite-status.1' | translate}}</span>
                                    </div>
                                    <ng-container [ngSwitch]="guard?.status">
                                        <div class="pill waiting" *ngSwitchCase="1">
                                            <span>{{'staff.invite-status.1' | translate}}</span>
                                        </div>
                                        <div class="pill active" *ngSwitchCase="2">
                                            <span>{{'staff.invite-status.2' | translate}}</span>
                                        </div>
                                        <div class="pill expired" *ngSwitchCase="3">
                                            <span>{{'staff.invite-status.3' | translate}}</span>
                                        </div>
                                    </ng-container>
                                </td>
                                <td><img class="x-icon" src="/assets/img/close.png" alt=""
                                         (click)="onRemoveMember(guard)"></td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td colspan="5">
                                    <div class="showing">
                                        <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                                             class="left-arrows" src="/assets/img/Arrow-left end.svg"
                                             (click)="onFirstPage()">
                                        <img [ngClass]="this?.pagination?.currentPage === 1 ? 'disabled' : '' "
                                             class="left-arrows" src="/assets/img/Arrow-left.svg"
                                             (click)="onPreviousPage()">
                                        <p *ngIf="pagination.total > 0;else noRecords">
                                            {{'pagination' | translate:
                                            {
                                                from: this?.pagination?.from,
                                                to: this?.pagination?.to,
                                                total: this?.pagination?.total
                                            }
                                            }}
                                        </p>
                                        <ng-template #noRecords>
                                            <p>{{'pagination-no-records' | translate}}</p>
                                        </ng-template>
                                        <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                                             class="right-arrows" src="/assets/img/Arrow-right.svg"
                                             (click)="onNextPage()">
                                        <img [ngClass]="this?.pagination?.currentPage === this?.pagination?.lastPage ? 'disabled' : '' "
                                             class="right-arrows" src="/assets/img/Arrow-right end.svg"
                                             (click)="onLastPage()">
                                    </div>
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        <!-- /TABLE -->
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>

<ng-template #noData>
    -
</ng-template>

<!---Create/Edit clothing modal--->
<app-modal [modalType]="1" *ngIf="createEditClothingModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">

            <h3 class="modal-title" *ngIf="mode=='EDIT'">{{'staff.competences.edit-clothing' | translate}}</h3>
            <h3 class="modal-title" *ngIf="mode=='CREATE'">{{'staff.competences.create-clothing' | translate}}</h3>

            <span (click)="createEditClothingModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <form [formGroup]="clothingForm" id="form" (ngSubmit)="onSubmitClothing()">
            <div class="form-group">
                <label for="name">{{'staff.competences.name' | translate}}</label>
                <input formControlName="name" class="form-control" type="text" id="name"/>
            </div>
            <input type="file"
                   accept="image/*"
                   formControlName="file"
                   (change)="onFileChange($event.target.files)"
                   #upload>
        </form>

        <div class="d-flex justify-content-start gap-3">
            <div class="img-wrapper"
                 *ngIf="mode=='EDIT' && this?.uniformToEdit?.imagePath && !this.clothingForm.get('fileSource').value">
                <img src="{{this?.uniformToEdit?.imagePath}}" alt="">
            </div>
            <div class="mt-auto mb-auto">
                <p class="blue-p" (click)="upload.click()"><b>{{'staff.competences.upload-img' | translate}}</b></p>
                <p class="file-name"
                   *ngIf="this.clothingForm.get('fileSource').value">{{this.clothingForm.get('fileSource').value.name}}</p>
                <!--<p class="faded-p pointer" *ngIf="mode=='EDIT'" (click)="onDeleteImage()"><b>Delete image</b></p>-->
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-between gap-2">
            <span class="delete-btn" (click)="onDeleteClothing(this.uniformToEdit)"
                  *ngIf="mode == 'EDIT'">{{'staff.competences.delete-option' | translate}}</span>
            <div class="d-flex justify-content-end gap-2">
                <span class="cancel-btn"
                      (click)="createEditClothingModal = false">{{'buttons.cancel' | translate}}</span>
                <input type="submit"
                       form="form"
                       [disabled]="(!this.clothingForm.valid && mode=='CREATE') || (mode=='EDIT' && !this.clothingForm.get('name').value)"
                       value="{{'buttons.save-changes' | translate}}"
                       class="btn save-btn btn-primary"/>
            </div>
        </div>
    </div>
</app-modal>

<!---Create/Edit option modal--->
<app-modal [modalType]="1" *ngIf="createEditOptionModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">

            <h3 class="modal-title" *ngIf="mode=='EDIT'">{{'staff.competences.edit-option' | translate}}</h3>
            <h3 class="modal-title" *ngIf="mode=='CREATE'">{{'staff.competences.create-option' | translate}}</h3>

            <span (click)="createEditOptionModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">
        <form [formGroup]="optionForm" id="option-form" (ngSubmit)="onSubmitOption()">
            <div class="form-group">
                <label for="name2">{{'staff.competences.name' | translate}}</label>
                <input formControlName="name" class="form-control" type="text" id="name2"/>
            </div>
            <input type="file"
                   accept="image/*"
                   formControlName="file"
                   (change)="onFileChange($event.target.files)"
                   #upload>
        </form>

        <div class="d-flex justify-content-start gap-3">
            <div class="img-wrapper"
                 *ngIf="mode=='EDIT' && this?.optionToEdit?.imagePath && !this.optionForm.get('fileSource').value">
                <img src="{{this?.optionToEdit?.imagePath}}" alt="">
            </div>
            <div class="mt-auto mb-auto">
                <p class="blue-p" (click)="upload.click()"><b>{{'staff.competences.upload-img' | translate}}</b></p>
                <p class="file-name"
                   *ngIf="this.optionForm.get('fileSource').value">{{this.optionForm.get('fileSource').value.name}}</p>
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-between gap-2">
            <span class="delete-btn" (click)="onDeleteOption(this.optionToEdit)"
                  *ngIf="mode == 'EDIT'">{{'staff.competences.delete-option' | translate}}</span>
            <div class="d-flex justify-content-end gap-2">
                <span class="cancel-btn"
                      (click)="createEditOptionModal = false">{{'buttons.cancel' | translate}}</span>
                <input type="submit"
                       form="option-form"
                       [disabled]="(!this.optionForm.valid && mode=='CREATE') || (mode=='EDIT' && !this.optionForm.get('name').value)"
                       value="{{'buttons.save-changes' | translate}}"
                       class="btn save-btn btn-primary"/>
            </div>
        </div>
    </div>
</app-modal>

<!---Edit skill modal--->
<app-skill-form *ngIf="showSkillForm"
                [action]="'update'"
                (destroyComponent)="showSkillForm = false"
                [skill]="skill"></app-skill-form>


<!---Add members modal--->
<app-modal [modalType]="1" *ngIf="addMembersModal">
    <div class="popup-header">
        <div class="d-flex justify-content-between gap-2">
            <h3 class="modal-title">{{'staff.competences.add-members' | translate}}</h3>
            <span (click)="addMembersModal = false" class="close">
                <img src="/assets/img/Close.svg" alt="">
            </span>
        </div>
    </div>
    <div class="popup-body mt-3 mb-3">

        <div class="search-wrapper w-100 mb-3">
            <img class="search-icon" src="/assets/img/search 1.svg">
            <input class="search-input" type="search" placeholder="Search staff members"/>
        </div>

        <div class="scrolling" id="staff-id" (scroll)="onScrollStaff($event)">
            <div [ngClass]="isSelectedMember(guard?.id) ? 'selected': ''"
                 class="member-wrapper d-flex justify-content-between"
                 *ngFor="let guard of this?.staff">
                <div class="d-flex justify-content-start gap-2">
                    <div class="profile-img-wrapper">
                        <img src="{{guard.image || '/assets/img/empty-profile-picture.png'}}" alt="">
                    </div>
                    <div class="mt-auto mb-auto">
                        <p class="mb-0">{{guard?.firstName}} {{guard?.lastName}}</p>
                    </div>
                </div>
                <input type="checkbox" class="my-checkbox"
                       [value]="guard.id"
                       (change)="onChangeMember($event.target.value, $event.target.checked)"
                       [checked]="isSelectedMember(guard.id)">
            </div>
        </div>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-end gap-2">
            <span class="cancel-btn" (click)="addMembersModal = false">{{'buttons.cancel' | translate}}</span>
            <input type="button"
                   value="{{'staff.competences.add-members' | translate}}"
                   (click)="onAddMembers()"
                   class="btn save-btn btn-primary"/>
        </div>
    </div>
</app-modal>

<!---Delete Competence--->
<app-modal [modalType]="1" *ngIf="deleteCompetenceModal">
    <div class="popup-body mt-3 d-flex flex-column justify-content-center text-center w-100">
        <h4 class="mb-0">{{'staff.competences.delete-approve' | translate}}</h4>
        <h4>{{'staff.competences.action-irreversible' | translate}}</h4>
    </div>
    <div class="popup-footer">
        <div class="d-flex justify-content-end gap-2">
            <span class="cancel-btn" (click)="deleteCompetenceModal = false">{{'buttons.cancel' | translate}}</span>
            <input type="button"
                   (click)="onDeleteCompetence()"
                   value="{{'buttons.delete' | translate}}"
                   class="btn save-btn btn-primary"/>
        </div>
    </div>
</app-modal>
