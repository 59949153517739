import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from './company.reducer';
import * as fromApp from '../../../store/app.reducer';
import * as CompanyActions from './company.actions';
import {environment} from '../../../../environments/environment';
import { catchError, delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {of} from 'rxjs';
import {EmployeeModel} from '../../../models/employee.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PaginationModel} from '../../../models/pagination.model';
import {InvoiceModel} from '../../../models/invoice.model';
import {InviteModel} from "../../../models/invite.model";
import * as AuthActions from '../../../auth/store/auth.actions';
import {BillingInformationModel} from "../../../models/billingInformation.model";
import {RegionModel} from "../../../models/region.model";
import {JobPlaceModel} from "../../../models/jobPlace.model";
import {HereSuggesstionModel} from "../../../models/hereSuggesstion.model";
import { HereLocationModel } from '../../../models/hereLocation.model';
import * as SharedActions from '../../../shared-store/shared.actions';

interface CompanyResponseData {
    employees: EmployeeModel[];
    employee: EmployeeModel;
    pagination: PaginationModel;
}

@Injectable()
export class CompanyEffects {

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private router: Router,
        private _snackBar: MatSnackBar,
        private store: Store<fromApp.AppState>,
    ) {
    }

    loadEmployees = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.LOAD_EMPLOYEES),
            withLatestFrom(this.store.select('company')),
            switchMap(([action, state]: [CompanyActions.LoadEmployees, State]) => {
                let params = new HttpParams();
                params = params.append('page', action.payload.page);
                params = params.append('per_page', 10);

                return this.http.get(environment.apiUrl + '/customers/' + action.payload.customerId + '/employees', {params})
                    .pipe(
                        switchMap((resData: { data: EmployeeModel[], pagination: PaginationModel }) => {
                            return of(new CompanyActions.LoadEmployeesSuccess({
                                employees: resData.data,
                                pagination: resData.pagination
                            }));
                        }),
                        catchError(error => {
                            return of(new CompanyActions.LoadEmployeesFailed());
                        }),
                    )
            }),
        );
    });

    inviteUsers = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.INVITE_USERS),
            switchMap((action: CompanyActions.InviteUsers) => {
                let data = action.payload.data;
                return this.http.post(environment.apiUrl + `/customers/${action.payload.customer}/invites`, {
                        data
                    })
                    .pipe(
                        switchMap((resData: { data: InviteModel[] }) => {
                            this._snackBar.open('Invitations successfully sent!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            this.router.navigate(['/components/company/users']);
                            return of(new CompanyActions.InviteUsersSuccess({invites: resData.data}));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to send invitations! Try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.InviteUsersFailed());
                        }),
                    )
            }),
        );
    });

    deleteEmployee = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.DELETE_EMPLOYEE),
            switchMap((storeData: CompanyActions.DeleteEmployee) => {
                return this.http.delete(environment.apiUrl + '/customers/' + storeData.payload.customerId + '/employees/' + storeData.payload.employeeId)
                    .pipe(
                        switchMap((resData: EmployeeModel) => {
                            this._snackBar.open('Employee is successfully deleted', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.DeleteEmployeeSuccess({employee: resData}));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to delete employee', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.DeleteEmployeeFailed());
                        }),
                    )
            }),
        );
    });

    updateEmployee = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.UPDATE_EMPLOYEE),
            switchMap((action: CompanyActions.UpdateEmployee) => {
                return this.http.put(environment.apiUrl + '/customers/' + action.payload.customerId + '/employees/' + action.payload.employeeId, {
                        employeeRoleId: action.payload.employeeRoleId
                    })
                    .pipe(
                        switchMap((resData: { data: EmployeeModel }) => {
                            this._snackBar.open('Employee is successfully updated', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.UpdateEmployeeSuccess({employee: resData.data}));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to update employee', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.UpdateEmployeeFailed());
                        }),
                    )
            }),
        );
    });

    loadInvoices = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.LOAD_INVOICES),
            switchMap((action: CompanyActions.LoadInvoices) => {
                let params = new HttpParams();
                params = params.append('page', action.payload.page);
                params = params.append('per_page', 10);
                return this.http.get(environment.apiUrl + '/customers/' + action.payload.customerId + '/invoices', {params})
                    .pipe(
                        switchMap((resData: { data: InvoiceModel[], pagination: PaginationModel }) => {
                            return of(new CompanyActions.LoadInvoicesSuccess({
                                invoices: resData.data,
                                pagination: resData.pagination
                            }));
                        }),
                        catchError(error => {
                            return of(new CompanyActions.LoadInvoicesFailed());
                        }),
                    )
            }),
        );
    });

    loadInvoice = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.LOAD_INVOICE),
            switchMap((storeData: CompanyActions.LoadInvoice) => {
                return this.http.get(environment.apiUrl + '/customers/' + storeData.payload.customerId + '/invoices/' + storeData.payload.invoiceId)
                    .pipe(
                        switchMap((resData: InvoiceModel) => {
                            return of(new CompanyActions.LoadInvoiceSuccess({invoice: resData}));
                        }),
                        catchError(error => {
                            return of(new CompanyActions.LoadInvoiceFailed());
                        }),
                    )
            }),
        );
    });

    inviteSuccess = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.ACCEPT_INVITE),
            switchMap((storeData: CompanyActions.AcceptInvite) => {
                return this.http.get(environment.apiUrl + '/invites/' + storeData.payload.inviteToken + '/accept-invite')
                    .pipe(
                        switchMap((resData: { data: EmployeeModel, jwt: {accessToken: string} }) => {

                            localStorage.setItem('jwt', resData.jwt.accessToken);

                            // return [
                            //     new AuthActions.LoginSuccess({
                            //         jwt: resData.jwt,
                            //         employee: resData.data,
                            //     }),
                            //     new AuthActions.LoadCustomers({employeeId: resData.data.id}),
                            //     new SharedActions.SendFirebaseTokenToServer(),
                            //     new CompanyActions.AcceptInviteSuccess({employee: resData.data, jwt: resData.jwt.accessToken}),
                            // ];
                            // this.store.dispatch();
                            return [
                                new CompanyActions.AcceptInviteSuccess({employee: resData.data, jwt: resData.jwt.accessToken}),
                                new AuthActions.LoginSuccess({employee: resData.data, jwt: resData.jwt}),
                                // new AuthActions.LoadCustomersSuccess({customers: resData.data.customers }),
                            ];
                        }),
                        filter((state) => state.type == AuthActions.LOGIN_SUCCESS),
                        take(1),
                        tap(() => {
                            this.router.navigate(['/components/home']);
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to accept invitation! ', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            this.router.navigate(['/']);
                            return of(new CompanyActions.AcceptInviteFailed());
                        }),
                    )
            }),
        );
    });

    loadRegions = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.LOAD_REGIONS),
            switchMap((action: CompanyActions.LoadRegions) => {
                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/regions`)
                    .pipe(
                        switchMap((resData: { data: RegionModel[] }) => {
                            return of(new CompanyActions.LoadRegionsSuccess({regions: resData.data}));
                        }),
                        catchError(error => {
                            return of(new CompanyActions.LoadRegionsFailed());
                        }),
                    )
            }),
        );
    });

    addNewRegion = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.ADD_REGION),
            switchMap((action: CompanyActions.AddNewRegion) => {
                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/regions`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: RegionModel }) => {
                            this._snackBar.open('New region successfully created!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.AddNewRegionSuccess({region: resData.data}));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to create new region! Try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.AddNewRegionFailed());
                        }),
                    )
            }),
        );
    });

    loadObjects = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.LOAD_OBJECTS),
            switchMap((action: CompanyActions.LoadObjects) => {
                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/job-places`)
                    .pipe(
                        switchMap((resData: { data: JobPlaceModel[] }) => {
                            return of(new CompanyActions.LoadObjectsSuccess({objects: resData.data}));
                        }),
                        catchError(error => {
                            return of(new CompanyActions.LoadObjectsFailed());
                        }),
                    )
            }),
        );
    });

    loadObject = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.LOAD_OBJECT),
            switchMap((action: CompanyActions.LoadObject) => {
                return this.http.get(environment.apiUrl + `/customers/${action.payload.customerId}/job-places/${action.payload.objectId}`)
                    .pipe(
                        switchMap((resData: { data: JobPlaceModel }) => {
                            return of(new CompanyActions.LoadObjectSuccess({object: resData.data}));
                        }),
                        catchError(error => {
                            return of(new CompanyActions.LoadObjectFailed());
                        }),
                    )
            }),
        );
    });

    addNewObject = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.ADD_OBJECT),
            switchMap((action: CompanyActions.AddNewObject) => {
                return this.http.post(environment.apiUrl + `/customers/${action.payload.customerId}/job-places`, action.payload.data)
                    .pipe(
                        switchMap((resData: { data: JobPlaceModel }) => {
                            this._snackBar.open('New object successfully created!', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.AddNewObjectSuccess({object: resData.data}));
                        }),
                        catchError(error => {
                            this._snackBar.open('Failed to create new object! Try again later', 'Close', {
                                duration: 3000,
                                panelClass: ['snackbar']
                            });
                            return of(new CompanyActions.AddNewObjectFailed());
                        }),
                    )
            }),
        );
    });

    addressAutocomplete = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.ADDRESS_AUTOCOMPLETE),
            switchMap((action: CompanyActions.AddressAutocomplete) => {
                return this.http.post(environment.apiUrl + `/here/geocoding/autocomplete`, {
                        address: action.payload.address
                    })
                    .pipe(
                        switchMap((resData: { data: HereSuggesstionModel[] }) => {
                            return of(new CompanyActions.AddressAutocompleteSuccess({hereSuggestions: resData.data}));
                        }),
                        catchError(error => {
                            return of(new CompanyActions.AddressAutocompleteFailed());
                        }),
                    )
            }),
        );
    });

    loadHereLocation = createEffect(() => {
        return this.actions$.pipe(
            ofType(CompanyActions.LOAD_HERE_LOCATION_BY_LOCATION_ID),
            switchMap((action: CompanyActions.LoadHereLocationByLocationId) => {
                return this.http.get(environment.apiUrl + `/here/geocoding/autocomplete/${action.payload.locationId}`)
                           .pipe(
                               switchMap((resData: HereLocationModel) => {
                                   return of(new CompanyActions.LoadHereLocationByLocationIdSuccess({
                                       hereLocation: resData
                                   }));
                               }),
                               catchError(error => {
                                   return of(new CompanyActions.LoadHereLocationByLocationIdFailed());
                               }),
                           )
            }),
        );
    });

}
