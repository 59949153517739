<div class="main-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="inner-wrapper">
                <div class="flexed">
                    <p class="faded-p">{{'assignments.title' | translate}}</p>
                    <img class="small-arrow" src="/assets/img/arrow small.svg">
                    <p class="faded-p">{{'assignments.favourites.title' | translate}}</p>
                </div>
                <h1>{{'assignments.favourites.title' | translate}}</h1>
                <div class="table-responsive scrolling">
                    <!-- TABLE -->
                    <table class="table table-hover">
                        <thead>
                        <tr>
                            <th>{{'table.thead.object' | translate}}</th>  <!--[mdbTableSort]="elements" sortBy="id" -->
                            <!--                                <th>{{'table.thead.region' | translate}}</th>-->
                            <th class="sm-hide">{{'assignments.favourites.title' | translate}}</th>
                            <th style="opacity: 0;">Arrow</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let object of this?.objects"
                            routerLink="/components/assignments/favourites/{{object?.id}}">
                            <td *ngIf="object?.name; else noData">
                                <p class="black mb-0">{{object?.name}}</p>
                                <p class="mt-0 table-faded">{{object?.address}}</p>
                            </td>
                            <!--                                <td *ngIf="object?.regions?.length > 2">-->
                            <!--                                    <div class="d-flex flex-row justify-content-start gap-2">-->
                            <!--                                        <span class="mt-auto mb-auto">{{object?.regions[0]?.name}}</span>-->
                            <!--                                        <span class="mt-auto mb-auto">{{object?.regions[1]?.name}}</span>-->
                            <!--                                        <div class="num-objects"><span>+{{object?.regions?.length - 2}}</span></div>-->
                            <!--                                    </div>-->
                            <!--                                </td>-->
                            <!--                                <td *ngIf="object?.regions?.length <= 2 && object?.regions?.length > 0">-->
                            <!--                                    <div class="d-flex flex-row justify-content-start gap-2">-->
                            <!--                                        <span class="mt-auto mb-auto">{{object?.regions[0]?.name}}</span>-->
                            <!--                                        <span class="mt-auto mb-auto">{{object?.regions[1]?.name}}</span>-->
                            <!--                                    </div>-->
                            <!--                                </td>-->
                            <!--                                <td *ngIf="!object?.regions || object?.regions?.length == 0">-</td>-->
                            <td>
                                <p class="black">{{object?.favouritesCount}}</p>
                            </td>
                            <td><img class="arrow" src="/assets/img/Arrow-right.svg"></td>
                            <ng-template #noData>
                                <td>-</td>
                            </ng-template>
                        </tr>
                        </tbody>

                    </table>
                    <!-- /TABLE -->
                </div>
            </div>
        </div>
    </div>
</div>
