import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {clearData, createData} from "./create-object";
import {Observable, of, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as fromAssignments from "../store/assignments.reducer";
import * as AssignmentsActions from '../store/assignments.actions';
import {CustomerModel} from "../../../models/customer.model";
import {selectStep} from "../store/assignment.selector";

@Component({
    selector: 'app-create-assignment',
    templateUrl: './create-assignment.component.html',
    styleUrls: ['./create-assignment.component.scss']
})
export class CreateAssignmentComponent implements OnInit, OnDestroy, AfterViewInit {

    // public step1: boolean = true;
    // public step2: boolean = false;
    // public step3: boolean = false;
    // public step4: boolean = false;
    // public step5: boolean = false;
    // public step6: boolean = false;
    // public step7: boolean = false;

    steps = [
        {
            id: 1,
            route: '/components/assignments/create-assignment/step1',
            active: true,
            iconNotActiveSrc: '/assets/img/buildings 1.svg',
            iconActiveSrc: '/assets/img/buildings 1.svg'
        },
        {
            id: 2,
            route: '/components/assignments/create-assignment/step2',
            active: false,
            iconNotActiveSrc: '/assets/img/single-neutral-actions-shield 1.svg',
            iconActiveSrc: '/assets/img/single-neutral-actions-shield 1 active (1).svg'
        },
        {
            id: 3,
            route: '/components/assignments/create-assignment/step3',
            active: false,
            iconNotActiveSrc: '/assets/img/calendar 1.svg',
            iconActiveSrc: '/assets/img/calendar 1 active (1).svg'
        },
        {
            id: 4,
            route: '/components/assignments/create-assignment/step4',
            active: false,
            iconNotActiveSrc: '/assets/img/task-list-text-1 1.svg',
            iconActiveSrc: '/assets/img/task-list-text-active.svg'
        },
        {
            id: 5,
            route: '/components/assignments/create-assignment/step5',
            active: false,
            iconNotActiveSrc: '/assets/img/shield-unlock 1.svg',
            iconActiveSrc: '/assets/img/shield-unlock-active.svg'
        },
        // {
        //     id: 6,
        //     route: '/components/assignments/create-assignment/step6',
        //     active: false,
        //     iconNotActiveSrc: '/assets/img/pie-line-graph 1.svg',
        //     iconActiveSrc: '/assets/img/pie-line-graph 1 active (1).svg'
        // },
        {
            id: 7,
            route: '/components/assignments/create-assignment/step7',
            active: false,
            iconNotActiveSrc: '/assets/img/check-circle 1.svg',
            iconActiveSrc: '/assets/img/check-circle 1 active.svg'
        },
        {
            id: 8,
            route: '/components/assignments/create-assignment/step8',
            active: false,
            iconNotActiveSrc: '/assets/img/single-neutral-actions-shield 1.svg',
            iconActiveSrc: '/assets/img/single-neutral-actions-shield 1 active (1).svg'
        },
    ];

    public subscription: Subscription;
    public customer: CustomerModel;
    public step: number = 1;
    private authSubscription: Subscription;
    private stepSubscription: Subscription;

    constructor(public router: Router,
                private cd: ChangeDetectorRef,
                private store: Store<fromApp.AppState>,) {
    }

    ngOnInit(): void {
        this.authSubscription = this.store.select('auth').subscribe(
            (auth) => {
                this.customer = auth.customer;
            });
        this.stepSubscription = this.store.pipe(select(selectStep)).subscribe(stepNumber => {
            this.step = stepNumber;
            this.onSelectedStep();
            // switch (stepNumber) {
            //     case 1:
            //         this.onStep1();
            //         break;
            //     case 2:
            //         this.onStep2();
            //         break;
            //     case 3:
            //         this.onStep3();
            //         break;
            //     case 4:
            //         this.onStep4();
            //         break;
            //     case 5:
            //         this.onStep5();
            //         break;
            //     case 6:
            //         this.onStep6();
            //         break;
            //     case 7:
            //         this.onStep7();
            //         break;
            //     case 8:
            //         this.onStep8();
            //         break;
            // }
        });
    }

    ngAfterViewInit() {
    }

    ngOnDestroy() {
        this.stepSubscription.unsubscribe();
        this.authSubscription.unsubscribe();
        this.store.dispatch(new AssignmentsActions.UpdateStep(1));
        clearData();
    }

    onSelectedStep() {
        this.steps = this.steps.map(x => {
            x.active = x.id <= this.step;
            if(x.id == this.step) this.router.navigate([x.route]);
            return x;
        })
    }

    // public onStep1(): void {
    //     this.step2 = false;
    //     this.step3 = false;
    //     this.step4 = false;
    //     this.step5 = false;
    //     this.step6 = false;
    //     this.step7 = false;
    //     this.router.navigate(['/components/assignments/create-assignment/step1']);
    // }
    //
    // public onStep2(): void {
    //     this.step2 = true;
    //     this.step3 = false;
    //     this.step4 = false;
    //     this.step5 = false;
    //     this.step6 = false;
    //     this.step7 = false;
    //     this.router.navigate(['/components/assignments/create-assignment/step2']);
    // }
    //
    // public onStep3(): void {
    //     this.step2 = true;
    //     this.step3 = true;
    //     this.step4 = false;
    //     this.step5 = false;
    //     this.step6 = false;
    //     this.step7 = false;
    //     this.router.navigate(['/components/assignments/create-assignment/step3']);
    // }
    //
    // public onStep4(): void {
    //     this.step2 = true;
    //     this.step3 = true;
    //     this.step4 = true;
    //     this.step5 = false;
    //     this.step6 = false;
    //     this.step7 = false;
    //     this.router.navigate(['/components/assignments/create-assignment/step4']);
    // }
    //
    // public onStep5(): void {
    //     this.step2 = true;
    //     this.step3 = true;
    //     this.step4 = true;
    //     this.step5 = true;
    //     this.step6 = false;
    //     this.step7 = false;
    //     this.router.navigate(['/components/assignments/create-assignment/step5']);
    // }
    //
    // public onStep6(): void {
    //     this.step2 = true;
    //     this.step3 = true;
    //     this.step4 = true;
    //     this.step5 = true;
    //     this.step6 = true;
    //     this.step7 = false;
    //     this.router.navigate(['/components/assignments/create-assignment/step6']);
    // }
    //
    // public onStep7(): void {
    //     this.step2 = true;
    //     this.step3 = true;
    //     this.step4 = true;
    //     this.step5 = true;
    //     this.step6 = true;
    //     this.step7 = true;
    //     this.router.navigate(['/components/assignments/create-assignment/step7']);
    // }
    //
    // public onStep8(): void {
    //     this.step2 = true;
    //     this.step3 = true;
    //     this.step4 = true;
    //     this.step5 = true;
    //     this.step6 = true;
    //     this.step7 = true;
    //     this.router.navigate(['/components/assignments/create-assignment/step8']);
    // }

    public onBack(): void {
        if (this.step != 1) {
            let stepToGoBack = this.step === 7 ? 5 : this.step - 1;
            this.store.dispatch(new AssignmentsActions.UpdateStep(stepToGoBack));
        } else {
            this.router.navigate(['/components/assignments']);
        }
    }

    public onSaveAsDraft(): void {
        // createData.status = 1;
        this.store.select('assignments')
            .subscribe(assignmentsStore => {
                if (assignmentsStore.isAssignmentCreatingFromHistory) {
                    createData.id = assignmentsStore.assignment.id;
                }
                this.store.dispatch(new AssignmentsActions.AddAssignmentAsDraft({
                    customerId: this.customer.id,
                    data: createData,
                }));
            })
            .unsubscribe();



    }

}

