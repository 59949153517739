import {Action} from '@ngrx/store';
import {EmployeeModel} from '../../../models/employee.model';
import {PaginationModel} from '../../../models/pagination.model';
import {InvoiceModel} from '../../../models/invoice.model';
import {InviteModel} from "../../../models/invite.model";
import {BillingInformationModel} from "../../../models/billingInformation.model";
import {RegionModel} from "../../../models/region.model";
import {JobPlaceModel} from "../../../models/jobPlace.model";
import {HereSuggesstionModel} from "../../../models/hereSuggesstion.model";
import { HereLocationModel } from '../../../models/hereLocation.model';

export const LOAD_EMPLOYEES = '[Company] Load Collection Of Employees In Company';
export const LOAD_EMPLOYEES_SUCCESS = '[Company] Load Collection Of Employees In Company Success';
export const LOAD_EMPLOYEES_FAILED = '[Company] Load Collection Of Employees In Company Fail';
export const CLEAR_EMPLOYEES = '[Company] Clear Employees';

export const INVITE_USERS = '[Company] Send Invites To Users By Company';
export const INVITE_USERS_SUCCESS = '[Company] Send Invites To Users By Company Success';
export const INVITE_USERS_FAILED = '[Company] Send Invites To Users By Company Failed';

export const ACCEPT_INVITE = '[Company] Accept Invite';
export const ACCEPT_INVITE_SUCCESS = '[Company] Accept Invite Success';
export const ACCEPT_INVITE_FAILED = '[Company] Accept Invite Failed';

export const DELETE_EMPLOYEE = '[Company] Delete Employee';
export const DELETE_EMPLOYEE_SUCCESS = '[Company] Delete Employee Success';
export const DELETE_EMPLOYEE_FAILED = '[Company] Delete Employee Failed';

export const UPDATE_EMPLOYEE = '[Company] Update Employee';
export const UPDATE_EMPLOYEE_SUCCESS = '[Company] Update Employee Success';
export const UPDATE_EMPLOYEE_FAILED = '[Company] Update Employee Failed';

export const LOAD_INVOICES = '[Company] Load Collection Of Invoices For Company';
export const LOAD_INVOICES_SUCCESS = '[Company] Load Collection Of Invoices For Company Success';
export const LOAD_INVOICES_FAILED = '[Company] Load Collection Of Invoices For Company Failed';

export const LOAD_INVOICE = '[Company] Load Invoice For Company';
export const LOAD_INVOICE_SUCCESS = '[Company] Load Invoice For Company Success';
export const LOAD_INVOICE_FAILED = '[Company] Load Invoice For Company Failed';

// export const LOAD_BILLING_COLLECTION = '[Company] Load Billing Information Collection For Company';
// export const LOAD_BILLING_COLLECTION_SUCCESS = '[Company] Load Billing Information Collection For Company Success';
// export const LOAD_BILLING_COLLECTION_FAILED = '[Company] Load Billing Information Collection For Company Failed';

// export const LOAD_BILLING_INFO = '[Company] Load Billing Information For Company';
// export const LOAD_BILLING_INFO_SUCCESS = '[Company] Load Billing Information For Company Success';
// export const LOAD_BILLING_INFO_FAILED = '[Company] Load Billing Information For Company Failed';



export const TRANSFER_OWNERSHIP = '[Company] Transfer Ownership';

export const LOAD_REGIONS = '[Company] Load All Regions For Company';
export const LOAD_REGIONS_SUCCESS = '[Company] Load All Regions For Company Success';
export const LOAD_REGIONS_FAILED = '[Company] Load All Regions For Company Failed';

export const ADD_REGION = '[Company] Add Region For Company';
export const ADD_REGION_SUCCESS = '[Company] Add Region For Company Success';
export const ADD_REGION_FAILED = '[Company] Add Region For Company Failed';

export const LOAD_OBJECTS = '[Company] Load All Objects For Company';
export const LOAD_OBJECTS_SUCCESS = '[Company] Load All Objects For Company Success';
export const LOAD_OBJECTS_FAILED = '[Company] Load All Objects For Company Failed';

export const LOAD_OBJECT = '[Company] Load Object For Company';
export const LOAD_OBJECT_SUCCESS = '[Company] Load Object For Company Success';
export const LOAD_OBJECT_FAILED = '[Company] Load Object For Company Failed';

export const ADD_OBJECT = '[Company] Add Object For Company';
export const ADD_OBJECT_SUCCESS = '[Company] Add Object For Company Success';
export const ADD_OBJECT_FAILED = '[Company] Add Object For Company Failed';

export const ADDRESS_AUTOCOMPLETE = '[Company] Collection Of Points Based On Address';
export const ADDRESS_AUTOCOMPLETE_SUCCESS = '[Company] Collection Of Points Based On Address Success';
export const ADDRESS_AUTOCOMPLETE_FAILED = '[Company] Collection Of Points Based On Address Failed';

export const LOAD_HERE_LOCATION_BY_LOCATION_ID = '[Company] Load coordinates by location id';
export const LOAD_HERE_LOCATION_BY_LOCATION_ID_SUCCESS = '[Company] Load coordinates by location id Success';
export const LOAD_HERE_LOCATION_BY_LOCATION_ID_FAILED = '[Company] Load coordinates by location id Failed';


export class LoadHereLocationByLocationId implements Action {
    readonly type = LOAD_HERE_LOCATION_BY_LOCATION_ID;

    constructor(public payload: {
        locationId: string
    }) {
    }
}

export class LoadHereLocationByLocationIdSuccess implements Action {
    readonly type = LOAD_HERE_LOCATION_BY_LOCATION_ID_SUCCESS;

    constructor(public payload: {
        hereLocation: HereLocationModel
    }) {
    }

}

export class LoadHereLocationByLocationIdFailed implements Action {
    readonly type = LOAD_HERE_LOCATION_BY_LOCATION_ID_FAILED;
}


export class LoadEmployees implements Action {
    readonly type = LOAD_EMPLOYEES;

    constructor(public payload: {
        customerId: number,
        page: number
    }) {
    }
}

export class LoadEmployeesSuccess implements Action {
    readonly type = LOAD_EMPLOYEES_SUCCESS;

    constructor(public payload: {
        employees: EmployeeModel[],
        pagination: PaginationModel
    }) {
    }
}

export class LoadEmployeesFailed implements Action {
    readonly type = LOAD_EMPLOYEES_FAILED;
}

export class InviteUsers implements Action {
    readonly type = INVITE_USERS;

    constructor(public payload: {
        employee: number,
        customer: number,
        data: any
    }) {
    }
}

export class InviteUsersSuccess implements Action {
    readonly type = INVITE_USERS_SUCCESS;

    constructor(public payload: { invites: InviteModel[] }) {
    }
}

export class InviteUsersFailed implements Action {
    readonly type = INVITE_USERS_FAILED;
}

export class ClearEmployees implements Action {
    readonly type = CLEAR_EMPLOYEES;
}

export class DeleteEmployee implements Action {
    readonly type = DELETE_EMPLOYEE;

    constructor(public payload: {
        customerId: number,
        employeeId: number,
    }) {
    }
}

export class DeleteEmployeeSuccess implements Action {
    readonly type = DELETE_EMPLOYEE_SUCCESS;

    constructor(public payload: {
        employee: EmployeeModel
    }) {
    }
}

export class DeleteEmployeeFailed implements Action {
    readonly type = DELETE_EMPLOYEE_FAILED;
}

export class LoadInvoices implements Action {
    readonly type = LOAD_INVOICES;

    constructor(public payload: {
        customerId: number,
        page: number
    }) {
    }
}

export class LoadInvoicesSuccess implements Action {
    readonly type = LOAD_INVOICES_SUCCESS;

    constructor(public payload: {
        invoices: InvoiceModel[],
        pagination: PaginationModel
    }) {
    }
}

export class LoadInvoicesFailed implements Action {
    readonly type = LOAD_INVOICES_FAILED;
}

export class LoadInvoice implements Action {
    readonly type = LOAD_INVOICE;

    constructor(public payload: {
        customerId: number,
        invoiceId: number
    }) {
    }
}

export class LoadInvoiceSuccess implements Action {
    readonly type = LOAD_INVOICE_SUCCESS;

    constructor(public payload: {
        invoice: InvoiceModel,
    }) {
    }
}

export class LoadInvoiceFailed implements Action {
    readonly type = LOAD_INVOICE_FAILED;
}

export class AcceptInvite implements Action {
    readonly type = ACCEPT_INVITE;

    constructor(public payload: { inviteToken: string }) {
    }
}

export class AcceptInviteSuccess implements Action {
    readonly type = ACCEPT_INVITE_SUCCESS;

    constructor(public payload: { employee: EmployeeModel, jwt: string }) {
    }
}

export class AcceptInviteFailed implements Action {
    readonly type = ACCEPT_INVITE_FAILED;
}

export class UpdateEmployee implements Action {
    readonly type = UPDATE_EMPLOYEE;

    constructor(public payload: {
        customerId: number,
        employeeId: number,
        employeeRoleId: number
    }) {
    }
}

export class UpdateEmployeeSuccess implements Action {
    readonly type = UPDATE_EMPLOYEE_SUCCESS;

    constructor(public payload: { employee: EmployeeModel }) {
    }
}

export class UpdateEmployeeFailed implements Action {
    readonly type = UPDATE_EMPLOYEE_FAILED;
}

export class TransferOwnership implements Action {
    readonly type = TRANSFER_OWNERSHIP;

    constructor(public payload: { loggedId: number }) {
    }
}

// export class LoadBillingInfoCollection implements Action {
//     readonly type = LOAD_BILLING_COLLECTION;
//
//     constructor(public payload: { customerId: number }) {
//     }
// }
//
// export class LoadBillingInfoCollectionSuccess implements Action {
//     readonly type = LOAD_BILLING_COLLECTION_SUCCESS;
//
//     constructor(public payload: { billings: BillingInformationModel[] }) {
//     }
// }
//
// export class LoadBillingInfoCollectionFailed implements Action {
//     readonly type = LOAD_BILLING_COLLECTION_FAILED;
// }

// export class LoadBillingInfo implements Action {
//     readonly type = LOAD_BILLING_INFO;
//
//     constructor(public payload: { customerId: number, billingId: number }) {
//     }
// }
//
// export class LoadBillingInfoSuccess implements Action {
//     readonly type = LOAD_BILLING_INFO_SUCCESS;
//
//     constructor(public payload: { billing: BillingInformationModel }) {
//     }
// }
//
// export class LoadBillingInfoFailed implements Action {
//     readonly type = LOAD_BILLING_INFO_FAILED;
// }



export class LoadRegions implements Action {
    readonly type = LOAD_REGIONS;

    constructor(public payload: { customerId: number }) {
    }
}

export class LoadRegionsSuccess implements Action {
    readonly type = LOAD_REGIONS_SUCCESS;

    constructor(public payload: { regions: RegionModel[] }) {
    }
}

export class LoadRegionsFailed implements Action {
    readonly type = LOAD_REGIONS_FAILED;
}

export class AddNewRegion implements Action {
    readonly type = ADD_REGION;

    constructor(public payload: {
        customerId: number,
        data: {
            name: string,
            jobPlacesIds: number[]
        }
    }) {
    }
}

export class AddNewRegionSuccess implements Action {
    readonly type = ADD_REGION_SUCCESS;

    constructor(public payload: { region: RegionModel }) {
    }
}

export class AddNewRegionFailed implements Action {
    readonly type = ADD_REGION_FAILED;
}

export class LoadObjects implements Action {
    readonly type = LOAD_OBJECTS;

    constructor(public payload: { customerId: number }) {
    }
}

export class LoadObjectsSuccess implements Action {
    readonly type = LOAD_OBJECTS_SUCCESS;

    constructor(public payload: { objects: JobPlaceModel[] }) {
    }
}

export class LoadObjectsFailed implements Action {
    readonly type = LOAD_OBJECTS_FAILED;
}

export class AddNewObject implements Action {
    readonly type = ADD_OBJECT;

    constructor(public payload: {
        customerId: number,
        data: {
            name: string,
            regionsIds: number[],
            locationId: string
        }
    }) {
    }
}

export class AddNewObjectSuccess implements Action {
    readonly type = ADD_OBJECT_SUCCESS;

    constructor(public payload: { object: JobPlaceModel }) {
    }
}

export class AddNewObjectFailed implements Action {
    readonly type = ADD_OBJECT_FAILED;
}

export class AddressAutocomplete implements Action {
    readonly type = ADDRESS_AUTOCOMPLETE;

    constructor(public payload: { address: string }) {
    }
}

export class AddressAutocompleteSuccess implements Action {
    readonly type = ADDRESS_AUTOCOMPLETE_SUCCESS;

    constructor(public payload: { hereSuggestions: HereSuggesstionModel[] }) {
    }
}

export class AddressAutocompleteFailed implements Action {
    readonly type = ADDRESS_AUTOCOMPLETE_FAILED;
}

export class LoadObject implements Action {
    readonly type = LOAD_OBJECT;

    constructor(public payload: { customerId: number, objectId: number }) {
    }
}

export class LoadObjectSuccess implements Action {
    readonly type = LOAD_OBJECT_SUCCESS;

    constructor(public payload: { object: JobPlaceModel }) {
    }
}

export class LoadObjectFailed implements Action {
    readonly type = LOAD_OBJECT_FAILED;
}

export type CompanyActions = LoadEmployees
    | LoadEmployeesSuccess
    | LoadEmployeesFailed
    | ClearEmployees
    | DeleteEmployee
    | DeleteEmployeeSuccess
    | DeleteEmployeeFailed
    | LoadInvoices
    | LoadInvoicesSuccess
    | LoadInvoicesFailed
    | LoadInvoice
    | LoadInvoiceSuccess
    | LoadInvoiceFailed
    | InviteUsers
    | InviteUsersSuccess
    | InviteUsersFailed
    | AcceptInvite
    | AcceptInviteSuccess
    | AcceptInviteFailed
    | UpdateEmployee
    | UpdateEmployeeSuccess
    | UpdateEmployeeFailed
    | TransferOwnership
    | LoadRegions
    | LoadRegionsSuccess
    | LoadRegionsFailed
    | AddNewRegion
    | AddNewRegionSuccess
    | AddNewRegionFailed
    | LoadObjects
    | LoadObjectsSuccess
    | LoadObjectsFailed
    | AddNewObject
    | AddNewObjectSuccess
    | AddNewObjectFailed
    | AddressAutocomplete
    | AddressAutocompleteSuccess
    | AddressAutocompleteFailed
    | LoadObject
    | LoadObjectSuccess
    | LoadObjectFailed
    | LoadHereLocationByLocationId
    | LoadHereLocationByLocationIdSuccess
    | LoadHereLocationByLocationIdFailed
    ;
