import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../store/app.reducer';
import { createData } from '../create-object';
import * as AssignmentsActions from '../../store/assignments.actions';
import * as CompanyActions from '../../../company/store/company.actions';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../../models/customer.model';
import { BillingInformationModel } from '../../../../models/billingInformation.model';
import * as AuthActions from '../../../../auth/store/auth.actions';

@Component({
    selector: 'app-step8',
    templateUrl: './step8.component.html',
    styleUrls: ['./step8.component.scss'],
})
export class Step8Component implements OnInit, OnDestroy {

    public subscription: Subscription;
    public customer: CustomerModel;

    constructor(private store: Store<fromApp.AppState>,
                private fb: FormBuilder) {
    }

    public storeBillingInformation = this.fb.group({
        companyName: ['', [Validators.required]],
        companyNumber: ['', [
            Validators.required,
            Validators.pattern('[0-9]{10}'),
        ]],
        address: ['', [Validators.required]],
        addressSecond: ['', []],
        postalCode: ['', [Validators.required]],
        city: ['', [Validators.required]],
        contactName: ['', [Validators.required]],
        phone: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
    });

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onSave(): void {

        if (this.storeBillingInformation.invalid) {
            Object.keys(this.storeBillingInformation.controls).forEach(key => {
                this.storeBillingInformation.controls[key].markAsDirty();
            });
            return;
        }

        let billingInformation = new BillingInformationModel();

        this.storeBillingInformation['_forEachChild']((control, name) => {
            if (control.dirty) {
                billingInformation[name] = control.value;
            }
        });

        this.store.dispatch(new AuthActions.CreateBillingInfo({
            customerId: this.customer.id,
            ...billingInformation,
        }));

        this.store.dispatch(new AssignmentsActions.AddAssignment({
            customerId: this.customer.id,
            data: createData,
        }));
    }
}
