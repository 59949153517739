import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {BehaviorSubject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../store/app.reducer';
import * as SharedActions from '../shared-store/shared.actions';
import {mergeMapTo} from 'rxjs/operators';
import {ActivatedRoute, Router} from "@angular/router";
import * as MessageActions from "../components/messages/store/messages.actions";
import { EventEmitter } from '@angular/core';


@Injectable()
export class MessagingService {
    currentMessage = new BehaviorSubject(null);
    messageEvent = new EventEmitter();

    constructor(private angularFireMessaging: AngularFireMessaging,
                private store: Store<fromApp.AppState>,
                private route: ActivatedRoute,
                private router: Router,
    ) {
        navigator.serviceWorker.addEventListener('message', event => {
            this.messageEvent.emit(event.data);
            this.currentMessage.next(event.data);
            switch (event.data.messageType) {
                case 'notification-clicked':
                    this.router.navigate(['/components/messages']);
                    break
            }
            if (this.router.url != '/components/messages') {
                this.store.dispatch(new MessageActions.UpdateConversationUnseenCount({
                    conversationId: event.data.conversationId,
                    count: 1,
                }));
            }
        });
        // this.angularFireMessaging.messages.subscribe(
        //     (messaging: AngularFireMessaging) => {
        //         messaging.onMessage = messaging.onMessage.bind(messaging);
        //         messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
        //     },
        // );
    }

    requestPermission(): void {
        this.angularFireMessaging.requestPermission
            .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges))
            .subscribe(
                (token) => {
                    this.store.dispatch(new SharedActions.SetFirebaseTokenForPushNotifications(token));
                },
                (error) => {
                    // console.error(error);
                },
            );
        // this.angularFireMessaging.requestToken.subscribe(
        //     (token) => {
        //         // console.log(token);
        //     },
        //     (err) => {
        //         console.error('Unable to get permission to notify.', err);
        //     },
        // );
    }

    receiveMessage(): void {
        // this.angularFireMessaging.messages.subscribe(
        //     (payload) => {
        //         console.log('new message received. ', payload);
        //         this.messageEvent.emit(payload);
        //         this.currentMessage.next(payload);
        //     });
    }
}
