import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {LoginEmailStep2Component} from './auth/login-email-step2/login-email-step2.component';
import {LoginEmailComponent} from './auth/login-email/login-email.component';
import {LoginPasswordComponent} from './auth/login-password/login-password.component';
import {RegisterStep2Component} from './auth/register-step2/register-step2.component';
import {AssignmentsComponent} from './components/assignments/assignments.component';
import {AssignmentOrderedComponent} from './components/assignments/create-assignment/assignment-ordered/assignment-ordered.component';
import {CreateAssignmentComponent} from './components/assignments/create-assignment/create-assignment.component';
import {Step1Component} from './components/assignments/create-assignment/step1/step1.component';
import {Step2Component} from './components/assignments/create-assignment/step2/step2.component';
import {Step3Component} from './components/assignments/create-assignment/step3/step3.component';
import {Step4Component} from './components/assignments/create-assignment/step4/step4.component';
import {Step5Component} from './components/assignments/create-assignment/step5/step5.component';
import {Step6Component} from './components/assignments/create-assignment/step6/step6.component';
import {Step7Component} from './components/assignments/create-assignment/step7/step7.component';
import {Step8Component} from './components/assignments/create-assignment/step8/step8.component';
import {FavouritesObjectComponent} from './components/assignments/favourites-object/favourites-object.component';
import {FavouritesComponent} from './components/assignments/favourites/favourites.component';

import {BillingComponent} from './components/company/billing/billing.component';
import {CompanyComponent} from './components/company/company.component';
import {RegionsComponent} from './components/company/regions/regions.component';
import {SettingsComponent} from './components/company/settings/settings.component';
import {UsersComponent} from './components/company/users/users.component';
import {ComponentsComponent} from './components/components.component';
import {HelpComponent} from './components/help/help.component';
import {HomeComponent} from './components/home/home.component';
import {InviteUsersComponent} from './components/invite-users/invite-users.component';
import {ProfileComponent} from './components/profile/profile.component';
import {ReportTypesComponent} from './components/reports/report-types/report-types.component';
import {ReportComponent} from './components/reports/report/report.component';
import {ReportsDetailsComponent} from './components/reports/reports-details/reports-details.component';
import {ReportsComponent} from './components/reports/reports.component';
import {EmailVerificationComponent} from "./auth/email-verification/email-verification.component";
import {MessagesComponent} from "./components/messages/messages.component";
import {LoginOverLinkComponent} from "./auth/login-over-link/login-over-link.component";
import {AuthGuard} from "./guards/auth.guard";
import {AuthEmployeeGuard} from "./guards/auth-employee.guard";
import {AcceptInviteComponent} from "./components/company/accept-invite/accept-invite.component";
import {AssignmentDetailsComponent} from "./components/assignments/assignment-details/assignment-details.component";
import {EmployeeResolverResolver} from "./components/employee-resolver.resolver";
import {MyStaffComponent} from "./components/my-staff/my-staff.component";
import {CompetencesComponent} from "./components/my-staff/competences/competences.component";
import {PayrollComponent} from "./components/my-staff/payroll/payroll.component";
import {InviteStaffComponent} from "./components/my-staff/invite-staff/invite-staff.component";
import {StaffProfileComponent} from "./components/my-staff/staff-profile/staff-profile.component";
import {CompetenceDetailsComponent} from "./components/my-staff/competence-details/competence-details.component";
import {InviteManualComponent} from "./components/my-staff/invite-staff/invite-manual/invite-manual.component";
import {SendInvitesComponent} from "./components/my-staff/invite-staff/send-invites/send-invites.component";
import {GuardAcceptInviteComponent} from "./components/my-staff/guard-accept-invite/guard-accept-invite.component";
import { CreateCompanyComponent } from './components/company/create-company/create-company.component';

const routes: Routes = [
    {path: '', component: AuthComponent, canActivate: [AuthGuard]},
    {path: 'register/security-code', component: RegisterStep2Component, canActivate: [AuthGuard]},
    {path: 'login-password', component: LoginPasswordComponent, canActivate: [AuthGuard]},
    {path: 'invite', component: LoginPasswordComponent, canActivate: [AuthGuard]},
    {path: 'login-email', component: LoginEmailComponent, canActivate: [AuthGuard]},
    {path: 'login-email-step2', component: LoginEmailStep2Component, canActivate: [AuthGuard]},
    {path: 'verify-new-email', component: EmailVerificationComponent},
    {path: 'login-over-link', component: LoginOverLinkComponent},
    {path: 'accept-invite', component: AcceptInviteComponent},
    {path: 'guard/accept-invite', component: GuardAcceptInviteComponent},

    {
        path: 'components', component: ComponentsComponent, canActivate: [AuthEmployeeGuard], children: [
            {path: 'home', component: HomeComponent, resolve: [EmployeeResolverResolver]},
            {path: 'company', component: CreateCompanyComponent, resolve: [EmployeeResolverResolver]},
            {path: 'profile', component: ProfileComponent},
            {path: 'help', component: HelpComponent},
            {path: 'messages', component: MessagesComponent, resolve: [EmployeeResolverResolver]},
            {path: 'assignments', component: AssignmentsComponent, resolve: [EmployeeResolverResolver]},
            {path: 'assignments/favourites', component: FavouritesComponent, resolve: [EmployeeResolverResolver]},
            {path: 'assignments/assignment-ordered', component: AssignmentOrderedComponent},

            {
                path: 'assignments/create-assignment', component: CreateAssignmentComponent, children: [
                    {path: 'step1', component: Step1Component, resolve: [EmployeeResolverResolver]},
                    {path: 'step2', component: Step2Component, resolve: [EmployeeResolverResolver]},
                    {path: 'step3', component: Step3Component},
                    {path: 'step4', component: Step4Component, resolve: [EmployeeResolverResolver]},
                    {path: 'step5', component: Step5Component, resolve: [EmployeeResolverResolver]},
                    {path: 'step6', component: Step6Component, resolve: [EmployeeResolverResolver]},
                    {path: 'step7', component: Step7Component, resolve: [EmployeeResolverResolver]},
                    {path: 'step8', component: Step8Component, resolve: [EmployeeResolverResolver]},
                ]
            },
            {path: 'assignments/assignment/:id', component: AssignmentDetailsComponent, resolve: [EmployeeResolverResolver]},
            {path: 'assignments/favourites/:id', component: FavouritesObjectComponent, resolve: [EmployeeResolverResolver]},
            {path: 'company/invite-users', component: InviteUsersComponent},

            {
                path: 'company', component: CompanyComponent, children: [
                    {path: 'users', component: UsersComponent, resolve: [EmployeeResolverResolver]},
                    {path: 'billing', component: BillingComponent, resolve: [EmployeeResolverResolver]},
                    {path: 'regions', component: RegionsComponent, resolve: [EmployeeResolverResolver]},
                    {path: 'settings', component: SettingsComponent, resolve: [EmployeeResolverResolver]},
                ]
            },

            {
                path: 'reports', component: ReportsComponent, children: [
                    {path: 'reports-details', component: ReportsDetailsComponent, resolve: [EmployeeResolverResolver]},
                    {path: 'report/:id', component: ReportComponent, resolve: [EmployeeResolverResolver]},
                    {path: 'report-types', component: ReportTypesComponent}
                ]
            },
            {path: 'my-staff', component: MyStaffComponent, resolve: [EmployeeResolverResolver]},
            {path: 'my-staff/profile/:id', component: StaffProfileComponent, resolve: [EmployeeResolverResolver]},
            {path: 'my-staff/competences', component: CompetencesComponent, resolve: [EmployeeResolverResolver]},
            {path: 'my-staff/competences/:id', component: CompetenceDetailsComponent, resolve: [EmployeeResolverResolver]},
            {path: 'my-staff/payroll', component: PayrollComponent, resolve: [EmployeeResolverResolver]},
            {path: 'my-staff/invite', component: InviteStaffComponent, resolve: [EmployeeResolverResolver]},
            {path: 'my-staff/invite/:id', component: InviteManualComponent},
            {path: 'my-staff/send-invites', component: SendInvitesComponent},
        ]
    },
    // {path: '**', component: NotFoundComponent},

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'top',
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
