import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerModel } from '../../../models/customer.model';
import { SkillModel } from '../../../models/skill.model';
import * as SharedActions from '../../../shared-store/shared.actions';
import { PaginationModel } from '../../../models/pagination.model';

@Component({
    selector: 'app-competences',
    templateUrl: './competences.component.html',
    styleUrls: ['./competences.component.scss'],
})
export class CompetencesComponent implements OnInit, OnDestroy {

    // competenceModal = false;
    showSkillForm = false;
    subscription: Subscription;
    customer: CustomerModel;
    skills: SkillModel[];
    pagination: PaginationModel;

    constructor(private store: Store<fromApp.AppState>,
                private _snackBar: MatSnackBar) {

    }

    ngOnInit(): void {
        this.subscription = this.store.subscribe(appState => {
            this.customer = appState.auth.customer;
            this.skills = appState.shared.skills;
            this.pagination = appState.shared.pagination;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onCreateCompetence(): void {
        // this.competenceModal = true;
        // this.skillForm.reset();
        // this.skillForm.get(['pricing', 'isRequiredCertificate']).setValue('0');
    }



}
